import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NewButton} from 'letrus-ui';
import {useHistory} from 'react-router-dom';
import styles from './Header.module.scss';

interface HeaderProps {
  title: string;
  button?: {
    text: string;
    onClick(): void;
  };
  children?: JSX.Element;
}

function Header({title, button, children}: HeaderProps): JSX.Element {
  const history = useHistory();

  return (
    <header className={styles.Header}>
      <div className={styles.topPart}>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className={styles.goBackButton}
          aria-label="Voltar"
        >
          <FontAwesomeIcon icon="chevron-left" color="#FF4410" size="2x" />
        </button>

        <h1 className={styles.title}>{title}</h1>

        {button && (
          <NewButton
            outline
            size="small"
            text={button.text}
            type="button"
            userRole="student-sae"
            kind="secondary"
            onClick={button.onClick}
          />
        )}
      </div>

      {children && <div className={styles.bottomPart}>{children}</div>}
    </header>
  );
}

export default Header;
