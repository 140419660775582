const mobile = {
  android: () => navigator.userAgent.match(/Android/i),
  blackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  ios: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  opera: () => navigator.userAgent.match(/Opera Mini/i),
  windows: () =>
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/WPDesktop/i),
  any: () =>
    mobile.android() ||
    mobile.blackBerry() ||
    mobile.ios() ||
    mobile.opera() ||
    mobile.windows(),
};

interface UseMobileReturn {
  isMobile: boolean;
  isIos: boolean;
  isAndroid: boolean;
}

export const useMobile = (): UseMobileReturn => {
  return {
    isMobile: !!mobile.any(),
    isIos: !!mobile.ios(),
    isAndroid: !!mobile.android(),
  };
};
