import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {action} from 'typesafe-actions';
import {
  studentParentFetchStudentParentByStudentId,
  studentParentsFetchListMembers,
  studentParentsFetchListSchoolGroupsBySchoolId,
  studentParentsFetchStudentInfo,
  studentParentsFetchTerms,
  studentParentsUpdateStudentParentByStudentId,
} from '../../services/studentParentServices';

// Action Types
export enum StudentParentsTypes {
  FETCH_TERMS_REQUEST = '@newStudentParents/FETCH_TERMS_REQUEST',
  FETCH_TERMS_SUCCESS = '@newStudentParents/FETCH_TERMS_SUCCESS',
  FETCH_TERMS_FAILURE = '@newStudentParents/FETCH_TERMS_FAILURE',

  FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_REQUEST = '@newStudentParents/FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_REQUEST',
  FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_SUCCESS = '@newStudentParents/FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_SUCCESS',
  FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_FAILURE = '@newStudentParents/FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_FAILURE',

  FETCH_LIST_MEMBERS_REQUEST = '@newStudentParents/FETCH_LIST_MEMBERS_REQUEST',
  FETCH_LIST_MEMBERS_SUCCESS = '@newStudentParents/FETCH_LIST_MEMBERS_SUCCESS',
  FETCH_LIST_MEMBERS_FAILURE = '@newStudentParents/FETCH_LIST_MEMBERS_FAILURE',

  FETCH_STUDENT_INFO_REQUEST = '@newStudentParents/FETCH_STUDENT_INFO_REQUEST',
  FETCH_STUDENT_INFO_SUCCESS = '@newStudentParents/FETCH_STUDENT_INFO_SUCCESS',
  FETCH_STUDENT_INFO_FAILURE = '@newStudentParents/FETCH_STUDENT_INFO_FAILURE',

  UPDATE_STUDENT_PARENT_BY_STUDENT_ID_REQUEST = '@newStudentParents/UPDATE_STUDENT_PARENT_BY_STUDENT_ID_REQUEST',
  UPDATE_STUDENT_PARENT_BY_STUDENT_ID_SUCCESS = '@newStudentParents/UPDATE_STUDENT_PARENT_BY_STUDENT_ID_SUCCESS',
  UPDATE_STUDENT_PARENT_BY_STUDENT_ID_FAILURE = '@newStudentParents/UPDATE_STUDENT_PARENT_BY_STUDENT_ID_FAILURE',

  FETCH_STUDENT_PARENT_BY_STUDENT_ID_REQUEST = '@newStudentParents/FETCH_STUDENT_PARENT_BY_STUDENT_ID_REQUEST',
  FETCH_STUDENT_PARENT_BY_STUDENT_ID_SUCCESS = '@newStudentParents/FETCH_STUDENT_PARENT_BY_STUDENT_ID_SUCCESS',
  FETCH_STUDENT_PARENT_BY_STUDENT_ID_FAILURE = '@newStudentParents/FETCH_STUDENT_PARENT_BY_STUDENT_ID_FAILURE',
}

export interface StudentParentsState extends Map<string, any> {
  readonly error: boolean;
  readonly isLoadingStudentInfo: boolean;
  readonly isLoadingUpdateStudentParent: boolean;
  readonly studentParent?: ImmutableMap<LetrusApi.StudentParent>;
  readonly studentInfo?: ImmutableMap<any>;
  readonly schoolGroups?: List<ImmutableMap<LetrusApi.SchoolGroupList>>;
  readonly isLoadingSchoolGroups: boolean;
  readonly schoolGroupMembers?: List<ImmutableMap<LetrusApi.SchoolGroup>>;
  readonly isLoadingSchoolGroupMembers: boolean;
}

// Request actions
export const fetchTermsRequest = () =>
  action(StudentParentsTypes.FETCH_TERMS_REQUEST);

export const fetchTermsSuccess = (data: any) =>
  action(StudentParentsTypes.FETCH_TERMS_SUCCESS, data);

export const fetchTermsFailure = () =>
  action(StudentParentsTypes.FETCH_TERMS_FAILURE);

export const fetchListSchoolGroupsBySchoolIdRequest = (schoolId: number) =>
  action(StudentParentsTypes.FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_REQUEST, {
    schoolId,
  });

export const fetchListSchoolGroupsBySchoolIdSuccess = (data: any) =>
  action(StudentParentsTypes.FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_SUCCESS, {
    data,
  });

export const fetchListSchoolGroupsBySchoolIdFailure = () =>
  action(StudentParentsTypes.FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_FAILURE);

export const fetchListMembersRequest = (schoolGroupId: number) =>
  action(StudentParentsTypes.FETCH_LIST_MEMBERS_REQUEST, {schoolGroupId});

export const fetchListMembersSuccess = (data: any) =>
  action(StudentParentsTypes.FETCH_LIST_MEMBERS_SUCCESS, {data});

export const fetchListMembersFailure = () =>
  action(StudentParentsTypes.FETCH_LIST_MEMBERS_FAILURE);

export const fetchStudentInfoRequest = (userId: number) =>
  action(StudentParentsTypes.FETCH_STUDENT_INFO_REQUEST, {userId});

export const fetchStudentInfoSuccess = (data: any) =>
  action(StudentParentsTypes.FETCH_STUDENT_INFO_SUCCESS, {data});

export const fetchStudentInfoFailure = () =>
  action(StudentParentsTypes.FETCH_STUDENT_INFO_FAILURE);

export const updateStudentParentByStudentIdRequest = (
  studentId: number,
  parentData: any,
) =>
  action(StudentParentsTypes.UPDATE_STUDENT_PARENT_BY_STUDENT_ID_REQUEST, {
    studentId,
    parentData,
  });

export const updateStudentParentByStudentIdSuccess = (data: any) =>
  action(StudentParentsTypes.UPDATE_STUDENT_PARENT_BY_STUDENT_ID_SUCCESS, {
    data,
  });

export const updateStudentParentByStudentIdFailure = () =>
  action(StudentParentsTypes.UPDATE_STUDENT_PARENT_BY_STUDENT_ID_FAILURE);

export const fetchStudentParentByStudentIdRequest = (studentId: number) =>
  action(StudentParentsTypes.FETCH_STUDENT_PARENT_BY_STUDENT_ID_REQUEST, {
    studentId,
  });

export const fetchStudentParentByStudentIdSuccess = (data: any) =>
  action(StudentParentsTypes.FETCH_STUDENT_PARENT_BY_STUDENT_ID_SUCCESS, {
    data,
  });

export const fetchStudentParentByStudentIdFailure = () =>
  action(StudentParentsTypes.FETCH_STUDENT_PARENT_BY_STUDENT_ID_FAILURE);

// Sagas
export function* fetchTerms() {
  try {
    const response = yield call(studentParentsFetchTerms);
    yield put(fetchTermsSuccess(response.data));
  } catch (err) {
    yield put(fetchTermsFailure());
  }
}

export function* fetchListSchoolGroupsBySchoolId(action: AnyAction) {
  try {
    const response = yield call(
      studentParentsFetchListSchoolGroupsBySchoolId,
      action.payload.schoolId,
    );
    yield put(fetchListSchoolGroupsBySchoolIdSuccess(response.data));
  } catch (err) {
    yield put(fetchListSchoolGroupsBySchoolIdFailure());
  }
}

export function* fetchListMembers(action: AnyAction) {
  try {
    const response = yield call(
      studentParentsFetchListMembers,
      action.payload.schoolGroupId,
    );
    yield put(fetchListMembersSuccess(response.data));
  } catch (err) {
    yield put(fetchListMembersFailure());
  }
}

export function* fetchStudentInfo(action: AnyAction) {
  try {
    const response = yield call(
      studentParentsFetchStudentInfo,
      action.payload.userId,
    );
    yield put(fetchStudentInfoSuccess(response.data));
  } catch (err) {
    yield put(fetchStudentInfoFailure());
  }
}

export function* fetchStudentParentByStudentId(action: AnyAction) {
  try {
    const response = yield call(
      studentParentFetchStudentParentByStudentId,
      action.payload.studentId,
    );
    yield put(fetchStudentParentByStudentIdSuccess(response.data));
  } catch (err) {
    yield put(fetchStudentParentByStudentIdFailure());
  }
}

export function* updateStudentParentByStudentId(action: AnyAction) {
  try {
    const response = yield call(
      studentParentsUpdateStudentParentByStudentId,
      action.payload.studentId,
      action.payload.parentData,
    );
    yield put(updateStudentParentByStudentIdSuccess(response.data));
  } catch (err) {
    yield put(updateStudentParentByStudentIdFailure());
  }
}

export function updatePreSubscription(
  studentId: number,
  selectedAdvancedPlan: boolean,
) {
  const payload = {
    pre_subscription: selectedAdvancedPlan ? 'completo' : 'basico',
    authorization: selectedAdvancedPlan ? 1 : 2,
    authorization_datetime: new Date(),
  };

  return updateStudentParentByStudentIdRequest(studentId, payload);
}

export const INITIAL_STATE: StudentParentsState = fromJS({
  error: false,
  isLoadingStudentInfo: false,
  isLoadingUpdateStudentParent: false,
  studentParent: null,
  studentInfo: null,
  schoolGroups: fromJS([]),
  isLoadingSchoolGroups: false,
  schoolGroupMembers: fromJS([]),
  isLoadingSchoolGroupMembers: false,
});

// Selectors
const studentParentsDataSelector = (state) => state.get('studentParents');

export const getSchoolGroupsMembers = createSelector(
  studentParentsDataSelector,
  (studentParents) => studentParents.get('schoolGroupMembers'),
);

export const getStudentInfo = createSelector(
  studentParentsDataSelector,
  (studentParents) => studentParents.get('studentInfo'),
);

export const isLoadingSchoolGroupMembers = createSelector(
  studentParentsDataSelector,
  (studentParents) => studentParents.get('isLoadingSchoolGroupMembers'),
);

export const reducer: Reducer<StudentParentsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case StudentParentsTypes.UPDATE_STUDENT_PARENT_BY_STUDENT_ID_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUpdateStudentParent', true).set('error', false),
      );
    }

    case StudentParentsTypes.UPDATE_STUDENT_PARENT_BY_STUDENT_ID_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingUpdateStudentParent', false)
          .set('error', false)
          .set('studentParent', fromJS(action.payload.data.results)),
      );
    }

    case StudentParentsTypes.UPDATE_STUDENT_PARENT_BY_STUDENT_ID_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUpdateStudentParent', false).set('error', true),
      );
    }
    // fetchListSchoolGroupsBySchoolId
    case StudentParentsTypes.FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingSchoolGroups', true).set('error', false),
      );
    }

    case StudentParentsTypes.FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingSchoolGroups', false)
          .set('error', false)
          .set('schoolGroups', fromJS(action.payload.data.results)),
      );
    }

    case StudentParentsTypes.FETCH_LIST_SCHOOL_GROUPS_BY_SCHOOL_ID_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingSchoolGroups', false).set('error', true),
      );
    }

    case StudentParentsTypes.FETCH_LIST_MEMBERS_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingSchoolGroupMembers', true).set('error', false),
      );
    }

    case StudentParentsTypes.FETCH_LIST_MEMBERS_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingSchoolGroupMembers', false)
          .set('error', false)
          .set('schoolGroupMembers', fromJS(action.payload.data.students)),
      );
    }

    case StudentParentsTypes.FETCH_LIST_MEMBERS_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingSchoolGroupMembers', false).set('error', true),
      );
    }

    case StudentParentsTypes.FETCH_STUDENT_INFO_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingStudentInfo', true).set('error', false),
      );
    }

    case StudentParentsTypes.FETCH_STUDENT_INFO_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingStudentInfo', false)
          .set('error', false)
          .set('studentInfo', fromJS(action.payload.data)),
      );
    }

    case StudentParentsTypes.FETCH_STUDENT_INFO_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingStudentInfo', false).set('error', true),
      );
    }

    case StudentParentsTypes.FETCH_TERMS_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTerms', true).set('error', false),
      );
    }

    case StudentParentsTypes.FETCH_TERMS_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingTerms', false)
          .set('error', false)
          .set('terms', fromJS(action.payload.data.results)),
      );
    }

    case StudentParentsTypes.FETCH_TERMS_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTerms', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
