import {fetchFeatureFlagService} from 'store/services/featureFlagServices';

export default async function isMaintenanceMode(): Promise<boolean> {
  const {
    data: {variantKey},
  } = await fetchFeatureFlagService({
    flagKey: 'maintenance_mode',
  });

  return variantKey === 'on';
}
