import api from './api';

export const addLikeInGeneralAnnotationService = (
  reviewAnnotationId: string | number,
  like: boolean,
) =>
  api(`/api/v1/general_annotations/${reviewAnnotationId}/like_info/`, {
    method: 'patch',
    data: {
      like_info: like,
    },
  });
