import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';
import {GetParams} from '../../services/api';
import {
  fetchSchoolGroupsBySchoolService,
  fetchSchoolGroupsBySchoolYearService,
  fetchSchoolGroupService,
  fetchSchoolGroupsService,
  fetchSchoolsService,
  fetchStudentListParams,
  fetchStudentsBySchoolGroupService,
  resetStudentPasswordService,
} from '../../services/schoolGroupServices';

// Action types
export enum SchoolGroupsTypes {
  FETCH_SCHOOLS_REQUEST = '@newSchoolGroups/FETCH_SCHOOLS_REQUEST',
  FETCH_SCHOOLS_SUCCESS = '@newSchoolGroups/FETCH_SCHOOLS_SUCCESS',
  FETCH_SCHOOLS_FAILURE = '@newSchoolGroups/FETCH_SCHOOLS_FAILURE',

  FETCH_SCHOOL_GROUPS_REQUEST = '@newSchoolGroups/FETCH_SCHOOL_GROUPS_REQUEST',
  FETCH_SCHOOL_GROUPS_SUCCESS = '@newSchoolGroups/FETCH_SCHOOL_GROUPS_SUCCESS',
  FETCH_SCHOOL_GROUPS_FAILURE = '@newSchoolGroups/FETCH_SCHOOL_GROUPS_FAILURE',

  RESET_STUDENT_PASSWORD_REQUEST = '@newSchoolGroups/RESET_STUDENT_PASSWORD_REQUEST',
  RESET_STUDENT_PASSWORD_SUCCESS = '@newSchoolGroups/RESET_STUDENT_PASSWORD_SUCCESS',
  RESET_STUDENT_PASSWORD_FAILURE = '@newSchoolGroups/RESET_STUDENT_PASSWORD_FAILURE',

  FETCH_BY_SCHOOL_YEAR_REQUEST = '@newSchoolGroups/FETCH_BY_SCHOOL_YEAR_REQUEST',
  FETCH_BY_SCHOOL_YEAR_SUCCESS = '@newSchoolGroups/FETCH_BY_SCHOOL_YEAR_SUCCESS',
  FETCH_BY_SCHOOL_YEAR_FAILURE = '@newSchoolGroups/FETCH_BY_SCHOOL_YEAR_FAILURE',

  FETCH_BY_SCHOOL_REQUEST = '@newSchoolGroups/FETCH_BY_SCHOOL_REQUEST',
  FETCH_BY_SCHOOL_SUCCESS = '@newSchoolGroups/FETCH_BY_SCHOOL_SUCCESS',
  FETCH_BY_SCHOOL_FAILURE = '@newSchoolGroups/FETCH_BY_SCHOOL_FAILURE',

  FETCH_STUDENTS_BY_SCHOOL_GROUP_REQUEST = '@newSchoolGroups/FETCH_STUDENTS_BY_SCHOOL_GROUP_REQUEST',
  FETCH_STUDENTS_BY_SCHOOL_GROUP_SUCCESS = '@newSchoolGroups/FETCH_STUDENTS_BY_SCHOOL_GROUP_SUCCESS',
  FETCH_STUDENTS_BY_SCHOOL_GROUP_FAILURE = '@newSchoolGroups/FETCH_STUDENTS_BY_SCHOOL_GROUP_FAILURE',

  FETCH_SCHOOL_GROUP_REQUEST = '@newSchoolGroups/FETCH_SCHOOL_GROUP_REQUEST',
  FETCH_SCHOOL_GROUP_SUCCESS = '@newSchoolGroups/FETCH_SCHOOL_GROUP_SUCCESS',
  FETCH_SCHOOL_GROUP_FAILURE = '@newSchoolGroups/FETCH_SCHOOL_GROUP_FAILURE',

  SET_SELECTED_SCHOOL_GROUP = '@newSchoolGroups/SET_SELECTED_SCHOOL_GROUP',
}

// State type
export interface SchoolGroupsState extends Map<string, any> {
  readonly data?: List<ImmutableMap<LetrusApi.SchoolGroup>>;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly dataCount: number;
  readonly selectedSchoolGroup?: ImmutableMap<LetrusApi.SchoolGroup>;
  readonly schools: List<ImmutableMap<LetrusApi.School>> | undefined;
  readonly schoolsCount: number;
  readonly isLoadingSchools: boolean;
  readonly schoolGroupsBySchoolYear: List<ImmutableMap<LetrusApi.SchoolGroup>>;
  readonly isLoadingSchoolsGroupBySchoolYear: boolean;
  readonly schoolGroupListRequestStatus: StoreStatus;

  readonly studentsBySchoolGroup: List<ImmutableMap<any>>;
  readonly totalOfStudents: number;
  readonly studentListRequestStatus: StoreStatus;
  readonly isLoadingStudentsBySchoolGroup: boolean;
  readonly schoolGroupData: List<ImmutableMap<LetrusApi.SchoolGroup>>;
  readonly schoolGroupLoading: boolean;
  readonly schoolGroupError: boolean;
  readonly isResetingStudentPassword: boolean;
  readonly hasFinishedResetStudentPassword: boolean;
  readonly resetStudentPasswordError: boolean;
}

// Fetch actions
export const fetchSchoolsRequest = () =>
  action(SchoolGroupsTypes.FETCH_SCHOOLS_REQUEST);

export const fetchSchoolsSuccess = (data: LetrusApi.School[]) =>
  action(SchoolGroupsTypes.FETCH_SCHOOLS_SUCCESS, {data});

export const fetchSchoolsFailure = () =>
  action(SchoolGroupsTypes.FETCH_SCHOOLS_FAILURE);

export const fetchSchoolGroupsRequest = (params?: GetParams) =>
  action(SchoolGroupsTypes.FETCH_SCHOOL_GROUPS_REQUEST, {params});

export const fetchSchoolGroupsSuccess = (data: LetrusApi.SchoolGroup[]) =>
  action(SchoolGroupsTypes.FETCH_SCHOOL_GROUPS_SUCCESS, {data});

export const fetchSchoolGroupsFailure = () =>
  action(SchoolGroupsTypes.FETCH_SCHOOL_GROUPS_FAILURE);

export const fetchSchoolsGroupBySchoolYearRequest = (schoolYear: number) =>
  action(SchoolGroupsTypes.FETCH_BY_SCHOOL_YEAR_REQUEST, {schoolYear});

export const fetchSchoolsGroupBySchoolYearSuccess = (
  data: LetrusApi.SchoolGroup,
) => action(SchoolGroupsTypes.FETCH_BY_SCHOOL_YEAR_SUCCESS, {data});

export const fetchSchoolsGroupBySchoolYearFailure = () =>
  action(SchoolGroupsTypes.FETCH_BY_SCHOOL_YEAR_FAILURE);

export const fetchSchoolGroupsBySchoolRequest = (schoolId: number) =>
  action(SchoolGroupsTypes.FETCH_BY_SCHOOL_REQUEST, {schoolId});

export const fetchSchoolGroupsBySchoolSuccess = (
  data: LetrusApi.SchoolGroup[],
) => action(SchoolGroupsTypes.FETCH_BY_SCHOOL_SUCCESS, {data});

export const fetchSchoolGroupsBySchoolFailure = () =>
  action(SchoolGroupsTypes.FETCH_BY_SCHOOL_FAILURE);

export const fetchStudentsBySchoolGroupRequest = (
  params: fetchStudentListParams,
) => action(SchoolGroupsTypes.FETCH_STUDENTS_BY_SCHOOL_GROUP_REQUEST, params);

export const fetchStudentsBySchoolGroupSuccess = (
  data: LetrusApi.SchoolGroup[],
) => action(SchoolGroupsTypes.FETCH_STUDENTS_BY_SCHOOL_GROUP_SUCCESS, {data});

export const fetchStudentsBySchoolGroupFailure = () =>
  action(SchoolGroupsTypes.FETCH_STUDENTS_BY_SCHOOL_GROUP_FAILURE);

export const resetStudentPasswordRequest = (
  studentId: string | number,
  password: string,
) =>
  action(SchoolGroupsTypes.RESET_STUDENT_PASSWORD_REQUEST, {
    studentId,
    password,
  });

export const resetStudentPasswordSuccess = (data: LetrusApi.UserDetail) =>
  action(SchoolGroupsTypes.RESET_STUDENT_PASSWORD_SUCCESS, {data});

export const resetStudentPasswordFailure = () =>
  action(SchoolGroupsTypes.RESET_STUDENT_PASSWORD_FAILURE);

export const setSelectedSchoolGroup = (schoolGroup: LetrusApi.SchoolGroup) =>
  action(SchoolGroupsTypes.SET_SELECTED_SCHOOL_GROUP, {schoolGroup});

export const fetchSchoolGroupRequest = () =>
  action(SchoolGroupsTypes.FETCH_SCHOOL_GROUP_REQUEST);

export const fetchSchoolGroupSuccess = (data: any) =>
  action(SchoolGroupsTypes.FETCH_SCHOOL_GROUP_SUCCESS, {data});

export const fetchSchoolGroupFailure = () =>
  action(SchoolGroupsTypes.FETCH_SCHOOL_GROUP_FAILURE);

// Sagas
export function* fetchSchools() {
  try {
    const response = yield call(fetchSchoolsService);

    yield put(fetchSchoolsSuccess(response.data));
  } catch (err) {
    yield put(fetchSchoolsFailure());
  }
}

export function* fetchSchoolGroups(action: AnyAction) {
  try {
    const response = yield call(
      fetchSchoolGroupsService,
      action.payload.params,
    );
    yield put(fetchSchoolGroupsSuccess(response.data));
  } catch (err) {
    yield put(fetchSchoolGroupsFailure());
  }
}

export function* resetStudentPassword(action: AnyAction) {
  try {
    const response = yield call(
      resetStudentPasswordService,
      action.payload.studentId,
      action.payload.password,
    );
    yield put(resetStudentPasswordSuccess(response.data));
  } catch (err) {
    yield put(resetStudentPasswordFailure());
  }
}

export function* fetchSchoolsGroupBySchoolYear(action: AnyAction) {
  try {
    const response = yield call(
      fetchSchoolGroupsBySchoolYearService,
      action.payload.schoolYear,
    );
    yield put(fetchSchoolsGroupBySchoolYearSuccess(response.data));
  } catch (err) {
    yield put(fetchSchoolsGroupBySchoolYearFailure());
  }
}

export function* fetchSchoolGroupsBySchool(action: AnyAction) {
  try {
    const response = yield call(
      fetchSchoolGroupsBySchoolService,
      action.payload,
    );
    yield put(fetchSchoolGroupsBySchoolSuccess(response.data));
  } catch (err) {
    yield put(fetchSchoolGroupsBySchoolFailure());
  }
}

export function* fetchStudentsBySchoolGroup(action: AnyAction) {
  try {
    const response = yield call(
      fetchStudentsBySchoolGroupService,
      action.payload,
    );
    yield put(fetchStudentsBySchoolGroupSuccess(response.data));
  } catch (err) {
    yield put(fetchStudentsBySchoolGroupFailure());
  }
}

export function* fetchSchoolGroup() {
  try {
    const response = yield call(fetchSchoolGroupService);
    yield put(fetchSchoolGroupSuccess(response.data));
  } catch (err) {
    yield put(fetchSchoolGroupFailure());
  }
}

// Selectors
const schoolGroupsDataSelector = (state) => state.get('schoolGroups');

export const getSchoolGroups = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('data'),
);

export const getIsLoadingSchoolGroups = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('loading'),
);

export const getSelectedSchoolGroup = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('selectedSchoolGroup'),
);

export const getSchoolGroupsBySchoolYear = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('schoolGroupsBySchoolYear'),
);

export const getStudentsBySchoolGroup = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('studentsBySchoolGroup'),
);

export const getTotalOfStudents = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('totalOfStudents'),
);

export const getStudentListRequestStatus = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('studentListRequestStatus'),
);

export const getSchools = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('schools'),
);

export const getSchoolHaveLearningPathActivities = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => {
    const schools = schoolGroups.get('data').toJS();

    return !!schools.find((school) => school.uses_learning_path === true);
  },
);

export const getSchoolsCount = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('schoolsCount'),
);

export const getSchoolGroupData = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('schoolGroupData'),
);

export const getSchoolGroupLoading = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('schoolGroupLoading'),
);

export const isResetingStudentPassword = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('isResetingStudentPassword'),
);

export const isLoadingStudentsBySchoolGroup = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups): boolean => schoolGroups.get('isLoadingStudentsBySchoolGroup'),
);

export const hasFinishedResetStudentPassword = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('hasFinishedResetStudentPassword'),
);

export const getResetStudentPasswordError = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('resetStudentPasswordError'),
);

export const isLoadingSchoolGroupsBySchoolYear = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('isLoadingSchoolGroupsBySchoolYear'),
);

export const isLoadingSchoolGroups = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('loading'),
);

export const isLoadingSchools = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('isLoadingSchools'),
);

export const getSchoolGroupListRequestStatus = createSelector(
  schoolGroupsDataSelector,
  (schoolGroups) => schoolGroups.get('schoolGroupListRequestStatus'),
);

// Initial state
export const INITIAL_STATE: SchoolGroupsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
  schools: fromJS([]),
  schoolsCount: 0,
  isLoadingSchools: false,
  schoolGroupsBySchoolYear: fromJS([]),
  isLoadingSchoolsGroupBySchoolYear: false,
  studentsBySchoolGroup: fromJS([]),
  totalOfStudents: 0,
  isLoadingStudentsBySchoolGroup: false,
  schoolGroupData: fromJS([]),
  schoolGroupLoading: false,
  schoolGroupError: false,
  isResetingStudentPassword: false,
  hasFinishedResetStudentPassword: false,
  resetStudentPasswordError: false,
  schoolGroupListRequestStatus: {
    loading: false,
    error: false,
    fulfilled: false,
    posting: false,
  },
  studentListRequestStatus: {
    loading: false,
    error: false,
    fulfilled: false,
    posting: false,
  },
});

// Reducer
export const reducer: Reducer<SchoolGroupsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SchoolGroupsTypes.FETCH_SCHOOLS_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingSchools', true).set('error', false),
      );

    case SchoolGroupsTypes.FETCH_SCHOOLS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingSchools', false)
          .set('error', false)
          .set('schools', fromJS(action.payload.data.results))
          .set('schoolsCount', action.payload.data.count),
      );

    case SchoolGroupsTypes.FETCH_SCHOOLS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingSchools', false)
          .set('error', true)
          .set('schools', fromJS([]))
          .set('schoolsCount', 0),
      );

    case SchoolGroupsTypes.FETCH_SCHOOL_GROUPS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', true)
          .set('error', false)
          .set('schoolGroupListRequestStatus', {
            loading: true,
            error: false,
            fulfilled: false,
            posting: false,
          }),
      );

    case SchoolGroupsTypes.FETCH_SCHOOL_GROUPS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS([...action.payload.data.results]))
          .set('dataCount', action.payload.data.count)
          .set('selectedSchoolGroup', fromJS(action.payload.data.results[0]))
          .set('schoolGroupListRequestStatus', {
            loading: false,
            error: false,
            fulfilled: true,
            posting: false,
          }),
      );

    case SchoolGroupsTypes.FETCH_SCHOOL_GROUPS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([]))
          .set('dataCount', 0)
          .set('schoolGroupListRequestStatus', {
            loading: false,
            error: true,
            fulfilled: false,
            posting: false,
          }),
      );

    case SchoolGroupsTypes.FETCH_BY_SCHOOL_YEAR_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', true)
          .set('isLoadingSchoolGroupsBySchoolYear', true)
          .set('error', false),
      );

    case SchoolGroupsTypes.FETCH_BY_SCHOOL_YEAR_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('schoolGroupsBySchoolYear', fromJS(action.payload.data.results))
          .set('isLoadingSchoolGroupsBySchoolYear', false)
          .set('error', false),
      );

    case SchoolGroupsTypes.FETCH_BY_SCHOOL_YEAR_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('schoolGroupsBySchoolYear', fromJS([]))
          .set('isLoadingSchoolGroupsBySchoolYear', false)
          .set('error', true)
          .set('data', fromJS([]))
          .set('dataCount', 0),
      );

    case SchoolGroupsTypes.FETCH_BY_SCHOOL_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case SchoolGroupsTypes.FETCH_BY_SCHOOL_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data))
          .set('dataCount', 1),
      );

    case SchoolGroupsTypes.FETCH_BY_SCHOOL_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([]))
          .set('dataCount', 0),
      );

    case SchoolGroupsTypes.FETCH_STUDENTS_BY_SCHOOL_GROUP_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingStudentsBySchoolGroup', true)
          .set('error', false)
          .set('studentListRequestStatus', {
            loading: true,
            error: false,
            fulfilled: false,
            posting: false,
          }),
      );

    case SchoolGroupsTypes.FETCH_STUDENTS_BY_SCHOOL_GROUP_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingStudentsBySchoolGroup', false)
          .set('error', false)
          .set('studentsBySchoolGroup', fromJS(action.payload.data.students))
          .set('totalOfStudents', action.payload.data.count_students)
          .set('studentListRequestStatus', {
            loading: false,
            error: false,
            fulfilled: true,
            posting: false,
          }),
      );

    case SchoolGroupsTypes.FETCH_STUDENTS_BY_SCHOOL_GROUP_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingStudentsBySchoolGroup', false)
          .set('error', true)
          .set('data', fromJS([]))
          .set('dataCount', 0)
          .set('studentListRequestStatus', {
            loading: false,
            error: true,
            fulfilled: false,
            posting: false,
          }),
      );

    case SchoolGroupsTypes.RESET_STUDENT_PASSWORD_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isResetingStudentPassword', true)
          .set('resetStudentPasswordError', false)
          .set('hasFinishedResetStudentPassword', false),
      );

    case SchoolGroupsTypes.RESET_STUDENT_PASSWORD_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isResetingStudentPassword', false)
          .set('resetStudentPasswordError', false)
          .set('hasFinishedResetStudentPassword', true),
      );

    case SchoolGroupsTypes.RESET_STUDENT_PASSWORD_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isResetingStudentPassword', false)
          .set('resetStudentPasswordError', true)
          .set('hasFinishedResetStudentPassword', true),
      );

    case SchoolGroupsTypes.SET_SELECTED_SCHOOL_GROUP:
      return state.withMutations((prevState) =>
        prevState.set(
          'selectedSchoolGroup',
          fromJS(action.payload.schoolGroup),
        ),
      );

    case SchoolGroupsTypes.FETCH_SCHOOL_GROUP_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('schoolGroupLoading', true).set('error', false),
      );

    case SchoolGroupsTypes.FETCH_SCHOOL_GROUP_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('schoolGroupLoading', false)
          .set('schoolGroupError', false)
          .set('schoolGroupData', fromJS(action.payload.data.results)),
      );

    case SchoolGroupsTypes.FETCH_SCHOOL_GROUP_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('schoolGroupLoading', false)
          .set('schoolGroupError', true),
      );

    default:
      return state;
  }
};

export default reducer;
