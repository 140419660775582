import useLearningPathReport from 'features/useLearningPathReport';
import {NewFormUnique} from 'letrus-ui';
import {
  IndividualQuestionsInfoMock,
  QuestionsInfoMock,
} from 'store/mocks/__mocks__/learningPathStep';
import PerformanceChart from '../PerformanceChart';
import styles from './RadioQuestion.module.scss';

export interface RadioQuestionProps {
  question: QuestionsInfoMock;
  showIndividualResults: boolean;
  individualResults: {
    currentStudentId: string;
    studentsIndividualAnswers: Record<string, IndividualQuestionsInfoMock>;
  };
}

function RadioQuestion({
  question,
  showIndividualResults,
  individualResults,
}: RadioQuestionProps): JSX.Element {
  const {
    getSchoolGroupStatistics,
    getStudentIndividualQuestionAnswers,
    hasCorrectAnswer,
  } = useLearningPathReport();

  const {totalOfCorrectStudents, totalOfStudents, percentageOfCorrectStudents} =
    getSchoolGroupStatistics(question);

  const {individualRadio} = getStudentIndividualQuestionAnswers({
    question,
    answersFromAllStudents: individualResults?.studentsIndividualAnswers,
    currentStudent: individualResults?.currentStudentId,
  });

  const questionHasCorrectAnswer = hasCorrectAnswer(question);

  return (
    <>
      <div
        className={styles.questionText}
        dangerouslySetInnerHTML={{__html: question?.question_text}}
      />

      {!showIndividualResults && questionHasCorrectAnswer ? (
        <p className={styles.performanceText}>
          {`Desempenho da turma: ${totalOfCorrectStudents}/${totalOfStudents} (${percentageOfCorrectStudents}%) estudantes acertaram.`}
        </p>
      ) : (
        ''
      )}

      {showIndividualResults ? (
        <NewFormUnique
          items={individualRadio.items}
          activeItem={individualRadio.activeItem}
          correctItem={individualRadio.correctItem}
          incorrectItem={individualRadio.incorrectItem}
          onClick={() => {}}
        />
      ) : (
        <PerformanceChart
          dataset={question.question_options.map((option) => ({
            name: option?.name,
            total: totalOfStudents,
            partial: option?.total,
            isCorrect: option?.is_correct,
          }))}
        />
      )}
    </>
  );
}

export default RadioQuestion;
