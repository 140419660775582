import {useEffect, useRef} from 'react';

export function usePrevious<T>(value: T, initialValue?: T) {
  const ref = useRef<T | undefined>(initialValue ?? undefined);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
