import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BasicModal, Button} from 'letrus-ui';
import styles from './ConfirmCloseModal.module.scss';

export interface ConfirmCloseModalProps {
  onClickBackButton(): void;
  onClickClose(): void;
  open?: boolean;
}

function ConfirmCloseModal({
  onClickClose,
  onClickBackButton,
  open,
}: ConfirmCloseModalProps): JSX.Element {
  return (
    <BasicModal
      onClose={onClickClose}
      open={open}
      size="tiny"
      title={' '}
      closeButton={
        <Button
          variant="tertiary"
          leftIcon={{icon: 'times', size: '6x', color: '#E94B56'}}
          onClick={onClickClose}
        />
      }
      footerButtons={
        <div className={styles.footerButtons}>
          <Button
            leftIcon={{icon: 'arrow-left', size: 'lg', color: '#2A5082'}}
            variant="secondary"
            text="Voltar"
            onClick={onClickBackButton}
          />
          <Button
            leftIcon={{icon: 'times', size: 'lg', color: '#E94B56'}}
            variant="secondary"
            text="Fechar"
            onClick={onClickClose}
          />
        </div>
      }
      footerButtonsOrientation="center"
    >
      <div className={styles.modalContent}>
        <h4 className={styles.warningText}>
          Atenção. Ao fechar, os dados não serão salvos.
        </h4>
        <FontAwesomeIcon
          icon={['fad', 'hand']}
          size="6x"
          color="#F7BB49"
          className="fa-swap-opacity"
        />
      </div>
    </BasicModal>
  );
}

export default ConfirmCloseModal;
