import api from './api';

export const studentParentsFetchTerms = () =>
  api('/api/v1/letrus_letrusglobalconfiguration/?search=terms', {
    method: 'get',
  });

export const studentParentsFetchListSchoolGroupsBySchoolId = (
  schoolId: number,
) =>
  api(`/api/v1/schools/${schoolId}/list_school_groups/`, {
    method: 'get',
  });

export const studentParentsFetchListMembers = (schoolGroupId: number) =>
  api(`/api/v1/school_groups/${schoolGroupId}/list_members/?limit=1000`, {
    method: 'get',
  });

export const studentParentsFetchStudentInfo = (userId: number) =>
  api(`/api/v1/users/get_student/?id=${userId}`, {
    method: 'get',
  });

export const studentParentFetchStudentParentByStudentId = (studentId: number) =>
  api(`/api/v1/student_parent/${studentId}/`, {
    method: 'get',
    data: {},
  });

export const studentParentsUpdateStudentParentByStudentId = (
  studentId: number,
  parentData: any,
) =>
  api(`/api/v1/student_parent/update_by_student_id/`, {
    method: 'post',
    data: {
      student_id: studentId,
      student_parent: parentData,
    },
  });
