import {
  Questionnaire,
  QuestionnaireStep,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import QuestionButton from 'containers/MultigenreReport/ReadingIndicatorsReport/QuestionButton';
import {Button, LoadingBox} from 'letrus-ui';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  fetchQuestionIndicatorByQuestionnaireIdRequest,
  fetchQuestionIndicatorsDetailsRequest,
  fetchStudentsAnswersRequest,
  getQuestionIndicatorByQuestionnaireId,
  getQuestionIndicatorsDetails,
  getStudentsAnswers,
  isLoadingQuestionIndicatorByQuestionnaireId,
  isLoadingQuestionIndicatorsDetails,
  isLoadingStudentsAnswers,
} from 'store/reducers/questionnaires';
import {ApplicationState} from 'store/rootReducer';
import useTrackEvent from 'utils/hooks/useTrackEvent';
import {EventData} from 'utils/hooks/useTrackEvent/types';
import {
  QuestionDetails,
  QuestionIndicatorsDetails,
} from 'utils/types/questionIndicatorsDetails';
import AnswerRateSlide, {SlideType} from './AnswerRateSlide';
import IndicatorsRelatedSlide from './IndicatorsRelatedSlide';
import LoadingSelectQuestion from './LoadingSelectQuestion';
import LoadingSlide from './LoadingSlide';
import PerformanceSummarySlide from './PerformanceSummarySlide';
import styles from './ReadingIndicatorsReport.module.scss';
import SelectQuestionModal from './SelectQuestionModal';
import SlidePagination from './SlidePagination';
import StudentsPerformanceSlide from './StudentsPerformanceSlide';

const MAX_NUMBER_OF_SLIDES = 5;

interface OwnProps {
  questionnaireId?: number;
  schoolGroupId?: number;
  learningPathName?: string;
  learningPathInstanceId: number;
  learningPathStatus: string;
}

interface DispatchProps {
  fetchQuestionIndicatorByQuestionnaireIdRequest: typeof fetchQuestionIndicatorByQuestionnaireIdRequest;
  fetchQuestionIndicatorsDetailsRequest: typeof fetchQuestionIndicatorsDetailsRequest;
  fetchStudentsAnswersRequest: typeof fetchStudentsAnswersRequest;
}

interface StateProps {
  questionsIndicators: ImmutableMap<Questionnaire>;
  isLoadingQuestionIndicator: boolean;
  questionIndicatorsDetails: ImmutableMap<QuestionIndicatorsDetails>;
  isLoadingQuestionIndicatorsDetails: boolean;
  studentsAnswers: QuestionDetails;
  isLoadingStudentsAnswers?: boolean;
}

export type ReadingIndicatorsReportProps = DispatchProps &
  StateProps &
  OwnProps;

function ReadingIndicatorsReport({
  learningPathName,
  schoolGroupId,
  questionnaireId,
  questionsIndicators,
  isLoadingQuestionIndicator,
  questionIndicatorsDetails,
  isLoadingQuestionIndicatorsDetails,
  studentsAnswers,
  isLoadingStudentsAnswers,
  learningPathInstanceId,
  learningPathStatus,
  fetchQuestionIndicatorByQuestionnaireIdRequest,
  fetchQuestionIndicatorsDetailsRequest,
  fetchStudentsAnswersRequest,
}: ReadingIndicatorsReportProps): JSX.Element {
  const [isSelectQuestionModalOpen, setIsSelectQuestionModalOpen] =
    useState(false);
  const [questionSelected, setQuestionSelected] =
    useState<QuestionnaireStep | undefined>();
  const [currentSlide, setCurrentSlide] = useState(1);
  const {trackEvent} = useTrackEvent();

  const eventData: EventData = {
    learningPathInstance: {
      learning_path_instance_id: learningPathInstanceId,
      learning_path_status: learningPathStatus,
    },
  };

  useEffect(() => {
    if (!questionsIndicators.size && questionnaireId)
      fetchQuestionIndicatorByQuestionnaireIdRequest(questionnaireId);
  }, [questionnaireId]);

  useEffect(() => {
    if (questionSelected && schoolGroupId && learningPathInstanceId) {
      fetchQuestionIndicatorsDetailsRequest(
        questionSelected.question?.id,
        schoolGroupId,
        learningPathInstanceId,
      );
      fetchStudentsAnswersRequest(
        questionSelected.question?.id,
        schoolGroupId,
        learningPathInstanceId,
      );
    }
  }, [questionSelected]);

  function onSelectQuestion(question?: QuestionnaireStep) {
    setQuestionSelected(question);
    setCurrentSlide(1);
    setIsSelectQuestionModalOpen(false);
  }

  function onChangeSlideInput(event: React.KeyboardEvent<HTMLInputElement>) {
    const slideInput = Number(event?.key);

    if (Number.isNaN(slideInput)) return;
    if (slideInput < 1 || slideInput > MAX_NUMBER_OF_SLIDES) return;

    setCurrentSlide(slideInput);
  }

  function goToNextSlide() {
    setCurrentSlide(currentSlide + 1);
    trackEvent('reading_indicators_report_next_slide_click', eventData);
  }

  function goToPreviousSlide() {
    setCurrentSlide(currentSlide - 1);
    trackEvent('reading_indicators_report_previous_slide_click', eventData);
  }

  const indicatorsDetails = questionIndicatorsDetails?.toJS();

  return (
    <main className={styles.container}>
      {questionSelected ? (
        <>
          <div className={styles.headerButtons}>
            <div>
              <QuestionButton
                key={questionSelected.id}
                order={questionSelected.order.toString()}
                title={questionSelected.question.name}
                onClick={() => setIsSelectQuestionModalOpen(true)}
              >
                <Button
                  variant="secondary"
                  text="Trocar Questão"
                  size="small"
                  leftIcon={{icon: ['fad', 'repeat'], color: '#1D79E6'}}
                />
              </QuestionButton>
            </div>
          </div>
          <div className={styles.slideContainer}>
            <Button
              variant="secondary"
              leftIcon={{icon: 'chevron-left'}}
              onClick={goToPreviousSlide}
              circular
              disabled={currentSlide === 1}
            />
            <div className={styles.centralContent}>
              {isLoadingQuestionIndicatorsDetails ? (
                <LoadingSlide />
              ) : (
                <>
                  {currentSlide === 1 && (
                    <PerformanceSummarySlide
                      learningPathName={learningPathName}
                      questionIndicatorsDetails={indicatorsDetails}
                    />
                  )}
                  {currentSlide === 2 && (
                    <IndicatorsRelatedSlide
                      questionName={questionSelected?.question?.name}
                      letrusIndicators={indicatorsDetails?.letrus_indicators}
                      mainExternalIndicator={
                        indicatorsDetails?.main_external_indicator
                      }
                    />
                  )}
                  {currentSlide === 3 && (
                    <AnswerRateSlide
                      questionIndicatorDetails={indicatorsDetails}
                      slideType={SlideType.CORRECT_ANSWER}
                    />
                  )}
                  {currentSlide === 4 && (
                    <AnswerRateSlide
                      questionIndicatorDetails={indicatorsDetails}
                      slideType={SlideType.INCORRECT_ANSWER}
                    />
                  )}
                  {currentSlide === 5 && (
                    <StudentsPerformanceSlide
                      studentsAnswers={studentsAnswers}
                      isLoadingStudentsAnswers={isLoadingStudentsAnswers}
                      questionIndicatorDetails={indicatorsDetails}
                    />
                  )}
                </>
              )}
              {isLoadingQuestionIndicatorsDetails ? (
                <LoadingBox width="20rem" height="2rem" borderRadius={20} />
              ) : (
                <SlidePagination
                  pageSize={MAX_NUMBER_OF_SLIDES}
                  currentPage={currentSlide}
                  nextPage={goToNextSlide}
                  previousPage={goToPreviousSlide}
                  goToPage={onChangeSlideInput}
                />
              )}
            </div>
            <Button
              variant="secondary"
              leftIcon={{icon: 'chevron-right'}}
              onClick={goToNextSlide}
              circular
              disabled={
                currentSlide === MAX_NUMBER_OF_SLIDES ||
                isLoadingQuestionIndicatorsDetails
              }
            />
          </div>
        </>
      ) : (
        <article className={styles.selectContent}>
          {isLoadingQuestionIndicator ? (
            <LoadingSelectQuestion />
          ) : (
            <>
              <img
                className={styles.image}
                src="https://s3.sa-east-1.amazonaws.com/media.letrus.com.br/carolina-apontando.png"
                alt="Carolina apontando"
              />

              <h4 className={styles.title}>
                Escolha uma questão para visualizar os Indicadores de Leitura
                Letrus
              </h4>

              <p className={styles.subtitle}>
                Os indicadores de leitura estão vinculados a algumas questões da
                atividade. Escolha uma questão para ver os indicadores.
              </p>

              <Button
                variant="primary"
                text="Selecionar Questão"
                backgroundColor="#3c405f"
                borderColor="#3c405f"
                textColor="#ffffff"
                boxShadow="0px 8px 16px 0px rgba(51, 74, 254, 0.50)"
                size="medium"
                onClick={() => setIsSelectQuestionModalOpen(true)}
              />
            </>
          )}{' '}
        </article>
      )}
      {isSelectQuestionModalOpen && (
        <SelectQuestionModal
          onClickClose={() => setIsSelectQuestionModalOpen(false)}
          questionsIndicators={questionsIndicators.toJS()}
          onClickSelectQuestion={onSelectQuestion}
          questionSelected={questionSelected}
        />
      )}
    </main>
  );
}

export default connect(
  (state: ApplicationState) => ({
    questionsIndicators: getQuestionIndicatorByQuestionnaireId(state),
    isLoadingQuestionIndicator:
      isLoadingQuestionIndicatorByQuestionnaireId(state),
    questionIndicatorsDetails: getQuestionIndicatorsDetails(state),
    isLoadingQuestionIndicatorsDetails:
      isLoadingQuestionIndicatorsDetails(state),
    studentsAnswers: getStudentsAnswers(state),
    isLoadingStudentsAnswers: isLoadingStudentsAnswers(state),
  }),
  {
    fetchQuestionIndicatorByQuestionnaireIdRequest,
    fetchQuestionIndicatorsDetailsRequest,
    fetchStudentsAnswersRequest,
  },
)(ReadingIndicatorsReport);
