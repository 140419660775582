export const AnswerChartOptions = {
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      displays: true,
      color: 'black',
      anchor: 'start',
      offset: 16,
      font: {
        family: 'Anek Latin',
        weight: 'bold',
      },
      textStrokeWidth: 2,
      textStrokeColor: 'white',
      textAlign: 'start',
    },
  },
  scales: {
    x: {
      suggestedMax: 100,
      ticks: {
        stepSize: 20,
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
    y: {
      ticks: {
        color: '#000',
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
  },
};
