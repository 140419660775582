import {all} from 'redux-saga/effects';
import abandonReviewSagas from './sagas/abandonReview';
import authenticationSagas from './sagas/authentication';
import compositionReviewSagas from './sagas/compositionReviews';
import compositionSagas from './sagas/compositions';
import featureFlagSagas from './sagas/featureFlags';
import generalAnnotationSagas from './sagas/generalAnnotations';
import globalConfigurationSagas from './sagas/globalConfiguration';
import knowledgeLearningPathsSagas from './sagas/knowledgeLearningPaths';
import learningPathSagas from './sagas/learningPaths';
import notificationSagas from './sagas/notifications';
import questionnaireSagas from './sagas/questionnaire';
import reviewAnnotationSagas from './sagas/reviewAnnotations';
import reviewGridsSagas from './sagas/reviewGrids';
import schoolGroupSagas from './sagas/schoolGroups';
import studentParentSagas from './sagas/studentParents';
import studentsSaga from './sagas/students';
import testSagas from './sagas/tests';
import themeSagas from './sagas/themes';
import userSagas from './sagas/users';
import zeroGradeCommentSagas from './sagas/zeroGradeComments';

export default function* rootSaga() {
  yield all([
    ...abandonReviewSagas,
    ...authenticationSagas,
    ...compositionSagas,
    ...compositionReviewSagas,
    ...featureFlagSagas,
    ...generalAnnotationSagas,
    ...globalConfigurationSagas,
    ...learningPathSagas,
    ...reviewAnnotationSagas,
    ...themeSagas,
    ...studentParentSagas,
    ...userSagas,
    ...testSagas,
    ...questionnaireSagas,
    ...schoolGroupSagas,
    ...notificationSagas,
    ...zeroGradeCommentSagas,
    ...knowledgeLearningPathsSagas,
    ...studentsSaga,
    ...reviewGridsSagas,
  ]);
}
