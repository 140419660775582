import {takeLatest} from 'redux-saga/effects';
import {
  fetchKnowledgeLearningPathList,
  KnowledgeLearningPathsTypes,
} from 'store/reducers/knowledgeLearningPaths';

const knowledgeLearningPathsSagas = [
  takeLatest(
    KnowledgeLearningPathsTypes.FETCH_KNOWLEDGE_LEARNING_PATH_LIST_REQUEST,
    fetchKnowledgeLearningPathList,
  ),
];

export default knowledgeLearningPathsSagas;
