import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactComponent as Logo} from 'assets/images/logoLetrus.svg';
import useReadingIndicator from 'features/useReadingIndicator';
import {QuestionIndicatorsDetails} from 'utils/types/questionIndicatorsDetails';
import styles from './PerformanceSummarySlide.module.scss';

export interface PerformanceSummarySlideProps {
  questionIndicatorsDetails: QuestionIndicatorsDetails;
  learningPathName?: string;
}

function PerformanceSummarySlide({
  questionIndicatorsDetails,
  learningPathName,
}: PerformanceSummarySlideProps): JSX.Element {
  const {getStyleBasedOnRates, getTotalAnswers} = useReadingIndicator({});

  const {
    borderColor,
    backgroundColor,
    iconName,
    iconColor,
    title,
    text,
    boxShadow,
  } = getStyleBasedOnRates(
    questionIndicatorsDetails?.rates_detail?.engagement_rate,
    questionIndicatorsDetails?.rates_detail?.correct_answers_rate,
  );

  const {totalAnswers} = getTotalAnswers(
    questionIndicatorsDetails?.rates_detail?.total_correct_answers,
    questionIndicatorsDetails?.rates_detail?.total_incorrect_answers,
  );

  return (
    <main>
      <div className={styles.content}>
        <div className={styles.leftContent}>
          <Logo className={styles.logo} />
          <h2 className={styles.title}>Indicadores de Leitura</h2>
          <h4 className={styles.question}>{questionIndicatorsDetails?.name}</h4>
          <div className={styles.activity}>
            <p>
              <b className={styles.activityTitle}>Atividade: </b>
              {learningPathName}
            </p>
          </div>
        </div>
        <div className={styles.rightContent}>
          <div
            className={styles.card}
            style={{borderColor, backgroundColor, boxShadow}}
          >
            <FontAwesomeIcon icon={iconName} color={iconColor} size="4x" />
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{text}</p>
            <div className={styles.indicators}>
              <div className={styles.indicator}>
                <p className={styles.rate}>
                  {questionIndicatorsDetails?.rates_detail?.engagement_rate}%
                </p>
                <p>Engajamento</p>
              </div>
              <div className={styles.indicator}>
                <p className={styles.rate}>{totalAnswers}</p>
                <p>Respostas</p>
              </div>
              <div className={styles.indicator}>
                <p className={styles.rate}>
                  {
                    questionIndicatorsDetails?.rates_detail
                      ?.correct_answers_rate
                  }
                  %
                </p>
                <p>Corretas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PerformanceSummarySlide;
