import {takeLatest} from 'redux-saga/effects';
import {
  accessUser,
  AuthenticationTypes,
  layersLoginUser,
  loginUser,
  logoutUser,
  restorePassword,
} from 'store/reducers/authentication';

const authenticationSagas = [
  takeLatest(AuthenticationTypes.LOGIN_REQUEST, loginUser),
  takeLatest(AuthenticationTypes.LOGOUT_REQUEST, logoutUser),
  takeLatest(AuthenticationTypes.RESTORE_PASSWORD_REQUEST, restorePassword),
  takeLatest(AuthenticationTypes.ACCESS_REQUEST, accessUser),
  takeLatest(AuthenticationTypes.LAYERS_LOGIN_REQUEST, layersLoginUser),
];

export default authenticationSagas;
