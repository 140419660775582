import Tooltip from 'rc-tooltip';
import {ReactElement, ReactNode} from 'react';
import './TooltipComponent.scss';

export type Placement =
  | 'right'
  | 'rightTop'
  | 'rightBottom'
  | 'bottom'
  | 'bottomLeft'
  | 'bottomRight'
  | 'top'
  | 'topRight'
  | 'topLeft'
  | 'left'
  | 'leftTop'
  | 'leftBottom';

export type Trigger = 'hover' | 'click' | 'focus';

export interface TooltipComponentProps {
  tooltipTrigger?: ReactElement;
  trigger?: Trigger | Trigger[];
  placement?: Placement;
  arrowContent?: ReactNode;
  overlayClassName?: string;
  overlay: ReactNode;
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({
  tooltipTrigger,
  trigger,
  placement,
  arrowContent = <div className="rc-tooltip-arrow-inner" />,
  overlayClassName,
  overlay,
}) => {
  return (
    <Tooltip
      destroyTooltipOnHide
      trigger={trigger}
      placement={placement}
      arrowContent={arrowContent}
      overlayClassName={overlayClassName}
      overlay={<div className="tooltipComponent">{overlay}</div>}
    >
      {tooltipTrigger}
    </Tooltip>
  );
};

export default TooltipComponent;
