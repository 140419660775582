import {User} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import {hubspotTokenToIdentifyUserToWebChat} from 'utils/libs/analytics';
import loadWidget from './loadWidget';

async function setupUserHubspotConversation(
  user: ImmutableMap<User>,
): Promise<void> {
  const filteredUserEmail = user.get('email').replace(' ', '');
  const tokenFromLocalStorage = localStorage.getItem('HubSpot_Token');

  if (tokenFromLocalStorage) {
    loadWidget({
      email: filteredUserEmail,
      token: tokenFromLocalStorage,
    });
  } else {
    const hubspotAuthenticationResponse =
      await hubspotTokenToIdentifyUserToWebChat();

    if (hubspotAuthenticationResponse?.token) {
      localStorage.setItem(
        'HubSpot_Token',
        hubspotAuthenticationResponse.token,
      );

      if (window.HubSpotConversations) {
        loadWidget({
          email: filteredUserEmail,
          token: hubspotAuthenticationResponse.token,
        });
      }
    }
  }
}

export default setupUserHubspotConversation;
