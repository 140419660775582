import api from './api';

export const fetchQuestionnaireByIdService = (questionnaireId: number) =>
  api(`/api/v1/questionnaires/${questionnaireId}/`);

export const postQuestionAttempService = (data: any) =>
  api(`/api/v1/question_attempts/`, {
    method: 'post',
    data,
  });

export function fetchQuestionIndicatorsByQuestionnaireIdService(
  questionnaireId: number,
) {
  return api(`/api/v1/questionnaires/${questionnaireId}/question_indicators/`);
}

export function fetchQuestionIndicatorsDetailsService(
  questionId: number,
  schoolGroupId: number,
  learningPathInstanceId: number,
) {
  return api(
    `/api/v1/questions/${questionId}/indicator_details/?school_group_id=${schoolGroupId}&learning_path_instance_id=${learningPathInstanceId}`,
  );
}

export function fetchStudentsAnswersService(
  questionId: number,
  schoolGroupId: number,
  learningPathInstanceId: number,
) {
  return api(
    `/api/v1/questions/${questionId}/student_answers/?school_group_id=${schoolGroupId}&learning_path_instance_id=${learningPathInstanceId}`,
  );
}
