import {BasicModal} from 'letrus-ui';
import {IndicatorCard} from 'utils/types/questionIndicatorsDetails';
import IndicatorsCard from '../../IndicatorsRelatedSlide/IndicatorsCard';

export interface IndicatorsModalProps {
  onClickClose(): void;
  indicatorCard: IndicatorCard;
}

function IndicatorsModal({
  onClickClose,
  indicatorCard,
}: IndicatorsModalProps): JSX.Element {
  return (
    <BasicModal
      onClose={onClickClose}
      open
      clickOutsideToClose
      title="Principal Indicador de Leitura trabalhado:"
    >
      <IndicatorsCard indicator={indicatorCard} />
    </BasicModal>
  );
}

export default IndicatorsModal;
