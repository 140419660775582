import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import CompositionInstructions from 'components/CompositionInstructions';
import {
  TestStepAidsMock,
  TestStepInstructionMock,
} from 'store/mocks/__mocks__/learningPathStep';
import MultigenreModal from '../MultigenreModal';

interface ProposalModalProps {
  onClickClose(): void;
  genre: string;
  aids: LetrusApi.CompositionAid[] | TestStepAidsMock[];
  instruction:
    | LetrusApi.CompositionInstruction
    | LetrusApi.CompositionInstructionNested
    | TestStepInstructionMock;
}

function ProposalModal({
  onClickClose,
  genre,
  aids,
  instruction,
}: ProposalModalProps): JSX.Element {
  return (
    <MultigenreModal
      onClickClose={onClickClose}
      title="Veja mais detalhes sobre a etapa de proposta de escrita:"
    >
      <CompositionInstructions
        instruction={instruction}
        genre={genre}
        aids={aids}
      />
    </MultigenreModal>
  );
}

export default ProposalModal;
