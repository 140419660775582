import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tag from 'components/Tag';
import Card from 'containers/LearningPathReportDashboard/Card';
import {Step} from 'containers/LearningPathReportEngagement';
import Table from 'containers/MultigenreReport/Table';
import {learningPathReportSummaryMock} from 'store/mocks/__mocks__/learningPaths';
import styles from './StepCard.module.scss';

enum StepTypeTitle {
  writing = 'Escrita',
  rewriting = 'Reescrita',
  pedagogical_content = 'Leitura',
  questionnaire = 'Questionário',
}

export interface StepCardProps {
  summary: typeof learningPathReportSummaryMock.summary;
  onClickButton(step: Partial<Step> & {type: string}): void;
}

function StepCard({summary, onClickButton}: StepCardProps): JSX.Element {
  const tableHeaders = ['Etapas', 'Conteúdo', 'Detalhes'];

  return (
    <section>
      <Card
        title="Veja abaixo todas as etapas desta atividade e tenha acesso às respostas dos estudantes:"
        styles={styles.learningPathSteps}
      >
        <Table tableHeaders={tableHeaders} customClassName={styles.reportTable}>
          <>
            {summary?.steps?.map((step) => (
              <tr key={`step-${step.id}`}>
                <td>
                  <Tag text={`${step.order}ª`} />
                </td>
                <td className={styles.stepTitle}>{StepTypeTitle[step.type]}</td>
                <td>
                  <button
                    type="button"
                    className={styles.stepButton}
                    onClick={() => onClickButton(step)}
                  >
                    <FontAwesomeIcon icon="eye" size="sm" />
                    Ver Detalhes
                  </button>
                </td>
              </tr>
            ))}
          </>
        </Table>
      </Card>
    </section>
  );
}

export default StepCard;
