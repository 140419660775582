import styles from './Tag.module.scss';

export interface TagProps {
  text: string;
}

function Tag({text}: TagProps): JSX.Element {
  return (
    <div className={styles.tag}>
      <span>{text}</span>
    </div>
  );
}

export default Tag;
