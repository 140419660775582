import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CSSProperties, useState} from 'react';
import './TooltipOverlay.scss';

export interface SimpleTooltipOverlayProps {
  className?: string;
  style?: CSSProperties;
  title?: string;
  entity?: any;
  addLikeAnnotation?: (entityId: string | number, like: any) => void;
  addLikeGeneralAnnotation?: (entityId: string | number, like: any) => void;
}

const SimpleTooltipOverlay: React.FC<SimpleTooltipOverlayProps> = ({
  addLikeAnnotation,
  addLikeGeneralAnnotation,
  className,
  style,
  title,
  entity,
  children,
}) => {
  const [like, setLike] = useState(null);

  const addLike = (like: any) => {
    if (entity.competence) {
      if (addLikeAnnotation) addLikeAnnotation(entity.id, like);
    } else if (addLikeGeneralAnnotation)
      addLikeGeneralAnnotation(entity.id, like);

    return setLike(like);
  };

  return (
    <div className={`TooltipOverlay ${className || ''}`} style={style}>
      {title && <div className="TooltipOverlay__title">{title}</div>}

      <div className="TooltipOverlay__body">{children}</div>
      <div className="TooltipOverlay__action">
        <span>Esse comentário foi útil?</span>
        <div>
          <button type="button" onClick={() => addLike(true)}>
            <span
              className={`thumbs up ${like && like !== null ? 'liked' : ''}`}
            >
              <FontAwesomeIcon
                icon={
                  like && like !== null
                    ? ['fas', 'thumbs-up']
                    : ['far', 'thumbs-up']
                }
              />
            </span>
          </button>

          <button type="button" onClick={() => addLike(false)}>
            <span
              className={`thumbs dislike ${
                !like && like !== null ? 'disliked' : ''
              }`}
            >
              <FontAwesomeIcon
                icon={
                  !like && like !== null
                    ? ['fas', 'thumbs-down']
                    : ['far', 'thumbs-down']
                }
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SimpleTooltipOverlay;
