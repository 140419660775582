import {takeLatest} from 'redux-saga/effects';
import {
  fetchSchoolGroup,
  fetchSchoolGroups,
  fetchSchools,
  fetchSchoolsGroupBySchoolYear,
  fetchStudentsBySchoolGroup,
  resetStudentPassword,
  SchoolGroupsTypes,
} from '../reducers/schoolGroups';

const schoolGroupSagas = [
  takeLatest(
    SchoolGroupsTypes.FETCH_BY_SCHOOL_YEAR_REQUEST,
    fetchSchoolsGroupBySchoolYear,
  ),
  takeLatest(SchoolGroupsTypes.FETCH_SCHOOLS_REQUEST, fetchSchools),
  takeLatest(
    SchoolGroupsTypes.FETCH_STUDENTS_BY_SCHOOL_GROUP_REQUEST,
    fetchStudentsBySchoolGroup,
  ),
  takeLatest(SchoolGroupsTypes.FETCH_SCHOOL_GROUP_REQUEST, fetchSchoolGroup),
  takeLatest(SchoolGroupsTypes.FETCH_SCHOOL_GROUPS_REQUEST, fetchSchoolGroups),
  takeLatest(
    SchoolGroupsTypes.RESET_STUDENT_PASSWORD_REQUEST,
    resetStudentPassword,
  ),
];

export default schoolGroupSagas;
