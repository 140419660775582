import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import TextArea from 'react-textarea-autosize';
import styles from './StudentComposition.module.scss';

interface StudentCompositionProps {
  compositionInfo: LetrusApi.CompositionDetail;
}

function StudentComposition({
  compositionInfo,
}: StudentCompositionProps): JSX.Element {
  return (
    <div className={styles.compositionContainer}>
      <h2 className={styles.studentName}>
        <b>Escrita do estudante:</b> {compositionInfo?.user?.first_name}{' '}
        {compositionInfo?.user?.last_name}
      </h2>
      <div className={styles.composition}>
        <h3 className={styles.compositionTitle}>{compositionInfo?.title}</h3>
        <div className={styles.compositionContent}>
          <TextArea readOnly value={compositionInfo?.composition_raw} />
        </div>
      </div>
    </div>
  );
}

export default StudentComposition;
