import {takeLatest} from 'redux-saga/effects';
import {
  createTest,
  deleteTest,
  fetchEvolutionReport,
  fetchLiveTestData,
  fetchScheduledActivities,
  fetchStudentsReopenStatus,
  fetchTestById,
  fetchTestGradeInfo,
  fetchTestIndicatorsInfo,
  fetchTestsBySchoolGroup,
  fetchTestStudentsInfo,
  fetchTestsWithScore,
  patchTest,
  putTest,
  sendTests,
  TestsTypes,
  triggerLiveTest,
} from '../reducers/tests';

export default [
  takeLatest(TestsTypes.CREATE_REQUEST, createTest),
  takeLatest(TestsTypes.DELETE_REQUEST, deleteTest),
  takeLatest(TestsTypes.FETCH_BY_ID_REQUEST, fetchTestById),
  takeLatest(TestsTypes.FETCH_BY_SCHOOL_GROUP_REQUEST, fetchTestsBySchoolGroup),
  takeLatest(TestsTypes.FETCH_EVOLUTION_REPORT_REQUEST, fetchEvolutionReport),
  takeLatest(TestsTypes.FETCH_TESTS_WITH_SCORE_REQUEST, fetchTestsWithScore),
  takeLatest(TestsTypes.FETCH_GRADE_INFO_REQUEST, fetchTestGradeInfo),
  takeLatest(TestsTypes.FETCH_INDICATORS_INFO_REQUEST, fetchTestIndicatorsInfo),
  takeLatest(TestsTypes.FETCH_LIVE_DATA_REQUEST, fetchLiveTestData),
  takeLatest(
    TestsTypes.FETCH_SCHEDULED_ACTIVITIES_REQUEST,
    fetchScheduledActivities,
  ),
  takeLatest(TestsTypes.FETCH_STUDENTS_INFO_REQUEST, fetchTestStudentsInfo),
  takeLatest(TestsTypes.SEND_TESTS_REQUEST, sendTests),
  takeLatest(TestsTypes.TRIGGER_LIVE_TEST_REQUEST, triggerLiveTest),
  takeLatest(TestsTypes.PUT_REQUEST, putTest),
  takeLatest(TestsTypes.PATCH_REQUEST, patchTest),
  takeLatest(
    TestsTypes.FETCH_STUDENTS_REOPEN_STATUS_REQUEST,
    fetchStudentsReopenStatus,
  ),
];
