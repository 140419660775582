export interface SpinnerProps {
  color?: string;
  className?: string;
  message?: string;
  size?: number;
}

const Spinner: React.FC<SpinnerProps> = ({
  className,
  color,
  message,
  size = 30,
}) => {
  return (
    <div className={`nprogress ${className}`} data-testid="spinner">
      <div
        className="spinner-icon"
        style={{
          borderTopColor: color,
          borderLeftColor: color,
          height: size,
          width: size,
          margin: '0 auto',
        }}
      />
      {message && <small>{message}</small>}
    </div>
  );
};

export default Spinner;
