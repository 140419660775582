import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import ContentTabs from 'components/ContentTabs';
import HTMLContent from 'components/HTMLContent';
import {
  TestStepAidsMock,
  TestStepInstructionMock,
} from 'store/mocks/__mocks__/learningPathStep';
import styles from './CompositionInstructions.module.scss';

interface CompositionInstructionsProps {
  genre: string;
  aids: LetrusApi.CompositionAid[] | TestStepAidsMock[];
  instruction:
    | LetrusApi.CompositionInstruction
    | LetrusApi.CompositionInstructionNested
    | TestStepInstructionMock;
}

function CompositionInstructions({
  genre,
  aids,
  instruction,
}: CompositionInstructionsProps): JSX.Element {
  const tabs = [
    {
      title: 'Textos de apoio',
      content: aids?.map((aid) => (
        <HTMLContent
          key={aid.id}
          title={aid.title}
          html={aid.content_html}
          imageSrc={aid.content_image}
          imageAlt={aid.alt_image}
        />
      )),
    },
    {
      title: 'Proposta',
      content: (
        <HTMLContent
          key={instruction?.id}
          title={instruction?.title}
          html={instruction?.content_html}
          imageSrc={instruction?.image}
          imageAlt={instruction?.alt_image}
        />
      ),
    },
  ];

  if (instruction?.repertory_contents?.length)
    tabs.push({
      title: 'Material de pré-escrita',
      content: instruction?.repertory_contents?.map((repertoryContent) => (
        <HTMLContent
          key={repertoryContent.id}
          title={repertoryContent.title}
          html={repertoryContent.content_html}
          link={repertoryContent.link}
        />
      )),
    });

  return (
    <main className={styles.wrapper}>
      <header className={styles.contentTitle}>
        <h2>{`Tema: ${instruction?.title}`}</h2>
        <p>{`Gênero: ${genre}`}</p>
      </header>
      <span className={styles.contentTabsWrapper}>
        <ContentTabs tabs={tabs} />
      </span>
    </main>
  );
}

export default CompositionInstructions;
