import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useLearningPathReport from 'features/useLearningPathReport';
import moment from 'moment';
import {useMemo} from 'react';
import styles from './ActivitySummary.module.scss';

interface ActivitySummaryProps {
  name: string;
  dateStart: string;
  dateEnd: string;
  activityStatus: string;
}

function ActivitySummary({
  name,
  dateStart,
  dateEnd,
  activityStatus,
}: ActivitySummaryProps): JSX.Element {
  const {getIconsAndTextBasedOnStatus} = useLearningPathReport();

  const activityStatusContent = useMemo(() => {
    return getIconsAndTextBasedOnStatus(activityStatus);
  }, [activityStatus]);

  const startMoment = moment(dateStart);
  const endMoment = moment(dateEnd);

  return (
    <div className={styles.container}>
      <div className={styles.activityName}>
        <FontAwesomeIcon icon={['fas', 'dumbbell']} color="#3c405f" />
        <span>Atividade: {name}</span>
      </div>

      <div className={styles.activityInfo}>
        <FontAwesomeIcon icon={['fas', 'calendar-alt']} color="#3c405f" />
        <span>
          Início:{' '}
          <time dateTime={startMoment.format('YYYY-MM-DD')}>
            {startMoment.format('DD/MM/YYYY')}
          </time>{' '}
          - Fim:{' '}
          <time dateTime={endMoment.format('YYYY-MM-DD')}>
            {endMoment.format('DD/MM/YYYY')}
          </time>
        </span>
        <div className={styles.status}>
          {activityStatusContent?.icon && (
            <FontAwesomeIcon {...activityStatusContent.icon} />
          )}
          <span>{activityStatusContent?.text}</span>
        </div>
      </div>
    </div>
  );
}

export default ActivitySummary;
