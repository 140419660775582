import {takeLatest} from 'redux-saga/effects';
import {
  addLikeInGeneralAnnotation,
  GeneralAnnotationTypes,
} from 'store/reducers/generalAnnotations';

const generalAnnotationSagas = [
  takeLatest(
    GeneralAnnotationTypes.ADD_LIKE_REQUEST,
    addLikeInGeneralAnnotation,
  ),
];

export default generalAnnotationSagas;
