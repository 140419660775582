import api from './api';

export interface FlagEvaluationRequest {
  flagKey: string;
  entityId?: string;
  entityContext?: Record<string, unknown>;
}

export interface MultipleFlagsEvaluationRequest {
  flagKeys?: string[];
  entityIds?: string[];
  entities?: {
    entityID?: string;
    entityType?: string;
    entityContext?: Record<string, any>;
  }[];
}

export const fetchFeatureFlagService = (data: FlagEvaluationRequest) =>
  api('https://flags.letrus.com.br/api/v1/evaluation/', {method: 'post', data});

export const fetchMultipleFeatureFlagsService = (
  data: MultipleFlagsEvaluationRequest,
) =>
  api('https://flags.letrus.com.br/api/v1/evaluation/batch/', {
    method: 'post',
    data,
  });
