import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useLearningPathReport from 'features/useLearningPathReport';
import {Accordeon} from 'letrus-ui';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {CriteriaContent} from '..';
import styles from './CriteriaChartCard.module.scss';

export interface CriteriaChartCardProps {
  criteria: CriteriaContent;
  onAccordeonClick(criteria: CriteriaContent): void;
}

function CriteriaChartCard({
  criteria,
  onAccordeonClick,
}: CriteriaChartCardProps): JSX.Element {
  const {getReportCriteriaAverageColor} = useLearningPathReport();
  const roundAverage = Math.round(criteria?.competence_average);
  const {colorHex, iconName} = getReportCriteriaAverageColor(
    roundAverage,
    false,
  );

  return (
    <div className={styles.wrapper}>
      <h2>{criteria?.competence_name}</h2>
      <h3>Entenda mais sobre o critério:</h3>
      <p>{criteria?.competence_grade_info?.description}</p>
      <div className={styles.averageWrapper}>
        <p>Nota no critério: </p>
        <p>
          <b
            className={styles.averageText}
            style={{color: colorHex}}
            data-testid="averageText"
          >
            {roundAverage} de 10
            <FontAwesomeIcon icon={['fas', iconName]} size="sm" />
          </b>
        </p>
      </div>
      <Accordeon
        items={[
          {
            content: (
              <div className={styles.accordeonCard}>
                <p>{criteria?.competence_grade_info?.activity_proposition}</p>
              </div>
            ),
            titleLeft: <u>E agora, qual é o próximo passo?</u>,
          },
        ]}
        onItemClick={() => onAccordeonClick(criteria)}
      />
    </div>
  );
}

export default CriteriaChartCard;
