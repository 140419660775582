import {takeLatest} from 'redux-saga/effects';
import {
  CompositionsTypes,
  createCompositionAPIV1,
  createCompositionForRewriteTest,
  createCompositionForTest,
  fetchAdvancedAvailableReviewCredits,
  fetchAdvancedFinishedCompositions,
  fetchAdvancedReviewedCompositions,
  fetchAdvancedWritingCompositions,
  fetchAvailableCredits,
  fetchCompositionById,
  fetchCompositionComments,
  fetchCompositionGenre,
  fetchCompositionGenreInfo,
  fetchCompositionIletrusScore,
  fetchCompositionIletrusTensorFlowScore,
  fetchCompositionInstructions,
  fetchCompositionJsonStatsAsync,
  fetchCompositionsAwaitingReview,
  fetchReviewedCompositions,
  fetchUserCompositions,
  reopenComposition,
  saveComposition,
  saveCompositionAPIV1,
  updateCompositionInstruction,
  writingToRewriting,
} from '../reducers/compositions';

const compositionSagas = [
  takeLatest(
    CompositionsTypes.CREATE_COMPOSITION_APIV1_REQUEST,
    createCompositionAPIV1,
  ),
  takeLatest(
    CompositionsTypes.CREATE_COMPOSITION_FOR_TEST_REQUEST,
    createCompositionForTest,
  ),
  takeLatest(
    CompositionsTypes.CREATE_COMPOSITION_FOR_REWRITE_TEST_REQUEST,
    createCompositionForRewriteTest,
  ),

  takeLatest(
    CompositionsTypes.FETCH_ADVANCED_AVAILABLE_REVIEW_CREDITS_REQUEST,
    fetchAdvancedAvailableReviewCredits,
  ),
  takeLatest(
    CompositionsTypes.FETCH_ADVANCED_WRITING_COMPOSITIONS_REQUEST,
    fetchAdvancedWritingCompositions,
  ),
  takeLatest(
    CompositionsTypes.FETCH_ADVANCED_FINISHED_COMPOSITIONS_REQUEST,
    fetchAdvancedFinishedCompositions,
  ),
  takeLatest(
    CompositionsTypes.FETCH_ADVANCED_REVIEWED_COMPOSITIONS_REQUEST,
    fetchAdvancedReviewedCompositions,
  ),
  takeLatest(
    CompositionsTypes.FETCH_AVAILABLE_CREDITS_REQUEST,
    fetchAvailableCredits,
  ),
  takeLatest(CompositionsTypes.FETCH_BY_ID_REQUEST, fetchCompositionById),
  takeLatest(
    CompositionsTypes.FETCH_COMPOSITIONS_AWAITING_REVIEW_REQUEST,
    fetchCompositionsAwaitingReview,
  ),
  takeLatest(
    CompositionsTypes.FETCH_COMPOSITION_JSON_STATS_ASYNC_REQUEST,
    fetchCompositionJsonStatsAsync,
  ),
  takeLatest(
    CompositionsTypes.FETCH_COMPOSITION_COMMENTS_REQUEST,
    fetchCompositionComments,
  ),
  takeLatest(
    CompositionsTypes.FETCH_COMPOSITION_ILETRUS_TENSOR_FLOW_SCORE_REQUEST,
    fetchCompositionIletrusTensorFlowScore,
  ),
  takeLatest(
    CompositionsTypes.FETCH_COMPOSITION_ILETRUS_SCORE_REQUEST,
    fetchCompositionIletrusScore,
  ),
  takeLatest(
    CompositionsTypes.FETCH_COMPOSITION_INSTRUCTIONS_REQUEST,
    fetchCompositionInstructions,
  ),
  takeLatest(CompositionsTypes.FETCH_GENRE_REQUEST, fetchCompositionGenre),
  takeLatest(
    CompositionsTypes.FETCH_GENRE_INFO_REQUEST,
    fetchCompositionGenreInfo,
  ),
  takeLatest(
    CompositionsTypes.FETCH_USER_COMPOSITIONS_REQUEST,
    fetchUserCompositions,
  ),
  takeLatest(
    CompositionsTypes.FETCH_REVIEWED_COMPOSITIONS_REQUEST,
    fetchReviewedCompositions,
  ),
  takeLatest(CompositionsTypes.REOPEN_REQUEST, reopenComposition),
  takeLatest(CompositionsTypes.SAVE_COMPOSITION_REQUEST, saveComposition),
  takeLatest(
    CompositionsTypes.SAVE_COMPOSITION_APIV1_REQUEST,
    saveCompositionAPIV1,
  ),
  takeLatest(
    CompositionsTypes.UPDATE_COMPOSITION_INSTRUCTION_REQUEST,
    updateCompositionInstruction,
  ),
  takeLatest(
    CompositionsTypes.WRITING_TO_REWRITING_REQUEST,
    writingToRewriting,
  ),
];

export default compositionSagas;
