import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {
  Question,
  Questionnaire,
  QuestionnaireStep,
  QuestionOption,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import {
  celebrateRatesStyles,
  discussRatesStyles,
  engageRatesStyles,
} from 'utils/ratesStyles';
import {
  CognitiveAxis,
  IndicatorCard,
  QuestionDetails,
  QuestionIndicatorsDetails,
  StudentAnswer,
} from 'utils/types/questionIndicatorsDetails';

interface GetReadingIndicatorQuestionnaireIdProps {
  steps: {
    type: string;
    order: number;
    id: number;
    has_indicator?: boolean;
    questionnaire_id?: number;
  }[];
}

interface GetCorrectChoiceProps {
  choices: QuestionOptionExtended[];
}

interface QuestionExtended extends Question {
  has_indicator: boolean;
}

export interface QuestionOptionExtended extends QuestionOption {
  order: string;
}

interface UseReadingIndicatorProps {
  questionsIndicators?: Questionnaire;
}

export interface GetStyleBasedOnRatesReturn {
  borderColor: string;
  backgroundColor: string;
  iconName: IconProp;
  iconColor: string;
  title: string;
  text: string;
  boxShadow: string;
}

interface GetTotalAnswersReturn {
  totalAnswers?: string;
}

interface GetCognitiveAxisFromLetrusIndicatorsReturn {
  mainExternalCognitiveAxis?: CognitiveAxis;
}

interface GetIndicatorsListReturn {
  indicatorsList?: IndicatorCard[];
}

interface getStudentsAnswersReturn {
  correctAnswers?: StudentAnswer[];
  incorrectAnswers?: StudentAnswer[];
}
interface GetCognitiveAxisListReturn {
  cognitiveAxisList?: CognitiveAxis[];
}

interface GetIncorrectChoiceOptionReturn {
  incorrectChoice?: string[];
}

export interface UseReadingIndicatorReturn {
  readingRelatedQuestions?: QuestionnaireStep[];
  nonReadingRelatedQuestions?: QuestionnaireStep[];
  getReadingIndicatorQuestionnaireId(
    params: GetReadingIndicatorQuestionnaireIdProps,
  ): number | undefined;
  getCorrectChoice(params: GetCorrectChoiceProps): string | undefined;
  getStyleBasedOnRates(
    engagementRates: number | undefined,
    correctAnswers: number | undefined,
  ): GetStyleBasedOnRatesReturn;
  getTotalAnswers(
    totalCorrectAnswers: number | undefined,
    totalIncorrectAnswers: number | undefined,
  ): GetTotalAnswersReturn;
  getCognitiveAxisFromLetrusIndicators(
    letrusIndicators: QuestionIndicatorsDetails['letrus_indicators'],
    relatedLetrusIndicatorId?: number,
  ): GetCognitiveAxisFromLetrusIndicatorsReturn;
  getIndicatorsList(
    letrusIndicators: QuestionIndicatorsDetails['letrus_indicators'],
    mainExternalIndicator: QuestionIndicatorsDetails['main_external_indicator'],
  ): GetIndicatorsListReturn;
  getStudentsAnswers(
    studentsAnswers: QuestionDetails,
  ): getStudentsAnswersReturn;
  getCognitiveAxisList(
    letrusIndicators: QuestionIndicatorsDetails['letrus_indicators'],
  ): GetCognitiveAxisListReturn;
  getCorrectChoiceOption(choices?: QuestionOptionExtended[]): string;
  getIncorrectChoiceOption(
    choices?: QuestionOptionExtended[],
  ): GetIncorrectChoiceOptionReturn;
}

function useReadingIndicator({
  questionsIndicators,
}: UseReadingIndicatorProps): UseReadingIndicatorReturn {
  const steps = questionsIndicators?.steps;

  const readingRelatedQuestions = steps?.filter((step: QuestionnaireStep) => {
    const question = step.question as QuestionExtended;

    if (question?.has_indicator === true) {
      return step;
    }

    return undefined;
  });

  const nonReadingRelatedQuestions = steps?.filter(
    (step: QuestionnaireStep) => {
      const question = step.question as QuestionExtended;

      if (question?.has_indicator !== true) {
        return step;
      }

      return undefined;
    },
  );

  function getReadingIndicatorQuestionnaireId({
    steps,
  }: GetReadingIndicatorQuestionnaireIdProps) {
    const step = steps.find((step) => step.has_indicator);

    return step?.questionnaire_id;
  }

  function getCorrectChoice({choices}: GetCorrectChoiceProps) {
    const correctChoice = choices.find((choice) => choice.is_correct === true);

    return correctChoice?.name;
  }

  function getTotalAnswers(
    totalCorrectAnswers: number,
    totalIncorrectAnswers: number,
  ): GetTotalAnswersReturn {
    if (
      totalCorrectAnswers !== undefined &&
      totalIncorrectAnswers !== undefined
    ) {
      return {
        totalAnswers: `${totalCorrectAnswers + totalIncorrectAnswers}`,
      };
    }
    return {totalAnswers: ''};
  }

  function getStyleBasedOnRates(
    engagementRates: number,
    correctAnswers: number,
  ): GetStyleBasedOnRatesReturn {
    if (engagementRates >= 50 && correctAnswers <= 59) {
      return {...discussRatesStyles};
    }
    if (engagementRates >= 50 && correctAnswers >= 60) {
      return {...celebrateRatesStyles};
    }
    return {...engageRatesStyles};
  }

  function getCognitiveAxisFromLetrusIndicators(
    letrusIndicators: QuestionIndicatorsDetails['letrus_indicators'],
    relatedLetrusIndicatorId: number | undefined,
  ): GetCognitiveAxisFromLetrusIndicatorsReturn {
    const relatedLetrusIndicator = letrusIndicators?.find(
      (indicator) => indicator.id === relatedLetrusIndicatorId,
    );

    return {
      mainExternalCognitiveAxis: relatedLetrusIndicator?.cognitive_axis,
    };
  }

  function getIndicatorsList(
    letrusIndicators: QuestionIndicatorsDetails['letrus_indicators'],
    mainExternalIndicator: QuestionIndicatorsDetails['main_external_indicator'],
  ): GetIndicatorsListReturn {
    const indicatorsList: IndicatorCard[] = [];

    if (mainExternalIndicator?.external_indicator) {
      const {mainExternalCognitiveAxis} = getCognitiveAxisFromLetrusIndicators(
        letrusIndicators,
        mainExternalIndicator?.related_letrus_indicator_id,
      );

      indicatorsList.push({
        id: mainExternalIndicator.external_indicator.id,
        externalName: mainExternalIndicator.external_indicator.external_name,
        cognitiveAxis: mainExternalCognitiveAxis,
        titleDescription:
          mainExternalIndicator.external_indicator.title_description,
        description: mainExternalIndicator.external_indicator.description,
      });
    }

    letrusIndicators?.forEach((indicator) => {
      indicatorsList.push({
        id: indicator.id,
        externalName: indicator.external_name,
        cognitiveAxis: indicator.cognitive_axis,
        titleDescription: indicator.title_description,
        description: indicator.description,
        contentFile: indicator.content_file,
      });
    });

    return {
      indicatorsList,
    };
  }

  function getStudentsAnswers(
    studentsAnswers: QuestionDetails,
  ): getStudentsAnswersReturn {
    const correctAnswers = studentsAnswers.answers?.filter(
      (answer) => answer.is_correct,
    );
    const incorrectAnswers = studentsAnswers.answers?.filter(
      (answer) => !answer.is_correct,
    );

    return {correctAnswers, incorrectAnswers};
  }
  function getCognitiveAxisList(
    letrusIndicators: QuestionIndicatorsDetails['letrus_indicators'],
  ): GetCognitiveAxisListReturn {
    const cognitiveAxisList = letrusIndicators?.map((indicator) => {
      return indicator.cognitive_axis;
    });

    return {
      cognitiveAxisList,
    };
  }

  function getCorrectChoiceOption(choices?: QuestionOptionExtended[]) {
    const correctChoice = choices?.find((choice) => choice.is_correct === true);

    return correctChoice?.order || '';
  }

  function getIncorrectChoiceOption(choices?: QuestionOptionExtended[]) {
    const incorrectChoices = choices?.filter((choice) => !choice.is_correct);

    const incorrectOrders = incorrectChoices?.map((choice, index) => {
      if (
        incorrectChoices?.length === 1 ||
        incorrectChoices?.length === index + 2
      )
        return `${choice.order}`;
      if (incorrectChoices?.length === index + 1) return ` e ${choice.order}`;
      return `${choice.order}, `;
    });

    return {incorrectChoice: incorrectOrders || ['']};
  }

  return {
    readingRelatedQuestions,
    nonReadingRelatedQuestions,
    getReadingIndicatorQuestionnaireId,
    getCorrectChoice,
    getStyleBasedOnRates,
    getTotalAnswers,
    getCognitiveAxisFromLetrusIndicators,
    getIndicatorsList,
    getStudentsAnswers,
    getCognitiveAxisList,
    getCorrectChoiceOption,
    getIncorrectChoiceOption,
  };
}

export default useReadingIndicator;
