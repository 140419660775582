import React, {CSSProperties, ReactNode} from 'react';
import SimpleTooltipOverlay from './SimpleTooltipOverlay';
import './TooltipOverlay.scss';

export interface TooltipOverlayProps {
  entity: any;
  entityCategory?: any;
  message?: string;
  className: string;
  style?: CSSProperties;
  addLikeAnnotation?: (entityId: string | number, like: any) => void;
  addLikeGeneralAnnotation?: (entityId: string | number, like: any) => void;
}

export const TooltipSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="TooltipOverlay__section">
      <div className="TooltipOverlay__sectionTitle">{title}</div>
      <div className="TooltipOverlay__sectionBody">{children}</div>
    </div>
  );
};

export const HintOverlay = ({children}: {children: ReactNode}) => {
  return (
    <SimpleTooltipOverlay
      style={{
        minWidth: 'auto',
        padding: '10px 15px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          lineHeight: '30px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
    </SimpleTooltipOverlay>
  );
};

const TooltipOverlay: React.FC<TooltipOverlayProps> = ({
  addLikeAnnotation,
  addLikeGeneralAnnotation,
  className,
  entity,
  entityCategory,
  message,
  style,
}) => {
  const suggestions = (entity.suggestions || []).filter(
    (suggestion: any) => suggestion,
  );

  return (
    <SimpleTooltipOverlay
      title={entityCategory}
      className={className}
      style={style}
      addLikeAnnotation={addLikeAnnotation}
      addLikeGeneralAnnotation={addLikeGeneralAnnotation}
      entity={entity}
    >
      {message && message !== 'Comentário' && message !== 'Comentários' ? (
        <TooltipSection title="Comentário">
          <div
            className="TooltipOverlay__sectionBody"
            dangerouslySetInnerHTML={{__html: message}}
          />
        </TooltipSection>
      ) : null}

      {suggestions && suggestions.length ? (
        <TooltipSection title="Sugestões">
          <ul>
            {entity.suggestions.map((suggestion: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{suggestion}</li>
            ))}
          </ul>
        </TooltipSection>
      ) : null}

      {entity.suggested_text ? (
        <TooltipSection title="Sugestão">
          <div
            dangerouslySetInnerHTML={{
              __html: entity.suggested_text,
            }}
          />
        </TooltipSection>
      ) : null}

      {!message && !entity.suggested_text ? (
        <p className="TooltipSection__NoCommentOrSuggestion">
          Nenhum comentário ou sugestão
        </p>
      ) : null}
    </SimpleTooltipOverlay>
  );
};

export default TooltipOverlay;
