import {LoadingBox} from 'letrus-ui';
import styles from './LoadingSlide.module.scss';

function LoadingSlide(): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <LoadingBox width="4rem" height="4rem" borderRadius={100} />
        <LoadingBox width="20rem" height="1.5rem" borderRadius={40} />
        <LoadingBox width="8.5rem" height="1rem" borderRadius={40} />
        <LoadingBox width="24.125rem" height="0.75rem" borderRadius={20} />
        <LoadingBox width="20.625rem" height="0.75rem" borderRadius={20} />
        <LoadingBox width="17.875rem" height="0.75rem" borderRadius={20} />
      </div>
      <div className={styles.rightContent}>
        <LoadingBox width="100%" height="26.25rem" borderRadius={8} />
      </div>
    </div>
  );
}

export default LoadingSlide;
