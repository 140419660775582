import InlineAlert from 'components/InlineAlert';
import useLearningPathReport from 'features/useLearningPathReport';
import {useRef, useState} from 'react';
import {Bar} from 'react-chartjs-2';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {criteriaChartOptions} from 'utils/criteriaChartOptions';
import {formatBarChartLabel} from 'utils/functions/formatBarChartLabel';
import MultigenreModal from '../MultigenreModal';
import CriteriaChartCard from './CriteriaChartCard';
import styles from './CriteriaChartModal.module.scss';
import NextArrow from './NextArrow';
import PreviousArrow from './PreviousArrow';

export interface CriteriaChartModalProps {
  onClickClose(): void;
  criteriaContent: CriteriaContent[];
  isLive: boolean;
  onAccordeonClick(criteria: CriteriaContent): void;
}

export interface CriteriaContent {
  competence_name: string;
  competence_average: number;
  competence_grade_info: CompetenceGradeInfo;
}

interface CompetenceGradeInfo {
  id: number;
  description: string;
  activity_proposition: string;
  competence: number;
}

function CriteriaChartModal({
  onClickClose,
  criteriaContent,
  isLive,
  onAccordeonClick,
}: CriteriaChartModalProps): JSX.Element {
  const sliderRef = useRef<Slider>(null);
  const [currentCriteria, setCurrentCriteria] = useState(0);
  const {getScoreChartColors} = useLearningPathReport();

  const chartData = criteriaContent?.map((content) =>
    Math.round(content?.competence_average),
  );

  return (
    <MultigenreModal
      onClickClose={onClickClose}
      title="Explore as informações abaixo para compreender os critérios:"
    >
      <>
        <div className={styles.chart}>
          <Bar
            options={criteriaChartOptions('gm')}
            data={{
              labels: criteriaContent?.map((content) =>
                formatBarChartLabel(content.competence_name, 12),
              ),
              datasets: [
                {
                  data: chartData,
                  barThickness: 52,
                  borderRadius: 15,
                  borderWidth: 2,
                  ...(chartData
                    ? getScoreChartColors('gm', chartData, currentCriteria)
                    : {}),
                },
              ],
            }}
          />
        </div>
        {isLive && (
          <InlineAlert
            text="Esta
            atividade está em andamento, as informações apresentadas podem
            sofrer alterações."
            customClassName={styles.activityInProgressWarning}
          />
        )}
        <Slider
          dots
          infinite={false}
          slidesToShow={1}
          slidesToScroll={1}
          className={styles.slider}
          variableWidth={false}
          ref={sliderRef}
          afterChange={(current: number) => setCurrentCriteria(current)}
          nextArrow={<NextArrow />}
          prevArrow={<PreviousArrow />}
        >
          {criteriaContent?.map((content, index) => (
            <div
              id={`slide-${index}`}
              key={content.competence_grade_info.id}
              className={styles.modalContent}
            >
              <CriteriaChartCard
                criteria={content}
                onAccordeonClick={onAccordeonClick}
                // eslint-disable-next-line react/no-array-index-key
                key={currentCriteria + index}
              />
            </div>
          ))}
        </Slider>
      </>
    </MultigenreModal>
  );
}

export default CriteriaChartModal;
