import SelectDropdownField from 'components/SelectDropdownField';
import {useEffect, useState} from 'react';
import {
  IndividualQuestionsInfoMock,
  QuestionsInfoMock,
} from 'store/mocks/__mocks__/learningPathStep';
import isObjectEmpty from 'utils/functions/isObjectEmpty';
import CheckboxQuestion from './CheckboxQuestion';
import styles from './QuestionnaireStep.module.scss';
import RadioQuestion from './RadioQuestion';
import RatingQuestion from './RatingQuestion';
import Statement from './Statement';
import TextQuestion from './TextQuestion';

export interface QuestionnaireStepProps {
  // TODO: replace QuestionsInfoMock with LetrusAPI correct type after endpoint is done.
  questions: QuestionsInfoMock[];
  activeTab: string;
  studentsIndividualAnswers: Record<string, IndividualQuestionsInfoMock>;
  labelText?: string;
}

export enum QuestionnaireTabs {
  summary = 'resumo',
  individual = 'individual',
  both = 'ambos',
}

enum QuestionTypes {
  checkbox = 'caixa de seleção.',
  radio = 'múltipla escolha.',
  text = 'dissertativa.',
  statement = 'texto sem interação.',
  rating = 'avaliação.',
}

function QuestionnaireStep({
  questions,
  activeTab,
  studentsIndividualAnswers,
  labelText = 'Escolha o(a) estudante',
}: QuestionnaireStepProps): JSX.Element {
  const [currentStudent, setCurrentStudent] = useState('');
  const showIndividualResults =
    activeTab === QuestionnaireTabs.individual ||
    (activeTab === QuestionnaireTabs.both &&
      currentStudent !== 'turma' &&
      !!currentStudent);

  const hasMockStudent = !isObjectEmpty(
    studentsIndividualAnswers?.mockStudent ?? {},
  );

  useEffect(() => {
    if (studentsIndividualAnswers) {
      if (activeTab === QuestionnaireTabs.individual) {
        setCurrentStudent(Object.keys(studentsIndividualAnswers)[0]);
      }
    }
  }, [studentsIndividualAnswers]);

  return (
    <section className={styles.wrapper}>
      {(activeTab === QuestionnaireTabs.individual ||
        activeTab === QuestionnaireTabs.both) &&
        !hasMockStudent &&
        studentsIndividualAnswers && (
          <div
            className={`${styles.studentSelect} ${
              activeTab === QuestionnaireTabs.both && styles.justifyCenter
            }`}
          >
            <label htmlFor="studentList">{labelText}</label>
            <SelectDropdownField
              name="studentList"
              onChange={(event) => {
                setCurrentStudent(event.currentTarget.value);
              }}
            >
              {activeTab === QuestionnaireTabs.both && (
                <option key="turma" value="turma">
                  Turma
                </option>
              )}
              {Object.values(studentsIndividualAnswers)?.map((student) => (
                <option key={student.user_id} value={student.user_id}>
                  {student.user_full_name}
                </option>
              ))}
            </SelectDropdownField>
          </div>
        )}

      {questions?.map((question) => (
        <article key={question?.question_id} className={styles.questionWrapper}>
          <h3 className={styles.questionName}>{question?.question_name}</h3>
          <p className={styles.questionType}>
            {`Tipo da questão: ${QuestionTypes[question?.question_type]}`}
          </p>

          <hr className={styles.divider} />

          {question?.question_type === 'checkbox' && (
            <CheckboxQuestion
              question={question}
              showIndividualResults={showIndividualResults}
              individualResults={{
                studentsIndividualAnswers,
                currentStudentId: currentStudent,
              }}
            />
          )}

          {question?.question_type === 'radio' && (
            <RadioQuestion
              question={question}
              showIndividualResults={showIndividualResults}
              individualResults={{
                studentsIndividualAnswers,
                currentStudentId: currentStudent,
              }}
            />
          )}

          {question?.question_type === 'text' && (
            <TextQuestion
              question={question}
              showIndividualResults={showIndividualResults}
              individualResults={{
                studentsIndividualAnswers,
                currentStudentId: currentStudent,
              }}
            />
          )}

          {question.question_type === 'statement' && (
            <Statement questionText={question?.question_text} />
          )}

          {question?.question_type === 'rating' && (
            <RatingQuestion
              question={question}
              showIndividualResults={activeTab === QuestionnaireTabs.individual}
              individualResults={{
                studentsIndividualAnswers,
                currentStudentId: currentStudent,
              }}
            />
          )}
        </article>
      ))}
    </section>
  );
}

export default QuestionnaireStep;
