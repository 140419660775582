import {Utils} from '@letrustech/letrus-api-interfaces';
import getFullTextFromEditorState from 'components/BasicEditor/lib/get-full-text-from-editor-state';
import moment from 'moment';
import compactObject from 'utils/libs/compact-object';
import api from './api';

export const fetchCompositionGenreService = (params?: Utils.GetParams) =>
  api('/api/v1/composition_genre/', {
    params,
  });

export const fetchCompositionGenreInfoService = (
  genreId: number,
  params?: Utils.GetParams,
) =>
  api(`/api/v1/composition_genre/${genreId}/details/`, {
    params,
  });

export const reopenCompositionService = (
  compositionId: number | string,
  params?: Utils.GetParams,
) =>
  api(`/api/v1/compositions/${compositionId}/unfinish/`, {
    method: 'post',
    params,
  });

export const fetchCompositionByIdService = (compositionId: number | string) =>
  api(`/api/v1/compositions/${compositionId}/`);

export const fetchUserCompositionsService = (params) =>
  api('/api/v1/compositions/', {
    params: {
      offset: 0,
      limit: 20,
      ...params,
    },
  });

export const fetchAvailableCreditsService = () =>
  api('/api/v1/compositions/', {
    params: {
      flag_manual_review: 2,
      source: 'letrus-avancado',
      revision_deadline__gte: moment().startOf('month').format(),
    } as Utils.GetParams,
  });

export const saveCompositionService = (
  composition: any,
  editorState: any,
): any => {
  if (composition.toJS) {
    composition = composition.toJS();
  }
  return api(`/api/v1/compositions/${composition.id}/?minimal=1`, {
    method: 'patch',
    data: {
      title: composition.title,
      flag_finished: composition.flag_finished,
      composition_raw:
        editorState && editorState.getCurrentContent
          ? getFullTextFromEditorState(editorState).join('\n\n')
          : composition.composition_raw,
      review_type: composition.review_type ? composition.review_type : null,
    },
  });
};

export const createCompositionAPIV1Service = (composition) =>
  api('/api/v1/compositions/', {
    method: 'post',
    data: composition,
  });

export const saveCompositionAPIV1Service = (composition) =>
  api(`/api/v1/compositions/${composition.id}/`, {
    method: 'put',
    data: {
      ...compactObject({
        ...composition,
        instruction_id_id: composition.instruction_id.id,
        update_statistics: 4,
      }),
    },
  });

export const fetchReviewedCompositionsService = () =>
  api('/api/v1/compositions/reviewed/');

export const fetchCompositionsAwaitingReviewService = () =>
  api('/api/v1/compositions/finished/');

export const fetchCompositionJsonStatsAsyncService = (
  composition,
  options,
  eEditorState,
) => {
  async function runFetch() {
    let res;
    try {
      const {data} = await api('/api/v1/json_stats_async/', {
        method: 'post',
        data: compactObject({
          id: composition.id,
          process_comments: options.processComments ? 1 : null,
          persist_to_db: options.persistToDb ? 1 : null,
          markers_to_process: options.markersToProcess,
          composition_raw:
            eEditorState && eEditorState.editorState && !options.fromMobile
              ? getFullTextFromEditorState(eEditorState.editorState).join(
                  '\n\n',
                )
              : options.compositionRaw,
        }),
      });

      res = data;
    } catch (err) {
      return err;
    }

    return res;
  }

  return runFetch();
};

export const writingToRewritingService = (compositionId: number | string) =>
  api(`/api/v1/compositions/${compositionId}/writing_to_rewriting/`, {
    method: 'post',
    data: {
      client_created: new Date(),
    },
  });

export const fetchCompositionCommentsService = (
  compositionId: number | string,
) => api(`/api/v1/compositions/${compositionId}/comments/`);

export const updateCompositionInstructionService = (
  compositionId: number | string,
  instructionId: number | string,
) =>
  api(`/compositions/update_theme/`, {
    params: {
      compositionId,
      instructionId,
    } as Utils.GetParams,
  });

export const fetchAdvancedAvailableReviewCreditsService = () =>
  api('/api/v1/compositions/advanced_available_review_credits/');

export const fetchAdvancedWritingCompositionsService = () =>
  api('/api/v1/compositions/advanced_writing_compositions/');

export const fetchAdvancedFinishedCompositionsService = () =>
  api('/api/v1/compositions/advanced_finished_compositions/');

export const fetchAdvancedReviewedCompositionsService = () =>
  api('/api/v1/compositions/advanced_reviewed_compositions/');

export const fetchCompositionIletrusTensorFlowScoreService = (
  compositionId: number | string,
) => api(`/api/v1/iletrus_tensor_flow_result/${compositionId}/`);

export const fetchCompositionIletrusScoreService = (
  compositionId: number | string,
) => api(`/api/v1/iletrus_score_result/${compositionId}/`);

export const createCompositionForTestService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/pick/`, {
    method: 'post',
  });

export const createCompositionForRewriteTestService = (
  testId: number | string,
) =>
  api(`/api/v1/tests/${testId}/pick/`, {
    method: 'post',
  });

export const fetchCompositionInstructionsService = (params?: Utils.GetParams) =>
  api('/api/v1/composition_instructions/', {
    params,
  });
