import underMaintenance from 'assets/images/under_maintenance.svg';
import styles from './UnderMaintenance.module.scss';

function UnderMaintenance(): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <main className={styles.container}>
        <img
          className={styles.image}
          src={underMaintenance}
          alt="Pessoas trabalhando"
        />
        <h1>
          Em manutenção{' '}
          <span role="img" aria-label="Ícone de construção">
            🚧
          </span>
        </h1>
        <b>Estamos trabalhando para melhorar os sistemas da Letrus.</b>
        <p>Voltaremos em breve!</p>
      </main>
    </div>
  );
}

export default UnderMaintenance;
