import {createBrowserHistory} from 'history';
import {applyMiddleware, createStore, Store} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {composeEnhancers} from 'utils/composeEnhancers';
import {throttleLast} from 'utils/functions/throttleLast';
import rootReducer, {ApplicationState} from './rootReducer';
import rootSaga from './rootSaga';
import {loadState, saveState} from './services/localStorageServices';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

const persistedState = loadState();

const store: Store<ApplicationState> = createStore(
  rootReducer(),
  persistedState,
  enhancer,
);

sagaMiddleware.run(rootSaga);

store.subscribe(
  throttleLast(() => {
    saveState(store.getState());
  }, 200),
);
export default store;
