import useReadingIndicator from 'features/useReadingIndicator';
import {Carousel} from 'letrus-ui';
import {QuestionIndicatorsDetails} from 'utils/types/questionIndicatorsDetails';
import IndicatorsCard from './IndicatorsCard';
import styles from './IndicatorsRelatedSlide.module.scss';

export interface IndicatorsRelatedSlideProps {
  questionName: string;
  letrusIndicators: QuestionIndicatorsDetails['letrus_indicators'];
  mainExternalIndicator: QuestionIndicatorsDetails['main_external_indicator'];
}

function IndicatorsRelatedSlide({
  questionName,
  letrusIndicators,
  mainExternalIndicator,
}: IndicatorsRelatedSlideProps): JSX.Element {
  const {getIndicatorsList} = useReadingIndicator({});

  const {indicatorsList} = getIndicatorsList(
    letrusIndicators,
    mainExternalIndicator,
  );

  return (
    <div className={styles.container}>
      <h4>Veja o(s) Indicador(es) de Leitura relacionados a {questionName}:</h4>
      <p>
        Os Indicadores de Leitura são relacionados a eixos cognitivos
        específicos e a descritores SAEB e habilidades da BNCC.
      </p>
      <div className={styles.carouselContainer}>
        <Carousel arrowSize="sm" minWidth={270} spaceBetweenSlides={16}>
          {indicatorsList?.map((indicator) => (
            <IndicatorsCard key={indicator.id} indicator={indicator} />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default IndicatorsRelatedSlide;
