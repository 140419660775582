import {IndicatorCard} from 'utils/types/questionIndicatorsDetails';
import styles from './IndicatorsCard.module.scss';

export enum CognitiveAxisTagName {
  Avaliar = 'evaluateTag',
  Analisar = 'analyzeTag',
  Reconhecer = 'recognizeTag',
}

export interface IndicatorsCardProps {
  indicator: IndicatorCard;
}

function IndicatorsCard({indicator}: IndicatorsCardProps): JSX.Element {
  return (
    <div className={styles.container}>
      <h4 className={styles.cognitiveAxisTitle}>
        Eixo Cognitivo:{' '}
        <span
          className={`${styles.cognitiveAxisTag} ${
            indicator.cognitiveAxis?.name
              ? styles[CognitiveAxisTagName[indicator.cognitiveAxis?.name]]
              : ''
          }`}
        >
          {indicator.cognitiveAxis?.name}
        </span>
      </h4>
      <h5>{indicator.externalName}</h5>
      {indicator.contentFile && (
        <a
          href={indicator.contentFile}
          target="_blank"
          rel="noopener noreferrer"
        >
          Relacionado a Descritores Saeb e Habilidades BNCC
        </a>
      )}
      <h6>{indicator.titleDescription}</h6>
      <p>{indicator.description}</p>
    </div>
  );
}

export default IndicatorsCard;
