import {takeLatest} from 'redux-saga/effects';
import {
  fetchZeroGradeComment,
  ZeroGradeCommentsTypes,
} from '../reducers/zeroGradeComments';

const zeroGradeCommentSagas = [
  takeLatest(
    ZeroGradeCommentsTypes.FETCH_ZERO_GRADE_COMMENTS_REQUEST,
    fetchZeroGradeComment,
  ),
];

export default zeroGradeCommentSagas;
