export const mainFilterOptions = [
  {
    text: 'Concluídos',
    id: 'finished',
  },
  {
    text: 'Zeradas',
    id: 'zeroed',
  },
];

export const filterOptions = [
  {
    category: 'Por Status da Trilha',
    filters: [
      {
        text: 'Concluídos',
        id: 'finished',
      },
    ],
  },
  {
    category: 'Por Status da Redação',
    filters: [
      {
        text: 'Zeradas',
        id: 'zeroed',
      },
    ],
  },
];
