import api from './api';

export const fetchAbandonReviewReasonsService = () =>
  api('/api/v1/abandon_review_reasons/');

export const abandonReviewService = (data: any) =>
  api(`/api/v1/composition_reviews/${data.compositionReviewId}/`, {
    method: 'delete',
    data: {
      reason_id: data.abandonReasonId,
    },
  });
