import useLearningPathReport from 'features/useLearningPathReport';
import {NewStarRating} from 'letrus-ui';
import {
  IndividualQuestionsInfoMock,
  QuestionsInfoMock,
} from 'store/mocks/__mocks__/learningPathStep';
import {useMobile} from 'utils/hooks';
import styles from './RatingQuestion.module.scss';

export interface RatingQuestionProps {
  question: QuestionsInfoMock;
  showIndividualResults: boolean;
  individualResults: {
    currentStudentId: string;
    studentsIndividualAnswers: Record<string, IndividualQuestionsInfoMock>;
  };
}

function RatingQuestion({
  question,
  showIndividualResults,
  individualResults,
}: RatingQuestionProps): JSX.Element {
  const {getStudentIndividualQuestionAnswers} = useLearningPathReport();
  const {isMobile} = useMobile();

  const {individualAnswer} = getStudentIndividualQuestionAnswers({
    question,
    answersFromAllStudents: individualResults?.studentsIndividualAnswers,
    currentStudent: individualResults?.currentStudentId,
  });

  return (
    <div>
      <div
        className={styles.questionText}
        dangerouslySetInnerHTML={{__html: question?.question_text}}
      />

      <p className={styles.performanceText}>Avaliação média dos estudantes:</p>
      <span className={styles.starRatingWrapper}>
        <NewStarRating
          amountOfStars={5}
          activeStars={
            showIndividualResults
              ? Number(individualAnswer)
              : question?.average_rating
          }
          onClick={() => {}}
          biggerRatingLabel={question?.average_higher_label}
          smallerRatingLabel={question?.average_lower_label}
          size={isMobile ? '2x' : '3x'}
          disableHoverEffect
        />
      </span>
    </div>
  );
}

export default RatingQuestion;
