import api from './api';

export const fetchZeroGradeCommentService = (
  genreId: number,
  userRole: string,
) =>
  api(`/api/v1/zero_grade_comment/`, {
    params: {
      genre_id: genreId,
      user_role: userRole,
    },
  });
