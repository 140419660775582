import {fromJS, Map} from 'immutable';
import {Reducer} from 'redux';
import {createSelector} from 'reselect';
import {action} from 'typesafe-actions';

// Action types
export enum UITypes {
  OPEN_THEME_LIGHT_BOX = '@newUI/OPEN_THEME_LIGHT_BOX',
  CLOSE_THEME_LIGHT_BOX = '@newUI/CLOSE_THEME_LIGHT_BOX',
}

// Data types

// State type
export interface UIState extends Map<string, any> {
  readonly modalName: string;
  readonly modalProps: Record<string, unknown>;
  readonly themeAidLightBoxIsOpen: boolean;
  readonly themeAidLightBoxIndex: number;
}

// UI actions
export const openThemeLightboxRequest = (index: number | string) =>
  action(UITypes.OPEN_THEME_LIGHT_BOX, {index});

export const closeThemeLightboxRequest = (index: number | string) =>
  action(UITypes.CLOSE_THEME_LIGHT_BOX, {index});

// Selectors
const uiSelector = (state) => state.get('newUI');

export const isThemeLightBoxOpen = createSelector(uiSelector, (ui): boolean =>
  ui.get('themeAidLightBoxIsOpen'),
);

export const getThemeLightBoxIndex = createSelector(uiSelector, (ui): boolean =>
  ui.get('themeAidLightBoxIndex'),
);

// Initial state
export const INITIAL_STATE: UIState = fromJS({
  modalName: null,
  modalProps: {},
  themeAidLightBoxIsOpen: false,
  themeAidLightBoxIndex: 0,
});

// Reducer
export const reducer: Reducer<UIState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UITypes.OPEN_THEME_LIGHT_BOX:
      return state.withMutations((prevState) =>
        prevState
          .set('themeAidLightBoxIsOpen', true)
          .set('themeAidLightBoxIndex', action.payload.index),
      );

    case UITypes.CLOSE_THEME_LIGHT_BOX:
      return state.withMutations((prevState) =>
        prevState
          .set('themeAidLightBoxIsOpen', false)
          .set('themeAidLightBoxIndex', action.payload.index),
      );

    default:
      return state;
  }
};

export default reducer;
