import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LoadingRow from 'components/LoadingComponents/LoadingRow';
import useReadingIndicator from 'features/useReadingIndicator';
import {Button, Row} from 'letrus-ui';
import {useState} from 'react';
import {
  QuestionDetails,
  QuestionIndicatorsDetails,
} from 'utils/types/questionIndicatorsDetails';
import ChoicesModal from '../ChoicesModal';
import {CognitiveAxisTagName} from '../IndicatorsRelatedSlide/IndicatorsCard';
import styles from './StudentsPerformanceSlide.module.scss';

export interface StudentsPerformanceSlideProps {
  questionIndicatorDetails: QuestionIndicatorsDetails;
  studentsAnswers: QuestionDetails;
  isLoadingStudentsAnswers?: boolean;
}

function StudentsPerformanceSlide({
  questionIndicatorDetails,
  studentsAnswers,
  isLoadingStudentsAnswers,
}: StudentsPerformanceSlideProps): JSX.Element {
  const [isChoicesModalOpen, setIsChoicesModalOpen] = useState(false);
  const {getStudentsAnswers, getCognitiveAxisList} = useReadingIndicator({});
  const {correctAnswers, incorrectAnswers} =
    getStudentsAnswers(studentsAnswers);
  const {cognitiveAxisList} = getCognitiveAxisList(
    questionIndicatorDetails.letrus_indicators,
  );

  return (
    <section className={styles.container}>
      {isLoadingStudentsAnswers ? (
        <div>
          <LoadingRow width="100%" height={100} />
          <div className={styles.loadingRowContainer}>
            <LoadingRow width="50%" height={333} />
            <LoadingRow width="50%" height={333} />
          </div>
        </div>
      ) : (
        <>
          <div>
            <Row padding="0">
              <>
                <div className={styles.titleContainer}>
                  <h3 className={styles.cognitiveAxisTitle}>
                    Eixo Cognitivo trabalhado:
                    {cognitiveAxisList?.map((cognitiveAxis) => (
                      <span
                        key={cognitiveAxis?.id}
                        className={`${styles.cognitiveAxisTag} ${
                          cognitiveAxis?.name
                            ? styles[CognitiveAxisTagName[cognitiveAxis?.name]]
                            : ''
                        }`}
                      >
                        {cognitiveAxis?.name}
                      </span>
                    ))}
                  </h3>
                  <h4 className={styles.questionNameText}>
                    Veja abaixo qual foi a alternativa escolhida por cada
                    estudante na {studentsAnswers.name}:
                  </h4>
                  <p>Questão de Múltipla Escolha</p>
                </div>
                <Button
                  variant="secondary"
                  text="Ver Alternativas"
                  size="medium"
                  onClick={() => setIsChoicesModalOpen(true)}
                />
              </>
            </Row>
          </div>
          <div className={styles.studentsListContainer}>
            <div className={styles.studentsListContent}>
              <p className={styles.studentsListTitle}>
                <FontAwesomeIcon
                  icon={['fad', 'square-check']}
                  color="#2FAC66"
                  className={styles.icon}
                  size="1x"
                />
                Estudantes que <b>responderam corretamente</b> a questão :
              </p>
              <div>
                {correctAnswers?.length ? (
                  <div className={styles.tableWrapper}>
                    <table className={styles.studentsTable}>
                      <thead>
                        <tr className={styles.tableHeader}>
                          <th>Estudante</th>
                          <th>Resposta</th>
                          <th>Alternativa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {correctAnswers?.map((correctAnswer) => (
                          <tr
                            key={correctAnswer.student_name}
                            className={styles.tableContent}
                          >
                            <td>{correctAnswer.student_name}</td>
                            <td>
                              <FontAwesomeIcon
                                icon={['fad', 'square-check']}
                                color="#2FAC66"
                                className={styles.icon}
                              />
                              <b>Correta</b>
                            </td>
                            <td>{correctAnswer.alternative}</td>{' '}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className={styles.noAnswersText}>
                    Nenhum estudante respondeu corretamente a questão.
                  </p>
                )}
              </div>
            </div>
            <div className={styles.studentsListContent}>
              <p className={styles.studentsListTitle}>
                <FontAwesomeIcon
                  icon={['fad', 'diamond-exclamation']}
                  color="#E94B56"
                  className={styles.icon}
                  size="1x"
                />
                Estudantes que <b>responderam incorretamente</b> a questão:
              </p>
              {incorrectAnswers?.length ? (
                <div>
                  <div className={styles.tableWrapper}>
                    <table className={styles.studentsTable}>
                      <thead>
                        <tr className={styles.tableHeader}>
                          <th>Estudante</th>
                          <th>Resposta</th>
                          <th>Alternativa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {incorrectAnswers?.map((incorrectAnswer) => (
                          <tr
                            key={incorrectAnswer.student_name}
                            className={styles.tableContent}
                          >
                            <td>{incorrectAnswer.student_name}</td>
                            <td>
                              <FontAwesomeIcon
                                icon={['fad', 'diamond-exclamation']}
                                color="#E94B56"
                                className={styles.icon}
                              />
                              <b>Incorreta</b>
                            </td>
                            <td>{incorrectAnswer.alternative})</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <p className={styles.noAnswersText}>
                  Parabéns! Nenhum estudante respondeu incorretamente a questão.
                </p>
              )}
            </div>
          </div>
        </>
      )}
      {isChoicesModalOpen && (
        <ChoicesModal
          onClickClose={() => setIsChoicesModalOpen(false)}
          choices={questionIndicatorDetails?.choices}
          questionText={questionIndicatorDetails?.text}
        />
      )}
    </section>
  );
}

export default StudentsPerformanceSlide;
