import {LetrusApi} from '@letrustech/letrus-api-interfaces';

export const NOTIFICATION_TITLE_MAX_NUMBER_OF_CHARACTERS = 75;
export const DROPDOWN_NOTIFICATION_DESCRIPTION_MAX_NUMBER_OF_CHARACTERS = 155;
export const PANEL_NOTIFICATION_DESCRIPTION_MAX_NUMBER_OF_CHARACTERS = 310;
export const NOTIFICATION_LIMIT_PER_PAGE = 10;
export const MIN_NUMBER_OF_NOTIFICATIONS_TO_ALLOW_PAGINATION = 11;

type NotificationLinkType = 'internal' | 'external';
type NotificationSeenState = 'not-received' | 'not-opened' | null;

interface UseNotificationReturn {
  getLinkType(notification: LetrusApi.NotificationLog): NotificationLinkType;
  hasBeenSeen(notification: LetrusApi.NotificationLog): boolean;
  getNotificationSeenState(
    notification: LetrusApi.NotificationLog,
  ): NotificationSeenState;
}

function useNotification(): UseNotificationReturn {
  function getLinkType(
    notification: LetrusApi.NotificationLog,
  ): NotificationLinkType {
    if (notification?.link?.includes('http')) {
      return 'external';
    }

    return 'internal';
  }

  function getNotificationSeenState(
    notification: LetrusApi.NotificationLog,
  ): NotificationSeenState {
    if (!notification.link && !notification.received) {
      return 'not-received';
    }

    if (notification.link && !(notification as any).opened) {
      return 'not-opened';
    }

    return null;
  }

  function hasBeenSeen(notification: LetrusApi.NotificationLog): boolean {
    return notification.link
      ? !!(notification as any).opened
      : !!notification.received;
  }

  return {
    getLinkType,
    hasBeenSeen,
    getNotificationSeenState,
  };
}

export default useNotification;
