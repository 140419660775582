import TooltipComponent from 'components/TooltipComponent';
import {Step} from '..';
import styles from './Stepper.module.scss';

export interface StepperProps {
  steps: Step[];
}

function Stepper({steps}: StepperProps): JSX.Element {
  function getStepType(step: Step): string {
    if (step.questionnaire_id) return 'Questionário';
    if (step.test_template_id) return 'Escrita';
    if (step.rewriting) return 'Reescrita';
    if (step.pedagogical_content_id) return 'Leitura';

    return 'Tipo não encontrado';
  }

  return (
    <div className={styles.container}>
      {steps.map((step) => (
        <TooltipComponent
          key={`step-${step.id}`}
          placement="top"
          trigger="hover"
          overlay={getStepType(step)}
          tooltipTrigger={
            <span
              className={`
            ${styles.step} 
            ${step.isCompleted ? styles.completed : ''}
          `}
            />
          }
        />
      ))}
    </div>
  );
}

export default Stepper;
