import api, {GetParams} from './api';

export interface FetchThemesParams {
  params?: GetParams;
  genreId?: string;
  learningPathType?: string;
  reviewGridId?: string | number;
  filter?: string;
  limit?: number;
  reviewType?: string;
  hasTestTemplate?: boolean;
}

export const fetchThemesService = ({
  params,
  genreId,
  learningPathType,
  reviewGridId,
  filter,
  limit,
  reviewType,
  hasTestTemplate,
}: FetchThemesParams) => {
  return api(`/api/v1/composition_instructions/`, {
    params: {
      ...params,
      filter,
      genre_id: genreId,
      review_grid_id: reviewGridId,
      ...(learningPathType && {learning_path_type: learningPathType}),
      limit,
      review_type: reviewType,
      has_test_template: hasTestTemplate,
    },
  });
};

export const fetchThemeByIdService = (themeId: number) =>
  api(`/api/v1/composition_instructions/${themeId}/`);

export const fetchAidsService = (params?: GetParams) =>
  api(`/api/v1/composition_aids/`, {
    params,
  });
