import styles from './PreviousArrow.module.scss';

function PreviousArrow(props) {
  const {className, onClick, currentSlide} = props;

  return (
    <div
      role="button"
      className={`${className} ${styles.previousArrow}`}
      onClick={onClick}
    >
      {currentSlide !== 0 ? (
        <img
          alt="prev-arrow"
          src="https://s3.sa-east-1.amazonaws.com/media.letrus.com.br/arrow-left-enabled.svg"
        />
      ) : (
        <img
          alt="prev-arrow-disabled"
          src="https://s3.sa-east-1.amazonaws.com/media.letrus.com.br/arrow-left.svg"
        />
      )}
    </div>
  );
}

export default PreviousArrow;
