import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {AxiosResponse} from 'axios';
import {SidebarNotification} from 'store/reducers/notifications';
import api, {GetParams} from './api';

export const fetchNotificationsService = (params?: GetParams) =>
  api('/api/v1/notification_logs/', {
    params: {
      ...params,
      created__year: new Date().getFullYear(),
    },
  });

export const updateNotificationByIdService = (
  notificationId: number,
  notificationData: Partial<LetrusApi.NotificationLog>,
) =>
  api(`/api/v1/notification_logs/${notificationId}/`, {
    method: 'patch',
    data: notificationData,
  });

export function fetchSidebarNotificationService(): Promise<
  AxiosResponse<SidebarNotification>
> {
  return api(`api/v1/teacher_notifications/`, {
    method: 'get',
  });
}
