import {
  School,
  SchoolGroupList,
  UserRoleEmbed,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import Loading from 'components/Loading';
import {List} from 'immutable';
import sign from 'jwt-encode';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  fetchSchoolGroupsRequest,
  fetchSchoolsRequest,
  getSchoolGroups,
  getSchools,
} from 'store/reducers/schoolGroups';
import {getUserRoles} from 'store/reducers/user';
import {ApplicationState} from 'store/rootReducer';

interface StateProps {
  userRole: ImmutableMap<UserRoleEmbed>;
  schools: List<ImmutableMap<School>>;
  schoolGroups: List<ImmutableMap<SchoolGroupList>>;
}

interface DispatchProps {
  fetchSchoolGroupsRequest: typeof fetchSchoolGroupsRequest;
  fetchSchoolsRequest: typeof fetchSchoolsRequest;
}

type SocioEmotionalDashboardProps = StateProps & DispatchProps;

function SocioemotionalDashboard({
  schools,
  userRole,
  schoolGroups,
  fetchSchoolsRequest,
  fetchSchoolGroupsRequest,
}: SocioEmotionalDashboardProps): JSX.Element {
  const [metabaseURL, setMetabaseURL] = useState('');
  const {REACT_APP_METABASE_SECRET_KEY, REACT_APP_METABASE_SITE_URL} =
    process.env;

  useEffect(() => {
    fetchSchoolsRequest();
    fetchSchoolGroupsRequest();
  }, []);

  useEffect(() => {
    const hasSchoolData = schools.size && schoolGroups.size;

    if (REACT_APP_METABASE_SECRET_KEY && hasSchoolData) {
      const dashboardConfig = {
        resource: {
          dashboard: 273,
        },
        params: {
          rede: userRole.get('network_name'),
          escola: schools.map((school) => school.get('long_name')).toArray(),
          turma: schoolGroups.map((group) => group.get('long_name')).toArray(),
        },
      };

      const token = sign(dashboardConfig, REACT_APP_METABASE_SECRET_KEY);

      setMetabaseURL(
        `${REACT_APP_METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=false`,
      );
    }
  }, [schools, schoolGroups]);

  return metabaseURL ? (
    <iframe
      src={metabaseURL}
      title="Painel"
      style={{width: '100%', height: '100%'}}
    />
  ) : (
    <Loading show />
  );
}

export default connect(
  (state: ApplicationState) => ({
    schools: getSchools(state),
    schoolGroups: getSchoolGroups(state),
    userRole: getUserRoles(state).first(),
  }),
  {fetchSchoolGroupsRequest, fetchSchoolsRequest},
)(SocioemotionalDashboard);
