import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'letrus-ui';
import styles from './RequestStatusModal.module.scss';

enum StatusColor {
  success = '#306230',
  error = '#F7BB49',
  loading = '',
}

enum StatusTextColor {
  success = '#fff',
  error = '#000',
  loading = '#000',
}

enum StatusButtonText {
  success = 'Finalizar',
  error = 'Tentar novamente',
  loading = 'Aguarde',
}

const StatusIcon: Record<string, IconProp> = {
  success: ['fad', 'check-circle'],
  error: ['fad', 'calendar-exclamation'],
  loading: ['fad', 'loader'],
};

enum StatusMessage {
  success = 'Sua solicitação foi realizada com sucesso!',
  error = 'Ops... Ocorreu um Erro',
  loading = 'Estamos processando sua solicitação',
}

enum StatusHint {
  success = '',
  error = 'Por favor tente novamente',
  loading = '',
}

interface RequestResponseModalProps {
  open: boolean;
  status?: string;
  onClose(): void;
  onError?(): void;
  successText?: string;
}

function RequestStatusModal({
  open,
  status,
  onClose,
  onError,
  successText,
}: RequestResponseModalProps): JSX.Element {
  const isLoading = status === 'loading';

  function handleClose() {
    if (status === 'error') {
      onError?.();
    } else {
      onClose();
    }
  }

  return status ? (
    <Modal
      buttonClose={false}
      userRole="teacher"
      onClose={handleClose}
      clickOutsideToClose={false}
      isOpen={open}
    >
      <div className={styles.wrapper}>
        <header>
          <h3>
            {(status === 'success' && successText) || StatusMessage[status]}
          </h3>
          {StatusHint[status] && <p>{StatusHint[status]}</p>}
        </header>

        <FontAwesomeIcon
          size="5x"
          spin={isLoading}
          icon={StatusIcon[status]}
          color={StatusColor[status]}
        />

        <div className={styles.buttonWrapper}>
          <Button
            size="small"
            type="button"
            variant="secondary"
            disabled={isLoading}
            onClick={handleClose}
            borderColor="transparent"
            text={StatusButtonText[status]}
            textColor={StatusTextColor[status]}
            backgroundColor={StatusColor[status]}
          />
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
}

export default RequestStatusModal;
