export function throttleLast(
  callback: () => void,
  timeBetweenCalls: number,
): () => void {
  let timeout;
  let lastCallTime;

  return function throttleLastFunction() {
    const thisCallTime = Date.now();
    const timeElapsed = thisCallTime - lastCallTime;

    if (!lastCallTime || timeElapsed >= timeBetweenCalls) {
      callback.apply(null, []);
      lastCallTime = thisCallTime;
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callback.apply(null, []);
        lastCallTime = Date.now();
      }, timeBetweenCalls - timeElapsed);
    }
  };
}
