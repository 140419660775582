import {takeEvery} from 'redux-saga/effects';
import {
  FeatureFlagsTypes,
  fetchFeatureFlag,
  fetchMultipleFeatureFlags,
} from '../reducers/featureFlags';

const featureFlagSagas = [
  takeEvery(FeatureFlagsTypes.FETCH_REQUEST, fetchFeatureFlag),
  takeEvery(
    FeatureFlagsTypes.FETCH_MULTIPLE_REQUEST,
    fetchMultipleFeatureFlags,
  ),
];

export default featureFlagSagas;
