import {takeLatest} from 'redux-saga/effects';
import {
  CompositionReviewsTypes,
  createOrUpdateCompetenceGrade,
  fetchCompositionReviewByCompositionId,
  fetchCompositionReviewByCompositionIdWithRewriting,
  updateCompositionGrade,
  zeroReview,
} from '../reducers/compositionReviews';

const compositionReviewSagas = [
  takeLatest(CompositionReviewsTypes.ZERO_REQUEST, zeroReview),
  takeLatest(
    CompositionReviewsTypes.UPDATE_COMPOSITION_GRADE_REQUEST,
    updateCompositionGrade,
  ),
  takeLatest(
    CompositionReviewsTypes.CREATE_OR_UPDATE_COMPETENCE_GRADE_REQUEST,
    createOrUpdateCompetenceGrade,
  ),
  takeLatest(
    CompositionReviewsTypes.FETCH_BY_COMPOSITION_ID_REQUEST,
    fetchCompositionReviewByCompositionId,
  ),
  takeLatest(
    CompositionReviewsTypes.FETCH_COMPOSITION_REVIEW_BY_COMPOSITION_ID_WITH_REWRITING_REQUEST,
    fetchCompositionReviewByCompositionIdWithRewriting,
  ),
];

export default compositionReviewSagas;
