import useLearningPathReport from 'features/useLearningPathReport';
import {Checkbox, Tag} from 'letrus-ui';
import {ForwardedRef, forwardRef, InputHTMLAttributes} from 'react';
import styles from './OptionCard.module.scss';

export type Status =
  | 'finished'
  | 'in_progress'
  | 'not_started'
  | 'pending'
  | 'zeroed';

export interface OptionCardProps extends InputHTMLAttributes<HTMLInputElement> {
  onClick(): void;
  text: string;
  status: Status;
  selected?: boolean;
  disabled?: boolean;
}

function OptionCard(
  {
    onClick,
    text,
    status,
    selected,
    disabled,
    ...checkBoxProps
  }: OptionCardProps,
  checkboxRef: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const {getOptionCardStatusConfig} = useLearningPathReport();

  const {tagText, boxShadow, mainColor, backgroundColor, tagTextColor} =
    getOptionCardStatusConfig(status);

  return (
    <div
      role="button"
      data-testid="OptionCard"
      onClick={disabled ? undefined : onClick}
      className={`${styles.optionButtonContainer} ${
        styles[selected ? 'selected' : 'default']
      }   ${disabled ? styles.disabled : ''}`}
    >
      <div className={styles.optionButtonContent}>
        <div className={styles.content}>
          <Checkbox
            ref={checkboxRef}
            onClick={disabled ? undefined : onClick}
            checked={selected}
            disabled={selected ? false : disabled}
            className={styles.optionButtonCheckbox}
            {...checkBoxProps}
          />
          <p className={styles.optionButtonText}>{text}</p>
        </div>
        <div className={styles.content}>
          <Tag
            variant="readOnly"
            text={tagText}
            textColor={tagTextColor}
            boxShadow={boxShadow}
            mainColor={mainColor}
            backgroundColor={backgroundColor}
          />
        </div>
      </div>
    </div>
  );
}

export default forwardRef(OptionCard);
