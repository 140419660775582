import useLearningPathReport from 'features/useLearningPathReport';
import ReactWordcloud from 'react-wordcloud';
import {
  IndividualQuestionsInfoMock,
  QuestionsInfoMock,
} from 'store/mocks/__mocks__/learningPathStep';
import {roundArrow} from 'tippy.js';
import styles from './TextQuestion.module.scss';

export interface TextQuestionProps {
  question: QuestionsInfoMock;
  showIndividualResults: boolean;
  individualResults?: {
    currentStudentId?: string;
    studentsIndividualAnswers?: Record<string, IndividualQuestionsInfoMock>;
  };
}

function TextQuestion({
  question,
  individualResults,
  showIndividualResults,
}: TextQuestionProps): JSX.Element {
  const {getStudentIndividualQuestionAnswers} = useLearningPathReport();

  const {individualAnswer} = getStudentIndividualQuestionAnswers({
    question,
    answersFromAllStudents: individualResults?.studentsIndividualAnswers ?? {},
    currentStudent: individualResults?.currentStudentId ?? '0',
  });

  const showQuestionAnswer =
    !!individualAnswer || !!question?.most_typed_words?.length;

  return (
    <>
      <div
        className={styles.questionText}
        dangerouslySetInnerHTML={{__html: question?.question_text}}
      />

      {showQuestionAnswer && (
        <>
          <p className={styles.performanceText}>
            {showIndividualResults
              ? 'Resposta do estudante:'
              : 'Palavras mais utilizadas pelos estudantes:'}
          </p>
          {showIndividualResults ? (
            <p className={styles.studentAnswer}>{individualAnswer}</p>
          ) : (
            <div className={styles.wordCloudWrapper}>
              {process.env.NODE_ENV !== 'test' && (
                <ReactWordcloud
                  words={
                    question?.most_typed_words?.length
                      ? question?.most_typed_words?.map((word) => ({
                          text: word?.word,
                          value: word?.occurrences,
                        }))
                      : []
                  }
                  options={{
                    rotations: 1,
                    rotationAngles: [0, 0],
                    fontSizes: [16, 100],
                    padding: 10,
                    spiral: 'rectangular',
                    deterministic: true,
                    tooltipOptions: {
                      theme: 'dark',
                      arrow: roundArrow,
                    },
                  }}
                  callbacks={{
                    getWordTooltip: (word) => `Apareceu ${word?.value} vezes.`,
                  }}
                  minSize={[320, 160]}
                  maxWords={100}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default TextQuestion;
