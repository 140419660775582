import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useLearningPathReport from 'features/useLearningPathReport';
import {NewButton} from 'letrus-ui';
import Tooltip from 'rc-tooltip';
import {Bar} from 'react-chartjs-2';
import {criteriaChartOptions} from 'utils/criteriaChartOptions';
import {formatBarChartLabel} from 'utils/functions/formatBarChartLabel';
import styles from './CriteriaChart.module.scss';

interface CriteriaChartProps {
  labels: string[];
  chartData: number[];
  onButtonClick(): void;
  isButtonDisabled: boolean;
}

function CriteriaChart({
  labels = [],
  chartData = [],
  onButtonClick,
  isButtonDisabled,
}: CriteriaChartProps): JSX.Element {
  const {getScoreChartColors} = useLearningPathReport();

  return (
    <article className={styles.CriteriaChart}>
      <header className={styles.header}>
        <h2 className={styles.title}>
          Veja as notas pelos critérios de avaliação:
        </h2>
      </header>
      <div className={styles.chart}>
        <Bar
          options={criteriaChartOptions('gm')}
          data={{
            labels: labels?.map((label) => formatBarChartLabel(label, 12)),
            datasets: [
              {
                data: chartData,
                barThickness: 52,
                borderRadius: 15,
                borderWidth: 2,
                ...(chartData ? getScoreChartColors('gm', chartData) : {}),
              },
            ],
          }}
        />
      </div>
      <div className={styles.tooltipContainer}>
        <Tooltip
          align={{
            offset: [40, 5],
            targetOffset: [0, 0],
          }}
          placement="bottomRight"
          overlayClassName={styles.tooltipOverlay}
          overlay={
            <div className={styles.tooltipContent}>
              <b>Você Sabia?</b>
              <p>
                A turma com desempenho médio <b>satisfatório</b> tirou de{' '}
                <b>8 a 10 pontos</b>, com desempenho médio <b>regular</b> tirou
                de <b>5 a 7 pontos</b> e com desempenho <b>insatisfatório</b> de{' '}
                <b>1 a 4 pontos</b>.
              </p>
            </div>
          }
        >
          <span>
            <FontAwesomeIcon
              icon={['fas', 'info-circle']}
              color="#3C405F"
              size="1x"
            />
          </span>
        </Tooltip>
      </div>
      <NewButton
        icon="question-circle"
        outline
        size="small"
        text="Entenda os Critérios"
        type="button"
        userRole="student-sae"
        kind="secondary"
        onClick={onButtonClick}
        disabled={isButtonDisabled}
      />
    </article>
  );
}

export default CriteriaChart;
