import {takeLatest} from 'redux-saga/effects';
import {
  fetchQuestionIndicatorByQuestionnaireId,
  fetchQuestionIndicatorsDetails,
  fetchQuestionnaireById,
  fetchStudentsAnswers,
  postQuestionAttempt,
  QuestionnairesTypes,
} from '../reducers/questionnaires';

const questionnaireSagas = [
  takeLatest(QuestionnairesTypes.FETCH_BY_ID_REQUEST, fetchQuestionnaireById),
  takeLatest(
    QuestionnairesTypes.POST_QUESTION_ATTEMPT_REQUEST,
    postQuestionAttempt,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_REQUEST,
    fetchQuestionIndicatorByQuestionnaireId,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_QUESTION_INDICATORS_DETAILS_REQUEST,
    fetchQuestionIndicatorsDetails,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_STUDENTS_ANSWERS_REQUEST,
    fetchStudentsAnswers,
  ),
];

export default questionnaireSagas;
