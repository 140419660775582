import styles from './Statement.module.scss';

export interface StatementProps {
  questionText: string;
}

function Statement({questionText}: StatementProps): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <div
        dangerouslySetInnerHTML={{
          __html: questionText,
        }}
      />
    </div>
  );
}

export default Statement;
