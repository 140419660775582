import {takeLatest} from 'redux-saga/effects';
import {
  fetchListMembers,
  fetchStudentInfo,
  StudentParentsTypes,
} from '../reducers/studentParents';

const studentParentSagas = [
  takeLatest(StudentParentsTypes.FETCH_LIST_MEMBERS_REQUEST, fetchListMembers),
  takeLatest(StudentParentsTypes.FETCH_STUDENT_INFO_REQUEST, fetchStudentInfo),
];

export default studentParentSagas;
