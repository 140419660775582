import {takeLatest} from 'redux-saga/effects';
import {
  fetchAids,
  fetchThemeById,
  fetchThemes,
  ThemesTypes,
} from '../reducers/themes';

const themeSagas = [
  takeLatest(ThemesTypes.FETCH_AIDS_REQUEST, fetchAids),
  takeLatest(ThemesTypes.FETCH_BY_ID_REQUEST, fetchThemeById),
  takeLatest(ThemesTypes.FETCH_THEMES_REQUEST, fetchThemes),
];

export default themeSagas;
