import styles from './NextArrow.module.scss';

function NextArrow(props) {
  const {className, onClick, currentSlide, slideCount} = props;

  return (
    <div
      role="button"
      className={`${className} ${styles.nextArrow}`}
      onClick={onClick}
    >
      {currentSlide !== slideCount - 1 ? (
        <img
          alt="next-arrow"
          src="https://s3.sa-east-1.amazonaws.com/media.letrus.com.br/arrow-right.svg"
        />
      ) : (
        <img
          alt="next-arrow-disabled"
          src="https://s3.sa-east-1.amazonaws.com/media.letrus.com.br/arrow-right-disabled.svg"
        />
      )}
    </div>
  );
}

export default NextArrow;
