import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';
import {FeatureFlagsResponse} from 'utils/types/featureFlags';
import {
  fetchFeatureFlagService,
  fetchMultipleFeatureFlagsService,
  FlagEvaluationRequest,
  MultipleFlagsEvaluationRequest,
} from '../../services/featureFlagServices';

// Action types
export enum FeatureFlagsTypes {
  FETCH_REQUEST = '@newFeatureFlags/FETCH_REQUEST',
  FETCH_SUCCESS = '@newFeatureFlags/FETCH_SUCCESS',
  FETCH_FAILURE = '@newFeatureFlags/FETCH_FAILURE',

  FETCH_MULTIPLE_REQUEST = '@newFeatureFlags/FETCH_MULTIPLE_REQUEST',
  FETCH_MULTIPLE_SUCCESS = '@newFeatureFlags/FETCH_MULTIPLE_SUCCESS',
  FETCH_MULTIPLE_FAILURE = '@newFeatureFlags/FETCH_MULTIPLE_FAILURE',
}

// Data types

// State type
export interface FeatureFlagsState extends Map<string, any> {
  readonly data?: Map<string, string | undefined>;
  readonly loading: boolean;
  readonly error?: boolean | number;
  readonly variantAttachment?: Map<string, string | undefined>;

  readonly multipleFeatureFlagsData: FeatureFlagsResponse[];
  readonly isLoadingMultipleFeatureFlags: boolean;
  readonly multipleFeatureFlagsError: boolean;
}

// Fetch actions
export const fetchFeatureFlagRequest = (data: FlagEvaluationRequest) =>
  action(FeatureFlagsTypes.FETCH_REQUEST, data);

export const fetchFeatureFlagSuccess = (data: any) =>
  action(FeatureFlagsTypes.FETCH_SUCCESS, data);

export const fetchFeatureFlagFailure = () =>
  action(FeatureFlagsTypes.FETCH_FAILURE);

export const fetchMultipleFeatureFlagsRequest = (
  data: MultipleFlagsEvaluationRequest,
) => action(FeatureFlagsTypes.FETCH_MULTIPLE_REQUEST, data);

export const fetchMultipleFeatureFlagsSuccess = (
  data: FeatureFlagsResponse[],
) => action(FeatureFlagsTypes.FETCH_MULTIPLE_SUCCESS, data);

export const fetchMultipleFeatureFlagsFailure = () =>
  action(FeatureFlagsTypes.FETCH_MULTIPLE_FAILURE);

// Sagas
export function* fetchFeatureFlag(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(fetchFeatureFlagService, action.payload);

    yield put(fetchFeatureFlagSuccess(response.data));
  } catch (err) {
    yield put(fetchFeatureFlagFailure());
  }
}

export function* fetchMultipleFeatureFlags(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      fetchMultipleFeatureFlagsService,
      action.payload,
    );
    yield put(fetchMultipleFeatureFlagsSuccess(response.data));
  } catch (err) {
    yield put(fetchMultipleFeatureFlagsFailure());
  }
}

// Selectors
const featureFlagsDataSelector = (state: ApplicationState) =>
  state.get('featureFlags');

export const getFeatureFlags = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('data'),
);

export const getVariantAttachment = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('variantAttachment'),
);

export const isLoadingFeatureFlags = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('loading'),
);

export const getMultipleFeatureFlags = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('multipleFeatureFlagsData'),
);

export const isLoadingMultipleFeatureFlags = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('isLoadingMultipleFeatureFlags'),
);

export const multipleFeatureFlagsError = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('multipleFeatureFlagsError'),
);

// Initial state
export const INITIAL_STATE: FeatureFlagsState = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
  multipleFeatureFlagsData: [],
  isLoadingMultipleFeatureFlags: false,
  multipleFeatureFlagsError: false,
  variantAttachment: fromJS({}),
});

// Reducer
export const reducer: Reducer<FeatureFlagsState | undefined> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case FeatureFlagsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case FeatureFlagsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', action.payload.flagKey], action.payload.variantKey)
          .setIn(
            ['variantAttachment', action.payload.flagKey],
            action.payload.variantAttachment,
          ),
      );

    case FeatureFlagsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case FeatureFlagsTypes.FETCH_MULTIPLE_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingMultipleFeatureFlags', true)
          .set('multipleFeatureFlagsError', false),
      );

    case FeatureFlagsTypes.FETCH_MULTIPLE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingMultipleFeatureFlags', false)
          .set('multipleFeatureFlagsError', false)
          .set(
            'multipleFeatureFlagsData',
            action.payload.evaluationResults.reduce(
              (flags, currentFlag) =>
                flags.some(
                  (flag) =>
                    flag.evalContext.entityID ===
                      currentFlag.evalContext.entityID ||
                    flag.flagID === currentFlag.flagID,
                )
                  ? flags
                  : [...flags, currentFlag],
              [],
            ),
          ),
      );

    case FeatureFlagsTypes.FETCH_MULTIPLE_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingMultipleFeatureFlags', false)
          .set('multipleFeatureFlagsError', true),
      );

    default:
      return state;
  }
};

export default reducer;
