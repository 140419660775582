import DropdownAccount from './DropdownAccount';
import DropdownNotifications from './DropdownNotifications';
import styles from './Menu.module.scss';

function Menu(): JSX.Element {
  return (
    <div className={styles.container}>
      <DropdownNotifications />
      <DropdownAccount />
    </div>
  );
}
export default Menu;
