import {GetStyleBasedOnRatesReturn} from 'features/useReadingIndicator';

export const discussRatesStyles: GetStyleBasedOnRatesReturn = {
  borderColor: '#F3AF31',
  backgroundColor: '#FFEAC4',
  iconName: ['fad', 'diamond-exclamation'],
  iconColor: '#F3AF31',
  boxShadow: '0px 4px 16px 0px rgba(136, 115, 0, 0.50)',
  title: 'Discuta a questão com a sua turma!',
  text: 'Os estudantes que responderam à questão assinalaram a alternativa incorreta. Aproveite para fazer uma revisão do conteúdo da questão em sala de aula.',
};

export const celebrateRatesStyles: GetStyleBasedOnRatesReturn = {
  borderColor: '#306230',
  backgroundColor: '#C6F1D3',
  iconName: ['fad', 'trophy-star'],
  iconColor: '#306230',
  boxShadow: '0px 4px 16px 0px rgba(2, 97, 33, 0.50)',
  title: 'Comemore com a sua turma!',
  text: 'A maioria dos estudantes que respondeu à questão assinalou a alternativa correta.',
};

export const engageRatesStyles: GetStyleBasedOnRatesReturn = {
  borderColor: '#2A5082',
  backgroundColor: '#E8F2FC',
  iconName: ['fad', 'lightbulb-on'],
  iconColor: '#2A5082',
  boxShadow: '0px 4px 16px 0px rgba(0, 87, 189, 0.50)',
  title: 'Engaje a sua turma!',
  text: 'Lembre os estudantes de responder completamente o questionário, verifique se há dúvidas sobre o conteúdo da questão e os auxilie, se necessário.',
};
