import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import InlineAlert from 'components/InlineAlert';
import StudentComposition from 'components/StudentComposition';
import MultigenreModal from '../MultigenreModal';
import styles from './CompositionModal.module.scss';

interface CompositionModalProps {
  compositionInfo: LetrusApi.CompositionDetail;
  onClickClose(): void;
  isActivityInProgress: boolean;
}

function CompositionModal({
  onClickClose,
  isActivityInProgress,
  compositionInfo,
}: CompositionModalProps): JSX.Element {
  return (
    <MultigenreModal
      onClickClose={onClickClose}
      title="Leia, edite a pontuação e escreva comentários para os estudantes:"
      maxWidth={1058}
    >
      <div>
        <InlineAlert
          text={
            isActivityInProgress
              ? 'Esta atividade está em andamento, as informações apresentadas podem sofrer alterações.'
              : 'Esta atividade está finalizada, mas a correção está em andamento. Por favor volte mais tarde.'
          }
          customClassName={`${styles.activityWarning} ${
            !isActivityInProgress && styles.notReviewedWarning
          }`}
          iconName={isActivityInProgress ? 'exclamation-circle' : 'clock'}
        />
        <StudentComposition compositionInfo={compositionInfo} />
      </div>
    </MultigenreModal>
  );
}

export default CompositionModal;
