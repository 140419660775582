import {useEffect} from 'react';

interface ExternalRedirectProps {
  to: string;
}

const ExternalRedirect: React.FC<ExternalRedirectProps> = ({to}) => {
  useEffect(() => {
    document.location = to as any; // TODO: It should a Location object
  });

  return <div />;
};

export default ExternalRedirect;
