import api from './api';

export type KnowledgeLearningPathFilters =
  | 'year'
  | 'school'
  | 'school_group'
  | 'q';

export const fetchKnowledgeLearningPathListService = (
  params?: Partial<Record<KnowledgeLearningPathFilters, any>>,
) => api(`/api/v1/knowledge_learning_path/`, {params});
