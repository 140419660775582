import {takeLatest} from 'redux-saga/effects';
import {
  fetchNotifications,
  fetchSidebarNotifications,
  NotificationsTypes,
  updateNotificationById,
} from '../reducers/notifications';

const notificationSagas = [
  takeLatest(NotificationsTypes.FETCH_REQUEST, fetchNotifications),
  takeLatest(NotificationsTypes.UPDATE_BY_ID_REQUEST, updateNotificationById),
  takeLatest(
    NotificationsTypes.FETCH_SIDEBAR_NOTIFICATION_REQUEST,
    fetchSidebarNotifications,
  ),
];

export default notificationSagas;
