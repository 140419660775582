import {takeLatest} from 'redux-saga/effects';
import {
  createLearningPathInstance,
  fetchLearningPathById,
  fetchLearningPathInstanceCreationStatus,
  fetchLearningPathReportEngagement,
  fetchLearningPathReportRelatedSkills,
  fetchLearningPathReportStep,
  fetchLearningPathReportSummary,
  fetchLearningPaths,
  fetchLearningPathTemplatesByGenre,
  LearningPathsTypes,
  reopenComposition,
} from '../reducers/learningPaths';

const learningPathSagas = [
  takeLatest(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_REQUEST,
    fetchLearningPathReportEngagement,
  ),
  takeLatest(LearningPathsTypes.FETCH_REQUEST, fetchLearningPaths),
  takeLatest(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_REQUEST,
    fetchLearningPathReportRelatedSkills,
  ),
  takeLatest(LearningPathsTypes.FETCH_BY_ID_REQUEST, fetchLearningPathById),
  takeLatest(
    LearningPathsTypes.FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_REQUEST,
    fetchLearningPathTemplatesByGenre,
  ),
  takeLatest(
    LearningPathsTypes.CREATE_LEARNING_PATH_INSTANCE_REQUEST,
    createLearningPathInstance,
  ),
  takeLatest(
    LearningPathsTypes.FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_REQUEST,
    fetchLearningPathInstanceCreationStatus,
  ),
  takeLatest(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_REQUEST,
    fetchLearningPathReportSummary,
  ),
  takeLatest(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_REQUEST,
    fetchLearningPathReportStep,
  ),
  takeLatest(LearningPathsTypes.REOPEN_COMPOSITION_REQUEST, reopenComposition),
];

export default learningPathSagas;
