import {Utils} from '@letrustech/letrus-api-interfaces';
import {AxiosResponse} from 'axios';
import {LearningPathReportStepData} from 'store/mocks/__mocks__/learningPathStep';
import {SidebarNotification} from 'store/reducers/notifications';
import api from './api';

export interface FetchLearningPathReportStepServiceParams {
  learningPathId: string | number;
  stepType: 'pedagogical_content_step' | 'questionnaire_step' | 'test_step';
  stepId: string | number;
}

export interface reopenCompositionParams {
  composition_ids: string[];
  test_id?: string | number;
  new_test_revision_deadline?: string;
  new_test_finish_deadline?: string;
}

export const fetchLearningPathReportEngagementService = (
  learningPathId: number | string,
) => api(`/api/v1/learning_path_instance/${learningPathId}/engagement/`);

export const fetchLearningPathsService = () =>
  api(`/api/v1/learning_path_user_instances/`);

export const fetchLearningPathReportRelatedSkillsService = (
  learningPathId: number | string,
) => api(`/api/v1/learning_path_instance/${learningPathId}/skills/`);

export const fetchLearningPathByIdService = (learningPathId: number | string) =>
  api(`/api/v1/learning_path_user_instance/${learningPathId}/`);

export const fetchLearningPathReportSummaryService = (
  learningPathId: number | string,
) => api(`/api/v1/learning_path_instance/${learningPathId}/summary/`);

export const fetchLearningPathReportStepService = ({
  learningPathId,
  stepType,
  stepId,
}: FetchLearningPathReportStepServiceParams): Promise<
  AxiosResponse<LearningPathReportStepData>
> =>
  api(
    `/api/v1/learning_path_instance/${learningPathId}/${stepType}/${stepId}/`,
  );

export const fetchLearningPathTemplatesByGenreService = (
  params?: Utils.GetParams,
) =>
  api(
    `/admin_api/v1/learning_path_templates/get_learning_path_templates_by_genre/`,
    {params},
  );

export const createLearningPathInstanceService = (
  data?: any,
  params?: Utils.GetParams,
) =>
  api(
    `/admin_api/v1/learning_path_instances/massive_learning_path_instances_create/`,
    {
      method: 'post',
      data,
      params,
    },
  );

export const fetchLearningPathInstanceCreationStatusService = (
  params?: Utils.GetParams,
) =>
  api(
    `/admin_api/v1/learning_path_instances/monitoring_massive_create_lpi_progress/`,
    {
      params,
    },
  );

export const reopenCompositionService = (data?: reopenCompositionParams) =>
  api(`/api/v1/compositions/reopen_compositions/`, {
    method: 'post',
    data,
  });

export function fetchLearningPathNotificationService(): Promise<
  AxiosResponse<SidebarNotification>
> {
  return api(`api/v1/learning_path_instance/notifications/`, {
    method: 'get',
  });
}

export async function updateLearningPathNotificationService(
  learningPathId: number | string,
  seen_by_teacher: string,
) {
  await api(`api/v1/learning_path_instance/${learningPathId}/`, {
    method: 'patch',
    data: {seen_by_teacher},
  })
    .then((response) => response)
    .catch(() => console.log('sidebar notification error'));
}
