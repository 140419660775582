import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  progress: number;
  barColor: string;
}

function ProgressBar({progress, barColor}: ProgressBarProps): JSX.Element {
  return (
    <div className={styles.progressBar}>
      <div
        className={styles.progressBarInner}
        style={{width: `${progress}%`, backgroundColor: barColor}}
      />
    </div>
  );
}

export default ProgressBar;
