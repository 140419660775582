/* eslint-disable no-underscore-dangle */
/* eslint-disable testing-library/no-debug */
import Debug from 'debug';
import * as diff from 'diff';
import {EditorState, Modifier, SelectionState} from 'draft-js';
import CharacterMetadata from 'draft-js/lib/CharacterMetadata';
import DraftEntity from 'draft-js/lib/DraftEntity';
import memoize from 'lodash.memoize';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import {Component} from 'react';
import TooltipOverlay from '../../TooltipOverlay';
import './LetrusHighlighter.scss';

const debug = Debug('letrus:LetrusHighlighter');

function getSelectionStateAtOffset(
  currentContentState,
  offsetStart,
  offsetEnd,
  mistake,
) {
  const anchorKey = currentContentState.blockMap
    .keySeq()
    .get(mistake.paragraph);

  return new SelectionState({
    anchorKey,
    focusKey: anchorKey,
    anchorOffset: offsetStart,
    focusOffset: offsetEnd,
  });
}

/**
 * Applies highlights to the editor state after clearing old highlights
 *
 * @param {Object} options
 * @param {Array} [options.oldHighlight] Optional list of old highlights
 * @param {Array} options.newHighlight List of highlights to apply
 * @param {EditorState} options.editorState The draft.js state to apply
 * highlights to
 * @return {EditorState} The updated state
 */

export function applyAndClearHighlights({
  oldHighlight,
  oldHighlightName,
  newHighlight,
  highlightName,
  editorState,
}) {
  if (!oldHighlight) {
    oldHighlight = [];
  }

  if (!newHighlight) {
    newHighlight = [];
  }

  if (oldHighlight.toJS) {
    oldHighlight = oldHighlight.toJS(); // eslint-disable-line no-param-reassign
  }

  if (newHighlight.toJS) {
    newHighlight = newHighlight.toJS(); // eslint-disable-line no-param-reassign
  }

  if (!oldHighlight) {
    return applyHighlights(newHighlight, cleanEditorState, highlightName);
  }

  if (oldHighlightName !== highlightName) {
    const cleanEditorState = applyHighlights(
      oldHighlight,
      editorState,
      highlightName,
      true,
    );

    return applyHighlights(newHighlight, cleanEditorState, highlightName);
  }

  const mistakesDiff = diff.diffArrays(oldHighlight, newHighlight, {
    comparator: isEqualMistake,
  });
  const removedMistakes = mistakesDiff
    .filter((diff) => diff.removed === true)
    .map((diff) => diff.value)
    .flat();

  const addedMistakes = mistakesDiff
    .filter((diff) => diff.added === true)
    .map((diff) => diff.value)
    .flat();

  const cleanEditorState = applyHighlights(
    removedMistakes,
    editorState,
    highlightName,
    true,
  );

  return applyHighlights(addedMistakes, cleanEditorState, highlightName);
}

export function applyHighlights(
  mistakes = [],
  editorState,
  highlightName,
  clearHighlight,
) {
  debug(
    'Applying highlights',
    highlightName,
    mistakes,
    'clearHighlight =',
    clearHighlight,
  );
  const contentStateWithMistakes = mistakes.reduce(
    (currentContentState, mistake) =>
      applyHighlight(
        currentContentState,
        mistake,
        highlightName,
        clearHighlight,
      ),
    editorState.getCurrentContent(),
  );

  return EditorState.set(editorState, {
    currentContent: contentStateWithMistakes,
  });
}

function applyHighlight(
  currentContentState,
  mistake,
  highlightName,
  clearHighlight,
) {
  if (mistake.toJS) {
    mistake = mistake.toJS(); // eslint-disable-line no-param-reassign
  }

  const selectionState = getSelectionStateAtOffset(
    currentContentState,
    mistake.boundaries[0],
    mistake.boundaries[1],
    mistake,
  );

  if (!selectionState || !selectionState.focusKey) {
    return currentContentState;
  }

  if (clearHighlight) {
    debug('Clearing highlight', mistake, highlightName);
  }

  const entityKey = clearHighlight
    ? null
    : createEntityMemoized('HIGHLIGHT', 'MUTABLE', mistake, highlightName);

  return applyEntity(currentContentState, selectionState, entityKey);
}

function isEqualMistake(mistake1, mistake2) {
  return (
    mistake1.paragraph === mistake2.paragraph &&
    mistake1.boundaries[0] === mistake2.boundaries[0] &&
    mistake1.boundaries[1] === mistake2.boundaries[1]
  );
}

/*
 * DRAFT.JS OPTIMIZATION
 */

function applyEntity(contentState, selectionState, entityKey) {
  const blockMap = contentState.getBlockMap();
  const startKey = selectionState.getStartKey();
  const startOffset = selectionState.getStartOffset();
  const endKey = selectionState.getEndKey();
  const endOffset = selectionState.getEndOffset();

  if (startKey !== endKey) {
    return Modifier.applyEntity(contentState, selectionState, entityKey);
  }

  const block = blockMap.get(startKey);
  const sliceStart = startOffset;
  const sliceEnd = endOffset;

  const newBlock = applyEntityToContentBlock(
    block,
    sliceStart,
    sliceEnd,
    entityKey,
  );
  const newBlocks = blockMap.set(startKey, newBlock);

  return contentState.merge({
    blockMap: newBlocks,
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  });
}

function applyEntityToContentBlock(contentBlock, start, end, entityKey) {
  let characterList = contentBlock.getCharacterList();
  while (start < end) {
    if (characterList.get(start)) {
      characterList = characterList.set(
        start,
        CharacterMetadata.applyEntity(characterList.get(start), entityKey),
      );
    }
    start++;
  }
  return contentBlock.set('characterList', characterList);
}

const createCharacterMetadata = CharacterMetadata.create;
const characterMetadata$createCache = {};

CharacterMetadata.create = function Letrus$CharacterMetadata$create(config) {
  if (config.style && !config.style.isEmpty()) {
    return createCharacterMetadata(config);
  }

  if (!characterMetadata$createCache[config.entity]) {
    characterMetadata$createCache[config.entity] =
      createCharacterMetadata(config);
  }

  return characterMetadata$createCache[config.entity];
};

function createEntity(entityType, entityMutability, mistake, highlightName) {
  DraftEntity.__create(entityType, entityMutability, {
    ...mistake,
    category: highlightName,
  });
  return DraftEntity.__getLastCreatedEntityKey();
}

const createEntityMemoized = memoize(createEntity, (...args) =>
  JSON.stringify(args),
);

/*
 * HIGHLIGHT RENDERING
 */

class HighlightMistakesNode extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    visible: false,
  };

  onVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };

  render() {
    const {children, entityKey} = this.props;
    const entity = DraftEntity.__get(entityKey).data;

    /* Show Editor Tooltip - To add new indicators that show tooltips, add the name of the indicators in the list bellow and configure at the database the composition genre shown stats table.
     * Talking to a backend developer is recomended */
    const showTooltip = !!(
      entity.category == 'mistake' ||
      entity.category == 'spell' ||
      entity.category == 'erros_ortograficos' ||
      entity.category == 'erros_gramaticais' ||
      entity.category == 'oralidades' ||
      entity.category == 'grammar' ||
      entity.category == 'orality' ||
      entity.category == 'special_cases'
    );

    const is_mistake = entity.category === 'mistake';

    let entityCategory;

    if (
      entity.category === 'spell' ||
      entity.category === 'erros_ortograficos'
    ) {
      entityCategory = 'Ortografia';
    } else if (
      entity.category === 'orality' ||
      entity.category === 'oralidades'
    ) {
      entityCategory = 'Oralidade';
    } else if (entity.category === 'special_cases') {
      entityCategory = 'Casos Especiais';
    } else {
      entityCategory = '';
    }

    debug('HighlightMistakesNode::render', entity.category);

    return (
      <span
        className={`LetrusHighlighter__highlight ${
          // eslint-disable-next-line react/destructuring-assignment
          this.state && this.state.visible
            ? 'LetrusHighlighter__highlight--visible'
            : ''
        } ${is_mistake ? 'LetrusHighlighter__highlight--mistake' : ''}`}
      >
        <Tooltip
          id={`${entity.boundaries[0]}-${entity.boundaries[1]}`}
          trigger={showTooltip ? ['click', 'mouseenter', 'hover', 'focus'] : []}
          mouseEnterDelay={1}
          overlayClassName="no-padding"
          destroyTooltipOnHide
          onVisibleChange={this.onVisibleChange}
          overlay={
            <TooltipOverlay
              entityCategory={entityCategory}
              entity={entity}
              message={entity.comment || entity.message}
            />
          }
        >
          <span aria-label={`${entity.boundaries[0]}-${entity.boundaries[1]}`}>
            {children}
          </span>
        </Tooltip>
      </span>
    );
  }
}

export const highlightMistakes = (options) => ({
  strategy(contentBlock, callback /* , contentState */) {
    if (!options.highlightName) {
      return;
    }

    // debug('Calling strategy', contentBlock.getText());
    contentBlock.findEntityRanges((character) => {
      const entityKey = character && character.getEntity();
      // const entity = entityKey && contentState.getEntity(entityKey);

      const entity = entityKey && DraftEntity.__get(entityKey);

      if (!entity) {
        return false;
      }

      const shouldHighlight =
        options.highlightName === 'Sugestões'
          ? ['orality', 'spell'].indexOf(entity.data.category) !== -1
          : entity.data.category === options.highlightName;

      return entity.getType() === 'HIGHLIGHT' && shouldHighlight;
    }, callback);
  },
  component: HighlightMistakesNode,
});

function getFullSelectionState(contentState) {
  const anchorKey = contentState.getFirstBlock().getKey();
  const anchorOffset = 0;
  const focusKey = contentState.getLastBlock().getKey();
  const focusOffset = contentState.getLastBlock().getLength();
  return new SelectionState({
    anchorKey,
    anchorOffset,
    focusKey,
    focusOffset,
  });
}

export function clearHighlights(editorState) {
  const currentContentState = editorState.getCurrentContent();
  const selectionState = getFullSelectionState(currentContentState);
  const newContentState = Modifier.setBlockData(
    currentContentState,
    selectionState,
    null,
  );
  return EditorState.set(editorState, {
    currentContent: Modifier.applyEntity(newContentState, selectionState, null),
  });
}
