import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {AxiosResponse} from 'axios';
import api, {GetParams} from './api';

export interface fetchTestsWithScoreParams {
  currentTime?: string;
  type: 'scheduled' | 'finished' | 'inProgress';
  query: Record<string, any>;
}

export const fetchScheduledActivitiesService = () =>
  api('/api/v1/tests/scheduled_activities/');

export const createTestService = (testData: any) =>
  api('/api/v1/tests/', {
    method: 'post',
    data: testData,
  });

export const fetchTestByIdService = (
  testId: number | string,
  params: GetParams,
) =>
  api(`/api/v1/tests/${testId}/`, {
    params,
  });

export const fetchLiveTestDataService = (testId: number) =>
  api(`/api/v1/tests/${testId}/realtime_report/`);

export const triggerLiveTestService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/trigger_realtime_report/`, {
    method: 'post',
  });

export const fetchTestGradeInfoService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/grade_info/`);

export const fetchTestIndicatorsInfoService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/indicators_info/`);

export const fetchTestStudentsInfoService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/students_info/`);

export const fetchEvolutionReportService = (params: GetParams) =>
  api(`/api/v1/tests/evolution/`, {
    params,
  });

export const sendTestsService = (testId: number | string, compositions: any) =>
  api(`/api/v1/tests/${testId}/finish_revision/`, {
    method: 'post',
    data: {compositions},
  });

export const fetchTestsBySchoolGroupService = (schoolGroupId: number) =>
  api(`/api/v1/school_groups_list/${schoolGroupId}/tests_without_children/`);

export const putTestService = (testData: Partial<LetrusApi.Test>) =>
  api(`/api/v1/tests/${testData.id}/`, {
    method: 'put',
    data: testData,
  });

export const patchTestService = (testData: Partial<LetrusApi.Test>) =>
  api(`/api/v1/tests/${testData.id}/`, {
    method: 'patch',
    data: testData,
  });

export const deleteTestService = (testId: string | number) =>
  api(`/api/v1/tests/${testId}/`, {
    method: 'delete',
  });

export const fetchTestsWithScoreService = ({
  type,
  query,
}: fetchTestsWithScoreParams): Promise<
  AxiosResponse<LetrusApi.ApiV1TestsWithScoreGetResponse200>
> => {
  return api('/api/v1/tests_with_score/', {
    params: {
      limit: 10,
      type,
      ...query,
    },
  });
};

export const fetchStudentsReopenStatusService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/students_status_on_test/`);
