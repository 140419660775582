import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './InlineAlert.module.scss';

export interface InlineAlertProps {
  text: string;
  title?: string;
  customClassName?: string;
  iconName?: IconProp;
}

function InlineAlert({
  title,
  text,
  customClassName,
  iconName = 'exclamation-circle',
}: InlineAlertProps): JSX.Element {
  return (
    <div className={`${styles.wrapper} ${customClassName}`}>
      <span className={styles.iconWrapper}>
        <FontAwesomeIcon icon={iconName} />
      </span>
      {title && <h3 className={styles.title}>{title}:</h3>}

      <p className={styles.text}>{text}</p>
    </div>
  );
}

export default InlineAlert;
