import LoadingRow from 'components/LoadingComponents/LoadingRow';
import useLearningPathReport from 'features/useLearningPathReport';
import {NewFormMultiple} from 'letrus-ui';
import {useEffect, useState} from 'react';
import {
  IndividualQuestionsInfoMock,
  QuestionsInfoMock,
} from 'store/mocks/__mocks__/learningPathStep';
import PerformanceChart from '../PerformanceChart';
import styles from './CheckboxQuestion.module.scss';

export interface CheckboxQuestionProps {
  question: QuestionsInfoMock;
  showIndividualResults: boolean;
  individualResults: {
    currentStudentId: string;
    studentsIndividualAnswers: Record<string, IndividualQuestionsInfoMock>;
  };
}

function CheckboxQuestion({
  question,
  showIndividualResults,
  individualResults,
}: CheckboxQuestionProps): JSX.Element {
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    getSchoolGroupStatistics,
    getStudentIndividualQuestionAnswers,
    hasCorrectAnswer,
  } = useLearningPathReport();

  const {totalOfCorrectStudents, totalOfStudents, percentageOfCorrectStudents} =
    getSchoolGroupStatistics(question);

  const questionHasCorrectAnswer = hasCorrectAnswer(question);

  const {individualCheckbox} = getStudentIndividualQuestionAnswers({
    question,
    answersFromAllStudents: individualResults.studentsIndividualAnswers,
    currentStudent: individualResults.currentStudentId,
  });

  useEffect(() => {
    // needed to dismount and remount newFormMultiple,
    // if not remounted the active items don't get updated.
    setIsUpdating(true);

    setTimeout(() => {
      setIsUpdating(false);
    }, 300);
  }, [individualResults]);

  return (
    <>
      <div
        className={styles.questionText}
        dangerouslySetInnerHTML={{__html: question?.question_text}}
      />

      {!showIndividualResults && questionHasCorrectAnswer ? (
        <p className={styles.performanceText}>
          {`Desempenho da turma: ${totalOfCorrectStudents}/${totalOfStudents} (${percentageOfCorrectStudents}%) estudantes acertaram.`}
        </p>
      ) : (
        ''
      )}

      {showIndividualResults ? (
        <>
          {isUpdating ? (
            <div className={styles.checboxLoader} data-testid="checkbox-loader">
              <LoadingRow height="4rem" width="100%" />
              <LoadingRow height="4rem" width="100%" />
              <LoadingRow height="4rem" width="100%" />
            </div>
          ) : (
            <NewFormMultiple
              items={individualCheckbox.items}
              onClickItem={() => {}}
              title=""
              selectedItems={individualCheckbox.activeItems!}
              correctItems={individualCheckbox.correctItems}
              showCorrectAnswer={individualCheckbox.showCorrectAnswer}
              selectionDisabled
            />
          )}
        </>
      ) : (
        <PerformanceChart
          dataset={question.question_options.map((option) => ({
            name: option?.name,
            total: totalOfStudents,
            partial: option?.total,
            isCorrect: option?.is_correct,
          }))}
        />
      )}
    </>
  );
}

export default CheckboxQuestion;
