import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './QuestionButton.module.scss';

export interface QuestionButtonProps {
  icon?: IconProp;
  iconColor?: string;
  order?: string;
  title?: string;
  subtitle?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?(): void;
  children?: React.ReactNode;
}

function QuestionButton({
  icon = 'eye',
  iconColor,
  order,
  title,
  subtitle,
  active = false,
  disabled = false,
  onClick,
  children,
}: QuestionButtonProps): JSX.Element {
  return (
    <div
      data-testid="questionButtonContainer"
      className={`
        ${styles.container}
        ${active && styles.active}
        ${disabled && styles.disabled}`}
      role="button"
      onClick={onClick}
    >
      {order ? (
        <div
          className={`
          ${styles.iconContainer} 
          ${active && styles.active}
          ${disabled && styles.disabled}`}
        >
          {order}
        </div>
      ) : (
        <div className={styles.iconContainer}>
          <FontAwesomeIcon color={iconColor} size="1x" icon={icon} />
        </div>
      )}
      {(title || subtitle) && (
        <div className={`${styles.questionText} ${active && styles.active}`}>
          <span className={styles.title}>{title}</span>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
      )}
      {children && (
        <>
          <hr data-testid="divider" className={styles.divider} />
          {children}
        </>
      )}
    </div>
  );
}

export default QuestionButton;
