import {Accordeon} from 'letrus-ui';
import {ReactNode, useState} from 'react';
import {useMobile} from 'utils/hooks';
import useTrackEvent from 'utils/hooks/useTrackEvent';
import styles from './ContentTabs.module.scss';

export interface ContentTabsProps {
  tabs: {
    title: string;
    content: ReactNode;
  }[];
}

function ContentTabs({tabs}: ContentTabsProps): JSX.Element {
  const [currentTab, setCurrentTab] = useState(tabs[0].title);
  const {isMobile} = useMobile();
  const {trackEvent} = useTrackEvent();

  function onTabClick(tab: {title: string; content: ReactNode}) {
    if (tab.title === 'Material de pré-escrita') {
      trackEvent('pre-writing_material_tab_click');
    }
    setCurrentTab(tab.title);
  }

  return (
    <section className={styles.wrapper}>
      {isMobile ? (
        <article className={styles.accordeonWrapper} data-testid="accordeon">
          <Accordeon
            items={tabs.map((tab) => ({
              titleLeft: tab.title,
              content: tab.content,
            }))}
          />
        </article>
      ) : (
        <div className={styles.categoriesWrapper}>
          <menu className={styles.categoriesMenu}>
            {tabs.map((tab) => (
              <li key={tab.title}>
                <button
                  type="button"
                  onClick={() => onTabClick(tab)}
                  data-active={currentTab === tab.title}
                >
                  {tab.title}
                </button>
              </li>
            ))}
          </menu>
          <div className={styles.category}>
            {tabs.map(
              (tab) =>
                tab.title === currentTab && (
                  <span key={tab.title}>{tab.content}</span>
                ),
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default ContentTabs;
