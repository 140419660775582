import * as Sentry from '@sentry/react';
import {isBetaDomain, isProdEnvironment} from 'App';
import TagManager, {TagManagerArgs} from 'react-gtm-module';
import {setupInterceptors} from 'store/services/api';
import store from './store';

export function setupTrackers(): void {
  if (isProdEnvironment && !isBetaDomain) {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: 'GTM-M3LKWBW',
    };

    TagManager.initialize(tagManagerArgs);

    Sentry.init({
      dsn: 'https://623e379499964cbb82dff06ade895837@o222076.ingest.sentry.io/5838020',
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });
  }

  setupInterceptors(store);
}
