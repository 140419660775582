import ConfirmCloseModal from 'components/ConfirmCloseModal';
import DateInput from 'components/DateInput';
import RequestStatusModal from 'components/RequestStatusModal';
import useLearningPathReport from 'features/useLearningPathReport';
import {List} from 'immutable';
import {
  BasicModal,
  Button,
  Filter,
  LoadingBox,
  Row,
  SelectionCard,
  TimeFieldset,
} from 'letrus-ui';
import moment from 'moment';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {StudentReopenComposition} from 'store/mocks/__mocks__/reopenComposition';
import {
  getReopenCompositionRequestStatus,
  reopenCompositionRequest,
} from 'store/reducers/learningPaths';
import {
  fetchStudentsReopenStatusRequest,
  getStudentsReopenStatus,
  isLoadingStudentsReopenStatus,
} from 'store/reducers/tests';
import {ApplicationState} from 'store/rootReducer';
import {orderBy} from 'utils/functions/orderBy';
import useStepForm from 'utils/hooks/useStepForm';
import {
  filterOptions,
  mainFilterOptions,
} from '../../../features/useLearningPathReport/selectStudentFilters';
import OptionCard from './components/OptionCard';
import styles from './ReopenCompositionModal.module.scss';

type PageModals =
  | 'reopenComposition'
  | 'requestStatus'
  | 'confirmClose'
  | 'null';

interface FormData {
  composition_ids: string[];
  ending_date: string;
  ending_time: string;
  review_ending_date: string;
}

interface OwnProps {
  onClose?(): void;
  requestCallback?(): void;
  status?: string;
  testId?: number | string;
}

interface StateProps {
  studentsReopenStatus: List<ImmutableMap<StudentReopenComposition>>;
  isLoadingStudentsReopenStatus: boolean;
  requestStatus?: string;
}

interface DispatchProps {
  fetchStudentsReopenStatusRequest: typeof fetchStudentsReopenStatusRequest;
  reopenCompositionRequest: typeof reopenCompositionRequest;
}

export type ReopenCompositionModalProps = OwnProps & DispatchProps & StateProps;

function ReopenCompositionModal({
  onClose,
  fetchStudentsReopenStatusRequest,
  reopenCompositionRequest,
  requestCallback,
  testId,
  status,
  requestStatus,
  isLoadingStudentsReopenStatus,
  studentsReopenStatus,
}: ReopenCompositionModalProps): JSX.Element {
  const [filterSelected, setFilterSelected] = useState<string[]>([]);
  const [hasReviewHold, setHasReviewHold] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [activeModal, setActiveModal] =
    useState<PageModals>('reopenComposition');
  const history = useHistory();

  const form = useStepForm<FormData>({
    mode: 'all',
    defaultValues: {
      composition_ids: [],
      ending_date: '',
      ending_time: '',
      review_ending_date: '',
    },
  });

  const {
    isReopeningDateValid,
    isReviewingEndDateValid,
    combineDateAndTime,
    validateEndTime,
  } = useLearningPathReport();

  const selectedStudentsComposition = useMemo(
    () =>
      studentsReopenStatus
        ?.toJS()
        .reduce((selected: string[], studentComposition) => {
          if (
            status === 'finished' &&
            (studentComposition?.composition_info?.composition_status ===
              'in_progress' ||
              studentComposition?.composition_info?.composition_status ===
                'not_started')
          ) {
            selected.push(`${studentComposition?.composition_info.id}`);
          }
          return selected;
        }, []),
    [studentsReopenStatus],
  );

  useEffect(() => {
    if (testId) {
      fetchStudentsReopenStatusRequest(testId);
    }
  }, [testId]);

  useEffect(() => {
    form.setValue(
      'composition_ids',
      studentsReopenStatus
        ?.toJS()
        .reduce((selected: string[], studentComposition) => {
          if (
            filterSelected.includes(
              studentComposition?.composition_info?.composition_status,
            ) ||
            (filterSelected.includes('finished') &&
              studentComposition?.composition_info?.composition_status ===
                'zeroed')
          ) {
            selected.push(`${studentComposition?.composition_info.id}`);
          }
          return selected;
        }, []),
      {
        shouldDirty: true,
      },
    );
  }, [filterSelected]);

  const {ending_date, composition_ids} = form.watch();

  const orderedStudentsComposition = studentsReopenStatus
    .toJS()
    .concat()
    .sort(orderBy(['first_name', 'last_name']));

  const formSteps = [
    {
      title:
        'Professor(a), escolha para qual dos estudantes abaixo você gostaria de reabrir a etapa de escrita da trilha:',
      subtitle: 'Por favor, selecione uma ou mais opções abaixo.',
      status: form.currentStep === 0 ? 'active' : 'inactive',
    },
    {
      title: 'Selecione a data e o horário de encerramento:',
      subtitle:
        'Clique no calendário para selecionar a data e no relógio para a hora.',
      status: form.currentStep === 1 ? 'active' : 'inactive',
    },
    {
      title: 'Gostaria de selecionar um período de revisão?',
      subtitle:
        'Período de revisão é o tempo que o(a) professor(a) tem para revisar as correções da Letrus antes de elas serem disponibilizadas para a turma.',
      status: form.currentStep === 2 ? 'active' : 'inactive',
    },
    {
      title: 'Selecione o período de revisão:',
      subtitle:
        'Clique no calendário para selecionar a data final do período de revisão.',
      status: form.currentStep === 3 ? 'active' : 'inactive',
    },
  ];

  const isLastStep =
    form.currentStep === formSteps.length - 1 ||
    (formSteps[2]?.status === 'active' && !hasReviewHold) ||
    status === 'in_progress';

  const isFirstStep = form.currentStep === 0;

  const step0IsDisabled =
    formSteps[0]?.status === 'active' &&
    !form.formState.dirtyFields.composition_ids;

  const step1IsDisabled =
    formSteps[1]?.status === 'active' &&
    (!form.formState.dirtyFields.ending_date ||
      !form.formState.dirtyFields.ending_time);

  const step3IsDisabled =
    formSteps[3]?.status === 'active' &&
    !form.formState.dirtyFields.review_ending_date;

  function onClickFilterTag(filterId: string) {
    const updatedFilterSelected = filterSelected.includes(filterId)
      ? filterSelected.filter((selectedFilter) => selectedFilter !== filterId)
      : [...filterSelected, filterId];

    setFilterSelected(updatedFilterSelected);
  }

  function onClickFilterButton(filtersId: string[]) {
    setFilterSelected(filtersId);
  }

  function reopenCompositions(data: FormData) {
    const reopenDeadline =
      data.ending_date && data.ending_time
        ? combineDateAndTime(data.ending_date, data.ending_time)
        : undefined;

    reopenCompositionRequest({
      composition_ids: data.composition_ids,
      test_id: testId,
      new_test_finish_deadline: reopenDeadline,
      new_test_revision_deadline: data.review_ending_date
        ? data.review_ending_date
        : undefined,
    });

    setActiveModal('requestStatus');
  }

  function onClickOptionCard(compositionId: number) {
    const updatedSelectedCompositions = composition_ids.includes(
      compositionId?.toString(),
    )
      ? composition_ids.filter((id) => id !== compositionId?.toString())
      : [...composition_ids, compositionId?.toString()];

    form.setValue('composition_ids', updatedSelectedCompositions, {
      shouldDirty: true,
    });
  }

  function onClickCloseModal() {
    onClose?.();
    form.reset();
    setActiveModal('reopenComposition');
  }

  function onClickConfirmSuccessModal() {
    onClose?.();
    form.reset();

    if (status === 'finished') {
      history.push('/atividades/regulares?tab=1');
    }

    requestCallback?.();
  }

  function isNextButtonDisabled() {
    if (formSteps[0]?.status === 'active' && status === 'finished') {
      return false;
    }
    return step0IsDisabled || step1IsDisabled || step3IsDisabled;
  }

  return (
    <>
      <BasicModal
        onClose={() => setActiveModal('confirmClose')}
        size="large"
        open={activeModal === 'reopenComposition'}
        title=" "
        footerButtons={
          <div className={styles.buttonsModal}>
            {!isFirstStep && (
              <Button variant="secondary" text="Voltar" onClick={form.goBack} />
            )}
            {isLastStep ? (
              <Button
                textColor="#fff"
                variant="secondary"
                backgroundColor="#2a5082"
                borderColor="#2a5082"
                text="Reabrir escrita"
                leftIcon={{icon: 'check', color: '#fff'}}
                onClick={() => formRef.current?.requestSubmit()}
                disabled={isNextButtonDisabled()}
              />
            ) : (
              <Button
                variant="secondary"
                text="Próximo"
                backgroundColor="#2a5082"
                borderColor="#2a5082"
                textColor="#ffffff"
                onClick={form.goForward}
                disabled={
                  isLoadingStudentsReopenStatus || isNextButtonDisabled()
                }
              />
            )}
          </div>
        }
        footerButtonsOrientation="flex-end"
      >
        {isLoadingStudentsReopenStatus || studentsReopenStatus.size === 0 ? (
          <section className={`${styles.wrapper} ${styles.loading}`}>
            <LoadingBox width="100%" height="6rem" borderRadius={6} />
            <LoadingBox width="100%" height="16rem" borderRadius={6} />
          </section>
        ) : (
          <section className={styles.wrapper}>
            <Row padding="0">
              <>
                <div className={styles.titleContainer}>
                  <h4 className={styles.title}>
                    {formSteps[form.currentStep]?.title}
                  </h4>
                  <h5 className={styles.subtitle}>
                    {formSteps[form.currentStep]?.subtitle}
                  </h5>
                </div>
              </>
            </Row>
            <form
              ref={formRef}
              onSubmit={form.handleSubmit(reopenCompositions)}
            >
              <fieldset
                className={`${styles.radio} ${styles[formSteps[0]?.status]}`}
              >
                <Filter
                  mainFilters={mainFilterOptions}
                  allFilters={filterOptions}
                  selectedFilters={filterSelected}
                  onClickMainFilter={onClickFilterTag}
                  onClickFilterButton={onClickFilterButton}
                />
                <ul className={styles.optionContainer}>
                  {orderedStudentsComposition?.map((student) => (
                    <li key={student.id}>
                      <OptionCard
                        onClick={() =>
                          onClickOptionCard(student?.composition_info?.id)
                        }
                        text={`${student?.first_name} ${student?.last_name}`}
                        status={student?.composition_info?.composition_status}
                        disabled={!student?.composition_info?.reopen_enabled}
                        selected={selectedStudentsComposition
                          .concat(composition_ids)
                          .includes(`${student?.composition_info?.id}`)}
                      />
                    </li>
                  ))}
                </ul>
              </fieldset>
              <fieldset
                className={`${styles.radio} ${styles[formSteps[1]?.status]} `}
              >
                <div className={styles.endingTime}>
                  <TimeFieldset.Border>
                    <Controller
                      control={form.control}
                      name="ending_date"
                      render={({field}) => (
                        <DateInput
                          label="Selecione a data de encerramento"
                          data-testid="ending_date"
                          id="ending_date"
                          value={
                            form?.getValues('ending_date')
                              ? moment(form?.getValues('ending_date'))?.format(
                                  'DD/MM/YYYY',
                                )
                              : ''
                          }
                          isValidDate={(value) => isReopeningDateValid(value)}
                          onChange={field.onChange}
                          onDateChange={(value) =>
                            form.setValue(
                              'ending_date',
                              moment(value).format('YYYY-MM-DD'),
                              {shouldDirty: true},
                            )
                          }
                        />
                      )}
                    />
                  </TimeFieldset.Border>
                  <TimeFieldset.Border>
                    <Controller
                      control={form.control}
                      name="ending_time"
                      render={({field}) => (
                        <TimeFieldset.TimeInput
                          data-testid="ending_time"
                          id="ending_time"
                          label="Selecione a hora do encerramento"
                          value={
                            form?.getValues('ending_time')
                              ? form?.getValues('ending_time')
                              : ''
                          }
                          onChange={field.onChange}
                          isValidDate={(value) =>
                            validateEndTime(
                              form.getValues('ending_date'),
                              value,
                            )
                          }
                          handleChange={(value) =>
                            form.setValue(
                              'ending_time',
                              moment(value).format('HH:mm'),
                              {
                                shouldDirty: true,
                              },
                            )
                          }
                        />
                      )}
                    />
                    {form.formState.errors.ending_time?.message && (
                      <p className={styles.error}>
                        {form.formState.errors.ending_time?.message}
                      </p>
                    )}
                  </TimeFieldset.Border>
                </div>
              </fieldset>
              <fieldset
                className={`${styles.radio} ${styles[formSteps[2]?.status]} `}
              >
                <div className={styles.selectionCards}>
                  <SelectionCard
                    data-testid="yes-selection-card"
                    icon={{
                      icon: ['fad', 'thumbs-up'],
                      color: '#306230',
                      size: '5x',
                    }}
                    title="Sim"
                    onClickCard={() => setHasReviewHold(true)}
                    selected={hasReviewHold}
                  />
                  <SelectionCard
                    icon={{
                      icon: ['fad', 'thumbs-down'],
                      color: '#962F36',
                      size: '5x',
                    }}
                    title="Não"
                    onClickCard={() => setHasReviewHold(false)}
                    selected={!hasReviewHold}
                  />
                </div>
              </fieldset>
              {hasReviewHold && (
                <fieldset
                  className={`${styles.radio}  ${
                    styles[formSteps[3]?.status]
                  } `}
                >
                  <div className={styles.reviewPeriod}>
                    <TimeFieldset.Border>
                      <Controller
                        control={form.control}
                        name="review_ending_date"
                        render={({field}) => (
                          <DateInput
                            label="Selecione a data final"
                            data-testid="review_ending_date"
                            id="review_ending_date"
                            value={
                              form?.getValues('review_ending_date')
                                ? moment(
                                    form?.getValues('review_ending_date'),
                                  )?.format('DD/MM/YYYY')
                                : ''
                            }
                            isValidDate={(value) =>
                              isReviewingEndDateValid(value, ending_date)
                            }
                            onChange={field.onChange}
                            onDateChange={(value) => {
                              form.setValue(
                                'review_ending_date',
                                moment(value).format('YYYY-MM-DD'),
                                {shouldDirty: true},
                              );
                            }}
                          />
                        )}
                      />
                    </TimeFieldset.Border>
                  </div>
                </fieldset>
              )}
            </form>
          </section>
        )}
      </BasicModal>
      <ConfirmCloseModal
        open={activeModal === 'confirmClose'}
        onClickBackButton={() => setActiveModal('reopenComposition')}
        onClickClose={onClickCloseModal}
      />
      <RequestStatusModal
        status={requestStatus}
        successText="Reabertura Realizada com Sucesso!"
        open={activeModal === 'requestStatus'}
        onClose={onClickConfirmSuccessModal}
        onError={() => setActiveModal('reopenComposition')}
      />
    </>
  );
}

export default connect(
  (state: ApplicationState) => ({
    isLoadingStudentsReopenStatus: isLoadingStudentsReopenStatus(state),
    studentsReopenStatus: getStudentsReopenStatus(state),
    requestStatus: getReopenCompositionRequestStatus(state),
  }),
  {fetchStudentsReopenStatusRequest, reopenCompositionRequest},
)(ReopenCompositionModal);
