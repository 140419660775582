import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {AnswerChartOptions} from 'containers/MultigenreReport/ReadingIndicatorsReport/AnswerRateSlide/answerChartOptions';
import useReadingIndicator from 'features/useReadingIndicator';
import {Bar} from 'react-chartjs-2';
import {QuestionIndicatorsDetails} from 'utils/types/questionIndicatorsDetails';
import styles from './CorrectAnswerChartInfo.module.scss';

export interface CorrectAnswerChartInfoProps {
  questionIndicatorDetails: QuestionIndicatorsDetails;
  onClickOpenChoicesModal(): void;
  onClickOpenIndicatorsModal(): void;
}

function CorrectAnswerChartInfo({
  questionIndicatorDetails,
  onClickOpenChoicesModal,
  onClickOpenIndicatorsModal,
}: CorrectAnswerChartInfoProps): JSX.Element {
  const {getCorrectChoiceOption} = useReadingIndicator({});

  return (
    <div className={styles.container}>
      <div className={styles.chart}>
        <Bar
          options={AnswerChartOptions}
          plugins={[ChartDataLabels]}
          data={{
            labels: [''],
            datasets: [
              {
                data: [
                  questionIndicatorDetails?.rates_detail?.correct_answers_rate,
                ],
                barThickness: 160,
                borderRadius: 4,
                borderWidth: 2,
                borderColor: '#306230',
                backgroundColor: '#C6F1D3',
                datalabels: {
                  labels: {
                    value: {
                      font: {size: 32},
                      align: 'right',
                      formatter: (value: number) => {
                        return `${value}%`;
                      },
                    },
                    name: {
                      font: {size: 16},
                      align: '45',
                      formatter: () => {
                        return `${questionIndicatorDetails?.rates_detail?.total_correct_answers} de ${questionIndicatorDetails?.rates_detail?.total_engagement} estudantes`;
                      },
                      padding: 12,
                    },
                  },
                },
              },
            ],
          }}
        />
      </div>
      <div className={styles.textContainer}>
        <h4 className={styles.subtitle}>
          Alternativa Correta:{' '}
          {getCorrectChoiceOption(questionIndicatorDetails?.choices)}{' '}
          <button
            type="button"
            onClick={onClickOpenChoicesModal}
            className={styles.modalLink}
          >
            (Ver Alternativas)
          </button>
        </h4>
        <div>
          <FontAwesomeIcon icon={['fas', 'check-square']} color="#00C341" />{' '}
          <span>
            {questionIndicatorDetails?.rates_detail?.correct_answers_rate}% dos
            Estudantes <b>responderam corretamente</b> a questão!
          </span>
          <p>
            <button
              type="button"
              onClick={onClickOpenIndicatorsModal}
              className={styles.modalLink}
            >
              (Ver Indicadores Associados)
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CorrectAnswerChartInfo;
