import * as Sentry from '@sentry/react';
import UnderMaintenance from 'containers/UnderMaintenance';
import {useEffect, useState} from 'react';
import Router from 'routes';
import isMaintenanceMode from 'utils/functions/isMaintenanceMode';

const BETA_SUB_DOMAIN = '.beta.letrus.com.br';

export const isProdEnvironment = process.env.NODE_ENV === 'production';
export const isBetaDomain = window.location.origin.includes(BETA_SUB_DOMAIN);

function App(): JSX.Element {
  const [isMaintenanceModeOn, setIsMaintenanceModeOn] = useState(false);

  useEffect(() => {
    (async () => {
      const isMaintenance = await isMaintenanceMode();
      setIsMaintenanceModeOn(isMaintenance);
    })();
  }, []);

  return isMaintenanceModeOn ? <UnderMaintenance /> : <Router />;
}

export default Sentry.withProfiler(App);
