import styles from './Table.module.scss';

interface TableProps {
  tableHeaders: string[];
  children: JSX.Element;
  customClassName?: string;
}

function Table({
  tableHeaders,
  children,
  customClassName,
}: TableProps): JSX.Element {
  return (
    <div className={styles.tableContainer}>
      <table className={`${styles.table} ${customClassName}`}>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <th className={styles.tableHeaderTitle} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

export default Table;
