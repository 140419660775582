import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {ContentState, EditorState} from 'draft-js';

export const reviveEditorState = (
  payload: LetrusApi.CompositionDetail | LetrusApi.CompositionEmbed,
): any => {
  if (!payload.composition_raw) {
    return EditorState.createEmpty();
  }

  return EditorState.createWithContent(
    ContentState.createFromText(
      payload.composition_raw
        .split('\n')
        .filter((line) => line)
        .join('\n') || '',
    ),
  );
};
