import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FeatureFlags, Flag} from '@letrustech/feature-flags-web';
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {StudentInfoItem} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import Loading from 'components/Loading';
import PillTabs from 'components/PillTabs';
import ReopenCompositionModal from 'containers/LearningPathReport/ReopenCompositionModal';
import {Step} from 'containers/LearningPathReportEngagement';
import useLearningPathReport, {
  LearningPathStatus,
  StudentStatus,
} from 'features/useLearningPathReport';
import useReadingIndicator from 'features/useReadingIndicator';
import {List} from 'immutable';
import {InlineAlert} from 'letrus-ui';
import {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  learningPathReportEngagementMock,
  learningPathReportSummaryMock,
} from 'store/mocks/__mocks__/learningPaths';
import {LearningPathReportStepData} from 'store/mocks/__mocks__/learningPathStep';
import {
  fetchCompositionByIdRequest,
  getCompositionById,
} from 'store/reducers/compositions';
import {
  fetchFeatureFlagRequest,
  getFeatureFlags,
} from 'store/reducers/featureFlags';
import {
  fetchLearningPathReportEngagementRequest,
  fetchLearningPathReportStepRequest,
  fetchLearningPathReportSummaryRequest,
  getLearningPathReportEngagement,
  getLearningPathReportStep,
  getLearningPathReportSummary,
} from 'store/reducers/learningPaths';
import {
  fetchTestStudentsInfoRequest,
  getTestStudentsInfo,
} from 'store/reducers/tests';
import {getUser, isAllowedToEditTests} from 'store/reducers/user';
import {ApplicationState} from 'store/rootReducer';
import {FetchLearningPathReportStepServiceParams} from 'store/services/learningPathsServices';
import {isEmpty} from 'utils/functions/isEmpty';
import useTrackEvent from 'utils/hooks/useTrackEvent';
import {FeatureFlags as FeatureFlagType} from 'utils/types/featureFlags';
import ActivitySummary from './ActivitySummary';
import BNCCRelatedSkillsModal from './BNCCRelatedSkillsModal';
import Card from './Card';
import CompositionModal from './CompositionModal';
import CriteriaChart from './CriteriaChart';
import CriteriaChartModal, {CriteriaContent} from './CriteriaChartModal';
import EngagementModal from './EngagementModal';
import Header from './Header';
import ListStudentsModal from './ListStudentsModal';
import styles from './MultigenreReport.module.scss';
import PedagogicalContentModal from './PedagogicalContentModal';
import ProposalModal from './ProposalModal';
import QuestionnaireModal from './QuestionnaireModal';
import ReadingIndicatorsReport from './ReadingIndicatorsReport';
import StepCard from './StepCard';

interface MultigenreReportParams {
  learning_path_id: string;
}

interface StateProps {
  summaryData: typeof learningPathReportSummaryMock;
  testStudentsInfo: List<ImmutableMap<StudentInfoItem>>;
  composition: LetrusApi.CompositionDetail;
  engagement: typeof learningPathReportEngagementMock;
  selectedStepInfo: LearningPathReportStepData;
  featureFlags: ImmutableMap<FeatureFlagType>;
  user: ImmutableMap<LetrusApi.User>;
  isTestSchool: boolean;
  isAllowedToEditTests: boolean;
}

interface DispatchProps {
  fetchLearningPathReportSummaryRequest: typeof fetchLearningPathReportSummaryRequest;
  fetchTestStudentsInfoRequest: typeof fetchTestStudentsInfoRequest;
  fetchCompositionByIdRequest: typeof fetchCompositionByIdRequest;
  fetchLearningPathReportEngagementRequest: typeof fetchLearningPathReportEngagementRequest;
  fetchLearningPathReportStepRequest: typeof fetchLearningPathReportStepRequest;
  fetchFeatureFlagRequest: typeof fetchFeatureFlagRequest;
}

export type MultigenreReportProps = MultigenreReportParams &
  StateProps &
  DispatchProps;

function MultigenreReport({
  summaryData,
  fetchLearningPathReportSummaryRequest,
  testStudentsInfo,
  fetchTestStudentsInfoRequest,
  composition,
  fetchCompositionByIdRequest,
  engagement,
  fetchLearningPathReportEngagementRequest,
  selectedStepInfo,
  fetchLearningPathReportStepRequest,
  user,
  fetchFeatureFlagRequest,
  featureFlags,
  isAllowedToEditTests,
}: MultigenreReportProps): JSX.Element {
  const [isEngagementModalOpen, setIsEngagementModalOpen] = useState(false);
  const [isBNCCModalOpen, setIsBNCCModalOpen] = useState(false);
  const [isCompositionModalOpen, setIsCompositionModalOpen] = useState(false);
  const [isListStudentsModalOpen, setIsListStudentsModalOpen] = useState(false);
  const [isReportCriteriaModalOpen, setIsReportCriteriaModalOpen] =
    useState(false);
  const [selectedStep, setSelectedStep] =
    useState<(Partial<Step> & {type: string}) | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isReopenCompositionModalOpen, setIsReopenCompositionModalOpen] =
    useState(false);
  const [refreshPageData, setRefreshPageData] = useState(false);
  const {learning_path_id} = useParams<MultigenreReportParams>();
  const {getStatusBasedOnDates} = useLearningPathReport();
  const {getReadingIndicatorQuestionnaireId} = useReadingIndicator({});
  const {trackEvent} = useTrackEvent();
  const isReviewQueueOverloaded =
    featureFlags.get('overloaded_review_queue_warning') === 'on';

  const activityStatus = useMemo(() => {
    return getStatusBasedOnDates(
      summaryData?.learning_path_instance_start_date,
      summaryData?.learning_path_instance_end_date,
    );
  }, [
    summaryData?.learning_path_instance_start_date,
    summaryData?.learning_path_instance_end_date,
  ]);

  const canReopenComposition =
    activityStatus === 'Iniciou'
      ? true
      : !!(activityStatus === 'Finalizou' && isAllowedToEditTests);

  useEffect(() => {
    fetchLearningPathReportSummaryRequest(learning_path_id);
  }, [refreshPageData]);

  useEffect(() => {
    if (summaryData?.id === Number(learning_path_id)) {
      fetchFeatureFlag('overloaded_review_queue_warning', {});

      fetchFeatureFlag('reading_report_access', {
        school_id: `${summaryData?.school_id}`,
        isDev: process.env.NODE_ENV !== 'production',
      });

      trackEvent('multigenre_report_access', {
        learningPathInstance: {
          learning_path_instance_id: Number(learning_path_id),
          learning_path_status: LearningPathStatus[activityStatus],
        },
      });
    }
  }, [summaryData?.id]);

  useEffect(() => {
    if (selectedStep?.id) {
      const stepTypePrefix =
        selectedStep.type === 'writing' || selectedStep.type === 'rewriting'
          ? 'test'
          : selectedStep.type;

      fetchLearningPathReportStepRequest({
        learningPathId: learning_path_id,
        stepType:
          `${stepTypePrefix}_step` as FetchLearningPathReportStepServiceParams['stepType'],
        stepId: selectedStep.id,
      });
    }
  }, [selectedStep]);

  function fetchFeatureFlag(flagKey, entityContext) {
    const userId = String(user?.get('id') || null);

    fetchFeatureFlagRequest({
      flagKey,
      entityId: userId,
      entityContext: {...entityContext, user_id: userId},
    });
  }

  function onOpenListStudentsModal() {
    trackEvent('multigenre_access_student_list_button_click', {
      learningPathInstance: {
        learning_path_instance_id: Number(learning_path_id),
        learning_path_status: LearningPathStatus[activityStatus],
      },
    });

    if (summaryData?.summary?.writing.id) {
      fetchTestStudentsInfoRequest(summaryData?.summary?.writing.id);
    }

    setIsListStudentsModalOpen(true);
  }

  function onOpenCompositionModal(compositionId: number) {
    fetchCompositionByIdRequest(compositionId);

    trackEvent('in_progress_composition_access', {
      learningPathInstance: {
        learning_path_instance_id: Number(learning_path_id),
        learning_path_status: LearningPathStatus[activityStatus],
      },
      composition: {
        composition_id: compositionId,
      },
    });

    setIsCompositionModalOpen(true);
  }

  function onOpenEngagementModal() {
    trackEvent('multigenre_access_engagement_button_click', {
      learningPathInstance: {
        learning_path_instance_id: Number(learning_path_id),
        learning_path_status: LearningPathStatus[activityStatus],
      },
    });

    fetchLearningPathReportEngagementRequest(learning_path_id);
    setIsEngagementModalOpen(true);
  }

  function onClickReadingButton(tabIndex: number) {
    trackEvent('multigenre_reading_button_click', {
      learningPathInstance: {
        learning_path_instance_id: Number(learning_path_id),
        learning_path_status: LearningPathStatus[activityStatus],
      },
    });

    setActiveTab(tabIndex);
  }

  function onClickStepCardButton(step: Partial<Step> & {type: string}) {
    trackEvent('multigenre_report_step_click', {
      learningPathInstance: {
        learning_path_instance_id: Number(learning_path_id),
        learning_path_status: LearningPathStatus[activityStatus],
        step_id: step?.id,
      },
    });

    setSelectedStep(step);
  }

  if (isEmpty(summaryData)) {
    return <Loading show />;
  }

  function onClickHeaderButton() {
    trackEvent('BNCC_skills_button_click', {
      learningPathInstance: {
        learning_path_instance_id: Number(learning_path_id),
        learning_path_status: LearningPathStatus[activityStatus],
      },
    });

    setIsBNCCModalOpen(true);
  }

  function onClickCriteriaNextSteps(criteria: CriteriaContent) {
    trackEvent('multigenre_competence_next_steps_click', {
      learningPathInstance: {
        learning_path_instance_id: Number(learning_path_id),
        learning_path_status: LearningPathStatus[activityStatus],
      },
      review: {
        competence_id: criteria?.competence_grade_info?.competence,
      },
    });
  }

  function onClickReopenTest() {
    trackEvent('reopen_test_button_click', {
      learningPathInstance: {
        learning_path_instance_id: Number(learning_path_id),
        learning_path_status: LearningPathStatus[activityStatus],
      },
    });

    setIsReopenCompositionModalOpen(true);
  }

  const testInformations = summaryData?.summary?.writing?.test_informations;

  const isActivityInProgress = activityStatus !== StudentStatus.finished;
  const isActivityNotStarted = activityStatus === StudentStatus.notStarted;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <FeatureFlags variantKey={featureFlags.get('reading_report_access')}>
          <Flag variant="on">
            {
              // if any of the questionnaires have reading indicators, release access to pilltab
              getReadingIndicatorQuestionnaireId({
                steps: summaryData?.summary?.steps,
              }) && (
                <PillTabs
                  tabs={[
                    {
                      text: 'Escrita',
                      onClick: (index) => {
                        setActiveTab(index);
                      },
                      icon: <FontAwesomeIcon icon={['fad', 'pencil']} />,
                    },
                    {
                      text: 'Leitura',
                      onClick: onClickReadingButton,
                      icon: (
                        <FontAwesomeIcon icon={['fad', 'book-open-cover']} />
                      ),
                    },
                  ]}
                  customClassName="blueButtons"
                  activeTab={activeTab}
                />
              )
            }
          </Flag>
        </FeatureFlags>
        <Header
          title={summaryData?.school_group_long_name}
          button={{
            text: 'Ver Habilidades',
            onClick: onClickHeaderButton,
          }}
        >
          <ActivitySummary
            dateStart={summaryData?.learning_path_instance_start_date}
            dateEnd={summaryData?.learning_path_instance_end_date}
            name={summaryData?.learning_path_template_name}
            activityStatus={activityStatus}
          />
        </Header>
        {canReopenComposition && (
          <InlineAlert
            backgroundColor={
              activityStatus === 'Finalizou' ? '#C6F1D3' : '#FFEAC4'
            }
            mainColor={activityStatus === 'Finalizou' ? '#306230' : '#F3AF31'}
            secondButtonText="Reabrir Escrita"
            leftIcon={
              activityStatus === 'Finalizou'
                ? {
                    icon: ['fad', 'octagon-check'],
                  }
                : {
                    icon: ['fad', 'diamond-exclamation'],
                  }
            }
            buttonTextColor={
              activityStatus === 'Finalizou' ? '#FFFFFF' : '#000000'
            }
            onClickSecondButton={onClickReopenTest}
          >
            {activityStatus === 'Finalizou' ? (
              <article className={styles.alertText}>
                <b className={styles.highlightText}>
                  Professor(a), esta trilha chegou ao fim!
                </b>{' '}
                Se desejar, clique no botão ao lado para reabrir a etapa de
                escrita e estipular um novo prazo.
              </article>
            ) : (
              <article className={styles.alertText}>
                <b className={styles.highlightText}>
                  Esta trilha está em andamento.
                </b>{' '}
                Se desejar, você pode clicar ao lado para reabrir a etapa de
                escrita de alguns estudantes.
              </article>
            )}
          </InlineAlert>
        )}
        {activeTab === 0 && (
          <>
            <main className={styles.centralContent}>
              <Card
                title="Nota Geral:"
                mainContent={`${
                  Math.max(testInformations?.average_score, 0) || 0
                } de 10`}
                contentDescription="é a nota média da turma nesta atividade."
                onClickButton={onOpenListStudentsModal}
                isDisabled={isActivityNotStarted}
              />

              <CriteriaChart
                chartData={testInformations?.competences_average?.map(
                  (competence) =>
                    Math.max(Math.round(competence?.competence_average), 0),
                )}
                labels={testInformations?.competences_average?.map(
                  (competence) => competence.competence_name,
                )}
                onButtonClick={() => setIsReportCriteriaModalOpen(true)}
                isButtonDisabled={isActivityNotStarted}
              />

              <Card
                title="Engajamento:"
                mainContent={`${Math.max(
                  summaryData?.summary?.engagement?.finished_students || 0,
                  0,
                )} de ${summaryData?.summary?.engagement?.total_students}`}
                contentDescription="estudantes finalizaram esta atividade."
                onClickButton={onOpenEngagementModal}
                isDisabled={isActivityNotStarted}
              />
            </main>
            <StepCard
              onClickButton={(step) => onClickStepCardButton(step)}
              summary={summaryData?.summary}
            />
          </>
        )}
        {activeTab === 1 && (
          <ReadingIndicatorsReport
            learningPathName={summaryData?.learning_path_template_name}
            schoolGroupId={summaryData?.school_group_id}
            questionnaireId={getReadingIndicatorQuestionnaireId({
              steps: summaryData?.summary?.steps,
            })}
            learningPathInstanceId={Number(learning_path_id)}
            learningPathStatus={LearningPathStatus[activityStatus]}
          />
        )}

        {isBNCCModalOpen && (
          <BNCCRelatedSkillsModal
            onClickClose={() => setIsBNCCModalOpen(false)}
            learning_path_instance_id={learning_path_id}
          />
        )}
        {isListStudentsModalOpen && (
          <ListStudentsModal
            isReviewQueueOverloaded={isReviewQueueOverloaded}
            testStudentsInfo={testStudentsInfo}
            onClickClose={() => setIsListStudentsModalOpen(false)}
            onButtonClick={(compositionId) =>
              onOpenCompositionModal(compositionId)
            }
            isActivityInProgress={isActivityInProgress}
          />
        )}
        {isCompositionModalOpen && (
          <CompositionModal
            onClickClose={() => setIsCompositionModalOpen(false)}
            isActivityInProgress={!composition?.finished}
            compositionInfo={composition}
          />
        )}
        {isEngagementModalOpen && (
          <EngagementModal
            onClickClose={() => setIsEngagementModalOpen(false)}
            engagementInfo={engagement}
            isActivityInProgress={isActivityInProgress}
          />
        )}
        {isReportCriteriaModalOpen && (
          <CriteriaChartModal
            onClickClose={() => setIsReportCriteriaModalOpen(false)}
            criteriaContent={
              summaryData?.summary?.writing?.test_informations
                ?.competences_average
            }
            isLive={isActivityInProgress}
            onAccordeonClick={onClickCriteriaNextSteps}
          />
        )}
        {selectedStep?.type === 'writing' && (
          <ProposalModal
            onClickClose={() => setSelectedStep(null)}
            instruction={selectedStepInfo?.instruction}
            genre={selectedStepInfo?.instruction?.genre_name}
            aids={selectedStepInfo?.instruction?.aids}
          />
        )}
        {selectedStep?.type === 'questionnaire' && (
          <QuestionnaireModal
            onClickClose={() => setSelectedStep(null)}
            learningPathReportStep={selectedStepInfo}
            isActivityInProgress={isActivityInProgress}
          />
        )}
        {selectedStep?.type === 'pedagogical_content' && (
          <PedagogicalContentModal
            onClickClose={() => setSelectedStep(null)}
            title={selectedStepInfo?.title}
            content={selectedStepInfo?.content_html}
          />
        )}
      </div>
      {isReopenCompositionModalOpen && (
        <ReopenCompositionModal
          testId={summaryData?.summary?.writing?.id}
          status={LearningPathStatus[activityStatus]}
          onClose={() => setIsReopenCompositionModalOpen(false)}
          requestCallback={() => setRefreshPageData(!refreshPageData)}
        />
      )}
    </div>
  );
}
export default connect(
  (state: ApplicationState) => ({
    summaryData: getLearningPathReportSummary(state),
    testStudentsInfo: getTestStudentsInfo(state),
    composition: getCompositionById(state).toJS(),
    engagement: getLearningPathReportEngagement(state),
    selectedStepInfo: getLearningPathReportStep(state),
    user: getUser(state),
    featureFlags: getFeatureFlags(state),
    isAllowedToEditTests: isAllowedToEditTests(state),
  }),
  {
    fetchLearningPathReportSummaryRequest,
    fetchTestStudentsInfoRequest,
    fetchCompositionByIdRequest,
    fetchLearningPathReportEngagementRequest,
    fetchLearningPathReportStepRequest,
    fetchFeatureFlagRequest,
  },
)(MultigenreReport);
