import Spinner from '../Spinner';
import './SelectDropdownField.scss';

interface ISelectDropdownField {
  value?: any;
  onChange: (any) => void;
  disabled?: boolean;
  input?: any;
  children?: any;
  style?: any;
  loading?: boolean;
  InputComponent?: any;
  name?: string;
}

const SelectDropdownField: React.FC<ISelectDropdownField> = ({
  value,
  onChange,
  disabled,
  input,
  children,
  loading,
}) => {
  return (
    <div className="arrow">
      <select
        className="SelectDropdownField"
        value={value}
        onChange={onChange}
        onBlur={() => undefined}
        disabled={disabled}
        {...input}
      >
        {children}
      </select>
      {loading && (
        <Spinner
          className="SelectDropdownField--loading"
          size={20}
          color="#24429b"
        />
      )}
    </div>
  );
};

export default SelectDropdownField;
