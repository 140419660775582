// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global sendinblue */
import api from 'store/services/api';

/**
 * HUBSPOT API
 */

export async function hubspotTokenToIdentifyUserToWebChat() {
  return api(
    `${process.env.REACT_APP_API_URL}/api/v1/hubspot_visitor_identification/`,
    {
      method: 'get',
    },
  )
    .then((response) => response.data)
    .catch(() => console.log('Hubspot error'));
}

/**
 * End HUBSPOT
 */
