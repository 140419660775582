import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Moment} from 'moment';
import {ForwardedRef, forwardRef, InputHTMLAttributes} from 'react';
import Datetime from 'react-datetime';
import 'utils/styles/react-datetime.scss';
import styles from './DateInput.module.scss';

export interface DateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onDateChange?(value: string | Moment): void;
  isValidDate?(currentDate: string, selectedDate: string): boolean;
}

function DateInput(
  {label, onDateChange, isValidDate, ...inputProps}: DateInputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  return (
    <div className={styles.container}>
      <label htmlFor={inputProps.id} className={styles.label}>
        {label}
      </label>
      <div className={styles.dateTimeContainer}>
        <Datetime
          closeOnSelect
          strictParsing
          locale="pt-br"
          timeFormat={false}
          onChange={onDateChange}
          dateFormat="DD/MM/YYYY"
          isValidDate={isValidDate}
          className={styles.dateTime}
          inputProps={{
            placeholder: 'dd / mm / aaaa',
            ref,
            ...inputProps,
            className: styles.dateInput,
          }}
        />
        <FontAwesomeIcon icon="calendar-alt" className={styles.icon} />
      </div>
    </div>
  );
}

export default forwardRef(DateInput);
