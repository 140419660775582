import {takeLatest} from 'redux-saga/effects';
import {
  abandonReview,
  AbandonReviewReasonsTypes,
  fetchAbandonReviewReasons,
} from '../reducers/abandonReview';

const abandonReviewSagas = [
  takeLatest(AbandonReviewReasonsTypes.ABANDON_REQUEST, abandonReview),
  takeLatest(
    AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_REQUEST,
    fetchAbandonReviewReasons,
  ),
];

export default abandonReviewSagas;
