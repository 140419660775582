import {isProdEnvironment} from 'App';
import api from './api';

export interface TrackEventRequest {
  DeliveryStreamName: string;
  Record: {
    Data: string; // JSON encoded with base64
  };
}

export function trackEventsService(data: TrackEventRequest) {
  return api(
    `${
      isProdEnvironment
        ? (process.env.REACT_APP_PROD_CLICKSTREAM_COLLECTOR_URL as string)
        : (process.env.REACT_APP_DEV_CLICKSTREAM_COLLECTOR_URL as string)
    }/api/clickstream`,
    {
      method: 'post',
      data,
      headers: {
        'x-api-key': isProdEnvironment
          ? (process.env.REACT_APP_PROD_CLICKSTREAM_X_API_KEY as string)
          : (process.env.REACT_APP_DEV_CLICKSTREAM_X_API_KEY as string),
      },
      withCredentials: false,
    },
  )
    .then((response) => response)
    .catch(() => console.log('Clickstream error'));
}
