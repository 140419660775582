import {fromJS, List} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {action} from 'typesafe-actions';
import {fetchZeroGradeCommentService} from '../../services/zeroGradeCommentServices';

// Action types
export enum ZeroGradeCommentsTypes {
  FETCH_ZERO_GRADE_COMMENTS_REQUEST = '@zero_grade_comments/FETCH_ZERO_GRADE_COMMENTS_REQUEST',
  FETCH_ZERO_GRADE_COMMENTS_SUCCESS = '@zero_grade_comments/FETCH_ZERO_GRADE_COMMENTS_SUCCESS',
  FETCH_ZERO_GRADE_COMMENTS_FAILURE = '@zero_grade_comments/FETCH_ZERO_GRADE_COMMENTS_FAILURE',
}

// Data types

// State type
export interface ZeroGradeCommentsState
  extends ImmutableMap<{
    data: List<any>;
    loading: boolean;
    error: boolean;
    dataCount: number;
  }> {
  withMutations(arg0: (prevState: any) => any): ZeroGradeCommentsState;
}

// Fetch ZeroGradeComment Actions
export const fetchZeroGradeCommentsRequest = (
  genreId: number,
  userRole: string,
) =>
  action(ZeroGradeCommentsTypes.FETCH_ZERO_GRADE_COMMENTS_REQUEST, {
    genreId,
    userRole,
  });

export const fetchZeroGradeCommentSuccess = (data: any) =>
  action(ZeroGradeCommentsTypes.FETCH_ZERO_GRADE_COMMENTS_SUCCESS, data);

export const fetchZeroGradeCommentFailure = () =>
  action(ZeroGradeCommentsTypes.FETCH_ZERO_GRADE_COMMENTS_FAILURE);

// Sagas
export function* fetchZeroGradeComment(action: AnyAction) {
  try {
    const response = yield call(
      fetchZeroGradeCommentService,
      action.payload.genreId,
      action.payload.userRole,
    );
    yield put(fetchZeroGradeCommentSuccess(response.data));
  } catch (error) {
    yield put(fetchZeroGradeCommentFailure());
  }
}

// Initial State
export const INITIAL_STATE: ZeroGradeCommentsState = fromJS({
  data: [],
  loading: false,
  error: false,
  dataCount: 0,
});

// Selectors
const zeroGradeCommentSelector = (state) => state.get('zeroGradeComments');

export const getZeroGradeComments = createSelector(
  zeroGradeCommentSelector,
  (zeroGradeComment) => zeroGradeComment.get('data'),
);

// Reducer
const reducer: Reducer<ZeroGradeCommentsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ZeroGradeCommentsTypes.FETCH_ZERO_GRADE_COMMENTS_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );
    }

    case ZeroGradeCommentsTypes.FETCH_ZERO_GRADE_COMMENTS_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.results))
          .set('dataCount', action.payload.count),
      );
    }

    case ZeroGradeCommentsTypes.FETCH_ZERO_GRADE_COMMENTS_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
