/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {
  bnccRelatedSkillsMock,
  learningPathReportEngagementMock,
  learningPathReportSummaryMock,
} from 'store/mocks/__mocks__/learningPaths';
import {LearningPathReportStepData} from 'store/mocks/__mocks__/learningPathStep';
import {action} from 'typesafe-actions';
import {
  createLearningPathInstanceService,
  fetchLearningPathByIdService,
  fetchLearningPathInstanceCreationStatusService,
  fetchLearningPathReportEngagementService,
  fetchLearningPathReportRelatedSkillsService,
  fetchLearningPathReportStepService,
  FetchLearningPathReportStepServiceParams,
  fetchLearningPathReportSummaryService,
  fetchLearningPathsService,
  fetchLearningPathTemplatesByGenreService,
  reopenCompositionParams,
  reopenCompositionService,
} from '../../services/learningPathsServices';

// Action types
export enum LearningPathsTypes {
  FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_REQUEST = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_REQUEST',
  FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_SUCCESS = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_SUCCESS',
  FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_FAILURE = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_FAILURE',

  FETCH_REQUEST = '@learningPaths/FETCH_REQUEST',
  FETCH_SUCCESS = '@learningPaths/FETCH_SUCCESS',
  FETCH_FAILURE = '@learningPaths/FETCH_FAILURE',

  FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_REQUEST = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_REQUEST',
  FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_SUCCESS = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_SUCCESS',
  FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_FAILURE = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_FAILURE',

  FETCH_BY_ID_REQUEST = '@learningPaths/FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS = '@learningPaths/FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE = '@learningPaths/FETCH_BY_ID_FAILURE',

  FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_REQUEST = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_REQUEST',
  FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_SUCCESS = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_SUCCESS',
  FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_FAILURE = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_FAILURE',

  FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_REQUEST = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_REQUEST',
  FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_SUCCESS = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_SUCCESS',
  FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_FAILURE = '@learningPaths/FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_FAILURE',

  FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_REQUEST = '@learningPaths/FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_REQUEST',
  FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_SUCCESS = '@learningPaths/FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_SUCCESS',
  FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_FAILURE = '@learningPaths/FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_FAILURE',

  CREATE_LEARNING_PATH_INSTANCE_REQUEST = '@learningPaths/CREATE_LEARNING_PATH_INSTANCE_REQUEST',
  CREATE_LEARNING_PATH_INSTANCE_SUCCESS = '@learningPaths/CREATE_LEARNING_PATH_INSTANCE_SUCCESS',
  CREATE_LEARNING_PATH_INSTANCE_FAILURE = '@learningPaths/CREATE_LEARNING_PATH_INSTANCE_FAILURE',

  FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_REQUEST = '@learningPaths/FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_REQUEST',
  FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_SUCCESS = '@learningPaths/FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_SUCCESS',
  FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_FAILURE = '@learningPaths/FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_FAILURE',

  REOPEN_COMPOSITION_REQUEST = '@learningPaths/REOPEN_COMPOSITION_REQUEST',
  REOPEN_COMPOSITION_SUCCESS = '@learningPaths/REOPEN_COMPOSITION_SUCCESS',
  REOPEN_COMPOSITION_FAILURE = '@learningPaths/REOPEN_COMPOSITION_FAILURE',
}

// Data types

// State type
export interface LearningPathsState extends Map<string, any> {
  readonly LearningPathReportEngagement: typeof learningPathReportEngagementMock;
  readonly isLoadingLearningPathReportEngagement: boolean;

  readonly data: List<ImmutableMap<any>> | undefined;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly LearningPathReportSummaryError: boolean;
  readonly LearningPathReportRelatedSkills: typeof bnccRelatedSkillsMock;
  readonly isLoadingLearningPathReportRelatedSkills: boolean;
  readonly dataCount: number;

  readonly learningPathById: ImmutableMap<LetrusApi.AdminApiV1LearningPathTemplatesByIdGetResponse200>;
  readonly LearningPathReportSummary: typeof learningPathReportSummaryMock;
  readonly isLoadingLearningPathReportSummary: boolean;
  readonly isLoadingLearningPathById: boolean;
  readonly LearningPathReportStep: LearningPathReportStepData;
  readonly isLoadingLearningPathReportStep: boolean;
  readonly learningPathTemplatesByGenre: {
    results: LetrusApi.AdminLearningPathTemplate[];
  };
  readonly isLoadingLearningPathTemplatesByGenre: boolean;
  readonly createLearningPathInstanceRequestId: string;

  readonly learningPathInstanceCreationStatus?: string;
}

// Fetch actions
export const fetchLearningPathReportEngagementRequest = (
  learningPathId: number | string,
) =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_REQUEST,
    {learningPathId},
  );

export const fetchLearningPathReportEngagementSuccess = (
  data: typeof learningPathReportEngagementMock,
) =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_SUCCESS,
    {data},
  );

export const fetchLearningPathReportEngagementFailure = () =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_FAILURE,
  );

export const fetchLearningPathsRequest = () =>
  action(LearningPathsTypes.FETCH_REQUEST);

export const fetchLearningPathsSuccess = (data: any) =>
  action(LearningPathsTypes.FETCH_SUCCESS, data);

export const fetchLearningPathsFailure = () =>
  action(LearningPathsTypes.FETCH_FAILURE);

export const fetchLearningPathReportRelatedSkillsRequest = (
  learningPathId: number | string,
) =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_REQUEST,
    {learningPathId},
  );

export const fetchLearningPathReportRelatedSkillsSuccess = (
  data: typeof bnccRelatedSkillsMock,
) =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_SUCCESS,
    {data},
  );

export const fetchLearningPathReportRelatedSkillsFailure = () =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_FAILURE,
  );

// Fetch by ID actions
export const fetchLearningPathByIdRequest = (learningPathId: number | string) =>
  action(LearningPathsTypes.FETCH_BY_ID_REQUEST, {learningPathId});

export const fetchLearningPathByIdSuccess = (
  data: LetrusApi.ApiV1LearningPathUserInstancesByIdGetResponse200,
) => action(LearningPathsTypes.FETCH_BY_ID_SUCCESS, {data});

export const fetchLearningPathByIdFailure = () =>
  action(LearningPathsTypes.FETCH_BY_ID_FAILURE);

export const fetchLearningPathReportSummaryRequest = (
  learningPathId: number | string,
) =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_REQUEST,
    {learningPathId},
  );

export const fetchLearningPathReportSummarySuccess = (
  data: typeof learningPathReportSummaryMock,
) =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_SUCCESS,
    {data},
  );

export const fetchLearningPathReportSummaryFailure = () =>
  action(
    LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_FAILURE,
  );

// Fetch Step by ID actions
export const fetchLearningPathReportStepRequest = ({
  learningPathId,
  stepType,
  stepId,
}: FetchLearningPathReportStepServiceParams) =>
  action(LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_REQUEST, {
    learningPathId,
    stepType,
    stepId,
  });

export const fetchLearningPathReportStepSuccess = (
  data: LearningPathReportStepData,
) =>
  action(LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_SUCCESS, {
    data,
  });

export const fetchLearningPathReportStepFailure = () =>
  action(LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_FAILURE);

export const fetchLearningPathTemplatesByGenreSuccess = (
  data: LearningPathReportStepData,
) =>
  action(LearningPathsTypes.FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_SUCCESS, {
    data,
  });

export const fetchLearningPathTemplatesByGenreFailure = () =>
  action(LearningPathsTypes.FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_FAILURE);

export const fetchLearningPathTemplatesByGenreRequest = (params?: {
  genre_id: number;
  review_type?: string;
}) =>
  action(LearningPathsTypes.FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_REQUEST, {
    params,
  });

export const createLearningPathInstanceSuccess = (
  data: LearningPathReportStepData,
) =>
  action(LearningPathsTypes.CREATE_LEARNING_PATH_INSTANCE_SUCCESS, {
    data,
  });

export const createLearningPathInstanceFailure = () =>
  action(LearningPathsTypes.CREATE_LEARNING_PATH_INSTANCE_FAILURE);

export const createLearningPathInstanceRequest = (data?: any, params?: any) =>
  action(LearningPathsTypes.CREATE_LEARNING_PATH_INSTANCE_REQUEST, {
    data,
    params,
  });

export const fetchLearningPathInstanceCreationStatusRequest = (params) =>
  action(
    LearningPathsTypes.FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_REQUEST,
    {
      params,
    },
  );

export const fetchLearningPathInstanceCreationStatusSuccess = (data: boolean) =>
  action(
    LearningPathsTypes.FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_SUCCESS,
    data,
  );

export const fetchLearningPathInstanceCreationStatusFailure = () =>
  action(
    LearningPathsTypes.FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_FAILURE,
  );

export const reopenCompositionRequest = (data: reopenCompositionParams) =>
  action(LearningPathsTypes.REOPEN_COMPOSITION_REQUEST, {
    data,
  });

export const reopenCompositionSuccess = (data: any) =>
  action(LearningPathsTypes.REOPEN_COMPOSITION_SUCCESS, data);

export const reopenCompositionFailure = () =>
  action(LearningPathsTypes.REOPEN_COMPOSITION_FAILURE);

// Sagas
export function* fetchLearningPathReportEngagement(action: AnyAction) {
  try {
    const response = yield call(
      fetchLearningPathReportEngagementService,
      action.payload.learningPathId,
    );

    yield put(fetchLearningPathReportEngagementSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathReportEngagementFailure());
  }
}

export function* fetchLearningPaths() {
  try {
    const response = yield call(fetchLearningPathsService);

    yield put(fetchLearningPathsSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathsFailure());
  }
}

export function* fetchLearningPathReportRelatedSkills(action: AnyAction) {
  try {
    const response = yield call(
      fetchLearningPathReportRelatedSkillsService,
      action.payload.learningPathId,
    );

    yield put(fetchLearningPathReportRelatedSkillsSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathReportRelatedSkillsFailure());
  }
}

export function* fetchLearningPathById(action: AnyAction) {
  try {
    const response = yield call(
      fetchLearningPathByIdService,
      action.payload.learningPathId,
    );

    yield put(fetchLearningPathByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathByIdFailure());
  }
}

export function* fetchLearningPathReportSummary(action: AnyAction) {
  try {
    const response = yield call(
      fetchLearningPathReportSummaryService,
      action.payload.learningPathId,
    );

    yield put(fetchLearningPathReportSummarySuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathReportSummaryFailure());
  }
}

export function* fetchLearningPathReportStep(action: AnyAction) {
  try {
    const response = yield call(fetchLearningPathReportStepService, {
      learningPathId: action.payload.learningPathId,
      stepType: action.payload.stepType,
      stepId: action.payload.stepId,
    });

    yield put(fetchLearningPathReportStepSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathReportStepFailure());
  }
}

export function* fetchLearningPathTemplatesByGenre(action: AnyAction) {
  try {
    const response = yield call(
      fetchLearningPathTemplatesByGenreService,
      action.payload.params,
    );
    yield put(fetchLearningPathTemplatesByGenreSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathTemplatesByGenreFailure());
  }
}

export function* createLearningPathInstance(action: AnyAction) {
  try {
    const response = yield call(
      createLearningPathInstanceService,
      action.payload.data,
      action.payload.params,
    );
    if (response.data.public_api_response.message !== 'queued') {
      yield put(createLearningPathInstanceFailure());
      return;
    }
    yield put(createLearningPathInstanceSuccess(response.data));
  } catch (err) {
    yield put(createLearningPathInstanceFailure());
  }
}

export function* fetchLearningPathInstanceCreationStatus(action: AnyAction) {
  try {
    const response = yield call(
      fetchLearningPathInstanceCreationStatusService,
      action.payload.params,
    );
    yield put(fetchLearningPathInstanceCreationStatusSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathInstanceCreationStatusFailure());
  }
}

export function* reopenComposition(action: AnyAction) {
  try {
    const response = yield call(reopenCompositionService, action.payload.data);
    yield put(reopenCompositionSuccess(response.data));
  } catch (err) {
    yield put(reopenCompositionFailure());
  }
}

// Selectors
const learningPathsDataSelector = (state) => state.get('learningPaths');

export const getLearningPathReportEngagement = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('LearningPathReportEngagement'),
);

export const isLoadingLearningPathReportEngagement = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('isLoadingLearningPathReportEngagement'),
);

export const isLearningPathReportSummaryError = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('LearningPathReportSummaryError'),
);

export const getLearningPaths = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('data'),
);

export const isLoadingLearningPathsData = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('loading'),
);

export const getLearningPathReportRelatedSkills = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('LearningPathReportRelatedSkills'),
);

export const isLoadingLearningPathReportRelatedSkills = createSelector(
  learningPathsDataSelector,
  (learningPaths) =>
    learningPaths.get('isLoadingLearningPathReportRelatedSkills'),
);

export const getCreateLearningPathInstanceRequestId = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('createLearningPathInstanceRequestId'),
);

export const getLearningPathById = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('learningPathById'),
);

export const getLearningPathReportSummary = createSelector(
  learningPathsDataSelector,
  (learningPaths) =>
    learningPaths.get('LearningPathReportSummary').toJS?.() ||
    learningPaths.get('LearningPathReportSummary'),
);

export const isLoadingLearningPathReportSummary = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('isLoadingLearningPathReportSummary'),
);

export const getLearningPathReportStep = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('LearningPathReportStep'),
);

export const isLoadingLearningPathReportStep = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('isLoadingLearningPathReportStep'),
);

export const getLearningPathTemplatesByGenre = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('learningPathTemplatesByGenre')?.results,
);

export const getIsCreateLearningPathInstanceLoading = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('isCreateLearningPathInstanceLoading'),
);

export const getCreateLearningPathInstanceError = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('createLearningPathInstanceError'),
);

export const getLearningPathFetchError = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('error'),
);

export const getLearningPathInstanceCreationStatus = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('learningPathInstanceCreationStatus'),
);

export const getReopenComposition = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('reopenComposition'),
);

export const getisReopenCompositionLoading = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('isReopenCompositionLoading'),
);

export const getReopenCompositionRequestStatus = createSelector(
  learningPathsDataSelector,
  (learningPaths) => learningPaths.get('reopenCompositionRequestStatus'),
);

// Initial state
export const INITIAL_STATE: LearningPathsState = fromJS({
  LearningPathReportEngagement: {},
  isLoadingLearningPathReportEngagement: false,
  data: fromJS([]),
  error: false,
  LearningPathReportSummaryError: false,
  loading: false,
  dataCount: 0,
  LearningPathReportRelatedSkills: [],
  isLoadingLearningPathReportRelatedSkills: false,
  learningPathById: fromJS({}),
  isLoadingLearningPathById: false,
  LearningPathReportSummary: {},
  isLoadingLearningPathReportSummary: false,
  LearningPathReportStep: {},
  isLoadingLearningPathReportStep: false,
  isCreateLearningPathInstanceLoading: false,
  createLearningPathInstanceError: false,
  learningPathInstanceCreationStatus: undefined,
  createLearningPathInstanceRequestId: null,
  reopenComposition: undefined,
  isReopenCompositionLoading: false,
  reopenCompositionRequestStatus: undefined,
});

// Reducer
export const reducer: Reducer<LearningPathsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLearningPathReportEngagement', true)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('LearningPathReportEngagement', action.payload.data)
          .set('isLoadingLearningPathReportEngagement', false)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_ENGAGEMENT_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLearningPathReportEngagement', false)
          .set('error', true),
      );

    case LearningPathsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case LearningPathsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.results))
          .set('dataCount', action.payload.count),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLearningPathReportRelatedSkills', true)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('LearningPathReportRelatedSkills', action.payload.data)
          .set('isLoadingLearningPathReportRelatedSkills', false)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_RELATED_SKILLS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLearningPathReportRelatedSkills', false)
          .set('error', true),
      );

    case LearningPathsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case LearningPathsTypes.FETCH_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingLearningPathById', true).set('error', false),
      );

    case LearningPathsTypes.FETCH_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('learningPathById', fromJS(action.payload.data))
          .set('dataCount', 1)
          .set('isLoadingLearningPathById', false)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingLearningPathById', false).set('error', true),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLerningPathById', true)
          .set('isLoadingfinishedLearningPathReportSummary', true)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('LearningPathReportSummary', action.payload.data)
          .set('isLoadingLearningPathReportSummary', false)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_SUMMARY_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLearningPathReportSummary', false)
          .set('LearningPathReportSummaryError', true),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLearningPathReportStep', true)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('LearningPathReportStep', action.payload.data)
          .set('isLoadingLearningPathReportStep', false)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_FINISHED_LEARNING_PATH_REPORT_STEP_FAILURE:
      return state.withMutations((prevState) => {
        return prevState
          .set('isLoadingLearningPathReportStep', false)
          .set('error', true);
      });
    case LearningPathsTypes.FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLearningPathTemplatesByGenre', true)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('learningPathTemplatesByGenre', action.payload.data)
          .set('isLoadingLearningPathTemplatesByGenre', false)
          .set('error', false),
      );

    case LearningPathsTypes.FETCH_LEARNING_PATH_TEMPLATES_BY_GENRE_FAILURE:
      return state.withMutations((prevState) => {
        return prevState
          .set('isLoadingLearningPathTemplatesByGenre', false)
          .set('error', true);
      });

    case LearningPathsTypes.CREATE_LEARNING_PATH_INSTANCE_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isCreateLearningPathInstanceLoading', true)
          .set('createLearningPathInstanceError', false),
      );

    case LearningPathsTypes.CREATE_LEARNING_PATH_INSTANCE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isCreateLearningPathInstanceLoading', false)
          .set(
            'createLearningPathInstanceRequestId',
            action.payload.data.public_api_response.request_id,
          )
          .set('learningPathInstanceCreationStatus', undefined),
      );

    case LearningPathsTypes.CREATE_LEARNING_PATH_INSTANCE_FAILURE:
      return state.withMutations((prevState) => {
        return prevState
          .set('isCreateLearningPathInstanceLoading', false)
          .set('createLearningPathInstanceError', true);
      });

    case LearningPathsTypes.FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_REQUEST:
      return state.withMutations((prevState) => prevState.set('error', false));

    case LearningPathsTypes.FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set(
            'learningPathInstanceCreationStatus',
            action.payload.creation_progress.status,
          )
          .set('createLearningPathInstanceRequestId', null),
      );

    case LearningPathsTypes.FETCH_LEARNING_PATH_INSTANCE_CREATION_STATUS_FAILURE:
      return state.withMutations((prevState) => prevState.set('error', true));

    case LearningPathsTypes.REOPEN_COMPOSITION_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isReopenCompositionLoading', true)
          .set('error', false)
          .set('reopenCompositionRequestStatus', ''),
      );

    case LearningPathsTypes.REOPEN_COMPOSITION_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isReopenCompositionLoading', false)
          .set('error', false)
          .set('reopenCompositionRequestStatus', 'success'),
      );

    case LearningPathsTypes.REOPEN_COMPOSITION_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isReopenCompositionLoading', false)
          .set('error', true)
          .set('reopenCompositionRequestStatus', 'error'),
      );

    default:
      return state;
  }
};

export default reducer;
