import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {StudentReopenComposition} from 'store/mocks/__mocks__/reopenComposition';
import {action} from 'typesafe-actions';
import {GetParams} from '../../services/api';
import {
  createTestService,
  deleteTestService,
  fetchEvolutionReportService,
  fetchLiveTestDataService,
  fetchScheduledActivitiesService,
  fetchStudentsReopenStatusService,
  fetchTestByIdService,
  fetchTestGradeInfoService,
  fetchTestIndicatorsInfoService,
  fetchTestsBySchoolGroupService,
  fetchTestStudentsInfoService,
  fetchTestsWithScoreParams,
  fetchTestsWithScoreService,
  patchTestService,
  putTestService,
  sendTestsService,
  triggerLiveTestService,
} from '../../services/testServices';

// Action types
export enum TestsTypes {
  FETCH_SCHEDULED_ACTIVITIES_REQUEST = '@tests/FETCH_SCHEDULED_ACTIVITIES_REQUEST',
  FETCH_SCHEDULED_ACTIVITIES_SUCCESS = '@tests/FETCH_SCHEDULED_ACTIVITIES_SUCCESS',
  FETCH_SCHEDULED_ACTIVITIES_FAILURE = '@tests/FETCH_SCHEDULED_ACTIVITIES_FAILURE',

  CREATE_REQUEST = '@tests/CREATE_REQUEST',
  CREATE_SUCCESS = '@tests/CREATE_SUCCESS',
  CREATE_FAILURE = '@tests/CREATE_FAILURE',

  FETCH_BY_ID_REQUEST = '@tests/FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS = '@tests/FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE = '@tests/FETCH_BY_ID_FAILURE',

  FETCH_LIVE_DATA_REQUEST = '@tests/FETCH_LIVE_DATA_REQUEST',
  FETCH_LIVE_DATA_SUCCESS = '@tests/FETCH_LIVE_DATA_SUCCESS',
  FETCH_LIVE_DATA_FAILURE = '@tests/FETCH_LIVE_DATA_FAILURE',

  TRIGGER_LIVE_TEST_REQUEST = '@tests/TRIGGER_LIVE_TEST_REQUEST',
  TRIGGER_LIVE_TEST_SUCCESS = '@tests/TRIGGER_LIVE_TEST_SUCCESS',
  TRIGGER_LIVE_TEST_FAILURE = '@tests/TRIGGER_LIVE_TEST_FAILURE',

  FETCH_GRADE_INFO_REQUEST = '@tests/FETCH_GRADE_INFO_REQUEST',
  FETCH_GRADE_INFO_SUCCESS = '@tests/FETCH_GRADE_INFO_SUCCESS',
  FETCH_GRADE_INFO_FAILURE = '@tests/FETCH_GRADE_INFO_FAILURE',

  FETCH_INDICATORS_INFO_REQUEST = '@tests/FETCH_INDICATORS_INFO_REQUEST',
  FETCH_INDICATORS_INFO_SUCCESS = '@tests/FETCH_INDICATORS_INFO_SUCCESS',
  FETCH_INDICATORS_INFO_FAILURE = '@tests/FETCH_INDICATORS_INFO_FAILURE',

  FETCH_STUDENTS_INFO_REQUEST = '@tests/FETCH_STUDENTS_INFO_REQUEST',
  FETCH_STUDENTS_INFO_SUCCESS = '@tests/FETCH_STUDENTS_INFO_SUCCESS',
  FETCH_STUDENTS_INFO_FAILURE = '@tests/FETCH_STUDENTS_INFO_FAILURE',

  FETCH_EVOLUTION_REPORT_REQUEST = '@tests/FETCH_EVOLUTION_REPORT_REQUEST',
  FETCH_EVOLUTION_REPORT_SUCCESS = '@tests/FETCH_EVOLUTION_REPORT_SUCCESS',
  FETCH_EVOLUTION_REPORT_FAILURE = '@tests/FETCH_EVOLUTION_REPORT_FAILURE',

  PUT_REQUEST = '@tests/PUT_REQUEST',
  PUT_SUCCESS = '@tests/PUT_SUCCESS',
  PUT_FAILURE = '@tests/PUT_FAILURE',

  PATCH_REQUEST = '@tests/PATCH_REQUEST',
  PATCH_SUCCESS = '@tests/PATCH_SUCCESS',
  PATCH_FAILURE = '@tests/PATCH_FAILURE',

  DELETE_REQUEST = '@tests/DELETE_REQUEST',
  DELETE_SUCCESS = '@tests/DELETE_SUCCESS',
  DELETE_FAILURE = '@tests/DELETE_FAILURE',

  FETCH_TESTS_WITH_SCORE_REQUEST = '@tests/FETCH_TESTS_WITH_SCORE_REQUEST',
  FETCH_TESTS_WITH_SCORE_SUCCESS = '@tests/FETCH_TESTS_WITH_SCORE_SUCCESS',
  FETCH_TESTS_WITH_SCORE_FAILURE = '@tests/FETCH_TESTS_WITH_SCORE_FAILURE',

  FETCH_BY_SCHOOL_GROUP_REQUEST = '@tests/FETCH_BY_SCHOOL_GROUP_REQUEST',
  FETCH_BY_SCHOOL_GROUP_SUCCESS = '@tests/FETCH_BY_SCHOOL_GROUP_SUCCESS',
  FETCH_BY_SCHOOL_GROUP_FAILURE = '@tests/FETCH_BY_SCHOOL_GROUP_FAILURE',

  SEND_TESTS_REQUEST = '@tests/SEND_TESTS_REQUEST',
  SEND_TESTS_SUCCESS = '@tests/SEND_TESTS_SUCCESS',
  SEND_TESTS_FAILURE = '@tests/SEND_TESTS_FAILURE',

  SET_TESTS_GROUP_FILTER = '@tests/SET_TESTS_GROUP_FILTER',
  SET_TESTS_SCHOOL_FILTER = '@tests/SET_TESTS_SCHOOL_FILTER',
  SET_TESTS_SEARCH_FILTER = '@tests/SET_TESTS_SEARCH_FILTER',
  SET_TESTS_YEAR_FILTER = '@tests/SET_TESTS_YEAR_FILTER',

  FETCH_STUDENTS_REOPEN_STATUS_REQUEST = '@tests/FETCH_STUDENTS_REOPEN_STATUS_REQUEST',
  FETCH_STUDENTS_REOPEN_STATUS_SUCCESS = '@tests/FETCH_STUDENTS_REOPEN_STATUS_SUCCESS',
  FETCH_STUDENTS_REOPEN_STATUS_FAILURE = '@tests/FETCH_STUDENTS_REOPEN_STATUS_FAILURE',
}

// Data types

// State type
export interface TestsState extends Map<string, any> {
  readonly data: List<ImmutableMap<LetrusApi.Test>> | undefined;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly dataCount: number;

  readonly testById: ImmutableMap<LetrusApi.Test>;
  readonly isLoadingTestById: boolean;

  readonly liveTestData: ImmutableMap<LetrusApi.Test>;
  readonly isLoadingLiveTestData: boolean;

  readonly studentsInfo: ImmutableMap<any>;
  readonly isLoadingStudentsInfo: boolean;

  readonly indicatorsSummaries: ImmutableMap<any>;
  readonly isLoadingIndicatorsSummaries: boolean;

  readonly testGradeInfo: ImmutableMap<any>;
  readonly isLoadingTestGradeInfo: boolean;
  readonly isLoadingTestsBySchoolGroup: any;
  readonly testsBySchoolGroup: any;
  readonly testIndicatorsInfo: ImmutableMap<LetrusApi.IndicatorsInfo>;

  readonly testsWithScore: LetrusApi.ApiV1TestsWithScoreGetResponse200;
  readonly testsWithScoreError: boolean;
  readonly testsWithScoreLoading: boolean;

  readonly loadingSendTests: boolean;
  readonly evolutionData: ImmutableMap<any>;
  readonly studentsById: ImmutableMap<any>;
  readonly evolutionReportError: boolean;
  readonly isLoadingEvolutionReport: boolean;
  readonly hasLoadedEvolutionReport: boolean;

  readonly isLoadingTestIndicatorsInfo: boolean;
  readonly testsGroupFilter: string;
  readonly testsSchoolFilter: string;
  readonly testsSearchFilter: string;
  readonly testsYearFilter: string;

  readonly deleteTestError: boolean;
  readonly deleteTestLoading: boolean;

  readonly createTestLoading: boolean;
  readonly createTestError: boolean;

  readonly studentsReopenStatus: List<ImmutableMap<StudentReopenComposition>>;
  readonly isLoadingStudentsReopenStatus: boolean;
  readonly studentsReopenStatusError: boolean;
}

// Fetch actions
export const fetchScheduledActivitiesRequest = () =>
  action(TestsTypes.FETCH_SCHEDULED_ACTIVITIES_REQUEST);

export const fetchScheduledActivitiesSuccess = (data: LetrusApi.Test[]) =>
  action(TestsTypes.FETCH_SCHEDULED_ACTIVITIES_SUCCESS, data);

export const fetchScheduledActivitiesFailure = () =>
  action(TestsTypes.FETCH_SCHEDULED_ACTIVITIES_FAILURE);

export const createTestRequest = (testData) =>
  action(TestsTypes.CREATE_REQUEST, {testData});

export const createTestSuccess = () => action(TestsTypes.CREATE_SUCCESS);

export const createTestFailure = () => action(TestsTypes.CREATE_FAILURE);

export const fetchTestByIdRequest = (testId: number | string, params?: any) =>
  action(TestsTypes.FETCH_BY_ID_REQUEST, {testId, params});

export const fetchTestByIdSuccess = (data: LetrusApi.Test) =>
  action(TestsTypes.FETCH_BY_ID_SUCCESS, {data});

export const fetchTestByIdFailure = () =>
  action(TestsTypes.FETCH_BY_ID_FAILURE);

export const fetchLiveTestDataRequest = (testId: number | string) =>
  action(TestsTypes.FETCH_LIVE_DATA_REQUEST, {testId});

export const fetchLiveTestDataSuccess = (data: LetrusApi.Test) =>
  action(TestsTypes.FETCH_LIVE_DATA_SUCCESS, {data});

export const fetchLiveTestDataFailure = () =>
  action(TestsTypes.FETCH_LIVE_DATA_FAILURE);

export const triggerLiveTestRequest = (testId: number | string) =>
  action(TestsTypes.TRIGGER_LIVE_TEST_REQUEST, {testId});

export const triggerLiveTestSuccess = (data: any) =>
  action(TestsTypes.TRIGGER_LIVE_TEST_SUCCESS, {data});

export const triggerLiveTestFailure = () =>
  action(TestsTypes.TRIGGER_LIVE_TEST_FAILURE);

export const fetchTestGradeInfoRequest = (testId: number | string) =>
  action(TestsTypes.FETCH_GRADE_INFO_REQUEST, {testId});

export const fetchTestGradeInfoSuccess = (data: LetrusApi.Test) =>
  action(TestsTypes.FETCH_GRADE_INFO_SUCCESS, {data});

export const fetchTestGradeInfoFailure = () =>
  action(TestsTypes.FETCH_GRADE_INFO_FAILURE);

export const fetchTestIndicatorsInfoRequest = (testId: number | string) =>
  action(TestsTypes.FETCH_INDICATORS_INFO_REQUEST, {testId});

export const fetchTestIndicatorsInfoSuccess = (data: LetrusApi.Test) =>
  action(TestsTypes.FETCH_INDICATORS_INFO_SUCCESS, {data});

export const fetchTestIndicatorsInfoFailure = () =>
  action(TestsTypes.FETCH_INDICATORS_INFO_FAILURE);

export const fetchTestStudentsInfoRequest = (testId: number | string) =>
  action(TestsTypes.FETCH_STUDENTS_INFO_REQUEST, {testId});

export const fetchTestStudentsInfoSuccess = (data: LetrusApi.Test) =>
  action(TestsTypes.FETCH_STUDENTS_INFO_SUCCESS, {data});

export const fetchTestStudentsInfoFailure = () =>
  action(TestsTypes.FETCH_STUDENTS_INFO_FAILURE);

export const fetchEvolutionReportRequest = (params: GetParams & any) =>
  action(TestsTypes.FETCH_EVOLUTION_REPORT_REQUEST, params);

export const fetchEvolutionReportSuccess = (data: LetrusApi.Test[]) =>
  action(TestsTypes.FETCH_EVOLUTION_REPORT_SUCCESS, {data});

export const fetchEvolutionReportFailure = () =>
  action(TestsTypes.FETCH_EVOLUTION_REPORT_FAILURE);

export const fetchTestsBySchoolGroupRequest = (schoolGroupId: number) =>
  action(TestsTypes.FETCH_BY_SCHOOL_GROUP_REQUEST, {schoolGroupId});

export const fetchTestsBySchoolGroupSuccess = (
  data: LetrusApi.Test[],
  schoolGroupId: number,
) => action(TestsTypes.FETCH_BY_SCHOOL_GROUP_SUCCESS, {data, schoolGroupId});

export const fetchTestsBySchoolGroupFailure = () =>
  action(TestsTypes.FETCH_BY_SCHOOL_GROUP_FAILURE);

export const putTestRequest = (test: Partial<LetrusApi.Test>) =>
  action(TestsTypes.PUT_REQUEST, {test});

export const putTestSuccess = (test: any) =>
  action(TestsTypes.PUT_SUCCESS, {test});

export const putTestFailure = () => action(TestsTypes.PUT_FAILURE);

export const patchTestRequest = (test: Partial<LetrusApi.Test>) =>
  action(TestsTypes.PATCH_REQUEST, {test});

export const patchTestSuccess = (test: any) =>
  action(TestsTypes.PATCH_SUCCESS, {test});

export const patchTestFailure = () => action(TestsTypes.PATCH_FAILURE);

export const deleteTestRequest = (testId: number | string) =>
  action(TestsTypes.DELETE_REQUEST, {testId});

export const deleteTestSuccess = () => action(TestsTypes.DELETE_SUCCESS);

export const deleteTestFailure = () => action(TestsTypes.DELETE_FAILURE);

export const fetchTestsWithScoreRequest = ({
  currentTime,
  type,
  query,
}: fetchTestsWithScoreParams) =>
  action(TestsTypes.FETCH_TESTS_WITH_SCORE_REQUEST, {currentTime, type, query});

export const fetchTestsWithScoreSuccess = (data: LetrusApi.Test[]) =>
  action(TestsTypes.FETCH_TESTS_WITH_SCORE_SUCCESS, {data});

export const fetchTestsWithScoreFailure = () =>
  action(TestsTypes.FETCH_TESTS_WITH_SCORE_FAILURE);

export const sendTestsRequest = (testId: number | string, compositions: any) =>
  action(TestsTypes.SEND_TESTS_REQUEST, {testId, compositions});
export const sendTestsSuccess = (data: any) =>
  action(TestsTypes.SEND_TESTS_SUCCESS, {data});
export const sendTestsFailure = () => action(TestsTypes.SEND_TESTS_FAILURE);

export const setTestsGroupFilter = (filter: string) =>
  action(TestsTypes.SET_TESTS_GROUP_FILTER, {filter});
export const setTestsSchoolFilter = (filter: string) =>
  action(TestsTypes.SET_TESTS_SCHOOL_FILTER, {filter});
export const setTestsSearchFilter = (filter: string) =>
  action(TestsTypes.SET_TESTS_SEARCH_FILTER, {filter});
export const setTestsYearFilter = (filter: string) =>
  action(TestsTypes.SET_TESTS_YEAR_FILTER, {filter});

export const fetchStudentsReopenStatusRequest = (testId: number | string) =>
  action(TestsTypes.FETCH_STUDENTS_REOPEN_STATUS_REQUEST, {testId});

export const fetchStudentsReopenStatusSuccess = (data: any) =>
  action(TestsTypes.FETCH_STUDENTS_REOPEN_STATUS_SUCCESS, {data});

export const fetchStudentsReopenStatusFailure = () =>
  action(TestsTypes.FETCH_STUDENTS_REOPEN_STATUS_FAILURE);

// Sagas
export function* fetchScheduledActivities() {
  try {
    const response = yield call(fetchScheduledActivitiesService);
    yield put(fetchScheduledActivitiesSuccess(response.data));
  } catch (err) {
    yield put(fetchScheduledActivitiesFailure());
  }
}

export function* createTest(action: AnyAction) {
  try {
    yield call(createTestService, action.payload.testData);
    yield put(createTestSuccess());
  } catch (err) {
    yield put(createTestFailure());
  }
}

export function* fetchTestById(action: AnyAction) {
  try {
    const response = yield call(
      fetchTestByIdService,
      action.payload.testId,
      action.payload.params,
    );
    yield put(fetchTestByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchTestByIdFailure());
  }
}

export function* fetchLiveTestData(action: AnyAction) {
  try {
    const response = yield call(
      fetchLiveTestDataService,
      action.payload.testId,
    );

    yield put(fetchLiveTestDataSuccess(response.data));
  } catch (err) {
    yield put(fetchLiveTestDataFailure());
  }
}

export function* triggerLiveTest(action: AnyAction) {
  try {
    const response = yield call(triggerLiveTestService, action.payload.testId);
    yield put(triggerLiveTestSuccess(response.data));
  } catch (err) {
    yield put(triggerLiveTestFailure());
  }
}

export function* fetchTestGradeInfo(action: AnyAction) {
  try {
    const response = yield call(
      fetchTestGradeInfoService,
      action.payload.testId,
    );
    yield put(fetchTestGradeInfoSuccess(response.data));
  } catch (err) {
    yield put(fetchTestGradeInfoFailure());
  }
}

export function* fetchTestIndicatorsInfo(action: AnyAction) {
  try {
    const response = yield call(
      fetchTestIndicatorsInfoService,
      action.payload.testId,
    );
    yield put(fetchTestIndicatorsInfoSuccess(response.data));
  } catch (err) {
    yield put(fetchTestIndicatorsInfoFailure());
  }
}

export function* fetchTestStudentsInfo(action: AnyAction) {
  try {
    const response = yield call(
      fetchTestStudentsInfoService,
      action.payload.testId,
    );
    yield put(fetchTestStudentsInfoSuccess(response.data));
  } catch (err) {
    yield put(fetchTestStudentsInfoFailure());
  }
}

export function* fetchEvolutionReport(action: AnyAction) {
  try {
    const response = yield call(fetchEvolutionReportService, action.payload);
    yield put(fetchEvolutionReportSuccess(response.data));
  } catch (err) {
    yield put(fetchEvolutionReportFailure());
  }
}

export function* fetchTestsBySchoolGroup(action: AnyAction) {
  try {
    const response = yield call(
      fetchTestsBySchoolGroupService,
      action.payload.schoolGroupId,
    );
    yield put(
      fetchTestsBySchoolGroupSuccess(
        response.data,
        action.payload.schoolGroupId,
      ),
    );
  } catch (err) {
    yield put(fetchTestsBySchoolGroupFailure());
  }
}

export function* putTest(action: AnyAction) {
  try {
    const response = yield call(putTestService, action.payload.test);
    yield put(putTestSuccess(response.data));
  } catch (err) {
    yield put(putTestFailure());
  }
}

export function* patchTest(action: AnyAction) {
  try {
    const response = yield call(patchTestService, action.payload.test);
    yield put(patchTestSuccess(response.data));
  } catch (err) {
    yield put(patchTestFailure());
  }
}

export function* deleteTest(action: AnyAction) {
  try {
    yield call(deleteTestService, action.payload.testId);
    yield put(deleteTestSuccess());
  } catch (err) {
    yield put(deleteTestFailure());
  }
}

export function* fetchTestsWithScore(action: AnyAction) {
  try {
    const response = yield call(fetchTestsWithScoreService, {
      currentTime: action.payload.currentTime,
      type: action.payload.type,
      query: action.payload.query,
    });
    yield put(fetchTestsWithScoreSuccess(response.data));
  } catch (err) {
    yield put(fetchTestsWithScoreFailure());
  }
}

export function* sendTests(action: AnyAction) {
  try {
    const response = yield call(
      sendTestsService,
      action.payload.testId,
      action.payload.compositions,
    );
    yield put(sendTestsSuccess(response.data));
  } catch (err) {
    yield put(sendTestsFailure());
  }
}

export function* fetchStudentsReopenStatus(action: AnyAction) {
  try {
    const response = yield call(
      fetchStudentsReopenStatusService,
      action.payload.testId,
    );

    yield put(fetchStudentsReopenStatusSuccess(response.data));
  } catch (err) {
    yield put(fetchStudentsReopenStatusFailure());
  }
}

// Selectors
const testsSelector = (state) => state.get('tests');

export const getTests = createSelector(testsSelector, (schoolGroups) =>
  schoolGroups.get('data'),
);

export const getTestGradeInfo = createSelector(
  testsSelector,
  (schoolGroups): ImmutableMap<any> => schoolGroups.get('testGradeInfo'),
);

export const isLoadingTestGradeInfo = createSelector(
  testsSelector,
  (schoolGroups): boolean => schoolGroups.get('isLoadingTestGradeInfo'),
);

export const isLoadingTestIndicatorsInfo = createSelector(
  testsSelector,
  (schoolGroups): boolean => schoolGroups.get('isLoadingTestIndicatorsInfo'),
);

export const getTestFromDataById = (state: any, id: number | string) => {
  const tests = getTests(state);

  if (typeof id === 'string') {
    return tests.filter((test) => test.get('id') === parseInt(id, 10));
  }
  return tests.filter((test) => test.get('id') === id);
};

export const isLoadingTests = createSelector(testsSelector, (tests) =>
  tests.get('loading'),
);

export const isErrorTests = createSelector(testsSelector, (tests) =>
  tests.get('error'),
);

export const getTestById = createSelector(
  testsSelector,
  (tests): ImmutableMap<any> => tests.get('testById'),
);

export const isLoadingTestById = createSelector(testsSelector, (tests) =>
  tests.get('isLoadingTestById'),
);

export const getLiveTestData = createSelector(testsSelector, (tests) =>
  tests.get('liveTestData'),
);

export const isLoadingTestLiveData = createSelector(testsSelector, (tests) =>
  tests.get('isLoadingLiveTestData'),
);

export const getThemeDetails = createSelector(testsSelector, (tests) =>
  tests.getIn(['testById', 'theme_detail']),
);

export const getTestStudentsInfo = createSelector(testsSelector, (tests) =>
  tests.getIn(['studentsInfo', 'students_info']),
);

export const getStudentsInfoData = createSelector(testsSelector, (tests) =>
  tests.get('studentsInfo'),
);

export const getStudentsInfo = createSelector(testsSelector, (tests) =>
  tests.getIn(['studentsInfo', 'students_info']),
);

export const isEnem = createSelector(
  testsSelector,
  (tests) => tests.getIn(['liveTestData', 'genre_name']) === 'ENEM',
);

export const getTestsWithScore = createSelector(testsSelector, (tests) =>
  tests.get('testsWithScore'),
);
export const isLoadingTestsWithScore = createSelector(testsSelector, (tests) =>
  tests.get('testsWithScoreLoading'),
);

export const getTestsWithScoreError = createSelector(testsSelector, (tests) =>
  tests.get('testsWithScoreError'),
);

export const getDeleteTestLoading = createSelector(testsSelector, (tests) =>
  tests.get('deleteTestLoading'),
);

export const getDeleteTestError = createSelector(testsSelector, (tests) =>
  tests.get('deleteTestError'),
);

export const getTestsBySchoolGroup = createSelector(testsSelector, (tests) =>
  tests.get('testsBySchoolGroup'),
);

export const getCreateTestLoading = createSelector(testsSelector, (tests) =>
  tests.get('createTestLoading'),
);

export const getCreateTestError = createSelector(testsSelector, (tests) =>
  tests.get('createTestError'),
);

export const getLiveTestPanels = createSelector(testsSelector, (tests) =>
  tests
    .get('indicatorsSummaries')
    .map((indicatorsSummarie) => fromIndicatorSummary(indicatorsSummarie))
    .filter(
      (indicatorsSummarie) =>
        !['paragrafos', 'pontos', 'virgulas', 'sentencas'].includes(
          indicatorsSummarie.get('varname'),
        ),
    )
    .sort((indicatorsSummarie1, indicatorsSummarie2) => {
      if (
        indicatorsSummarie1.get('higherPriority') !==
        indicatorsSummarie2.get('higherPriority')
      ) {
        return indicatorsSummarie1.get('higherPriority') ? -1 : 1;
      }

      // Currently we don't really care about the order
      return 0;
    }),
);

export const hasGradeInfoReviews = createSelector(
  testsSelector,
  (schoolGroups): boolean =>
    schoolGroups &&
    schoolGroups.get('testGradeInfo') &&
    schoolGroups.getIn([
      'testGradeInfo',
      'grade_info',
      'student_grade_infos',
    ]) &&
    schoolGroups.getIn(['testGradeInfo', 'grade_info', 'student_grade_infos'])
      .size > 0,
);

export const getPedagogicalContentsByCompetenceId = (
  state: TestsState,
  competenceId: string,
) => {
  return createSelector(testsSelector, (tests) => {
    const pedagogicalContents = tests.getIn([
      'testGradeInfo',
      'pedagogical_contents',
    ]);

    if (!pedagogicalContents) {
      return fromJS([]);
    }

    if (!(pedagogicalContents.size > 0)) {
      return fromJS([]);
    }

    const pedagogicalContentsByCompetenceId = pedagogicalContents.filter(
      (pedagogicalContent) => {
        if (
          !pedagogicalContent.get('competences') ||
          !(pedagogicalContent.get('competences').size > 0)
        ) {
          return false;
        }
        return pedagogicalContent
          .get('competences')
          .toJS()
          .includes(parseInt(competenceId, 10));
      },
    );
    return pedagogicalContentsByCompetenceId;
  })(state);
};

export const getIndicatorsInfo = createSelector(testsSelector, (tests) =>
  tests.get('testIndicatorsInfo'),
);

export const getIndicatorsSummary = createSelector(testsSelector, (tests) =>
  tests.get('indicatorsSummaries'),
);

export const isLoadingSendTests = createSelector(testsSelector, (tests) =>
  tests.get('loadingSendTests'),
);

export const getEvolutionData = createSelector(testsSelector, (tests) =>
  tests.get('evolutionData'),
);
export const getStudentsById = createSelector(testsSelector, (tests) =>
  tests.get('studentsById'),
);
export const getEvolutionReportError = createSelector(testsSelector, (tests) =>
  tests.get('evolutionReportError'),
);
export const isLoadingEvolutionReport = createSelector(testsSelector, (tests) =>
  tests.get('isLoadingEvolutionReport'),
);
export const hasLoadedEvolutionReport = createSelector(testsSelector, (tests) =>
  tests.get('hasLoadedEvolutionReport'),
);

export const hasTestsFilter = createSelector(
  testsSelector,
  (tests) =>
    tests.get('testsGroupFilter') !== 'all' ||
    tests.get('testsSchoolFilter') !== 'all' ||
    tests.get('testsYearFilter') !== 'all' ||
    tests.get('testsSearchFilter') !== '',
);

export const getTestsGroupFilter = createSelector(testsSelector, (tests) =>
  tests.get('testsGroupFilter'),
);

export const getTestsSchoolFilter = createSelector(testsSelector, (tests) =>
  tests.get('testsSchoolFilter'),
);

export const getTestsSearchFilter = createSelector(testsSelector, (tests) =>
  tests.get('testsSearchFilter'),
);

export const getTestsYearFilter = createSelector(testsSelector, (tests) =>
  tests.get('testsYearFilter'),
);

export const getReviewGrid = createSelector(testsSelector, (tests) =>
  tests.getIn(['testById', 'review_grid_name']),
);

export const fromIndicatorSummary = (indicatorSummary) => {
  const publicName =
    indicatorSummary.getIn(['summary', 'public_name']) ||
    indicatorSummary.get('varname').replace(/_/g, ' ');

  return fromJS({
    higherPriority: [
      'adjetivos',
      'substantivos',
      'verbos',
      'adverbios',
      'erros_ortograficos',
    ].includes(indicatorSummary.get('varname')),
    text: `Principais ${publicName.toLowerCase()}`,
    isGenreCategory: indicatorSummary.get('is_genre_category'),
    varname: indicatorSummary.get('varname'),
  });
};

export const getStudentsReopenStatus = createSelector(testsSelector, (tests) =>
  tests.get('studentsReopenStatus'),
);

export const isLoadingStudentsReopenStatus = createSelector(
  testsSelector,
  (tests) => tests.get('isLoadingStudentsReopenStatus'),
);

// Initial state
export const INITIAL_STATE: TestsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
  testById: fromJS({}),
  isLoadingTestById: false,
  liveTestData: fromJS({}),
  isLoadingLiveTestData: false,
  studentsInfo: fromJS({}),
  isLoadingStudentsInfo: false,
  indicatorsSummaries: fromJS([]),
  isLoadingIndicatorsSummaries: false,
  isLoadingTestsBySchoolGroup: false,
  testsBySchoolGroup: fromJS([]),
  testsWithScore: [],
  testsWithScoreError: false,
  testsWithScoreLoading: false,

  testGradeInfo: fromJS({}),
  isLoadingTestGradeInfo: false,
  testIndicatorsInfo: fromJS({}),
  loadingSendTests: false,
  evolutionData: fromJS([]),
  studentsById: fromJS([]),
  evolutionReportError: false,
  isLoadingEvolutionReport: false,
  hasLoadedEvolutionReport: false,
  isLoadingTestIndicatorsInfo: false,
  testsGroupFilter: 'all',
  testsSchoolFilter: 'all',
  testsYearFilter: 'all',
  testsSearchFilter: '',
  deleteTestError: false,
  deleteTestLoading: false,

  createTestLoading: false,
  createTestError: false,

  studentsReopenStatus: fromJS([]),
  isLoadingStudentsReopenStatus: false,
  studentsReopenStatusError: false,
});

// Reducer
export const reducer: Reducer<TestsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TestsTypes.FETCH_SCHEDULED_ACTIVITIES_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case TestsTypes.FETCH_SCHEDULED_ACTIVITIES_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload)),
      );

    case TestsTypes.FETCH_SCHEDULED_ACTIVITIES_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([]))
          .set('dataCount', 0),
      );

    case TestsTypes.CREATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('createTestLoading', true).set('createTestError', false),
      );

    case TestsTypes.CREATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('createTestLoading', false).set('createTestError', false),
      );

    case TestsTypes.CREATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('createTestLoading', false).set('createTestError', true),
      );

    case TestsTypes.FETCH_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTestById', true).set('error', false),
      );

    case TestsTypes.FETCH_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('testById', fromJS(action.payload.data))
          .set('dataCount', 1)
          .set('isLoadingTestById', false)
          .set('error', false),
      );

    case TestsTypes.FETCH_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('testById', fromJS({}))
          .set('dataCount', 0)
          .set('isLoadingTestById', false)
          .set('error', true),
      );

    case TestsTypes.FETCH_LIVE_DATA_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingLiveTestData', true).set('error', false),
      );

    case TestsTypes.FETCH_LIVE_DATA_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('liveTestData', fromJS(action.payload.data))
          .set(
            'indicatorsSummaries',
            fromJS(action.payload.data.indicators_info.indicator_summaries),
          )
          .set('dataCount', 1)
          .set('isLoadingLiveTestData', false)
          .set('error', false),
      );

    case TestsTypes.FETCH_LIVE_DATA_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('liveTestData', fromJS({}))
          .set('dataCount', 0)
          .set('isLoadingLiveTestData', false)
          .set('error', true),
      );

    case TestsTypes.TRIGGER_LIVE_TEST_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case TestsTypes.TRIGGER_LIVE_TEST_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case TestsTypes.TRIGGER_LIVE_TEST_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case TestsTypes.FETCH_GRADE_INFO_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTestGradeInfo', true).set('error', false),
      );

    case TestsTypes.FETCH_GRADE_INFO_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingTestGradeInfo', false)
          .set('error', false)
          .set('testGradeInfo', fromJS(action.payload.data)),
      );

    case TestsTypes.FETCH_GRADE_INFO_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case TestsTypes.FETCH_INDICATORS_INFO_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTestIndicatorsInfo', true).set('error', false),
      );

    case TestsTypes.FETCH_INDICATORS_INFO_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingTestIndicatorsInfo', false)
          .set('error', false)
          .set('testIndicatorsInfo', fromJS(action.payload.data))
          .set(
            'indicatorsSummaries',
            fromJS(action.payload.data.indicators_info.indicator_summaries),
          ),
      );

    case TestsTypes.FETCH_INDICATORS_INFO_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTestIndicatorsInfo', false).set('error', true),
      );

    case TestsTypes.FETCH_STUDENTS_INFO_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingStudentsInfo', true).set('error', false),
      );

    case TestsTypes.FETCH_STUDENTS_INFO_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingStudentsInfo', false)
          .set('error', false)
          .set('studentsInfo', fromJS(action.payload.data)),
      );

    case TestsTypes.FETCH_STUDENTS_INFO_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingStudentsInfo', false).set('error', true),
      );

    case TestsTypes.FETCH_EVOLUTION_REPORT_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingEvolutionReport', true).set('error', false),
      );

    case TestsTypes.FETCH_EVOLUTION_REPORT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingEvolutionReport', false)
          .set('evolutionReportError', false)
          .set('hasLoadedEvolutionReport', true)
          .set('evolutionData', fromJS(action.payload.data.test_metadatas))
          .set(
            'studentsById',
            fromJS(Object.values(action.payload.data.users)),
          ),
      );

    case TestsTypes.FETCH_EVOLUTION_REPORT_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingEvolutionReport', false)
          .set('evolutionReportError', true),
      );

    case TestsTypes.FETCH_BY_SCHOOL_GROUP_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTestsBySchoolGroup', true).set('error', false),
      );

    case TestsTypes.FETCH_BY_SCHOOL_GROUP_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingTestsBySchoolGroup', false)
          .set(
            'testsBySchoolGroup',
            fromJS(
              action.payload.data.results.map((test) => ({
                ...test,
                schoolGroupId: action.payload.schoolGroupId,
              })),
            ),
          )
          .set('error', false)
          .set('data', fromJS(action.payload.data.results)),
      );

    case TestsTypes.FETCH_BY_SCHOOL_GROUP_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTestsBySchoolGroup', false).set('error', true),
      );

    case TestsTypes.PUT_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTestById', true).set('error', false),
      );

    case TestsTypes.PUT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('testById', fromJS(action.payload.test))
          .set('dataCount', 1)
          .set('isLoadingTestById', false)
          .set('error', false),
      );

    case TestsTypes.PUT_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('testById', fromJS({}))
          .set('dataCount', 0)
          .set('isLoadingTestById', false)
          .set('error', true),
      );

    case TestsTypes.PATCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingTestById', true).set('error', false),
      );

    case TestsTypes.PATCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('testById', fromJS(action.payload.test))
          .set('dataCount', 1)
          .set('isLoadingTestById', false)
          .set('error', false),
      );

    case TestsTypes.PATCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('testById', fromJS({}))
          .set('dataCount', 0)
          .set('isLoadingTestById', false)
          .set('error', true),
      );

    case TestsTypes.DELETE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('deleteTestLoading', true).set('deleteTestError', false),
      );

    case TestsTypes.DELETE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('deleteTestLoading', false).set('deleteTestError', false),
      );

    case TestsTypes.DELETE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('deleteTestLoading', false).set('deleteTestError', true),
      );

    case TestsTypes.FETCH_TESTS_WITH_SCORE_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('testsWithScoreLoading', true)
          .set('testsWithScoreError', false),
      );

    case TestsTypes.FETCH_TESTS_WITH_SCORE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('testsWithScoreLoading', false)
          .set('testsWithScoreError', false)
          .set(
            'testsWithScore',
            prevState.get('testsWithScore').size > 0 &&
              action.payload.data.previous
              ? {
                  previous: action.payload.data.previous,
                  next: action.payload.data.next,
                  count: action.payload.data.count,
                  results: [
                    ...prevState.testsWithScore.results,
                    ...action.payload.data.results,
                  ],
                }
              : action?.payload?.data,
          ),
      );

    case TestsTypes.FETCH_TESTS_WITH_SCORE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('finishedLoading', false).set('finishedError', true),
      );

    case TestsTypes.SEND_TESTS_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loadingSendTests', true).set('error', false),
      );

    case TestsTypes.SEND_TESTS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loadingSendTests', false).set('error', false),
      );

    case TestsTypes.SEND_TESTS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loadingSendTests', false).set('error', true),
      );

    case TestsTypes.SET_TESTS_GROUP_FILTER:
      return state.withMutations((prevState) =>
        prevState.set('testsGroupFilter', action.payload.filter),
      );

    case TestsTypes.SET_TESTS_SCHOOL_FILTER:
      return state.withMutations((prevState) =>
        prevState.set('testsSchoolFilter', action.payload.filter),
      );

    case TestsTypes.SET_TESTS_SEARCH_FILTER:
      return state.withMutations((prevState) =>
        prevState.set('testsSearchFilter', action.payload.filter),
      );

    case TestsTypes.SET_TESTS_YEAR_FILTER:
      return state.withMutations((prevState) =>
        prevState.set('testsYearFilter', action.payload.filter),
      );

    case TestsTypes.FETCH_STUDENTS_REOPEN_STATUS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingStudentsReopenStatus', true)
          .set('studentsReopenStatusError', false),
      );

    case TestsTypes.FETCH_STUDENTS_REOPEN_STATUS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('studentsReopenStatus', fromJS(action.payload.data))
          .set('isLoadingStudentsReopenStatus', false)
          .set('studentsReopenStatusError', false),
      );

    case TestsTypes.FETCH_STUDENTS_REOPEN_STATUS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('studentsReopenStatus', fromJS({}))
          .set('isLoadingStudentsReopenStatus', false)
          .set('studentsReopenStatusError', true),
      );

    default:
      return state;
  }
};

export default reducer;
