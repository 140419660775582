import {GetOptionCardStatusConfigReturn} from 'features/useLearningPathReport';

export const finishedStyle: GetOptionCardStatusConfigReturn = {
  tagText: 'Concluiu',
  boxShadow: '0px 4px 16px 0px #30623080',
  mainColor: '#306230',
  backgroundColor: '#C6F1D3',
};

export const inProgressStyle: GetOptionCardStatusConfigReturn = {
  tagText: 'Em Andamento',
  boxShadow: '0px 4px 16px 0px #F7BB4980',
  mainColor: '#F7BB49',
  backgroundColor: '#FFEAC4',
  tagTextColor: '#000',
};

export const notStartedStyle: GetOptionCardStatusConfigReturn = {
  tagText: 'Não Iniciou',
  boxShadow: '0px 4px 16px 0px #962F3680',
  mainColor: '#962F36',
  backgroundColor: '#F8DADC',
};

export const pendingStyle: GetOptionCardStatusConfigReturn = {
  tagText: 'Em Correção',
  boxShadow: '0px 4px 16px 0px #4574B9',
  mainColor: '#4574B9',
  backgroundColor: '#E8F2FC',
  tagTextColor: '#000',
};
