import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {addLikeInGeneralAnnotationService} from 'store/services/generalAnnotationServices';
import {action} from 'typesafe-actions';

// Action types
export enum GeneralAnnotationTypes {
  ADD_LIKE_REQUEST = '@generalAnnotations/ADD_LIKE_REQUEST',
  ADD_LIKE_SUCCESS = '@generalAnnotations/ADD_LIKE_SUCCESS',
  ADD_LIKE_FAILURE = '@generalAnnotations/ADD_LIKE_FAILURE',
}

// Data types

// State type
export interface GeneralAnnotationsState extends Map<string, any> {
  readonly data?: Map<string, string | undefined>;
  readonly loading: boolean;
  readonly error?: boolean | number;
}

// Fetch actions
export const addLikeInGeneralAnnotationRequest = (
  reviewAnnotationId: string | number,
  like: boolean,
) =>
  action(GeneralAnnotationTypes.ADD_LIKE_REQUEST, {reviewAnnotationId, like});

export const addLikeInGeneralAnnotationSuccess = () =>
  action(GeneralAnnotationTypes.ADD_LIKE_SUCCESS);

export const addLikeInGeneralAnnotationFailure = () =>
  action(GeneralAnnotationTypes.ADD_LIKE_FAILURE);

// Sagas
export function* addLikeInGeneralAnnotation(action: AnyAction) {
  try {
    yield call(
      addLikeInGeneralAnnotationService,
      action.payload.reviewAnnotationId,
      action.payload.like,
    );
    yield put(addLikeInGeneralAnnotationSuccess());
  } catch (err) {
    yield put(addLikeInGeneralAnnotationFailure());
  }
}

// Selectors

// Initial state
export const INITIAL_STATE: GeneralAnnotationsState = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
});

// Reducer
export const reducer: Reducer<GeneralAnnotationsState | undefined> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case GeneralAnnotationTypes.ADD_LIKE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case GeneralAnnotationTypes.ADD_LIKE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case GeneralAnnotationTypes.ADD_LIKE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
