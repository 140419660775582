import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import InlineAlert from 'components/InlineAlert';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import Tag from 'components/Tag';
import Stepper from 'containers/LearningPathReportEngagement/Stepper';
import Table from 'containers/MultigenreReport/Table';
import useLearningPathReport from 'features/useLearningPathReport';
import {TabList} from 'letrus-ui';
import {useMemo, useState} from 'react';
import {learningPathReportEngagementMock} from 'store/mocks/__mocks__/learningPaths';
import {orderBy} from 'utils/functions/orderBy';
import MultigenreModal from '../MultigenreModal';
import ProgressBar from '../ProgressBar';
import styles from './EngagementModal.module.scss';

interface EngagementModalProps {
  engagementInfo: typeof learningPathReportEngagementMock;
  onClickClose(): void;
  isActivityInProgress: boolean;
}

function EngagementModal({
  engagementInfo,
  onClickClose,
  isActivityInProgress,
}: EngagementModalProps): JSX.Element {
  const [activeTab, setActiveTab] = useState(0);

  const {
    getIconsAndTextBasedOnStatus,
    getStudentCompletedStepsNumber,
    getStepType,
    getStudentStatus,
    getEngagementColor,
  } = useLearningPathReport();

  const tableHeaders = useMemo(
    () =>
      activeTab === 0
        ? ['Estudante', 'Status', 'Engajamento']
        : ['Etapas', 'Conteúdo', 'Engajamento'],
    [activeTab],
  );

  function renderActivityStatus(student) {
    const status = getStudentStatus(student);
    const content = getIconsAndTextBasedOnStatus(status);

    return (
      <div className={styles.activityStatus}>
        {content?.icon && <FontAwesomeIcon {...content.icon} />}
        <span>{content?.text}</span>
      </div>
    );
  }

  function renderStudentEngagement(student) {
    const totalSteps = engagementInfo.steps.length;
    const completedSteps = getStudentCompletedStepsNumber({
      currentStepNumber: student.step_number,
      finished: !!student.finished,
      totalNumberOfSteps: totalSteps,
    });

    return (
      <div className={styles.engagement}>
        <Stepper
          steps={engagementInfo.steps?.map((step) => ({
            ...step,
            isCompleted:
              student.finished ||
              (student.started && student.step_number > step.order),
          }))}
        />
        <span>{`${completedSteps} de ${totalSteps}`}</span>
      </div>
    );
  }

  function renderStepEngagement(step) {
    const finishedStudents = engagementInfo.students.filter(
      (student) => student.step_number > step.order || !!student.finished,
    ).length;
    const allStudents = engagementInfo.students.length;
    const engagementPercentage = (finishedStudents / allStudents) * 100;

    return (
      <div className={styles.engagement}>
        <ProgressBar
          progress={engagementPercentage}
          barColor={getEngagementColor(engagementPercentage)}
        />
        <span>{`${finishedStudents} de ${allStudents}`}</span>
      </div>
    );
  }

  return (
    <MultigenreModal
      onClickClose={onClickClose}
      title="Veja abaixo o engajamento dos estudantes individualmente e por etapas:"
    >
      {engagementInfo.students ? (
        <div className={styles.modalContent}>
          <div className={styles.tabListContainer}>
            <TabList
              data={[
                {
                  id: 0,
                  name: 'Individual',
                },
                {
                  id: 1,
                  name: 'Etapas',
                },
              ]}
              handleTabChange={(activeTab) => setActiveTab(activeTab)}
              tabActive={activeTab}
              userRole="student"
            />
          </div>
          <Table tableHeaders={tableHeaders}>
            <>
              {activeTab === 0
                ? engagementInfo.students
                    .sort(orderBy(['full_name']))
                    .map((student) => (
                      <tr key={student.id}>
                        <td>{student.full_name}</td>
                        <td>{renderActivityStatus(student)}</td>
                        <td>{renderStudentEngagement(student)}</td>
                      </tr>
                    ))
                : engagementInfo.steps.map((step, index) => (
                    <tr key={step.id}>
                      <td>
                        <Tag text={`${index + 1}ª`} />
                      </td>
                      <td>{getStepType(step)}</td>
                      <td>{renderStepEngagement(step)}</td>
                    </tr>
                  ))}
            </>
          </Table>
          {isActivityInProgress && (
            <InlineAlert
              text="Esta
            atividade está em andamento, as informações apresentadas podem
            sofrer alterações."
              customClassName={styles.activityInProgressWarning}
            />
          )}
        </div>
      ) : (
        <LoadingTable numberOfRows={5} />
      )}
    </MultigenreModal>
  );
}

export default EngagementModal;
