import styles from './EmptyWarning.module.scss';

interface EmptyWarningProps {
  title?: string;
  subtitle?: string;
  text?: string;
  fullWidth?: boolean;
}

function EmptyWarning({
  title = 'Ops!',
  subtitle = 'Não encontramos nenhum dado de estudante!',
  text = 'Em caso de dúvida, nossa equipe está pronta para te ajudar através do nosso chat!',
  fullWidth,
}: EmptyWarningProps): JSX.Element {
  return (
    <div className={styles.container} data-fullwidth={fullWidth}>
      <h1>{title}</h1>
      <p>{subtitle}</p>
      <p>{text}</p>
      <img
        src="https://s3.sa-east-1.amazonaws.com/media.letrus.com.br/empty-state.png"
        width="70%"
        alt="Conteúdo inexistente"
      />
    </div>
  );
}

export default EmptyWarning;
