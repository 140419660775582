import {Button} from 'letrus-ui';
import styles from './SlidePagination.module.scss';

export interface SlidePaginationProps {
  pageSize: number;
  currentPage: number;
  nextPage?(): void;
  previousPage?(): void;
  goToPage?(
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>,
  ): void;
}

function SlidePagination({
  pageSize,
  currentPage,
  nextPage,
  previousPage,
  goToPage,
}: SlidePaginationProps): JSX.Element {
  return (
    <div className={styles.container}>
      <Button
        variant="tertiary"
        leftIcon={{icon: 'chevron-left'}}
        text="Anterior"
        onClick={previousPage}
        disabled={currentPage === 1}
      />
      <div className={styles.pageContainer}>
        <span className={styles.pageText}>
          <input
            type="text"
            className={styles.inputPage}
            value={currentPage}
            onKeyDown={goToPage}
            onChange={goToPage}
            data-testid="slideInput"
          />
          de {pageSize}
        </span>
      </div>
      <Button
        variant="tertiary"
        rightIcon={{icon: 'chevron-right'}}
        text="Próximo"
        onClick={nextPage}
        disabled={currentPage === pageSize}
      />
    </div>
  );
}

export default SlidePagination;
