import styles from './TotalAnswersCard.module.scss';

export interface TotalAnswersCardProps {
  totalAnswers?: number;
}

function TotalAnswersCard({totalAnswers}: TotalAnswersCardProps): JSX.Element {
  return (
    <span className={styles.container}>
      <p className={styles.totalAnswers}>{totalAnswers}</p>
      <p className={styles.responses}>Respostas</p>
    </span>
  );
}

export default TotalAnswersCard;
