import {useEffect} from 'react';
import useTrackEvent from 'utils/hooks/useTrackEvent';

function KnowledgeCenter(): JSX.Element {
  const {trackEvent} = useTrackEvent();

  useEffect(() => {
    trackEvent('knowledge_center_page_access');
  }, []);

  return (
    <iframe
      width="100%"
      height="100%"
      style={{width: '100%'}}
      src="https://faq.letrus.com.br/"
      title="Central de conhecimentos"
    />
  );
}

export default KnowledgeCenter;
