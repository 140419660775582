/* eslint-disable react/no-array-index-key */
import styles from './PillTabs.module.scss';

export interface PillTabsProps {
  tabs: {
    text: string;
    icon?: JSX.Element;
    onClick(index: number): void;
  }[];
  customClassName: string;
  activeTab?: number;
  customStyles?: React.CSSProperties;
}

function PillTabs({
  tabs,
  customClassName,
  activeTab,
  customStyles,
}: PillTabsProps): JSX.Element {
  return (
    <div className={styles.container}>
      {tabs.map(({text, icon, onClick}, index) => (
        <button
          className={`${styles[customClassName]} ${
            activeTab === index ? styles.active : ''
          }`}
          type="button"
          key={index}
          onClick={() => onClick(index)}
          title={text}
          style={customStyles}
        >
          {icon && <span className={styles.icon}>{icon}</span>}
          {text}
        </button>
      ))}
    </div>
  );
}

export default PillTabs;
