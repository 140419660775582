import {IconName, IconProp} from '@fortawesome/fontawesome-svg-core';
import {
  LearningPathStep,
  StepRepertoryContent,
} from 'containers/LearningPathReport/StepCard';
import {Step} from 'containers/LearningPathReportEngagement';
import moment from 'moment';
import {
  IndividualQuestionsInfoMock,
  QuestionsInfoMock,
} from 'store/mocks/__mocks__/learningPathStep';
import {GrammaticalAspect} from 'utils/types/grammaticalAspects';
import {
  bestMarkersStyle,
  medianMarkersStyle,
  worstMarkersStyle,
} from './aspectCardStyles';
import {
  finishedStyle,
  inProgressStyle,
  notStartedStyle,
  pendingStyle,
} from './statusTagColors';

export const GRAMMATICAL_ASPECT_TITLE_CHARACTER_LIMIT = 21;

export enum StepTypeTitle {
  writing = 'Escrita',
  rewriting = 'Reescrita',
  pedagogical_content = 'Leitura',
  questionnaire = 'Questionário',
}

export enum EmptyStateMessages {
  noWritingStep = 'Esta atividade não possui etapa de escrita.',
  noFinishedCompositions = 'Nenhum estudante finalizou uma escrita nesta atividade.',
  compositionsInReview = 'A correção das redações está em andamento.',
  noIACompetencesReview = 'Esta atividade não possui análise de competências.',
  default = 'Sem dados',
}

export enum StudentStatus {
  notStarted = 'Não iniciou',
  started = 'Iniciou',
  finished = 'Finalizou',
  default = 'Sem dados',
}

export enum LearningPathStatus {
  'Não iniciou' = 'scheduled',
  Iniciou = 'in_progress',
  Finalizou = 'finished',
  aovivo = 'in_progress',
  concluidas = 'finished',
  'Sem dados' = 'not_data',
}

interface GetEmptyStateProps {
  steps: {
    type: string;
    order: number;
    id: number;
  }[];
  finished_compositions: number;
  average_score: number | null;
  review_type: string;
}

interface GetEmptyStateReturn {
  is: boolean;
  getMessage(): string;
}

interface GetStudentStatusProps {
  started: boolean;
  finished: boolean;
}

interface GetStudentCompletedStepsNumberProps {
  currentStepNumber: number | null;
  totalNumberOfSteps: number;
  finished: boolean;
}

interface GetSchoolGroupStatisticsReturn {
  totalOfStudents: number;
  totalOfCorrectStudents: number;
  percentageOfCorrectStudents: number;
  hasCorrectAnswer: boolean;
}

interface GetStudentIndividualQuestionAnswersParams {
  question: QuestionsInfoMock;
  answersFromAllStudents: Record<string, IndividualQuestionsInfoMock>;
  currentStudent: string;
}

interface RadioOrCheckboxItem {
  id: number;
  text: string;
}

interface GetStudentIndividualQuestionAnswersReturn {
  individualAnswer: string;
  individualCheckbox: {
    items: RadioOrCheckboxItem[];
    activeItems?: number[];
    correctItems?: number[];
    incorrectItems?: number[];
    showCorrectAnswer: boolean;
  };
  individualRadio: {
    items: RadioOrCheckboxItem[];
    activeItem?: number;
    correctItem?: number;
    incorrectItem?: number;
  };
}

export interface GetAspectCardStyleReturn {
  borderColor: string;
  text: string;
  iconName: IconProp;
  iconColor: string;
}

interface GetReportCriteriaAverageColorReturn {
  iconName: IconName;
  colorHex: string;
  maxPoints: number;
}

interface GetIconsAndTextBasedOnStatusReturn {
  text: string;
  icon: {
    icon: IconProp;
    color: string;
  };
}

interface GetCriteriaChartColorsReturn {
  borderColor: string[];
  backgroundColor: string[];
}

export interface GetOptionCardStatusConfigReturn {
  tagText: string;
  boxShadow: string;
  mainColor: string;
  backgroundColor: string;
  tagTextColor?: string;
}

interface getReviewingMinAndMaxDatesReturn {
  reopenEndingDate: Date;
  maxReviewingDate: Date;
}

interface getStepsCombinedParams {
  steps: LearningPathStep[];
  repertoryContent: StepRepertoryContent[];
  stepToRemove: LearningPathStep['type'];
}

const learningPathReportColors = {
  light: {red: '#FF4410', yellow: '#FFBF00', green: '#00C341'},
  dark: {red: '#D02D00', yellow: '#CC9706', green: '#00842C'},
};

const criteriaColorsNotSelected = {
  light: {red: '#FFA187', yellow: '#FFDF80', green: '#80E1A0'},
  dark: {red: '#E79680', yellow: '#E5CB83', green: '#80C195'},
};

export interface UseLearningPathReportReturn {
  getEmptyState(params: GetEmptyStateProps): GetEmptyStateReturn;
  getStudentStatus(params: GetStudentStatusProps): StudentStatus;
  getStudentCompletedStepsNumber(
    params: GetStudentCompletedStepsNumberProps,
  ): number;
  getSchoolGroupStatistics(
    question: QuestionsInfoMock,
  ): GetSchoolGroupStatisticsReturn;
  getStudentIndividualQuestionAnswers(
    params: GetStudentIndividualQuestionAnswersParams,
  ): GetStudentIndividualQuestionAnswersReturn;
  getReportCriteriaAverageColor(
    averageScore: number,
    genre: string,
    competence: string,
  ): GetReportCriteriaAverageColorReturn;
  getAspectCardStyle(aspect: GrammaticalAspect): GetAspectCardStyleReturn;
  getStatusBasedOnDates(dateStart: string, dateEnd: string): string;
  getIconsAndTextBasedOnStatus(
    status: string,
  ): GetIconsAndTextBasedOnStatusReturn;
  getScoreChartColors(
    genre: string,
    scores: number[],
    criteriaSelected?: number,
  ): GetCriteriaChartColorsReturn;
  getOptionCardStatusConfig(status: string): GetOptionCardStatusConfigReturn;
  learningPathReportColors: typeof learningPathReportColors;
  getCurrentYearFinalDate(): Date;
  getStepType(step: Step): string;
  getEngagementColor(percentage: number): string;
  getReviewingMinAndMaxDates(
    endingDate: string,
  ): getReviewingMinAndMaxDatesReturn;
  // TODO: replace QuestionsInfoMock with LetrusAPI correct type after endpoint is done.
  hasCorrectAnswer(question: QuestionsInfoMock): boolean;
  getCriteriaChartTooltipContent(genre: string, type: string): string;
  isReopeningDateValid(date: string): boolean;
  isReviewingEndDateValid(date: string, endingDateInput: string): boolean;
  combineDateAndTime(date: string, time: string): string;
  validateEndTime(endingDate: string, endTime: string): boolean | string;
  hasToUpdateNotification(seen_by_teacher: string, status: string): boolean;
  getLearningPathReportParameters(
    isRewriting: boolean,
    reviewGridName: string,
  ): string;
  getGeneralScore(averageScore: number, reviewGridName: string): string;
  getChartSuggestedMax(genre: string): number;
  getStepsCombined(params: getStepsCombinedParams): LearningPathStep[];
}

function useLearningPathReport(): UseLearningPathReportReturn {
  function getEmptyState({
    steps,
    finished_compositions,
    average_score,
    review_type,
  }: GetEmptyStateProps) {
    const hasWritingStep = steps.find((step) => step.type === 'writing');
    const hasFinishedCompositions = finished_compositions >= 1;
    const hasReviewedCompositions = !!average_score && average_score >= 1;
    const hasIAStructureReview = review_type === 'AI-Structure';

    const isEmptyState =
      !hasWritingStep ||
      !hasFinishedCompositions ||
      !hasReviewedCompositions ||
      hasIAStructureReview;

    function getMessage() {
      if (!hasWritingStep) {
        return EmptyStateMessages.noWritingStep;
      }
      if (!hasFinishedCompositions) {
        return EmptyStateMessages.noFinishedCompositions;
      }
      if (!hasReviewedCompositions) {
        return EmptyStateMessages.compositionsInReview;
      }
      if (hasIAStructureReview) {
        return EmptyStateMessages.noIACompetencesReview;
      }

      return EmptyStateMessages.default;
    }

    return {
      is: isEmptyState,
      getMessage,
    };
  }

  function getStudentStatus({
    started,
    finished,
  }: GetStudentStatusProps): StudentStatus {
    if (finished) {
      return StudentStatus.finished;
    }
    if (started) {
      return StudentStatus.started;
    }
    return StudentStatus.notStarted;
  }

  function getStudentCompletedStepsNumber({
    currentStepNumber,
    finished,
    totalNumberOfSteps,
  }: GetStudentCompletedStepsNumberProps): number {
    if (finished) {
      return totalNumberOfSteps;
    }
    if (!currentStepNumber) {
      return 0;
    }

    return currentStepNumber - 1;
  }

  function getSchoolGroupStatistics(
    question: QuestionsInfoMock,
  ): GetSchoolGroupStatisticsReturn {
    const totalOfCorrectAnswers = question?.question_options?.reduce(
      (totalOfCorrectAnswers, option) =>
        option.is_correct ? totalOfCorrectAnswers + 1 : totalOfCorrectAnswers,
      0,
    );

    const studentsIds = Array.from(
      new Set(
        question?.question_options?.reduce<number[]>(
          (studentsIds, option) => [...studentsIds, ...option.user_ids],
          [],
        ),
      ),
    );

    const answersFromAllStudents = studentsIds.map((studentId) => ({
      studentId,
      incorrectAnswers: question?.question_options?.filter(
        (option) => option?.user_ids?.includes(studentId) && !option.is_correct,
      ),
      correctAnswers: question?.question_options?.filter(
        (option) => option?.user_ids?.includes(studentId) && option.is_correct,
      ),
    }));

    const studentsWithOnlyCorrectAnswers = answersFromAllStudents?.filter(
      (studentAnswers) =>
        !studentAnswers.incorrectAnswers.length &&
        studentAnswers.correctAnswers.length === totalOfCorrectAnswers,
    ).length;

    const numberOfStudents = studentsIds.length ? studentsIds.length : 1;

    const percentageOfCorrectStudents = Math.round(
      (studentsWithOnlyCorrectAnswers / numberOfStudents) * 100,
    );

    return {
      totalOfStudents: studentsIds.length,
      totalOfCorrectStudents: studentsWithOnlyCorrectAnswers,
      percentageOfCorrectStudents,
      hasCorrectAnswer: !!totalOfCorrectAnswers,
    };
  }

  function getStudentIndividualQuestionAnswers({
    question,
    answersFromAllStudents,
    currentStudent,
  }: GetStudentIndividualQuestionAnswersParams): GetStudentIndividualQuestionAnswersReturn {
    const questionAnswers: GetStudentIndividualQuestionAnswersReturn = {
      individualCheckbox: {
        items: [],
        activeItems: [],
        correctItems: [],
        incorrectItems: [],
        showCorrectAnswer: false,
      },
      individualRadio: {
        items: [],
        activeItem: 0,
        correctItem: 0,
        incorrectItem: 0,
      },
      individualAnswer: '',
    };

    const currentStudentResponses =
      answersFromAllStudents[currentStudent]?.user_responses[
        question.question_id
      ];

    if (currentStudentResponses) {
      const hasCorrectAnswer = question?.question_options?.some(
        (option) => option.is_correct,
      );

      switch (question?.question_type) {
        case 'radio':
          questionAnswers.individualRadio = {
            items: question?.question_options?.map((option) => ({
              id: option?.id,
              text: option?.name,
            })),
            activeItem: currentStudentResponses[0].option_id,
            correctItem: hasCorrectAnswer
              ? question?.question_options?.find(
                  (option) =>
                    currentStudentResponses[0].option_id === option.id &&
                    option.is_correct,
                )?.id
              : 0,
            incorrectItem: hasCorrectAnswer
              ? question?.question_options?.find(
                  (option) =>
                    currentStudentResponses[0].option_id === option.id &&
                    !option.is_correct,
                )?.id
              : 0,
          };
          break;

        case 'checkbox':
          questionAnswers.individualCheckbox = {
            items: question?.question_options?.map((option) => ({
              id: option.id,
              text: option.name,
            })),
            activeItems: currentStudentResponses.map(
              (question) => question.option_id ?? 0,
            ),
            correctItems: question?.question_options
              ?.filter((option) => option.is_correct)
              ?.map((option) => option.id),
            incorrectItems: hasCorrectAnswer
              ? question.question_options
                  ?.filter((option) => !option.is_correct)
                  ?.map((option) => option.id)
              : [],
            showCorrectAnswer: hasCorrectAnswer,
          };
          break;

        default:
          questionAnswers.individualAnswer =
            currentStudentResponses[0].question_answer!;
          break;
      }
    }

    return questionAnswers;
  }

  function getReportCriteriaAverageColor(
    averageScore: number,
    genre: string,
    competence: string,
  ): GetReportCriteriaAverageColorReturn {
    if (genre !== 'fuvest') {
      if (averageScore < (genre === 'enem' ? 100 : 5)) {
        return {
          colorHex: '#FF4410',
          iconName: 'pause',
          maxPoints: genre === 'enem' ? 200 : 10,
        };
      }
      if (averageScore <= (genre === 'enem' ? 150 : 7)) {
        return {
          colorHex: '#FFBF00',
          iconName: 'play',
          maxPoints: genre === 'enem' ? 200 : 10,
        };
      }
      return {
        colorHex: '#00C341',
        iconName: 'check-square',
        maxPoints: genre === 'enem' ? 200 : 10,
      };
    }

    // FUVEST
    if (competence.includes('Competência 2')) {
      if (averageScore < 8) {
        return {colorHex: '#FF4410', iconName: 'pause', maxPoints: 20};
      }
      if (averageScore <= 13) {
        return {colorHex: '#FFBF00', iconName: 'play', maxPoints: 20};
      }
      return {colorHex: '#00C341', iconName: 'check-square', maxPoints: 20};
    }

    if (averageScore < 6) {
      return {colorHex: '#FF4410', iconName: 'pause', maxPoints: 15};
    }
    if (averageScore <= 10) {
      return {colorHex: '#FFBF00', iconName: 'play', maxPoints: 15};
    }
    return {colorHex: '#00C341', iconName: 'check-square', maxPoints: 15};
  }

  function getAspectCardStyle(
    aspect: GrammaticalAspect,
  ): GetAspectCardStyleReturn {
    if (aspect.best_markers) {
      return {...bestMarkersStyle};
    }
    if (aspect.worst_markers) {
      return {...worstMarkersStyle};
    }
    return {...medianMarkersStyle};
  }

  function getStatusBasedOnDates(
    dateStart: string,
    dateEnd: string,
  ): StudentStatus {
    const now = moment();
    if (moment(now).isAfter(dateEnd)) {
      return StudentStatus.finished;
    }
    if (moment(now).isAfter(dateStart)) {
      return StudentStatus.started;
    }
    return StudentStatus.notStarted;
  }

  function getIconsAndTextBasedOnStatus(
    status: StudentStatus,
  ): GetIconsAndTextBasedOnStatusReturn {
    if (status === StudentStatus.finished) {
      return {
        text: 'Concluído',
        icon: {icon: ['fad', 'square-check'], color: '#00C341'},
      };
    }
    if (status === StudentStatus.started) {
      return {
        text: 'Em Andamento',
        icon: {icon: ['fas', 'play'], color: '#F7BB49'},
      };
    }
    return {
      text: 'Não Iniciada',
      icon: {icon: ['fas', 'pause'], color: '#777777'},
    };
  }

  function getColor(type: string, score: number, index: number) {
    if (type !== 'fuvest') {
      const badScore = type === 'enem' ? 80 : 4;
      const regularScore = type === 'enem' ? 120 : 7;

      if (score <= badScore) {
        return 'red';
      }
      if (score <= regularScore) {
        return 'yellow';
      }
      return 'green';
    }

    // FUVEST - has different grade for second competence
    const badScore = index === 1 ? 7 : 5;
    const regularScore = index === 1 ? 13 : 10;

    if (score <= badScore) {
      return 'red';
    }
    if (score <= regularScore) {
      return 'yellow';
    }
    return 'green';
  }

  function getScoreChartColors(
    type: string,
    scores: number[],
    criteriaSelected?: number,
  ): GetCriteriaChartColorsReturn {
    const backgroundColor: string[] = [];
    const borderColor: string[] = [];

    scores.forEach((score, index) => {
      const color = getColor(type, score, index);

      if (criteriaSelected !== undefined) {
        backgroundColor.push(
          index === criteriaSelected
            ? learningPathReportColors.light[color]
            : criteriaColorsNotSelected.light[color],
        );
        borderColor.push(
          index === criteriaSelected
            ? learningPathReportColors.dark[color]
            : criteriaColorsNotSelected.dark[color],
        );
      } else {
        backgroundColor.push(learningPathReportColors.light[color]);
        borderColor.push(learningPathReportColors.dark[color]);
      }
    });

    return {backgroundColor, borderColor};
  }

  function getEngagementColor(percentage: number): string {
    let color: string;

    if (percentage > 33) {
      color = 'yellow';
    } else if (percentage === 100) {
      color = 'green';
    } else {
      color = 'red';
    }

    return learningPathReportColors.light[color];
  }

  function getStepType(step: Step): string {
    if (step.questionnaire_id) return 'Questionário';
    if (step.rewriting) return 'Reescrita';
    if (step.test_template_id) return 'Escrita';
    if (step.pedagogical_content_id) return 'Leitura';

    return 'tipo não encontrado';
  }

  function hasCorrectAnswer(question: QuestionsInfoMock) {
    return question.question_options.some((option) => option.is_correct);
  }

  function getCriteriaChartTooltipContent(genre: string, type: string) {
    enum enemLabel {
      badLabel = '0 a 80 pontos',
      regularLabel = '81 a 159 pontos',
      goodLabel = '160 a 200 pontos',
    }
    enum multiLabel {
      badLabel = '1 a 4 pontos',
      regularLabel = '5 a 7 pontos',
      goodLabel = '8 a 10 pontos',
    }

    if (genre === 'enem') {
      return enemLabel[type];
    }
    return multiLabel[type];
  }

  function getOptionCardStatusConfig(status: string) {
    if (status === 'finished' || status === 'zeroed') {
      return finishedStyle;
    }
    if (status === 'in_progress') {
      return inProgressStyle;
    }
    if (status === 'not_started') {
      return notStartedStyle;
    }
    return pendingStyle;
  }

  function getCurrentYearFinalDate() {
    const currentYear = new Date().getFullYear();
    const maxDate = new Date(currentYear, 11, 31);

    return maxDate;
  }

  function getReviewingMinAndMaxDates(endingDate: string) {
    const reopenEndingDate = moment(endingDate, 'YYYY-MM-DD').toDate();
    const maxReviewingDate = moment(reopenEndingDate).add(15, 'days').toDate();

    return {
      reopenEndingDate,
      maxReviewingDate,
    };
  }

  function isReopeningDateValid(date: string) {
    return (
      moment(date, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day') &&
      moment(date, 'YYYY-MM-DD').isSameOrBefore(
        getCurrentYearFinalDate(),
        'day',
      )
    );
  }

  function isReviewingEndDateValid(date: string, endingDateInput) {
    return moment(date).isBetween(
      endingDateInput,
      getReviewingMinAndMaxDates(endingDateInput).maxReviewingDate,
      'day',
      '[]',
    );
  }

  function combineDateAndTime(date: string, time: string) {
    const combinedDateString = `${date}T${time}:00Z`;

    return combinedDateString;
  }

  const validateEndTime = (endingDate, endTime) => {
    const currentDay = moment().startOf('day');
    const selectedDate = moment(endingDate, 'YYYY-MM-DD');

    if (selectedDate.isSame(currentDay, 'day')) {
      const minEndTime = moment().add(30, 'minutes');
      const selectedEndTime = moment(endTime, 'HH:mm');

      if (selectedEndTime.isBefore(minEndTime)) {
        return 'Selecione um horário com no mínimo 30 minutos à frente da hora atual.';
      }
    }

    return true;
  };

  function hasToUpdateNotification(seen_by_teacher: string, status: string) {
    if (seen_by_teacher === 'not_seen') return true;

    if (status === 'aovivo' && seen_by_teacher === 'finished') return true;

    if (status === 'concluidas' && seen_by_teacher === 'in_progress')
      return true;

    return false;
  }

  function getLearningPathReportParameters(
    isRewriting: boolean,
    reviewGridName: string,
  ) {
    const parameterToLearningPathReport = {
      ENEM: `${isRewriting ? '?type=reescrita' : '?type=escrita'}&genre=enem`,
      Multigênero: `${
        isRewriting ? '?type=reescrita' : '?type=escrita'
      }&genre=multi`,
      'Fuvest | Dissertação Legacy': `${
        isRewriting ? '?type=reescrita' : '?type=escrita'
      }&genre=fuvest`,
      default: `${isRewriting ? '?type=reescrita' : '?type=escrita'}`,
    };

    return (
      parameterToLearningPathReport[reviewGridName] ||
      parameterToLearningPathReport.default
    );
  }

  function getGeneralScore(averageScore: number, reviewGridName: string) {
    if (reviewGridName === 'multi') {
      return `${Math.max(averageScore, 0)} de 10`;
    }
    if (reviewGridName === 'enem') {
      return `${Math.max(Math.round(averageScore), 0)} de 1000`;
    }
    if (reviewGridName === 'fuvest') {
      return `${Math.max(Math.round(averageScore), 0)} de 50`;
    }
    return `${Math.max(Math.round(averageScore), 0)}`;
  }

  function getChartSuggestedMax(genre: string): number {
    if (genre === 'enem') {
      return 200;
    }
    if (genre === 'fuvest') {
      return 20;
    }
    return 10;
  }

  function getStepsCombined({
    steps,
    repertoryContent,
    stepToRemove,
  }: getStepsCombinedParams): LearningPathStep[] {
    const sanitizedRepertoryContent = repertoryContent?.map(
      ({id, link, tag}) => ({
        type: tag === 'teacher' ? 'teacher_repertory' : 'student_repertory',
        id,
        link,
      }),
    );

    const stepsWithoutRemovedStep = steps?.filter(
      (step) => step.type !== stepToRemove,
    );

    return [...sanitizedRepertoryContent, ...stepsWithoutRemovedStep];
  }

  return {
    getEmptyState,
    getStudentStatus,
    getStudentCompletedStepsNumber,
    getSchoolGroupStatistics,
    getStudentIndividualQuestionAnswers,
    getReportCriteriaAverageColor,
    getStatusBasedOnDates,
    getIconsAndTextBasedOnStatus,
    getOptionCardStatusConfig,
    getScoreChartColors,
    hasCorrectAnswer,
    getAspectCardStyle,
    learningPathReportColors,
    getStepType,
    getEngagementColor,
    getCriteriaChartTooltipContent,
    getCurrentYearFinalDate,
    getReviewingMinAndMaxDates,
    isReopeningDateValid,
    isReviewingEndDateValid,
    combineDateAndTime,
    validateEndTime,
    hasToUpdateNotification,
    getLearningPathReportParameters,
    getGeneralScore,
    getChartSuggestedMax,
    getStepsCombined,
  };
}

export default useLearningPathReport;
