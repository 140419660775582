type Revive = (payload: any, meta?: any) => any;

export const revive: Revive = (payload, meta = {}) => {
  if (payload.size) {
    return payload;
  }

  return {
    cid: meta.cid || payload.cid,
    composition_raw: payload.composition_raw,
    created: payload.created,
    client_created: payload.client_created,
    review_type: payload.review_type,
    flag_finished: payload.flag_finished,
    finished: payload.finished,
    flag_revision: payload.flag_revision,
    id: payload.id,
    instruction_id_id: null,
    instruction_id: new Map(payload.instruction_id),
    aids: payload.aids,
    modified: payload.modified,
    status: payload.status,
    test_id: payload.test_id,
    parent_id: payload.parent_id,
    child_id: payload.child_id,
    first_parent_id: payload.first_parent_id,
    transcription: payload.transcription,
    test: payload.test,
    genre: payload.genre,
    grades: payload.grades
      ? payload.grades.size
        ? payload.grades.map((g) => new Map(g))
        : null
      : null,
    reviews: payload.reviews,
    title: payload.title,
    user_id: payload.user_id,
    user: payload.user,
    revision_deadline: payload.revision_deadline,
    source: payload.source,
    iletrus_tensor_flow_score: payload.iletrus_tensor_flow_score,

    noTitle: !payload.title,
    fromMobile: payload.fromMobile,
    isLoading: false,
    isSaving: false,
    clientModified: payload.clientModified
      ? new Date(payload.clientModified)
      : null,
    step: payload.step,
    learning_path_user_instance_id: payload.learning_path_user_instance_id,
  };
};
