import {useEffect, useRef, useState} from 'react';
import {useMobile} from 'utils/hooks';
import styles from './PerformanceChart.module.scss';

export interface PerformanceChartProps {
  dataset: {
    total: number;
    partial: number;
    name: string;
    isCorrect: boolean;
  }[];
}

function PerformanceChart({dataset}: PerformanceChartProps): JSX.Element {
  const [svgWidth, setSvgWidth] = useState(500);
  const {isMobile} = useMobile();
  const svgRef = useRef<SVGSVGElement>(null);
  const scaleOffset = isMobile ? 0.5 : 0.8;
  const barHeight = 60;
  const distanceBetweenBars = 10;
  const distanceBetweenBarsAndText = 10;
  const barTextsSplittedByLine = dataset.map((option) =>
    option.name.match(/.{1,100}/g),
  );
  const totalOfBarTextLines = barTextsSplittedByLine.reduce(
    (sum, text) => (text?.length ?? 0) + sum,
    0,
  );

  useEffect(() => {
    // listen for dimensions change
    window.addEventListener('resize', () => {
      const dimensions = svgRef.current?.getBoundingClientRect();
      setSvgWidth(dimensions?.width || 500);
    });

    // set dimensions for first time
    const dimensions = svgRef.current?.getBoundingClientRect();
    setSvgWidth(dimensions?.width || 500);
  }, []);

  function calculatePercentage(partial: number, total: number) {
    return Math.round((partial / total) * 100);
  }

  function calculateLabelPosition(percent: number, isCorrect?: boolean) {
    const labelOffset = isCorrect ? 60 : 34;
    const labelXPosition = svgWidth * ((percent * scaleOffset) / 100);

    return labelOffset + labelXPosition;
  }

  return (
    <svg
      ref={svgRef}
      className={styles.svgBox}
      data-testid="performance-chart"
      style={{
        height: `${
          ((barHeight + distanceBetweenBars + totalOfBarTextLines * 4) *
            dataset.length) /
          16
        }rem`,
      }}
    >
      {dataset.map((data, index) => {
        const totalOptionAnswers = data.total ? data.total : 1;
        const percentageOfAnswers = calculatePercentage(
          data.partial,
          totalOptionAnswers,
        );
        const barTextHeight = (barTextsSplittedByLine[index]?.length ?? 1) * 16;
        const previousBarTextHeight =
          (barTextsSplittedByLine[index - 1]?.length ?? 1) * 16;

        const barYOffset = distanceBetweenBarsAndText + barTextHeight;

        const groupYOffset =
          barHeight + distanceBetweenBars + previousBarTextHeight;

        return (
          <g
            transform={`translate(-10, ${index * groupYOffset})`}
            key={data.name}
          >
            {barTextsSplittedByLine[index]?.map((text, index) => (
              <text
                key={text}
                x="15"
                y={16 * (index + 1)}
                className={styles.barText}
              >
                {text}
              </text>
            ))}

            <g
              transform={`translate(${calculateLabelPosition(
                percentageOfAnswers,
                data.isCorrect,
              )},${barYOffset + 17})`}
            >
              {data.isCorrect && (
                <path
                  transform="translate(-45,-12)"
                  d="M6.38235 12.7235L0.8869 7.22802C0.556743 6.89786 0.556743 6.36255 0.8869 6.03236L2.08252 4.83671C2.41268 4.50652 2.94802 4.50652 3.27818 4.83671L6.98018 8.53867L14.9095 0.609434C15.2396 0.279277 15.775 0.279277 16.1051 0.609434L17.3007 1.80509C17.6309 2.13525 17.6309 2.67056 17.3007 3.00075L7.57801 12.7235C7.24782 13.0537 6.71251 13.0537 6.38235 12.7235Z"
                  fill="#414449"
                />
              )}
              <rect x="-24" y="-5" className={styles.barLabelLine} />
              <text className={styles.barText}>
                {data.partial}/{data.total} ({percentageOfAnswers}%)
              </text>
            </g>

            <rect
              x="10"
              y={barYOffset}
              rx="5"
              className={styles.bar}
              data-correct={data.isCorrect}
              style={{width: `${percentageOfAnswers * scaleOffset}%`}}
            >
              <animate
                attributeName="width"
                from="0%"
                to={`${percentageOfAnswers * scaleOffset}%`}
                dur="0.5s"
                fill="freeze"
              />
            </rect>
            <rect
              x="10"
              y={barYOffset}
              className={styles.bar}
              data-correct={data.isCorrect}
              style={{width: `${percentageOfAnswers !== 0 ? 0.4 : 0}rem`}}
            />
          </g>
        );
      })}
    </svg>
  );
}

export default PerformanceChart;
