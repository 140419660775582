import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {StudentInfoItem} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import Table from 'containers/MultigenreReport/Table';
import {List} from 'immutable';
import {InlineAlert} from 'letrus-ui';
import {Link} from 'react-router-dom';
import {orderBy} from 'utils/functions/orderBy';
import MultigenreModal from '../MultigenreModal';
import styles from './ListStudentsModal.module.scss';

interface ListStudentsModalProps {
  testStudentsInfo: List<ImmutableMap<StudentInfoItem>>;
  onClickClose(): void;
  onButtonClick(compositionId: number): void;
  isActivityInProgress: boolean;
  isReviewQueueOverloaded: boolean;
}

function ListStudentsModal({
  testStudentsInfo,
  onClickClose,
  onButtonClick,
  isActivityInProgress,
  isReviewQueueOverloaded,
}: ListStudentsModalProps): JSX.Element {
  const tableHeaders = ['Estudante', 'Nota Geral', 'Detalhes'];

  function openHubspotChat() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  }

  return (
    <MultigenreModal
      onClickClose={onClickClose}
      title="Veja a nota geral e acesse a redação dos estudantes:"
    >
      <>
        {isReviewQueueOverloaded && (
          <InlineAlert
            backgroundColor="#E8F2FC"
            mainColor="#2A5082"
            secondButtonText="Entrar em contato"
            leftIcon={{icon: ['fad', 'triangle-exclamation']}}
            onClickSecondButton={openHubspotChat}
          >
            <div className={styles.alertText}>
              <strong className={styles.highlightText}>
                Nossa fila de correção está cheia.
              </strong>{' '}
              As redações serão corrigidas em breve. Se precisar, entre em
              contato com nossa equipe.
            </div>
          </InlineAlert>
        )}
        <div className={styles.tableContainer}>
          {testStudentsInfo?.size ? (
            <Table tableHeaders={tableHeaders}>
              <>
                {testStudentsInfo
                  .sort(orderBy(['first_name']))
                  .map((student) => (
                    <tr key={student.get('id')}>
                      <td>{`${student.get('first_name')} ${student.get(
                        'last_name',
                      )}`}</td>
                      <td>
                        <b>
                          {student.get('total_points') !== undefined &&
                          student.get('total_points') !== -1
                            ? student.get('total_points')
                            : 'Em Andamento'}
                        </b>
                      </td>
                      <td className={styles.seeMore}>
                        {student.get('status') === 'reviewed' ? (
                          <Link
                            to={`/correcao/${student.get('composition_id')}`}
                            target="_blank"
                          >
                            <FontAwesomeIcon
                              {...{icon: ['fas', 'eye'], color: '#1d79e6'}}
                            />{' '}
                            Ver detalhes
                          </Link>
                        ) : (
                          <button
                            type="button"
                            onClick={() =>
                              onButtonClick(student.get('composition_id'))
                            }
                          >
                            <FontAwesomeIcon
                              {...{icon: ['fas', 'eye'], color: '#1d79e6'}}
                            />{' '}
                            Ver detalhes
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </>
            </Table>
          ) : (
            <LoadingTable numberOfRows={5} />
          )}
        </div>
        {isActivityInProgress && (
          <InlineAlert
            text="Esta
            atividade está em andamento, as informações apresentadas podem
            sofrer alterações."
            customClassName={styles.activityInProgressWarning}
          />
        )}
      </>
    </MultigenreModal>
  );
}

export default ListStudentsModal;
