/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {action} from 'typesafe-actions';
import {
  QuestionDetails,
  QuestionIndicatorsDetails,
} from 'utils/types/questionIndicatorsDetails';
import {
  fetchQuestionIndicatorsByQuestionnaireIdService,
  fetchQuestionIndicatorsDetailsService,
  fetchQuestionnaireByIdService,
  fetchStudentsAnswersService,
  postQuestionAttempService,
} from '../../services/questionnairesServices';

// Action types
export enum QuestionnairesTypes {
  FETCH_BY_ID_REQUEST = '@questionnaires/FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS = '@questionnaires/FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE = '@questionnaires/FETCH_BY_ID_FAILURE',

  POST_QUESTION_ATTEMPT_REQUEST = '@questionnaires/POST_QUESTION_ATTEMPT_REQUEST',
  POST_QUESTION_ATTEMPT_SUCCESS = '@questionnaires/POST_QUESTION_ATTEMPT_SUCCESS',
  POST_QUESTION_ATTEMPT_FAILURE = '@questionnaires/POST_QUESTION_ATTEMPT_FAILURE',

  FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_REQUEST = '@questionnaires/FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_REQUEST',
  FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_SUCCESS = '@questionnaires/FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_SUCCESS',
  FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_FAILURE = '@questionnaires/FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_FAILURE',

  FETCH_QUESTION_INDICATORS_DETAILS_REQUEST = '@questionnaires/FETCH_QUESTION_INDICATORS_DETAILS_REQUEST',
  FETCH_QUESTION_INDICATORS_DETAILS_SUCCESS = '@questionnaires/FETCH_QUESTION_INDICATORS_DETAILS_SUCCESS',
  FETCH_QUESTION_INDICATORS_DETAILS_FAILURE = '@questionnaires/FETCH_QUESTION_INDICATORS_DETAILS_FAILURE',

  FETCH_STUDENTS_ANSWERS_REQUEST = '@questionnaires/FETCH_STUDENTS_ANSWERS_REQUEST',
  FETCH_STUDENTS_ANSWERS_SUCCESS = '@questionnaires/FETCH_STUDENTS_ANSWERS_SUCCESS',
  FETCH_STUDENTS_ANSWERS_FAILURE = '@questionnaires/FETCH_STUDENTS_ANSWERS_FAILURE',
}

// State type
export interface QuestionnairesState extends Map<string, any> {
  readonly data:
    | List<ImmutableMap<LetrusApi.ApiV1QuestionnairesGetResponse200>>
    | undefined;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly dataCount: number;

  readonly questionnaireById: ImmutableMap<LetrusApi.ApiV1QuestionnairesByIdGetResponse200>;
  readonly isLoadingQuestionnaireById: boolean;

  readonly isLoadingPostQuestionAttempt: boolean;

  readonly questionIndicatorByQuestionnaireId: ImmutableMap<LetrusApi.Questionnaire>;
  readonly isLoadingQuestionIndicatorByQuestionnaireId: boolean;

  readonly questionIndicatorsDetails: ImmutableMap<QuestionIndicatorsDetails>;
  readonly isLoadingQuestionIndicatorsDetails: boolean;

  readonly studentsAnswers: QuestionDetails;
  readonly isLoadingStudentsAnswers: boolean;
}

// Create actions
export const fetchQuestionnaireByIdRequest = (questionnaireId: number) =>
  action(QuestionnairesTypes.FETCH_BY_ID_REQUEST, {questionnaireId});

export const fetchQuestionnaireByIdSuccess = (data: any) =>
  action(QuestionnairesTypes.FETCH_BY_ID_SUCCESS, {data});

export const fetchQuestionnaireByIdFailure = () =>
  action(QuestionnairesTypes.FETCH_BY_ID_FAILURE);

export const postQuestionAttemptRequest = (questionAttemptData: any) =>
  action(QuestionnairesTypes.POST_QUESTION_ATTEMPT_REQUEST, {
    questionAttemptData,
  });

export const postQuestionAttempSuccess = () =>
  action(QuestionnairesTypes.POST_QUESTION_ATTEMPT_SUCCESS);

export const postQuestionAttempFailure = () =>
  action(QuestionnairesTypes.POST_QUESTION_ATTEMPT_FAILURE);

export const fetchQuestionIndicatorByQuestionnaireIdRequest = (
  questionnaireId: number,
) =>
  action(
    QuestionnairesTypes.FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_REQUEST,
    {questionnaireId},
  );

export const fetchQuestionIndicatorByQuestionnaireIdSuccess = (data: any) =>
  action(
    QuestionnairesTypes.FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_SUCCESS,
    {data},
  );

export const fetchQuestionIndicatorByQuestionnaireIdFailure = () =>
  action(
    QuestionnairesTypes.FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_FAILURE,
  );

export const fetchQuestionIndicatorsDetailsRequest = (
  questionId: number,
  schoolGroupId: number,
  learningPathInstanceId: number,
) =>
  action(QuestionnairesTypes.FETCH_QUESTION_INDICATORS_DETAILS_REQUEST, {
    questionId,
    schoolGroupId,
    learningPathInstanceId,
  });

export const fetchQuestionIndicatorsDetailsSuccess = (data: any) =>
  action(QuestionnairesTypes.FETCH_QUESTION_INDICATORS_DETAILS_SUCCESS, {data});

export const fetchQuestionIndicatorsDetailsFailure = () =>
  action(QuestionnairesTypes.FETCH_QUESTION_INDICATORS_DETAILS_FAILURE);

export const fetchStudentsAnswersRequest = (
  questionId: number,
  schoolGroupId: number,
  learningPathInstanceId: number,
) =>
  action(QuestionnairesTypes.FETCH_STUDENTS_ANSWERS_REQUEST, {
    questionId,
    schoolGroupId,
    learningPathInstanceId,
  });

export const fetchStudentsAnswersSuccess = (data: any) =>
  action(QuestionnairesTypes.FETCH_STUDENTS_ANSWERS_SUCCESS, {data});

export const fetchStudentsAnswersFailure = () =>
  action(QuestionnairesTypes.FETCH_STUDENTS_ANSWERS_FAILURE);

// Sagas
export function* fetchQuestionnaireById(action: AnyAction) {
  try {
    const response = yield call(
      fetchQuestionnaireByIdService,
      action.payload.questionnaireId,
    );

    yield put(fetchQuestionnaireByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionnaireByIdFailure());
  }
}

export function* postQuestionAttempt(action: AnyAction) {
  try {
    yield call(postQuestionAttempService, action.payload.questionAttemptData);

    yield put(postQuestionAttempSuccess());
  } catch (err) {
    yield put(postQuestionAttempFailure());
  }
}

export function* fetchQuestionIndicatorByQuestionnaireId(action: AnyAction) {
  try {
    const response = yield call(
      fetchQuestionIndicatorsByQuestionnaireIdService,
      action.payload.questionnaireId,
    );

    yield put(fetchQuestionIndicatorByQuestionnaireIdSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionIndicatorByQuestionnaireIdFailure());
  }
}

export function* fetchQuestionIndicatorsDetails(action: AnyAction) {
  try {
    const response = yield call(
      fetchQuestionIndicatorsDetailsService,
      action.payload.questionId,
      action.payload.schoolGroupId,
      action.payload.learningPathInstanceId,
    );

    yield put(fetchQuestionIndicatorsDetailsSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionIndicatorsDetailsFailure());
  }
}

export function* fetchStudentsAnswers(action: AnyAction) {
  try {
    const response = yield call(
      fetchStudentsAnswersService,
      action.payload.questionId,
      action.payload.schoolGroupId,
      action.payload.learningPathInstanceId,
    );

    yield put(fetchStudentsAnswersSuccess(response.data));
  } catch (err) {
    yield put(fetchStudentsAnswersFailure());
  }
}

// Selectors
const questionnairesDataSelector = (state) => state.get('questionnaires');

export const getQuestionnaires = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.get('data'),
);

export const getQuestionnaireById = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.get('questionnaireById'),
);

export const isLoadingQuestionnaireById = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.get('isLoadingQuestionnaireById'),
);

export const getQuestionIndicatorByQuestionnaireId = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.get('questionIndicatorByQuestionnaireId'),
);

export const isLoadingQuestionIndicatorByQuestionnaireId = createSelector(
  questionnairesDataSelector,
  (questionnaires) =>
    questionnaires.get('isLoadingQuestionIndicatorByQuestionnaireId'),
);

export const getQuestionIndicatorsDetails = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.get('questionIndicatorsDetails'),
);

export const isLoadingQuestionIndicatorsDetails = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.get('isLoadingQuestionIndicatorsDetails'),
);

export const getStudentsAnswers = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.get('studentsAnswers'),
);

export const isLoadingStudentsAnswers = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.get('isLoadingStudentsAnswers'),
);

// Initial state
export const INITIAL_STATE: QuestionnairesState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
  questionnaireById: fromJS({}),
  isLoadingQuestionnaireById: false,
  isLoadingPostQuestionAttempt: false,
  questionIndicatorByQuestionnaireId: fromJS({}),
  isLoadingQuestionIndicatorByQuestionnaireId: false,
  questionIndicatorsDetails: fromJS({}),
  isLoadingQuestionIndicatorsDetails: false,
  studentsAnswers: {},
  isLoadingStudentsAnswers: false,
});

// Reducer
export const reducer: Reducer<QuestionnairesState | undefined> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case QuestionnairesTypes.FETCH_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingQuestionnaireById', true).set('error', false),
      );

    case QuestionnairesTypes.FETCH_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('questionnaireById', fromJS(action.payload.data))
          .set('dataCount', 1)
          .set('isLoadingQuestionnaireById', false)
          .set('error', false),
      );

    case QuestionnairesTypes.FETCH_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingQuestionnaireById', false).set('error', true),
      );

    case QuestionnairesTypes.POST_QUESTION_ATTEMPT_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingPostQuestionAttempt', true).set('error', false),
      );

    case QuestionnairesTypes.POST_QUESTION_ATTEMPT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingPostQuestionAttempt', false)
          .set('error', false),
      );

    case QuestionnairesTypes.POST_QUESTION_ATTEMPT_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingPostQuestionAttempt', false).set('error', true),
      );

    case QuestionnairesTypes.FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingQuestionIndicatorByQuestionnaireId', true)
          .set('error', false),
      );

    case QuestionnairesTypes.FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set(
            'questionIndicatorByQuestionnaireId',
            fromJS(action.payload.data),
          )
          .set('isLoadingQuestionIndicatorByQuestionnaireId', false)
          .set('error', false),
      );

    case QuestionnairesTypes.FETCH_QUESTION_INDICATOR_BY_QUESTIONNAIRE_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingQuestionIndicatorByQuestionnaireId', false)
          .set('error', true),
      );

    case QuestionnairesTypes.FETCH_QUESTION_INDICATORS_DETAILS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingQuestionIndicatorsDetails', true)
          .set('error', false),
      );

    case QuestionnairesTypes.FETCH_QUESTION_INDICATORS_DETAILS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('questionIndicatorsDetails', fromJS(action.payload.data))
          .set('isLoadingQuestionIndicatorsDetails', false)
          .set('error', false),
      );

    case QuestionnairesTypes.FETCH_QUESTION_INDICATORS_DETAILS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingQuestionIndicatorsDetails', false)
          .set('error', true),
      );

    case QuestionnairesTypes.FETCH_STUDENTS_ANSWERS_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingStudentsAnswers', true).set('error', false),
      );

    case QuestionnairesTypes.FETCH_STUDENTS_ANSWERS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('studentsAnswers', action.payload.data)
          .set('isLoadingStudentsAnswers', false)
          .set('error', false),
      );

    case QuestionnairesTypes.FETCH_STUDENTS_ANSWERS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingStudentsAnswers', false).set('error', true),
      );
    default:
      return state;
  }
};

export default reducer;
