import {Modal} from 'letrus-ui';
import styles from './MultigenreModal.module.scss';

interface ModalProps {
  title: string;
  children: JSX.Element;
  onClickClose: () => void;
  maxWidth?: number;
}

function MultigenreModal({
  onClickClose,
  title,
  children,
  maxWidth = 812,
}: ModalProps): JSX.Element {
  return (
    <Modal
      isOpen
      onClose={onClickClose}
      clickOutsideToClose
      buttonClose={false}
      maxWidth={maxWidth}
    >
      <div className={styles.modalContent}>
        <header className={styles.modalHeader}>
          <h1 className={styles.title}>{title}</h1>
          <button
            type="button"
            onClick={onClickClose}
            className={styles.closeButton}
            aria-label="Fechar"
          >
            &times;
          </button>
        </header>
        <div className={styles.mainContent}>{children}</div>
      </div>
    </Modal>
  );
}

export default MultigenreModal;
