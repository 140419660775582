import Cookies from 'js-cookie';

interface CookiesOptions {
  name: string;
  value?: string;
  expires?: number;
}

export const getCookie = (name: string) => Cookies.get(name);
export const setCookie = ({name, value = '', expires}: CookiesOptions) =>
  Cookies.set(name, value, {expires});
export const deleteCookie = ({name}: CookiesOptions) => Cookies.remove(name);
