import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {action} from 'typesafe-actions';
import {AbandonReason} from 'utils/types/abandonReasons';
import {
  abandonReviewService,
  fetchAbandonReviewReasonsService,
} from '../../services/abandonReviewServices';

// Action types
export enum AbandonReviewReasonsTypes {
  FETCH_ABANDON_REVIEW_REASONS_REQUEST = '@abandon_review_reasons/FETCH_ABANDON_REVIEW_REASONS_REQUEST',
  FETCH_ABANDON_REVIEW_REASONS_SUCCESS = '@abandon_review_reasons/FETCH_ABANDON_REVIEW_REASONS_SUCCESS',
  FETCH_ABANDON_REVIEW_REASONS_FAILURE = '@abandon_review_reasons/FETCH_ABANDON_REVIEW_REASONS_FAILURE',

  ABANDON_REQUEST = '@compositionReviews/ABANDON_REQUEST',
  ABANDON_SUCCESS = '@compositionReviews/ABANDON_SUCCESS',
  ABANDON_FAILURE = '@compositionReviews/ABANDON_FAILURE',
}

// Data types

// State type
export interface AbandonReviewReasonsState extends Map<string, any> {
  readonly loading: boolean;
  readonly error: boolean;
  readonly dataCount: number;
  readonly abandonReviewReasons: List<ImmutableMap<AbandonReason>>;
  readonly reviewAbandoned: boolean;
}

interface AbandonRequestParams {
  compositionReviewId: number | string;
  abandonReasonId: number | string;
}

// Fetch abandonReviewReasons Actions
export const fetchAbandonReviewReasonsRequest = () =>
  action(AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_REQUEST);

export const fetchAbandonReviewReasonsSuccess = (data: AbandonReason[]) =>
  action(AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_SUCCESS, data);

export const fetchAbandonReviewReasonsFailure = () =>
  action(AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_FAILURE);

export const abandonRequest = (params: AbandonRequestParams) =>
  action(AbandonReviewReasonsTypes.ABANDON_REQUEST, {params});

export const abandonSuccess = (data: any) =>
  action(AbandonReviewReasonsTypes.ABANDON_SUCCESS, {data});

export const abandonFailure = () =>
  action(AbandonReviewReasonsTypes.ABANDON_FAILURE);

// Sagas
export function* fetchAbandonReviewReasons() {
  try {
    const response = yield call(fetchAbandonReviewReasonsService);
    yield put(fetchAbandonReviewReasonsSuccess(response));
  } catch (error) {
    yield put(fetchAbandonReviewReasonsFailure());
  }
}

export function* abandonReview(action: AnyAction) {
  try {
    const response = yield call(abandonReviewService, action.payload.params);
    yield put(abandonSuccess(response.data));
  } catch (error) {
    yield put(abandonFailure());
  }
}

// Initial State
export const INITIAL_STATE: AbandonReviewReasonsState = fromJS({
  abandonReviewReasons: fromJS([]),
  loading: false,
  reviewAbandoned: false,
  error: false,
  dataCount: 0,
});

// Selectors
const abandonReviewReasonsSelector = (state) => state.get('abandonReviews');

export const getAbandonReviewReasons = createSelector(
  abandonReviewReasonsSelector,
  (abandonReviewReasons) => abandonReviewReasons.get('abandonReviewReasons'),
);

export const reviewAbandoned = createSelector(
  abandonReviewReasonsSelector,
  (abandonReviewReasons) =>
    abandonReviewReasons.getIn(['data', 'reviewAbandoned']),
);

// Reducer
const reducer: Reducer<AbandonReviewReasonsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );
    }

    case AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('abandonReviewReasons', fromJS(action.payload.data.results))
          .set('dataCount', action.payload.count),
      );
    }

    case AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }

    case AbandonReviewReasonsTypes.ABANDON_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case AbandonReviewReasonsTypes.ABANDON_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('reviewAbandoned', true),
      );

    case AbandonReviewReasonsTypes.ABANDON_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
