import InlineAlert from 'components/InlineAlert';
import LoadingTable from 'components/LoadingComponents/LoadingTable';
import QuestionnaireStep, {
  QuestionnaireTabs,
} from 'components/QuestionnaireStep';
import {QuestionnaireStepMock} from 'store/mocks/__mocks__/learningPathStep';
import MultigenreModal from '../MultigenreModal';
import styles from './QuestionnaireModal.module.scss';

interface QuestionnaireModalProps {
  onClickClose(): void;
  learningPathReportStep: QuestionnaireStepMock;
  isActivityInProgress: boolean;
}

function QuestionnaireModal({
  onClickClose,
  learningPathReportStep,
  isActivityInProgress,
}: QuestionnaireModalProps): JSX.Element {
  return (
    <MultigenreModal
      onClickClose={onClickClose}
      title="Veja abaixo as perguntas da atividade e as respostas dos estudantes:"
      maxWidth={1056}
    >
      {!learningPathReportStep ? (
        <LoadingTable numberOfRows={5} />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.scrollWrapper}>
            {isActivityInProgress && (
              <InlineAlert
                text="Esta
            atividade está em andamento. As informações apresentadas correspondem apenas às respostas dos estudantes que concluíram a etapa do questionário."
                customClassName={styles.activityInProgressWarning}
              />
            )}
            <QuestionnaireStep
              questions={learningPathReportStep?.questions_info}
              studentsIndividualAnswers={
                learningPathReportStep?.individual_questions_info
              }
              activeTab={QuestionnaireTabs.both}
              labelText="Escolha ver os dados da turma ou do estudante"
            />
          </div>
        </div>
      )}
    </MultigenreModal>
  );
}

export default QuestionnaireModal;
