import BNCCRelatedSkills from 'components/BNCCRelatedSkills';
import MultigenreModal from '../MultigenreModal';

interface BNCCRelatedSkillsModalProps {
  onClickClose(): void;
  learning_path_instance_id: string;
}

function BNCCRelatedSkillsModal({
  onClickClose,
  learning_path_instance_id,
}: BNCCRelatedSkillsModalProps): JSX.Element {
  return (
    <MultigenreModal
      onClickClose={onClickClose}
      title="Veja mais informações sobre as habilidades da BNCC relacionadas a esta atividade:"
      maxWidth={1056}
    >
      <BNCCRelatedSkills
        learning_path_instance_id={learning_path_instance_id}
      />
    </MultigenreModal>
  );
}

export default BNCCRelatedSkillsModal;
