import {AxiosResponse} from 'axios';
import {Reducer} from 'redux';
import {call, CallEffect, put, PutEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchReviewGridsService, ReviewGrid} from 'store/services/reviewGrids';
import {Action, action} from 'typesafe-actions';
import {PaginatedRequest, StoreStatus} from 'utils/types/store';

export enum ReviewGridsTypes {
  FETCH_REVIEW_GRIDS_REQUEST = '@reviewGrids/FETCH_REVIEW_GRIDS_REQUEST',
  FETCH_REVIEW_GRIDS_SUCCESS = '@reviewGrids/FETCH_REVIEW_GRIDS_SUCCESS',
  FETCH_REVIEW_GRIDS_FAILURE = '@reviewGrids/FETCH_REVIEW_GRIDS_FAILURE',
}

// State type
export interface ReviewGridsState {
  readonly reviewGridsCount: number;
  readonly reviewGrids: ReviewGrid[];
  readonly reviewGridsRequestStatus: StoreStatus;
}

// Actions
export const fetchReviewGridsRequest = (): Action =>
  action(ReviewGridsTypes.FETCH_REVIEW_GRIDS_REQUEST);

export const fetchReviewGridsSuccess = (
  data: PaginatedRequest<ReviewGrid[]>,
): Action => action(ReviewGridsTypes.FETCH_REVIEW_GRIDS_SUCCESS, {data});

export const fetchReviewGridsFailure = (): Action =>
  action(ReviewGridsTypes.FETCH_REVIEW_GRIDS_FAILURE);

// Sagas
export function* fetchReviewGrids(): Generator<
  CallEffect<AxiosResponse<PaginatedRequest<ReviewGrid[]>>> | PutEffect<Action>,
  void,
  AxiosResponse<PaginatedRequest<ReviewGrid[]>>
> {
  try {
    const response = yield call(fetchReviewGridsService);
    yield put(fetchReviewGridsSuccess(response.data));
  } catch (err) {
    yield put(fetchReviewGridsFailure());
  }
}

// Selectors
const reviewGridsSelector = (state: ApplicationState) =>
  state.get('reviewGrids') as ReviewGridsState;

export const getReviewGrids = createSelector(
  reviewGridsSelector,
  (reviewGrids) => reviewGrids.reviewGrids,
);

export const getReviewGridsCount = createSelector(
  reviewGridsSelector,
  (reviewGrids) => reviewGrids.reviewGridsCount,
);

export const getReviewGridsRequestStatus = createSelector(
  reviewGridsSelector,
  (reviewGrids) => reviewGrids.reviewGridsRequestStatus,
);

// Initial state
export const INITIAL_STATE: ReviewGridsState = {
  reviewGridsCount: 0,
  reviewGrids: [],
  reviewGridsRequestStatus: {
    error: false,
    posting: false,
    loading: false,
    fulfilled: false,
  },
};

// Reducer
export const reducer: Reducer<ReviewGridsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ReviewGridsTypes.FETCH_REVIEW_GRIDS_REQUEST:
      return {
        ...state,
        reviewGridsRequestStatus: {
          error: false,
          posting: false,
          loading: true,
          fulfilled: false,
        },
      };

    case ReviewGridsTypes.FETCH_REVIEW_GRIDS_SUCCESS:
      return {
        ...state,
        reviewGridsCount: action.payload.data.count,
        reviewGrids: action.payload.data.results,
        reviewGridsRequestStatus: {
          error: false,
          posting: false,
          loading: false,
          fulfilled: true,
        },
      };

    case ReviewGridsTypes.FETCH_REVIEW_GRIDS_FAILURE:
      return {
        ...state,
        reviewGridsRequestStatus: {
          error: true,
          posting: false,
          loading: false,
          fulfilled: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
