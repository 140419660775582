import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  CreateStudentParams,
  CreateStudentsResponse,
  createStudentsService,
} from 'store/services/students';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';

// Action types
export enum StudentsTypes {
  CREATE_STUDENT_REQUEST = '@students/CREATE_STUDENT_REQUEST',
  CREATE_STUDENT_SUCCESS = '@students/CREATE_STUDENT_SUCCESS',
  CREATE_STUDENT_FAILURE = '@students/CREATE_STUDENT_FAILURE',

  RESET_CREATE_REQUEST_STATUS = '@students/RESET_CREATE_REQUEST_STATUS',
}

// State type
export interface StudentsState {
  readonly createdStudents?: CreateStudentsResponse[];
  readonly createStudentsRequestStatus: StoreStatus;
}

// Fetch actions

export const createStudentsRequest = (data: CreateStudentParams) =>
  action(StudentsTypes.CREATE_STUDENT_REQUEST, {data});

export const createStudentsSuccess = (data: CreateStudentsResponse) =>
  action(StudentsTypes.CREATE_STUDENT_SUCCESS, {data});

export const createStudentsFailure = () =>
  action(StudentsTypes.CREATE_STUDENT_FAILURE);

export const resetCreateStudentsRequestStatus = () =>
  action(StudentsTypes.RESET_CREATE_REQUEST_STATUS);

// Sagas
export function* createStudents(action: AnyAction) {
  try {
    const response = yield call(createStudentsService, action.payload.data);
    yield put(createStudentsSuccess(response.data));
  } catch (err) {
    yield put(createStudentsFailure());
  }
}

// Selectors
const studentsDataSelector = (state: ApplicationState) =>
  state.get('students') as StudentsState;

export const getCreatedStudents = createSelector(
  studentsDataSelector,
  (students) => students.createdStudents,
);

export const getCreateStudentsRequestStatus = createSelector(
  studentsDataSelector,
  (students) => students.createStudentsRequestStatus,
);

// Initial state
export const INITIAL_STATE: StudentsState = {
  createdStudents: undefined,
  createStudentsRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
};

// Reducer
export const reducer: Reducer<StudentsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case StudentsTypes.CREATE_STUDENT_REQUEST:
      return {
        ...state,
        createStudentsRequestStatus: {
          error: false,
          fulfilled: false,
          loading: false,
          posting: true,
        },
      };

    case StudentsTypes.CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        createdStudents: action.payload.data,
        createStudentsRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };

    case StudentsTypes.CREATE_STUDENT_FAILURE:
      return {
        ...state,
        createStudentsRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };

    case StudentsTypes.RESET_CREATE_REQUEST_STATUS:
      return {
        ...state,
        createStudentsRequestStatus: {
          error: false,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
