import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TooltipComponent from 'components/TooltipComponent';
import {NewButton} from 'letrus-ui';
import {ReactNode} from 'react';
import cardStyles from './Card.module.scss';

export interface CardProps {
  title: string;
  tooltipDescription?: string;
  onButtonClick?(): void;
  isButtonDisable?: boolean;
  styles?: string;
  children: ReactNode;
}

function Card({
  title,
  tooltipDescription,
  onButtonClick,
  children,
  isButtonDisable,
  styles,
}: CardProps): JSX.Element {
  return (
    <article className={`${cardStyles.container} ${styles}`}>
      <header className={cardStyles.title}>
        <h2>{title}</h2>

        {!!tooltipDescription && (
          <TooltipComponent
            placement="top"
            trigger="hover"
            overlay={tooltipDescription}
            tooltipTrigger={
              <span>
                <FontAwesomeIcon
                  icon={['fal', 'question-circle']}
                  color="#5D3D85"
                />
              </span>
            }
          />
        )}
      </header>

      {children}

      {onButtonClick && (
        <NewButton
          type="button"
          text="Ver mais detalhes"
          userRole="teacher"
          kind="secondary"
          onClick={onButtonClick}
          disabled={isButtonDisable}
        />
      )}
    </article>
  );
}

export default Card;
