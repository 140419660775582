import styles from './PedagogicalContentStep.module.scss';

interface PedagogicalContentStepProps {
  title: string;
  content: string;
}

function PedagogicalContentStep({
  title,
  content,
}: PedagogicalContentStepProps): JSX.Element {
  return (
    <main className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      <hr className={styles.divider} />
      <article
        className={styles.content}
        dangerouslySetInnerHTML={{__html: content}}
      />
    </main>
  );
}

export default PedagogicalContentStep;
