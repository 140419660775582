import styles from './HTMLContent.module.scss';

export interface HTMLContentProps {
  title?: string;
  html: string;
  imageSrc?: string;
  imageAlt?: string;
  link?: string;
}

function HTMLContent({
  title,
  html,
  imageSrc,
  imageAlt,
  link,
}: HTMLContentProps): JSX.Element {
  return link ? (
    <span className={styles.linkArea}>
      <a href={link} target="_blank" rel="noreferrer">
        {title}
      </a>
      <p>(Clique para acessar)</p>
    </span>
  ) : (
    <article>
      {title && <h3>{title}</h3>}
      {html && <span dangerouslySetInnerHTML={{__html: html}} />}
      {imageSrc && <img src={imageSrc} alt={imageAlt} />}
    </article>
  );
}

export default HTMLContent;
