import {takeLatest} from 'redux-saga/effects';
import {
  addLikeInReviewAnnotation,
  createReviewGradeReviewAnnotation,
  deleteReviewGradeReviewAnnotation,
  reportGradeReviewAnnotation,
  ReviewAnnotationsTypes,
  updateReviewGradeReviewAnnotation,
} from '../reducers/reviewAnnotations';

const reviewAnnotationSagas = [
  takeLatest(
    ReviewAnnotationsTypes.ADD_LIKE_REQUEST,
    addLikeInReviewAnnotation,
  ),
  takeLatest(
    ReviewAnnotationsTypes.DELETE_GRADE_REQUEST,
    deleteReviewGradeReviewAnnotation,
  ),
  takeLatest(
    ReviewAnnotationsTypes.UPDATE_GRADE_REQUEST,
    updateReviewGradeReviewAnnotation,
  ),
  takeLatest(
    ReviewAnnotationsTypes.CREATE_GRADE_REQUEST,
    createReviewGradeReviewAnnotation,
  ),
  takeLatest(
    ReviewAnnotationsTypes.REPORT_GRADE_REQUEST,
    reportGradeReviewAnnotation,
  ),
];

export default reviewAnnotationSagas;
