import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {QuestionOptionExtended} from 'features/useReadingIndicator';
import {BasicModal, Row} from 'letrus-ui';
import styles from './ChoicesModal.module.scss';

export interface ChoicesModalProps {
  onClickClose(): void;
  choices?: QuestionOptionExtended[];
  questionText?: string;
}

function ChoicesModal({
  onClickClose,
  choices,
  questionText,
}: ChoicesModalProps): JSX.Element {
  return (
    <BasicModal
      onClose={onClickClose}
      size="medium"
      open
      clickOutsideToClose
      title="Por favor, confira as alternativas da questão abaixo:"
    >
      <Row
        borderRadius="border-radius-2"
        border="border-2"
        shadow="drop-shadow-2"
        padding="24px"
      >
        <div>
          <h4
            title={questionText?.replace(/<\/?[^>]+(>|$)/g, '')}
            className={styles.title}
          >
            {`${questionText?.replace(/<\/?[^>]+(>|$)/g, '')}`}
          </h4>
          <ul className={styles.list}>
            {choices?.map((choice) =>
              choice.is_correct ? (
                <li
                  title={choice.name}
                  key={choice.id}
                  className={styles.correctChoice}
                  data-testid="choiceList"
                >
                  {choice.name}
                  <p className={styles.correctChoiceText}>
                    Alternativa Correta{' '}
                    <FontAwesomeIcon
                      icon={['fas', 'check-square']}
                      color="#00C341"
                    />{' '}
                  </p>
                </li>
              ) : (
                <li
                  key={choice.id}
                  title={choice.name}
                  className={styles.choice}
                  data-testid="choiceList"
                >
                  {choice.name}
                </li>
              ),
            )}
          </ul>
        </div>
      </Row>
    </BasicModal>
  );
}

export default ChoicesModal;
