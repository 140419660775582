import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';
import {ROLES} from 'utils/types/users';
import {
  createProfileService,
  createStudentParentService,
  fetchCurrentUserService,
  fetchUserByIdService,
  fetchUserProfileByUserIdService,
  fetchUserProfileService,
  pickBasicPlanService,
  refreshJwtTokenService,
  resetPasswordService,
  runCheckoutService,
  sendEmailInterestedUserService,
  sendUserOpinionForSurveysService,
  updateProfileService,
  updateStudentParentService,
  updateStudentProfileService,
  updateUserAddressService,
  updateUserInformationService,
  updateUserService,
} from '../../services/userServices';

// Action types
export enum UserTypes {
  REFRESH_JWT_TOKEN_REQUEST = '@user/REFRESH_JWT_TOKEN_REQUEST',
  REFRESH_JWT_TOKEN_SUCCESS = '@user/REFRESH_JWT_TOKEN_SUCCESS',
  REFRESH_JWT_TOKEN_FAILURE = '@user/REFRESH_JWT_TOKEN_FAILURE',

  UPDATE_REQUEST = '@user/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@user/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@user/UPDATE_FAILURE',

  FETCH_CURRENT_USER_REQUEST = '@user/FETCH_CURRENT_USER_REQUEST',
  FETCH_CURRENT_USER_SUCCESS = '@user/FETCH_CURRENT_USER_SUCCESS',
  FETCH_CURRENT_USER_FAILURE = '@user/FETCH_CURRENT_USER_FAILURE',

  FETCH_BY_ID_REQUEST = '@user/FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS = '@user/FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE = '@user/FETCH_BY_ID_FAILURE',

  FETCH_PROFILE_REQUEST = '@user/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS = '@user/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE = '@user/FETCH_PROFILE_FAILURE',

  UPDATE_PROFILE_REQUEST = '@user/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = '@user/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = '@user/UPDATE_PROFILE_FAILURE',

  UPDATE_ADDRESS_REQUEST = '@user/UPDATE_ADDRESS_REQUEST',
  UPDATE_ADDRESS_SUCCESS = '@user/UPDATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_FAILURE = '@user/UPDATE_ADDRESS_FAILURE',

  CREATE_PROFILE_REQUEST = '@user/CREATE_PROFILE_REQUEST',
  CREATE_PROFILE_SUCCESS = '@user/CREATE_PROFILE_SUCCESS',
  CREATE_PROFILE_FAILURE = '@user/CREATE_PROFILE_FAILURE',

  UPDATE_STUDENT_PARENT_REQUEST = '@user/UPDATE_STUDENT_PARENT_REQUEST',
  UPDATE_STUDENT_PARENT_SUCCESS = '@user/UPDATE_STUDENT_PARENT_SUCCESS',
  UPDATE_STUDENT_PARENT_FAILURE = '@user/UPDATE_STUDENT_PARENT_FAILURE',

  CREATE_STUDENT_PARENT_REQUEST = '@user/CREATE_STUDENT_PARENT_REQUEST',
  CREATE_STUDENT_PARENT_SUCCESS = '@user/CREATE_STUDENT_PARENT_SUCCESS',
  CREATE_STUDENT_PARENT_FAILURE = '@user/CREATE_STUDENT_PARENT_FAILURE',

  PICK_BASIC_PLAN_REQUEST = '@user/PICK_BASIC_PLAN_REQUEST',
  PICK_BASIC_PLAN_SUCCESS = '@user/PICK_BASIC_PLAN_SUCCESS',
  PICK_BASIC_PLAN_FAILURE = '@user/PICK_BASIC_PLAN_FAILURE',

  RUN_CHECKOUT_REQUEST = '@user/RUN_CHECKOUT_REQUEST',
  RUN_CHECKOUT_SUCCESS = '@user/RUN_CHECKOUT_SUCCESS',
  RUN_CHECKOUT_FAILURE = '@user/RUN_CHECKOUT_FAILURE',

  RESET_PASSWORD_REQUEST = '@user/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = '@user/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = '@user/RESET_PASSWORD_FAILURE',

  UPDATE_INFORMATION_REQUEST = '@user/UPDATE_INFORMATION_REQUEST',
  UPDATE_INFORMATION_SUCCESS = '@user/UPDATE_INFORMATION_SUCCESS',
  UPDATE_INFORMATION_FAILURE = '@user/UPDATE_INFORMATION_FAILURE',

  VALIDATE_ACCESS_CODE_REQUEST = '@user/VALIDATE_ACCESS_CODE_REQUEST',
  VALIDATE_ACCESS_CODE_SUCCESS = '@user/VALIDATE_ACCESS_CODE_SUCCESS',
  VALIDATE_ACCESS_CODE_FAILURE = '@user/VALIDATE_ACCESS_CODE_FAILURE',

  SEND_EMAIL_INTERESTED_USER_REQUEST = '@user/SEND_EMAIL_INTERESTED_USER_REQUEST',
  SEND_EMAIL_INTERESTED_USER_SUCCESS = '@user/SEND_EMAIL_INTERESTED_USER_SUCCESS',
  SEND_EMAIL_INTERESTED_USER_FAILURE = '@user/SEND_EMAIL_INTERESTED_USER_FAILURE',

  SEND_USER_OPINION_FOR_SURVEYS_REQUEST = '@user/SEND_USER_OPINION_FOR_SURVEYS_REQUEST',
  SEND_USER_OPINION_FOR_SURVEYS_SUCCESS = '@user/SEND_USER_OPINION_FOR_SURVEYS_SUCCESS',
  SEND_USER_OPINION_FOR_SURVEYS_FAILURE = '@user/SEND_USER_OPINION_FOR_SURVEYS_FAILURE',

  FETCH_PROFILE_BY_ID_REQUEST = '@user/FETCH_PROFILE_BY_ID_REQUEST',
  FETCH_PROFILE_BY_ID_SUCCESS = '@user/FETCH_PROFILE_BY_ID_SUCCESS',
  FETCH_PROFILE_BY_ID_FAILURE = '@user/FETCH_PROFILE_BY_ID_FAILURE',

  UPDATE_STUDENT_PROFILE_REQUEST = '@user/UPDATE_STUDENT_PROFILE_REQUEST',
  UPDATE_STUDENT_PROFILE_SUCCESS = '@user/UPDATE_STUDENT_PROFILE_SUCCESS',
  UPDATE_STUDENT_PROFILE_FAILURE = '@user/UPDATE_STUDENT_PROFILE_FAILURE',

  UPDATE_OR_CREATE_PROFILE_REQUEST = '@user/UPDATE_OR_CREATE_PROFILE_REQUEST',
  UPDATE_OR_CREATE_PROFILE_SUCCESS = '@user/UPDATE_OR_CREATE_PROFILE_SUCCESS',
  UPDATE_OR_CREATE_PROFILE_FAILURE = '@user/UPDATE_OR_CREATE_PROFILE_FAILURE',

  LOGOUT_SUCCESS = '@user/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = '@user/LOGOUT_FAILURE',
}

// Data types
interface User {
  id: number;
  name: string;
}

// State type
export interface UserState extends Map<string, any> {
  readonly data?: List<ImmutableMap<User>>;
  readonly profile: ImmutableMap<LetrusApi.UserProfile>;
  readonly roles: any;
  readonly finishedCompositionsCount: any;
  readonly hasLoadedCurrentUser: boolean;
  readonly isLoadingUser: any;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly dataCount: number;
  readonly schoolTheme: string;
  readonly profileInfo?: ImmutableMap<LetrusApi.UserProfile>;
  readonly isUpdatingOrCreatingProfile: boolean;
  readonly updateOrCreateProfileError: boolean;
  readonly isValidatingAccessCode: boolean;
  readonly isValidationAccessCode: boolean;
  readonly isSendingUserOpinionForSurveys: boolean;
  readonly sendUserOpinionForSurveysError: boolean;
  readonly isResetingPassword: boolean;
  readonly resetPasswordError: boolean;
  readonly resetPasswordErrorData: any;
  readonly isLoadingUserProfile: boolean;
  readonly isLoadingUpdateProfile: boolean;
  readonly isLoadingLogout: boolean;
}

export const logoutSuccess = () => action(UserTypes.LOGOUT_SUCCESS);

export const logoutFailure = () => action(UserTypes.LOGOUT_FAILURE);

export const refreshJwtTokenRequest = () =>
  action(UserTypes.REFRESH_JWT_TOKEN_REQUEST);

export const refreshJwtTokenSuccess = (data: LetrusApi.RefreshJSONWebToken) =>
  action(UserTypes.REFRESH_JWT_TOKEN_SUCCESS, {data});

export const refreshJwtTokenFailure = () =>
  action(UserTypes.REFRESH_JWT_TOKEN_FAILURE);

export const updateUserRequest = (userData: any) =>
  action(UserTypes.UPDATE_REQUEST, {userData});

export const updateUserSuccess = (data: any) =>
  action(UserTypes.UPDATE_SUCCESS, {data});

export const updateUserFailure = () => action(UserTypes.UPDATE_FAILURE);

export const updateOrCreateProfileRequest = (formData: any, profileData: any) =>
  action(UserTypes.UPDATE_OR_CREATE_PROFILE_REQUEST, {formData, profileData});

export const updateOrCreateProfileSuccess = ({
  updateUserResponse,
  updateProfileResponse,
  createProfileResponse,
}: any) =>
  action(UserTypes.UPDATE_OR_CREATE_PROFILE_SUCCESS, {
    updateUserResponse,
    updateProfileResponse,
    createProfileResponse,
  });

export const updateOrCreateProfileFailure = () =>
  action(UserTypes.UPDATE_OR_CREATE_PROFILE_FAILURE);

export const updateProfileRequest = (profileData: any) =>
  action(UserTypes.UPDATE_PROFILE_REQUEST, {profileData});

export const updateProfileSuccess = (data: any) =>
  action(UserTypes.UPDATE_PROFILE_SUCCESS, {data});

export const updateProfileFailure = () =>
  action(UserTypes.UPDATE_PROFILE_FAILURE);

export const fetchCurrentUserRequest = () =>
  action(UserTypes.FETCH_CURRENT_USER_REQUEST);

export const fetchCurrentUserSuccess = (data: any) =>
  action(UserTypes.FETCH_CURRENT_USER_SUCCESS, {data});

export const fetchCurrentUserFailure = () =>
  action(UserTypes.FETCH_CURRENT_USER_FAILURE);

export const fetchUserByIdSuccess = (data: any) =>
  action(UserTypes.FETCH_BY_ID_SUCCESS, data);

export const fetchUserByIdFailure = () => action(UserTypes.FETCH_BY_ID_FAILURE);

export const fetchUserByIdRequest = () => action(UserTypes.FETCH_BY_ID_REQUEST);

export const fetchUserProfileSuccess = (data: any) =>
  action(UserTypes.FETCH_PROFILE_SUCCESS, data);

export const fetchUserProfileFailure = () =>
  action(UserTypes.FETCH_PROFILE_FAILURE);

export const fetchUserProfileRequest = () =>
  action(UserTypes.FETCH_PROFILE_REQUEST);

export const updateUserAddressRequest = () =>
  action(UserTypes.UPDATE_ADDRESS_REQUEST);

export const updateUserAddressSuccess = (data: any) =>
  action(UserTypes.UPDATE_ADDRESS_SUCCESS, data);

export const updateUserAddressFailure = () =>
  action(UserTypes.UPDATE_ADDRESS_FAILURE);

export const createProfileSuccess = (data: any) =>
  action(UserTypes.CREATE_PROFILE_SUCCESS, data);

export const createProfileFailure = () =>
  action(UserTypes.CREATE_PROFILE_FAILURE);

export const createProfileRequest = () =>
  action(UserTypes.CREATE_PROFILE_REQUEST);

export const updateStudentParentRequest = (studentParentData: any) =>
  action(UserTypes.UPDATE_STUDENT_PARENT_REQUEST, {studentParentData});

export const updateStudentParentSuccess = () =>
  action(UserTypes.UPDATE_STUDENT_PARENT_SUCCESS);

export const updateStudentParentFailure = () =>
  action(UserTypes.UPDATE_STUDENT_PARENT_FAILURE);

export const createStudentParentRequest = (studentParentData: any) =>
  action(UserTypes.CREATE_STUDENT_PARENT_REQUEST, {studentParentData});

export const createStudentParentSuccess = () =>
  action(UserTypes.CREATE_STUDENT_PARENT_SUCCESS);

export const createStudentParentFailure = () =>
  action(UserTypes.CREATE_STUDENT_PARENT_FAILURE);

export const pickBasicPlanSuccess = (data: any) =>
  action(UserTypes.PICK_BASIC_PLAN_SUCCESS, data);

export const pickBasicPlanFailure = () =>
  action(UserTypes.PICK_BASIC_PLAN_FAILURE);

export const pickBasicPlanRequest = () =>
  action(UserTypes.PICK_BASIC_PLAN_REQUEST);

export const runCheckoutSuccess = (data: any) =>
  action(UserTypes.RUN_CHECKOUT_SUCCESS, data);

export const runCheckoutFailure = () => action(UserTypes.RUN_CHECKOUT_FAILURE);

export const runCheckoutRequest = () => action(UserTypes.RUN_CHECKOUT_REQUEST);

export const resetPasswordRequest = (
  userId: number | string,
  passwords: {
    current_password: string;
    new_password: string;
    new_password_re?: string;
  },
) => action(UserTypes.RESET_PASSWORD_REQUEST, {userId, passwords});

export const resetPasswordSuccess = (data: any) =>
  action(UserTypes.RESET_PASSWORD_SUCCESS, data);

export const resetPasswordFailure = (errorData: any) =>
  action(UserTypes.RESET_PASSWORD_FAILURE, errorData);

export const updateUserInformationSuccess = (data: any) =>
  action(UserTypes.UPDATE_INFORMATION_SUCCESS, data);

export const updateUserInformationFailure = () =>
  action(UserTypes.UPDATE_INFORMATION_FAILURE);

export const updateUserInformationRequest = () =>
  action(UserTypes.UPDATE_INFORMATION_REQUEST);

export const sendEmailInterestedUserSuccess = (data: any) =>
  action(UserTypes.SEND_EMAIL_INTERESTED_USER_SUCCESS, data);

export const sendEmailInterestedUserFailure = () =>
  action(UserTypes.SEND_EMAIL_INTERESTED_USER_FAILURE);

export const sendEmailInterestedUserRequest = (data: string) =>
  action(UserTypes.SEND_EMAIL_INTERESTED_USER_REQUEST, {data});

export const fetchUserProfileByUserIdSuccess = (data: {
  success: LetrusApi.UserProfile;
}) => action(UserTypes.FETCH_PROFILE_BY_ID_SUCCESS, data);

export const fetchUserProfileByUserIdFailure = () =>
  action(UserTypes.FETCH_PROFILE_BY_ID_FAILURE);

export const fetchUserProfileByUserIdRequest = (userId: number) =>
  action(UserTypes.FETCH_PROFILE_BY_ID_REQUEST, {userId});

export const sendUserOpinionForSurveysRequest = (data: any) =>
  action(UserTypes.SEND_USER_OPINION_FOR_SURVEYS_REQUEST, {data});

export const sendUserOpinionForSurveysSuccess = (data: any) =>
  action(UserTypes.SEND_USER_OPINION_FOR_SURVEYS_SUCCESS, {data});

export const sendUserOpinionForSurveysFailure = () =>
  action(UserTypes.SEND_USER_OPINION_FOR_SURVEYS_FAILURE);

export const updateStudentProfileSuccess = (data: any) =>
  action(UserTypes.UPDATE_STUDENT_PROFILE_SUCCESS, data);

export const updateStudentProfileFailure = () =>
  action(UserTypes.UPDATE_STUDENT_PROFILE_FAILURE);

export const updateStudentProfileRequest = (data: any) =>
  action(UserTypes.UPDATE_STUDENT_PROFILE_REQUEST, data);

export function* refreshJwtToken(): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(refreshJwtTokenService);

    yield put(refreshJwtTokenSuccess(response.data));
  } catch (err) {
    yield put(refreshJwtTokenFailure());
  }
}

export function* updateUser(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(updateUserService, action.payload.userData);
    yield put(updateUserSuccess(response.data));
  } catch (err) {
    yield put(updateUserFailure());
  }
}

export function* updateOrCreateProfile(action: AnyAction) {
  try {
    const {data: updateUserResponse} = yield call(
      updateUserService,
      action.payload.formData,
    );

    if (action.payload.formData.profile_id) {
      const {data: updateProfileResponse} = yield call(
        updateProfileService,
        action.payload.profileData,
      );

      yield put(
        updateOrCreateProfileSuccess({
          updateUserResponse,
          updateProfileResponse,
          createProfileResponse: null,
        }),
      );
    } else {
      const {data: createProfileResponse} = yield call(
        createProfileService,
        action.payload.profileData,
      );

      yield put(
        updateOrCreateProfileSuccess({
          updateUserResponse,
          updateProfileResponse: null,
          createProfileResponse,
        }),
      );
    }
  } catch (err) {
    yield put(updateOrCreateProfileFailure());
  }
}

export function* fetchCurrentUser(): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(fetchCurrentUserService);

    // analytics.identify(response.data.user);

    if (
      response.data.is_authenticated &&
      response.data.user.id === localStorage.getItem('csrftoken')
    ) {
      yield put(refreshJwtTokenRequest());
    }

    yield put(fetchCurrentUserSuccess(response.data));
  } catch (err) {
    yield put(fetchCurrentUserFailure());
  }
}

export function* fetchUserById(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(fetchUserByIdService, action.payload);
    yield put(fetchUserByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchUserByIdFailure());
  }
}

export function* fetchUserProfile(): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(fetchUserProfileService);

    yield put(fetchUserProfileSuccess(response.data));
  } catch (err) {
    yield put(fetchUserProfileFailure());
  }
}

export function* updateProfile(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      updateProfileService,
      action.payload.profileData,
    );
    yield put(updateProfileSuccess(response.data));
  } catch (err) {
    yield put(updateProfileFailure());
  }
}

export function* updateUserAddress(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(updateUserAddressService, action.payload.data);
    yield put(updateUserAddressSuccess(response.data));
  } catch (err) {
    yield put(updateUserAddressFailure());
  }
}

export function* createProfile(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(createProfileService, action.payload);
    yield put(createProfileSuccess(response.data));
  } catch (err) {
    yield put(createProfileFailure());
  }
}

export function* updateStudentParent(action: AnyAction) {
  try {
    yield call(updateStudentParentService, action.payload.studentParentData);
    yield put(updateStudentParentSuccess());
  } catch (err) {
    yield put(updateStudentParentFailure());
  }
}

export function* createStudentParent(action: AnyAction) {
  try {
    yield call(createStudentParentService, action.payload.studentParentData);
    yield put(createStudentParentSuccess());
  } catch (err) {
    yield put(createStudentParentFailure());
  }
}

export function* pickBasicPlan(): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(pickBasicPlanService);
    yield put(pickBasicPlanSuccess(response.data));
  } catch (err) {
    yield put(pickBasicPlanFailure());
  }
}

export function* runCheckout(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(runCheckoutService, action.payload);
    yield put(runCheckoutSuccess(response.data));
  } catch (err) {
    yield put(runCheckoutFailure());
  }
}

export function* resetPassword(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      resetPasswordService,
      action.payload.userId,
      action.payload.passwords,
    );
    yield put(resetPasswordSuccess(response.data));
  } catch (err) {
    yield put(resetPasswordFailure(err.response.data));
  }
}

export function* updateUserInformation(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(updateUserInformationService, action.payload);
    yield put(updateUserInformationSuccess(response.data));
  } catch (err) {
    yield put(updateUserInformationFailure());
  }
}

export function* sendEmailInterestedUser(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(sendEmailInterestedUserService, action.payload);
    yield put(sendEmailInterestedUserSuccess(response.data));
  } catch (err) {
    yield put(sendEmailInterestedUserFailure());
  }
}

export function* sendUserOpinionForSurveys(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      sendUserOpinionForSurveysService,
      action.payload.data,
    );
    yield put(sendUserOpinionForSurveysSuccess(response.data));
  } catch (err) {
    yield put(sendUserOpinionForSurveysFailure());
  }
}

export function* fetchUserProfileByUserId(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      fetchUserProfileByUserIdService,
      action.payload.userId,
    );
    yield put(fetchUserProfileByUserIdSuccess(response.data));
  } catch (err) {
    yield put(fetchUserProfileByUserIdFailure());
  }
}

export function* updateStudentProfile(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(updateStudentProfileService, action.payload);
    yield put(updateStudentProfileSuccess(response.data));
  } catch (err) {
    yield put(updateStudentProfileFailure());
  }
}

// Selectors
const userSelector = (state: ApplicationState) => state.get('user');

export const getUser = createSelector(userSelector, (user: UserState) =>
  user.get('data'),
);

export const getError = createSelector(userSelector, (user): boolean =>
  user.get('error'),
);

export const getUserRoleName = (state: ApplicationState) => {
  const userIsManager = isManager(state);

  const userIsCoordinator = isCoordinator(state);

  const userIsTeacher = isTeacher(state);

  const userIsReviwer = isReviewer(state);

  const userIsStudent = getIsStudent(state);

  return createSelector(userSelector, (user: UserState) => {
    const data = user.get('data');

    if (data.get('is_superuser')) {
      return ROLES.SUPER_USER;
    }

    if (userIsManager) {
      return ROLES.MANAGER;
    }

    if (userIsCoordinator) {
      return ROLES.COORDINATOR;
    }

    if (userIsTeacher) {
      return ROLES.TEACHER;
    }

    if (userIsReviwer) {
      return ROLES.REVIEWER;
    }

    if (userIsStudent) {
      return ROLES.STUDENT;
    }

    return ROLES.ANONYMOUS;
  })(state);
};

export const isCoordinator = createSelector(userSelector, (user: UserState) => {
  const data = user.get('data');

  if (data.get('is_superuser') || user.get('is_staff')) {
    return true;
  }

  return !!user
    .get('roles')
    .filter(
      (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
        userRole.get('role_type') === 'coordinator',
    ).size;
});

export const isManager = createSelector(userSelector, (user: UserState) => {
  const data = user.get('data');

  if (data.get('is_superuser') || data.get('is_staff')) {
    return true;
  }

  return !!user
    .get('roles')
    .filter(
      (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
        userRole.get('role_type') === 'director' ||
        userRole.get('role_type') === 'manager' ||
        userRole.get('role_type') === 'executive',
    ).size;
});

export const isReviewer = createSelector(userSelector, (user: UserState) => {
  const data = user.get('data');

  if (data.get('is_superuser') || data.get('is_staff')) {
    return true;
  }

  return !!user
    .get('roles')
    .filter(
      (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
        userRole.get('role_type') === 'reviewer:oficial' ||
        userRole.get('role_type') === 'reviewer:teste' ||
        userRole.get('role_type') === 'reviewer:interno' ||
        userRole.get('role_type') === 'reviewer',
    ).size;
});

export const getUserState = createSelector(
  userSelector,
  (user: UserState) => user,
);

export const getProfileInfo = createSelector(userSelector, (user: UserState) =>
  user.get('profileInfo'),
);

export const getUserId = createSelector(userSelector, (user) =>
  user.getIn(['data', 'id']),
);

export const isB2B2CStudent = createSelector(
  userSelector,
  (user): boolean =>
    user
      .get('roles')
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('contract_type') === 'B2B2C' &&
          userRole.get('role_type') === 'student',
      ).size > 0,
);

export const isTestReviewer = createSelector(
  userSelector,
  (user: UserState) => {
    const roleTypes = user
      .get('roles')
      .map((role: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
        role.get('role_type'),
      );

    return !!roleTypes
      .map((roleType) => ({
        user: roleType.split(':')[0],
        role: roleType.split(':')[1],
      }))
      .find(
        (roleType) => roleType.user === 'reviewer' && roleType.role === 'teste',
      );
  },
);

export const isB2BStudent = createSelector(
  userSelector,
  (user): boolean =>
    user
      .get('roles')
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('contract_type') === 'B2B' &&
          userRole.get('role_type') === 'student',
      ).size > 0,
);

export const isB2CStudent = createSelector(
  userSelector,
  (user): boolean =>
    user
      .get('roles')
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('contract_type') === 'B2C' &&
          userRole.get('role_type') === 'student',
      ).size > 0,
);

// TO-DO: remove school_is_test type when the LAI is updated
export const isTestSchool = createSelector(userSelector, (user): boolean =>
  user
    .get('roles')
    .some(
      (
        userRole: ImmutableMap<
          LetrusApi.UserRoleEmbed & {school_is_test: boolean}
        >,
      ) => userRole.get('school_is_test') === true,
    ),
);

export const getSchoolCategory = createSelector(userSelector, (user): string =>
  user?.get('roles')?.first()?.get('school_category'),
);

export const getSchoolNetworkId = createSelector(userSelector, (user) =>
  user?.get('roles')?.first()?.get('school_network_id'),
);

export const getUserRoles = createSelector(userSelector, (user: UserState) =>
  user.get('roles'),
);

export const getSchoolTheme = createSelector(userSelector, (user: UserState) =>
  user.get('schoolTheme'),
);

export const getUserProfile = createSelector(userSelector, (user: UserState) =>
  user.get('profile'),
);

export const getReviewFLow = createSelector(userSelector, (user: UserState) => {
  const role = user.get('roles').first();
  return role && role.review_flow ? role.review_flow : 'N';
});

// export const getIsB2B2CStudentForComposition = createSelector(
//   userSelector,
//   (user) => {
//     if (composition && composition.get('isLoading')) {
//       return false;
//     }

//     const currentUserRole = this.getUserRoleForComposition(composition);
//     return currentUserRole && currentUserRole.contract_type === 'B2B2C';
//   },
// );

export const isNormalFlow = createSelector(userSelector, (user: UserState) =>
  user.get('roles')
    ? user
        .get('roles')
        .some(
          (elem: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
            elem.get('school_review_flow') === 'N',
        ) ||
      user
        .get('roles')
        .some((elem: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          elem.get('school_is_allowed_to_edit_tests'),
        )
    : false,
);

export const isLoadingCurrentUser = createSelector(
  userSelector,
  (user: UserState) => user.get('isLoadingUser'),
);

export const hasLoadedCurrentUser = createSelector(
  userSelector,
  (user: UserState) => user.get('hasLoadedCurrentUser'),
);

export const isAllowedToEditTests = createSelector(
  userSelector,
  (user: UserState): boolean =>
    user?.get('roles')?.first()?.get('school_is_allowed_to_edit_tests'),
);

export const isTeacher = createSelector(userSelector, (user): boolean => {
  if (user.get('is_superuser') || user.get('is_staff')) {
    return true;
  }

  return user
    .get('roles')
    .filter(
      (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
        userRole.get('role_type') === 'teacher' ||
        userRole.get('role_type') === 'director' ||
        userRole.get('role_type') === 'manager' ||
        userRole.get('role_type') === 'coordinator',
    ).size;
});

export const getIsSuperuser = createSelector(userSelector, (user): boolean =>
  user.getIn(['data', 'is_superuser']),
);

export const isAdvancedStudent = createSelector(
  userSelector,
  (user): boolean =>
    !!user
      .get('roles')
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('plan_type') &&
          userRole.get('plan_type') === 'student_advanced',
      ).length,
);

export const getUserRoleForComposition = (
  state: ApplicationState,
  composition: ImmutableMap<any>,
): ImmutableMap<LetrusApi.UserRoleEmbed> => {
  const roles = getUserRoles(state);

  return createSelector(userSelector, () => {
    const schoolGroupId =
      composition && composition.get('test')
        ? composition.getIn(['test', 'school_group'])
        : null;

    return schoolGroupId
      ? roles.find(
          (role: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
            role.get('school_group') === schoolGroupId,
        )
      : roles.first();
  })(state);
};

export const isAdvancedStudentForComposition = (
  state: ApplicationState,
  composition: ImmutableMap<any>,
): boolean => {
  const currentUserRole = getUserRoleForComposition(state, composition);

  return createSelector(userSelector, () => {
    if (composition.get('isLoading')) {
      return false;
    }

    if (!currentUserRole) {
      return false;
    }

    return (
      !composition.get('test') &&
      (currentUserRole.get('plan_type') === 'student_intermediary' ||
        currentUserRole.get('plan_type') === 'student_advanced')
    );
  })(state);
};

export const isUpdatingOrCreatingProfile = createSelector(
  userSelector,
  (user: UserState): boolean => user.get('isUpdatingOrCreatingProfile'),
);

export const getUpdateOrCreateProfileError = createSelector(
  userSelector,
  (user: UserState): boolean => user.get('updateOrCreateProfileError'),
);

export const getFinishedCompositionsCount = createSelector(
  userSelector,
  (user: UserState) => user.get('finishedCompositionsCount'),
);

export const getIsStudent = createSelector(
  userSelector,
  (user: UserState): boolean =>
    !!user
      .get('roles')
      .toArray()
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('role_type').indexOf('student') !== -1,
      ).length,
);

export const getFirstStudent = createSelector(
  userSelector,
  (user: UserState) => {
    const filteredRoles = user
      .get('roles')
      .filter(
        (role: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          role.get('role_type') === 'student',
      );

    return filteredRoles.size > 0 ? filteredRoles.first() : null;
  },
);

export const getIsB2B2CStudent = createSelector(
  userSelector,
  (user: UserState): boolean =>
    !!user
      .get('roles')
      .find(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('contract_type') === 'B2B2C' &&
          userRole.get('role_type') === 'student',
      ),
);

export const getIsB2BStudent = createSelector(
  userSelector,
  (user: UserState): boolean =>
    !!user
      .get('roles')
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('contract_type') === 'B2B' &&
          userRole.get('role_type') === 'student',
      ).size,
);

export const getIsB2CStudent = createSelector(
  userSelector,
  (user: UserState): boolean =>
    !!user
      .get('roles')
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('contract_type') === 'B2C' &&
          userRole.get('role_type') === 'student',
      ).size,
);

export const getIsAdvancedStudent = createSelector(
  userSelector,
  (user: UserState): boolean => {
    const isAdvancedStudent = !!user
      .get('roles')
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('plan_type') &&
          userRole.get('plan_type') === 'student_advanced',
      ).size;
    return isAdvancedStudent;
  },
);

export const getIsIntermediaryStudent = createSelector(
  userSelector,
  (user: UserState): boolean => {
    const isIntermediaryStudent = !!user
      .get('roles')
      .filter(
        (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
          userRole.get('plan_type') &&
          userRole.get('plan_type') === 'student_intermediary',
      ).size;
    return isIntermediaryStudent;
  },
);

export const getIsNullPlanType = createSelector(
  userSelector,
  (user: UserState): boolean => {
    return (
      !!user
        .get('roles')
        .find(
          (userRole: ImmutableMap<LetrusApi.UserRoleEmbed>) =>
            userRole.get('contract_type') === 'B2B2C' &&
            userRole.get('role_type') === 'student',
        ) && user.get('roles').every((userRole) => !userRole.get('plan_type'))
    );
  },
);

export const getSendUserOpinionForSurveysError = createSelector(
  userSelector,
  (user: UserState): boolean => user.get('sendUserOpinionForSurveysError'),
);

export const isResetingPassword = createSelector(
  userSelector,
  (user: UserState): boolean => user.get('isResetingPassword'),
);

export const getResetPasswordError = createSelector(
  userSelector,
  (user: UserState): boolean => user.get('resetPasswordError'),
);

export const getResetPasswordErrorData = createSelector(
  userSelector,
  (user: UserState): boolean => user.get('resetPasswordErrorData'),
);

export const isLoadingUserProfile = createSelector(
  userSelector,
  (user: UserState): boolean => user.get('isLoadingUserProfile'),
);

export const isLoadingUpdateProfile = createSelector(
  userSelector,
  (user: UserState): boolean => user.get('isLoadingUpdateProfile'),
);

// Initial state
export const INITIAL_STATE: UserState = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
  dataCount: 0,
  profile: fromJS({}),
  roles: fromJS([]),
  finishedCompositionsCount: 0,
  hasLoadedCurrentUser: false,
  isLoadingUser: false,
  schoolTheme: 'letrus',
  isUpdatingOrCreatingProfile: false,
  updateOrCreateProfileError: false,
  isSendingUserOpinionForSurveys: false,
  sendUserOpinionForSurveysError: false,
  isResetingPassword: false,
  resetPasswordError: false,
  resetPasswordErrorData: {},
  isLoadingUserProfile: false,
  isLoadingUpdateProfile: false,
  isLoadingLogout: false,
});

// Reducer
export const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.LOGOUT_SUCCESS:
      return INITIAL_STATE;

    case UserTypes.LOGOUT_FAILURE:
      return INITIAL_STATE;

    case UserTypes.REFRESH_JWT_TOKEN_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.REFRESH_JWT_TOKEN_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false).set('', ''),
      );

    case UserTypes.REFRESH_JWT_TOKEN_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.UPDATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.UPDATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.UPDATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.UPDATE_OR_CREATE_PROFILE_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isUpdatingOrCreatingProfile', true)
          .set('updateOrCreateProfileError', false),
      );

    case UserTypes.UPDATE_OR_CREATE_PROFILE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isUpdatingOrCreatingProfile', false)
          .set('updateOrCreateProfileError', false)
          .set('data', fromJS(action.payload.updateUserResponse))
          .set('profile', fromJS(action.payload.updateProfileResponse)),
      );

    case UserTypes.UPDATE_OR_CREATE_PROFILE_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isUpdatingOrCreatingProfile', false)
          .set('updateOrCreateProfileError', true),
      );

    case UserTypes.FETCH_CURRENT_USER_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUser', true).set('error', false),
      );

    case UserTypes.FETCH_CURRENT_USER_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('data', fromJS(action.payload.data.user))
          .set('profile', fromJS(action.payload.data.user.user_profile))
          .set('roles', fromJS(action.payload.data.user_roles))
          .set(
            'finishedCompositionsCount',
            fromJS(action.payload.data.finished_compositions_count),
          )
          .set('hasLoadedCurrentUser', true)
          .set('isLoadingUser', false)
          .set(
            'schoolTheme',
            action.payload.data.user_roles[0].school_theme &&
              action.payload.data.user_roles[0].school_theme.includes('sae')
              ? 'sae'
              : 'letrus',
          ),
      );

    case UserTypes.FETCH_CURRENT_USER_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUser', false).set('error', true),
      );

    case UserTypes.FETCH_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.FETCH_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.FETCH_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.FETCH_PROFILE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUserProfile', true).set('error', false),
      );

    case UserTypes.FETCH_PROFILE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingUserProfile', false)
          .set('error', false)
          .set('profile', fromJS(action.payload.results[0])),
      );

    case UserTypes.FETCH_PROFILE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUserProfile', false).set('error', true),
      );

    case UserTypes.UPDATE_PROFILE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUpdateProfile', true).set('error', false),
      );

    case UserTypes.UPDATE_PROFILE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingUpdateProfile', false)
          .set('error', false)
          .set('profile', fromJS(action.payload.data)),
      );

    case UserTypes.UPDATE_PROFILE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingUpdateProfile', false).set('error', true),
      );

    case UserTypes.UPDATE_ADDRESS_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.UPDATE_ADDRESS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.UPDATE_ADDRESS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.CREATE_PROFILE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.CREATE_PROFILE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.CREATE_PROFILE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.UPDATE_STUDENT_PARENT_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.UPDATE_STUDENT_PARENT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.UPDATE_STUDENT_PARENT_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.CREATE_STUDENT_PARENT_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.CREATE_STUDENT_PARENT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.CREATE_STUDENT_PARENT_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.PICK_BASIC_PLAN_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.PICK_BASIC_PLAN_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.PICK_BASIC_PLAN_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.RUN_CHECKOUT_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.RUN_CHECKOUT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.RUN_CHECKOUT_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.RESET_PASSWORD_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isResetingPassword', true)
          .set('resetPasswordError', false),
      );

    case UserTypes.RESET_PASSWORD_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isResetingPassword', false)
          .set('resetPasswordError', false),
      );

    case UserTypes.RESET_PASSWORD_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isResetingPassword', false)
          .set('resetPasswordError', true)
          .set('resetPasswordErrorData', action.payload),
      );

    case UserTypes.UPDATE_INFORMATION_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.UPDATE_INFORMATION_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.UPDATE_INFORMATION_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.SEND_EMAIL_INTERESTED_USER_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.SEND_EMAIL_INTERESTED_USER_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.SEND_EMAIL_INTERESTED_USER_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.SEND_USER_OPINION_FOR_SURVEYS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isSendingUserOpinionForSurveys', true)
          .set('sendUserOpinionForSurveysError', false),
      );

    case UserTypes.SEND_USER_OPINION_FOR_SURVEYS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isSendingUserOpinionForSurveys', false)
          .set('sendUserOpinionForSurveysError', false),
      );

    case UserTypes.SEND_USER_OPINION_FOR_SURVEYS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isSendingUserOpinionForSurveys', false)
          .set('sendUserOpinionForSurveysError', true),
      );

    case UserTypes.FETCH_PROFILE_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.FETCH_PROFILE_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('profileInfo', fromJS(action.payload.success)),
      );

    case UserTypes.FETCH_PROFILE_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case UserTypes.UPDATE_STUDENT_PROFILE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case UserTypes.UPDATE_STUDENT_PROFILE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case UserTypes.UPDATE_STUDENT_PROFILE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
