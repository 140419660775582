import PedagogicalContentStep from 'components/PedagogicalContentStep';
import MultigenreModal from '../MultigenreModal';

interface PedagogicalContentModalProps {
  onClickClose(): void;
  title: string;
  content: string;
}

function PedagogicalContentModal({
  onClickClose,
  title,
  content,
}: PedagogicalContentModalProps): JSX.Element {
  return (
    <MultigenreModal
      onClickClose={onClickClose}
      title="Leia abaixo o conteúdo selecionado na etapa de leitura para expandir o repertório dos estudantes sobre o tema:"
    >
      <PedagogicalContentStep title={title} content={content} />
    </MultigenreModal>
  );
}

export default PedagogicalContentModal;
