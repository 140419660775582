import {AxiosResponse} from 'axios';
import {PaginatedRequest} from 'utils/types/store';
import api from './api';

export interface ReviewGrid {
  id: number;
  name: string;
  external_name: string;
}

export const fetchReviewGridsService = (): Promise<
  AxiosResponse<PaginatedRequest<ReviewGrid[]>>
> => api(`/api/v1/review_grids/`);
