import {useState} from 'react';
import {FieldValues, useForm, UseFormProps} from 'react-hook-form';

/**
 * A custom hook that provides navigation through a form that
 * has to be passed step by step.
 *
 * @return {Object} An object containing the following properties:
 *   - form: The form object from the `useForm` hook.
 *   - currentStep: The current step of the form.
 *   - goForward: A function to goForward to the next step.
 *   - goBack: A function to go back to the previous step.
 *   - isAdvanceDisabled: A boolean indicating whether the goForward is disabled.
 */
function useStepForm<FormType extends FieldValues>(
  formParams?: UseFormProps<FormType>,
) {
  const [currentStep, setCurrentStep] = useState(0);

  const form = useForm<FormType>(formParams);
  const {dirtyFields, isValid} = form.formState;

  function goForward() {
    setCurrentStep(currentStep + 1);
  }

  function goBack() {
    setCurrentStep(Math.max(currentStep - 1, 0));
  }

  function goToFirstStep() {
    setCurrentStep(0);
  }

  function resetStepForm() {
    form.reset();
    setCurrentStep(0);
  }

  const totalOfAnswers = Object.values(dirtyFields).length;
  const hasCurrentStepBeenAnswered = totalOfAnswers > currentStep;
  const isGoForwardDisabled = !(hasCurrentStepBeenAnswered && isValid);

  return {
    ...form,
    goBack,
    goForward,
    currentStep,
    goToFirstStep,
    isGoForwardDisabled,
    reset: resetStepForm,
  };
}

export default useStepForm;
