import {
  Questionnaire,
  QuestionnaireStep,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import QuestionButton from 'containers/MultigenreReport/ReadingIndicatorsReport/QuestionButton';
import useReadingIndicator, {
  QuestionOptionExtended,
} from 'features/useReadingIndicator';
import {BasicModal, Button} from 'letrus-ui';
import {useEffect, useState} from 'react';
import styles from './SelectQuestionModal.module.scss';

export interface SelectQuestionModalProps {
  questionsIndicators: Questionnaire;
  questionSelected?: QuestionnaireStep;
  onClickClose(): void;
  onClickSelectQuestion(question?: QuestionnaireStep): void;
}

function SelectQuestionModal({
  questionsIndicators,
  questionSelected,
  onClickClose,
  onClickSelectQuestion,
}: SelectQuestionModalProps): JSX.Element {
  const [activeQuestion, setActiveQuestion] =
    useState<QuestionnaireStep | undefined>();
  const {
    readingRelatedQuestions,
    nonReadingRelatedQuestions,
    getCorrectChoiceOption,
  } = useReadingIndicator({
    questionsIndicators,
  });

  useEffect(() => {
    if (readingRelatedQuestions?.length)
      setActiveQuestion(questionSelected || readingRelatedQuestions[0]);
  }, [questionSelected]);

  return (
    <BasicModal
      onClose={onClickClose}
      size="large"
      open
      closeButton={<></>}
      title="Escolha uma das questões abaixo para visualizar o Relatório de Leitura de seus estudantes:"
      footerButtons={
        <div className={styles.buttonsModal}>
          <Button variant="secondary" text="Voltar" onClick={onClickClose} />
          <Button
            variant="primary"
            text="Selecionar Questão"
            backgroundColor="#3c405f"
            borderColor="#3c405f"
            textColor="#ffffff"
            onClick={() => onClickSelectQuestion(activeQuestion)}
          />
        </div>
      }
      footerButtonsOrientation="flex-end"
    >
      <main className={styles.wrapper}>
        <aside className={styles.buttonContainer}>
          <div className={styles.buttonWrapper}>
            {readingRelatedQuestions?.map((question) => (
              <QuestionButton
                key={question.id}
                order={question.order.toString()}
                title={question.question.name}
                subtitle={question.question.text.replace(/<\/?[^>]+(>|$)/g, '')}
                onClick={() => setActiveQuestion(question)}
                active={question.id === activeQuestion?.id}
              />
            ))}
            {!!nonReadingRelatedQuestions?.length && (
              <>
                <span className={styles.emptyMessage}>
                  Questões da atividade não relacionadas a indicadores de
                  Leitura:
                </span>
                {nonReadingRelatedQuestions?.map((question) => (
                  <QuestionButton
                    key={question.id}
                    order={`${question.order.toString()}`}
                    title={question.question.name}
                    subtitle={question.question.text.replace(
                      /<\/?[^>]+(>|$)/g,
                      '',
                    )}
                    disabled
                  />
                ))}
              </>
            )}
          </div>
        </aside>
        <article className={styles.cardContainer}>
          <div className={styles.card}>
            <h3 className={styles.title}>{activeQuestion?.question?.name}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: activeQuestion?.question?.text || '',
              }}
            />
            {activeQuestion?.question?.choices?.map((choice) => (
              <p key={choice?.id}>{choice?.name}</p>
            ))}
            {activeQuestion?.question.has_correct_answer && (
              <p className={styles.paragraph}>
                <strong>
                  Resposta correta:{' '}
                  {getCorrectChoiceOption(
                    activeQuestion?.question
                      ?.choices as QuestionOptionExtended[],
                  )}
                </strong>
              </p>
            )}
            <p className={styles.paragraph}>
              {activeQuestion?.question?.correct_feedback}
            </p>
          </div>
        </article>
      </main>
    </BasicModal>
  );
}

export default SelectQuestionModal;
