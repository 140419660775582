export function injectHandTalkScript(): void {
  const sdkScript = document.createElement('script');

  sdkScript.src = 'https://plugin.handtalk.me/web/latest/handtalk.min.js';
  sdkScript.onload = () => {
    const authScript = document.createElement('script');
    authScript.innerHTML = `
      const ht = new HT({
        token: 'ac343c3038d60509d6e0b64f98b96686',
        pageSpeech: true,
        opacity: 50,
        avatar: 'MAYA',
      });
    `;

    document.body.appendChild(authScript);
  };

  document.body.appendChild(sdkScript);
}
