interface LoadWidgetParams {
  email: string;
  token: string;
}

function loadWidget({email, token}: LoadWidgetParams): void {
  window.hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: token,
  };
  window.HubSpotConversations.widget.load();
}

export default loadWidget;
