export default function getFullTextFromEditorState(editorState: any) {
  if (!editorState) {
    return [];
  }

  const blockMap = editorState.getCurrentContent().getBlockMap();

  return blockMap
    .valueSeq()
    .map((block: any) => (block ? block.getText() : ''))
    .toJS();
}
