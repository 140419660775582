import {GetAspectCardStyleReturn} from 'features/useLearningPathReport';

export const bestMarkersStyle: GetAspectCardStyleReturn = {
  borderColor: '#2FAC66',
  iconName: ['fas', 'face-laugh-beam'],
  iconColor: '#2FAC66',
  text: 'Dentro da meta',
};

export const medianMarkersStyle: GetAspectCardStyleReturn = {
  borderColor: '#F3AF31',
  iconName: ['fas', 'meh'],
  iconColor: '#F3AF31',
  text: 'Próximo à meta',
};

export const worstMarkersStyle: GetAspectCardStyleReturn = {
  borderColor: '#E94B56',
  iconName: ['fas', 'frown'],
  iconColor: '#E94B56',
  text: 'Fora da meta',
};
