import {LoadingBox} from 'letrus-ui';

function LoadingSelectQuestion(): JSX.Element {
  return (
    <>
      <LoadingBox width="5.5rem" height="7rem" borderRadius={100} />
      <LoadingBox width="37.5rem" height="1.5rem" borderRadius={25} />
      <LoadingBox width="58.5rem" height="1.5rem" borderRadius={25} />
      <LoadingBox width="10rem" height="2.5rem" borderRadius={100} />
    </>
  );
}

export default LoadingSelectQuestion;
