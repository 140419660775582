import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {
  fetchKnowledgeLearningPathListService,
  KnowledgeLearningPathFilters,
} from 'store/services/knowledgeLearningPathServices';
import {action} from 'typesafe-actions';
import {PaginatedRequest, StoreStatus} from 'utils/types/store';

export interface KnowledgeLearningPath {
  id: number;
  name: string;
  school_id: number;
  school_name: string;
  school_group_id: number;
  school_group_name: string;
}

// Action types
export enum KnowledgeLearningPathsTypes {
  FETCH_KNOWLEDGE_LEARNING_PATH_LIST_REQUEST = '@knowledgeLearningPaths/FETCH_KNOWLEDGE_LEARNING_PATH_LIST_REQUEST',
  FETCH_KNOWLEDGE_LEARNING_PATH_LIST_SUCCESS = '@knowledgeLearningPaths/FETCH_KNOWLEDGE_LEARNING_PATH_LIST_SUCCESS',
  FETCH_KNOWLEDGE_LEARNING_PATH_LIST_FAILURE = '@knowledgeLearningPaths/FETCH_KNOWLEDGE_LEARNING_PATH_LIST_FAILURE',
}

// State type
export interface KnowledgeLearningPathsState {
  readonly knowledgeLearningPathList?: KnowledgeLearningPath[];
  readonly knowledgeLearningPathListRequestStatus: StoreStatus;
}

// Fetch actions
export const fetchKnowledgeLearningPathListRequest = (
  params?: Partial<Record<KnowledgeLearningPathFilters, any>>,
) =>
  action(
    KnowledgeLearningPathsTypes.FETCH_KNOWLEDGE_LEARNING_PATH_LIST_REQUEST,
    params,
  );

export const fetchKnowledgeLearningPathListSuccess = (
  data: PaginatedRequest<KnowledgeLearningPath[]>,
) =>
  action(
    KnowledgeLearningPathsTypes.FETCH_KNOWLEDGE_LEARNING_PATH_LIST_SUCCESS,
    data,
  );

export const fetchKnowledgeLearningPathListFailure = () =>
  action(
    KnowledgeLearningPathsTypes.FETCH_KNOWLEDGE_LEARNING_PATH_LIST_FAILURE,
  );

// Sagas
export function* fetchKnowledgeLearningPathList(action: AnyAction) {
  try {
    const response = yield call(
      fetchKnowledgeLearningPathListService,
      action.payload,
    );

    yield put(fetchKnowledgeLearningPathListSuccess(response));
  } catch (err) {
    yield put(fetchKnowledgeLearningPathListFailure());
  }
}

// Selectors
const knowledgeLearningPathsDataSelector = (state) =>
  state.get('knowledgeLearningPaths') as KnowledgeLearningPathsState;

export const getKnowledgeLearningPathList = createSelector(
  knowledgeLearningPathsDataSelector,
  (state) => state.knowledgeLearningPathList,
);

export const getKnowledgeLearningPathListRequesStatus = createSelector(
  knowledgeLearningPathsDataSelector,
  (state) => state.knowledgeLearningPathListRequestStatus,
);

// Initial state
export const INITIAL_STATE: KnowledgeLearningPathsState = {
  knowledgeLearningPathList: undefined,
  knowledgeLearningPathListRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
};

// Reducer
export const reducer: Reducer<KnowledgeLearningPathsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case KnowledgeLearningPathsTypes.FETCH_KNOWLEDGE_LEARNING_PATH_LIST_REQUEST:
      return {
        ...state,
        knowledgeLearningPathListRequestStatus: {
          error: false,
          fulfilled: false,
          loading: true,
          posting: false,
        },
      };

    case KnowledgeLearningPathsTypes.FETCH_KNOWLEDGE_LEARNING_PATH_LIST_SUCCESS:
      return {
        ...state,
        knowledgeLearningPathList: action.payload.data.results,
        knowledgeLearningPathListRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };

    case KnowledgeLearningPathsTypes.FETCH_KNOWLEDGE_LEARNING_PATH_LIST_FAILURE:
      return {
        ...state,
        knowledgeLearningPathListRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
