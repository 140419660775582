export function formatBarChartLabel(label: string, maxwidth: number): string[] {
  const rows: string[] = [];
  const words = label.split(' ');
  let temp = '';

  words.forEach((currentWord, currentWordIndex) => {
    const isLastWord = currentWordIndex === words.length - 1;
    const isCurrentWordShorterThanMaxwidth = currentWord.length < maxwidth;

    if (temp.length > 0) {
      const concatenation = `${temp} ${currentWord}`;
      const isCurrentConcatenationLongerThanMaxwidth =
        concatenation.length > maxwidth;

      if (isCurrentConcatenationLongerThanMaxwidth) {
        rows.push(temp);
        temp = '';
      } else if (isLastWord) {
        rows.push(concatenation);
        return;
      } else {
        temp = concatenation;
        return;
      }
    }

    if (isLastWord) {
      rows.push(currentWord);
      return;
    }

    if (isCurrentWordShorterThanMaxwidth) {
      temp = currentWord;
    } else {
      rows.push(currentWord);
    }
  });

  return rows;
}
