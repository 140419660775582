import ContentTabs from 'components/ContentTabs';
import EmptyWarning from 'components/EmptyWarning';
import HTMLContent from 'components/HTMLContent';
import Loading from 'components/Loading';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {bnccRelatedSkillsMock} from 'store/mocks/__mocks__/learningPaths';
import {
  fetchLearningPathReportRelatedSkillsRequest,
  getLearningPathReportRelatedSkills,
  isLoadingLearningPathReportRelatedSkills,
} from 'store/reducers/learningPaths';
import {ApplicationState} from 'store/rootReducer';
import styles from './BNCCRelatedSkills.module.scss';

interface OwnProps {
  learning_path_instance_id: string;
}

interface DispatchProps {
  fetchLearningPathReportRelatedSkillsRequest: typeof fetchLearningPathReportRelatedSkillsRequest;
}

interface StateProps {
  learningPathReportRelatedSkills: typeof bnccRelatedSkillsMock;
  isLoadingLearningPathReportRelatedSkills: boolean;
}

type BNCCRelatedSkillsProps = DispatchProps & StateProps & OwnProps;

function BNCCRelatedSkills({
  learningPathReportRelatedSkills,
  isLoadingLearningPathReportRelatedSkills,
  fetchLearningPathReportRelatedSkillsRequest,
  learning_path_instance_id,
}: BNCCRelatedSkillsProps): JSX.Element {
  useEffect(() => {
    fetchLearningPathReportRelatedSkillsRequest(learning_path_instance_id);
  }, []);

  const {bncc_skills} = learningPathReportRelatedSkills;

  return isLoadingLearningPathReportRelatedSkills ? (
    <Loading show />
  ) : (
    <section className={styles.wrapper}>
      {bncc_skills?.length ? (
        <article>
          <header className={styles.bnccHeader}>
            <h2>
              Abaixo, encontra-se o conjunto de competências e habilidades da
              Base Nacional Comum Curricular que se relacionam com esta
              atividade:
            </h2>
          </header>
          <ContentTabs
            tabs={bncc_skills?.map((competence) => ({
              title: competence.title,
              content: (
                <div className={styles.tabsContentWrapper}>
                  <HTMLContent html={competence.content_html} />
                </div>
              ),
            }))}
          />
        </article>
      ) : (
        <EmptyWarning subtitle="Não encontramos as habilidades relacionadas a esta atividade!" />
      )}
    </section>
  );
}

export default connect(
  (state: ApplicationState) => ({
    learningPathReportRelatedSkills: getLearningPathReportRelatedSkills(state),
    isLoadingLearningPathReportRelatedSkills:
      isLoadingLearningPathReportRelatedSkills(state),
  }),
  {
    fetchLearningPathReportRelatedSkillsRequest,
  },
)(BNCCRelatedSkills);
