import api from 'store/services/api';

export interface LoginRequest {
  username: string;
  password: string;
  nextRoute?: string;
}

export const loginService = (data: LoginRequest) =>
  api('/api/v1/auth/jwt/', {method: 'post', data});

export const restorePasswordService = (data: {email: string}) =>
  api('/forgot_password/', {method: 'post', data});

export interface AccessRequest {
  token: string;
}

export const accessService = (data: AccessRequest) =>
  api('/api/v1/schoolsnetwork/auth/', {method: 'post', data});
