import useReadingIndicator from 'features/useReadingIndicator';
import {Row} from 'letrus-ui';
import {useState} from 'react';
import {QuestionIndicatorsDetails} from 'utils/types/questionIndicatorsDetails';
import ChoicesModal from '../ChoicesModal';
import {CognitiveAxisTagName} from '../IndicatorsRelatedSlide/IndicatorsCard';
import styles from './AnswerRateSlide.module.scss';
import CorrectAnswerChartInfo from './CorrectAnswerChartInfo';
import IncorrectAnswerChartInfo from './IncorrectAnswerChartInfo';
import IndicatorsModal from './IndicatorsModal';
import TotalAnswersCard from './TotalAnswersCard';

export enum SlideType {
  CORRECT_ANSWER,
  INCORRECT_ANSWER,
}

export interface AnswerRateSlideProps {
  questionIndicatorDetails: QuestionIndicatorsDetails;
  slideType: SlideType;
}

function AnswerRateSlide({
  questionIndicatorDetails,
  slideType,
}: AnswerRateSlideProps): JSX.Element {
  const [isChoicesModalOpen, setIsChoicesModalOpen] = useState(false);
  const [isIndicatorsModalOpen, setIsIndicatorsModalOpen] = useState(false);

  const {getCognitiveAxisList, getIndicatorsList} = useReadingIndicator({});

  const {cognitiveAxisList} = getCognitiveAxisList(
    questionIndicatorDetails.letrus_indicators,
  );

  const {indicatorsList} = getIndicatorsList(
    questionIndicatorDetails.letrus_indicators,
    questionIndicatorDetails.main_external_indicator,
  );

  return (
    <div className={styles.container}>
      <Row padding="0">
        <>
          <div className={styles.titleContainer}>
            <h3 className={styles.cognitiveAxisTitle}>
              Eixo Cognitivo trabalhado:
              {cognitiveAxisList?.map((cognitiveAxis) => (
                <span
                  key={cognitiveAxis?.id}
                  className={`${styles.cognitiveAxisTag} ${
                    cognitiveAxis?.name
                      ? styles[CognitiveAxisTagName[cognitiveAxis?.name]]
                      : ''
                  }`}
                >
                  {cognitiveAxis?.name}
                </span>
              ))}
            </h3>
            <h4
              title={questionIndicatorDetails.text?.replace(
                /<\/?[^>]+(>|$)/g,
                '',
              )}
              className={styles.title}
            >
              {questionIndicatorDetails.name} -{' '}
              {`${questionIndicatorDetails.text?.replace(
                /<\/?[^>]+(>|$)/g,
                '',
              )}`}
            </h4>
            <p>Questão de Múltipla Escolha</p>
          </div>
          <TotalAnswersCard
            totalAnswers={
              questionIndicatorDetails?.rates_detail?.total_engagement
            }
          />
        </>
      </Row>
      {slideType === SlideType.CORRECT_ANSWER && (
        <CorrectAnswerChartInfo
          questionIndicatorDetails={questionIndicatorDetails}
          onClickOpenChoicesModal={() => setIsChoicesModalOpen(true)}
          onClickOpenIndicatorsModal={() => setIsIndicatorsModalOpen(true)}
        />
      )}
      {slideType === SlideType.INCORRECT_ANSWER && (
        <IncorrectAnswerChartInfo
          questionIndicatorDetails={questionIndicatorDetails}
          onClickOpenChoicesModal={() => setIsChoicesModalOpen(true)}
          onClickOpenIndicatorsModal={() => setIsIndicatorsModalOpen(true)}
        />
      )}
      {isChoicesModalOpen && (
        <ChoicesModal
          onClickClose={() => setIsChoicesModalOpen(false)}
          choices={questionIndicatorDetails?.choices}
          questionText={questionIndicatorDetails?.text}
        />
      )}
      {isIndicatorsModalOpen && indicatorsList?.length && (
        <IndicatorsModal
          onClickClose={() => setIsIndicatorsModalOpen(false)}
          indicatorCard={indicatorsList[0]}
        />
      )}
    </div>
  );
}

export default AnswerRateSlide;
