export interface Roles {
  COORDINATOR: 'coordinator';
  MANAGER: 'manager';
  REVIEWER: 'reviewer';
  STUDENT: 'student';
  SUPER_USER: 'super_user';
  TEACHER: 'teacher';
  ANONYMOUS: 'anonymous';
}

export type UserRoleName =
  | 'anonymous'
  | 'teacher'
  | 'super_user'
  | 'student'
  | 'reviewer'
  | 'manager'
  | 'coordinator';

export const ROLES: Roles = {
  COORDINATOR: 'coordinator',
  MANAGER: 'manager',
  REVIEWER: 'reviewer',
  STUDENT: 'student',
  SUPER_USER: 'super_user',
  TEACHER: 'teacher',
  ANONYMOUS: 'anonymous',
};
