import {AxiosResponse} from 'axios';
import api from './api';

export interface CreateStudentParams {
  school_group: string;
  full_name: string;
  email?: string;
  birth: string;
}

export interface CreateStudentsResponse {
  status: string;
  userName: string;
}

export const createStudentsService = (
  data: CreateStudentParams,
): Promise<AxiosResponse<CreateStudentsResponse>> =>
  api('/api/v1/students/', {
    method: 'post',
    data,
  });
