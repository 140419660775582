import {NewButton} from 'letrus-ui';
import styles from './Card.module.scss';

export interface CardProps {
  title: string;
  mainContent: string;
  contentDescription: string;
  onClickButton(): void;
  isDisabled?: boolean;
}

function Card({
  title,
  mainContent,
  contentDescription,
  onClickButton,
  isDisabled,
}: CardProps): JSX.Element {
  return (
    <article className={`${styles.Card} ${isDisabled ? styles.disabled : ''}`}>
      <h2>{title}</h2>
      <div className={styles.content}>
        <b>{mainContent}</b>
        <p>{contentDescription}</p>
      </div>
      {!isDisabled && (
        <NewButton onClick={onClickButton} kind="primary" userRole="student">
          Acessar
        </NewButton>
      )}
    </article>
  );
}

export default Card;
