function orderByKey(key: string) {
  return (a, b): number => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
}

export function orderBy(keys: string[]): () => number {
  let callback: any = () => 0;
  keys.reverse();
  keys.forEach((key) => {
    callback = orderByKey(key);
  });
  return callback;
}
